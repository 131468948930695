<ion-toolbar
  id="reactionCard"
  class="ion-no-border"
  [class.hide]="!view.showReactionsCard"
  [class.small]="!!small"
  [hidden]="!item || item.vip"
>
  <ion-segment class="reactions" [(ngModel)]="view.reaction" scrollable>
    <ion-segment-button
      *ngFor="let emoji of view.emojis"
      (click)="react(emoji)"
      [value]="emoji.name"
      icon-only
    >
      <ion-label>
        <span [innerHTML]="emoji.emoji"></span>
        <small
          *ngIf="item && item.reactions && item.reactions[emoji.name]"
          [innerHTML]="item.reactions[emoji.name].count || 0"
        ></small>
      </ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-toolbar>
