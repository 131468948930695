import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CommentsService } from "src/app/services/social/comments.service";
import { EventsService } from "src/app/services/core/events.service";
import { UserService } from "src/app/services/core/user.service";

@Component({
  selector: 'pipeline-comments-list',
  standalone: false,
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss'],
})
export class CommentsListComponent implements OnInit {

  @Input() cards: any = {};

  @Input('context') context: string;

  @Input() cta: any = {
    icon: 'add-outline',
    label: 'add',
    url: '/integrations',
  };

  @Input('options') options: any = {};
  @Input('uid') itemId: number;
  @Input('type') itemType: string;

  commentConfig: any = {
    selectable: false,
    showContext: false,
    showContextButton: false,
  };

  @Input() selectionOptions: selectionOption[] = [
    {
      icon: 'search-outline',
      label: 'analyze',
      uid: 'analyze',
    },
    /*
    {
      icon: 'text-outline',
      label: 'generate_auto_reply',
      uid: 'generate_auto_reply',
    },
    {
      color: 'warning',
      icon: 'send-outline',
      label: 'auto_reply',
      uid: 'auto_reply',
    },
    */
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'hardware-chip-outline',
      label: 'add_to_training',
      uid: 'add_to_training',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  @Input() service: any;

  user: user;

  view: any = {
    options: {
      limit: 1000,
    },
    showReactionsCard: true,
  };

  @Output() onChanged = new EventEmitter();

  constructor(
    private comments: CommentsService,
    private events: EventsService,
    private userService: UserService,
  ) {
    this.user = this.userService.getUser() || {};
  }

  deleteMessage(message: comment, event: any | null = null) {
  }

  doRefresh() {
    this.loadComments(true)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};

    this.loadComments()
      .catch((error: any) => {
        console.warn('loading comments failed', error);
      });
  }

  loadComments(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      if (!this.itemId || (typeof this.itemId === 'string')) {
        reject('error_missing_item_uid');
      } else {
        try {
          this.view.loadingComments = true;
          let comments: any = [];

          switch (this.itemType) {
            case 'post':
              comments = await this.comments.getByPostUid(this.itemId, this.view.options, blForceRefresh);
              break;
            case 'media':
              comments = await this.comments.getByMediaUid(this.itemId, this.view.options, blForceRefresh);
              break;
            default:
              console.warn('loading comments failed: unsupported item type ', this.itemType);
              break;
          }

          this.view.comments = (!!comments && !!comments.length ? comments : []);
          this.view.loadingComments = false;

          this.onChanged.emit(this.view.comments);

          resolve(this.view.comments);
        } catch (e) {
          this.view.loadingComments = false;
          reject(e);
        }
      }
    });
  }

  onMessageClick(message: comment) {
  }

  sendComment(event: any | null = null, blSubmit: boolean = false) {
    console.log('sendComment: event', event);
    console.log('sendComment: blSubmit', blSubmit);

    if (!this.view.comment_message || !this.view.comment_message.length) {
      return false;
    }

    const comment: comment = {
      description: `${this.view.comment_message || ''}`,
      item: this.itemId,
      item_type: this.itemType,
      parent_type: this.itemType,
      parent_uid: this.itemId,
      platform: 'internal',
      user: this.userService.getUid(),
    };

    this.view.sendingComment = true;

    this.comments.submit(comment)
      .then(() => {
        this.view.comment_message = '';
        this.view.sendingComment = false;

        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.sendingComment = false;
        this.events.publish('error', error);
      });
  }

  thumbnailLoadingFailed(message: any) {
    console.log('thumbnailLoadingFailed', message);
  }

}