<ion-card class="sidebar-accordion-card">

    <ion-card-header>

        <ion-card-title [innerHTML]="'attributes'|translate"></ion-card-title>

        <ion-button *ngIf="!!cards && !!cards.attributes" class="card-toggle" (click)="toggleCard()" icon-only
            color="dark" fill="clear">
            <ion-icon [name]="!!cards && !!cards.attributes && cards.attributes.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <ion-list class="attributes-list" [hidden]="!cards || !cards.attributes || !cards.attributes.open"
        [class.expanded]="view.expandedList === 'attributes'">

        <ion-spinner [hidden]="!view.loadingAttributes"></ion-spinner>

        <pipeline-no-entries-card
            *ngIf="(!view.attributes || (view.attributes && !view.attributes.length)) && !view.loadingAttributes"></pipeline-no-entries-card>

        <div [hidden]="!!view.loadingAttributes" *ngIf="!!view.attributes && !!view.attributes.length">
            <ion-item button *ngFor="let attribute of view.attributes; trackBy:trackItems"
                (click)="openAttribute(attribute)" class="btn-item ion-text-wrap" [class.active]="attribute.checked"
                [hidden]="(attribute.key === 'caption') || (attribute.key === 'mask') || (attribute.key === 'segmentation')">

                <!--<ion-icon [name]="!!attribute.icon" *ngIf="!!attribute.icon"></ion-icon>-->

                <ion-label class="ion-text-wrap" *ngIf="attribute.type === 'range'"
                    [innerHTML]="(attribute.title || attribute.key)|translate"></ion-label>

                <ion-range *ngIf="attribute.type === 'range'" [min]="attribute.min" [max]="attribute.max"
                    [step]="attribute.min > 1 ? 1 : 0.1" snaps="true" pin="true"
                    (ionChange)="onAttributeChanged(attribute, $event)"></ion-range>

                <ion-select
                    *ngIf="(!attribute.type || (attribute.type === 'select')) && !!attribute.value"
                    interface="popover" justify="space-between" alignment="end" labelPlacement="start"
                    [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                    [label]="(attribute.title || attribute.key)|translate" [multiple]="!!attribute.multiple" [placeholder]="'select'|translate"
                    (ionChange)="onAttributeChanged(attribute, $event)">
                    <ion-select-option [value]="null" [innerHTML]="'all'|translate" *ngIf="!attribute.multiple"></ion-select-option>
                    <ion-select-option *ngFor="let option of attribute.value" [value]="option.title"
                        [innerHTML]="option.title|translate"></ion-select-option>
                </ion-select>

                <ion-spinner slot="end" [hidden]="!!attribute.uid"></ion-spinner>

            </ion-item>
        </div>

    </ion-list>

    <ion-fab>
        <ion-fab-button size="small" (click)="expandList('attributes')">
            <ion-icon name="chevron-down"></ion-icon>
        </ion-fab-button>
    </ion-fab>

</ion-card>