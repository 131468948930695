import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-item-tags-card',
  standalone: false,
  templateUrl: './item-tags-card.component.html',
  styleUrls: ['./item-tags-card.component.scss']
})
export class ItemTagsCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() media: mediaItem;
  @Input() view: any;

  @ViewChild('infoPopover') infoPopover: any;

  isInfoPopoverOpen: boolean = false;

  constructor(
    private events: EventsService,
    private mediaService: MediaextendService,
    private sidebar: SidebarService,
  ) {

  }

  doRefresh() {
    this.mediaService.getMediaItemByUid(this.media.uid, true)
      .then((media: mediaItem) => {
        if (!!media && !!media.uid) {
          this.media = media;
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnDestroy() {

  }

  ngOnInit() {
  }

  onTagAdd(event: any) {

    if (!this.media.uid) {
      return false;
    }

    this.mediaService.addTagToMedia(event, this.media.uid)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  onTagClicked(tag: tag) {
    console.log('on tag clicked', tag);
  }

  onTagRemove(event: any) {

    if (!this.media.uid) {
      return false;
    }

    this.mediaService.removeTagFromMedia(event, this.media.uid)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.tags = this.cards.tags || {};
    this.cards.tags.open = !this.cards.tags.open;

    this.sidebar.setCards(this.cards);
  }

}