import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { OauthService } from 'src/app/services/core/oauth.service';

@Injectable({
  providedIn: 'root'
})
export class TelegramService {

  constructor(
    private AppCMS: AppcmsService,
    private oauth: OauthService,
  ) {

  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("telegram", options, ['analyse']);
  }

  authorize() {
    const accessTokenRoute: string = this.AppCMS.getRequestUrl('telegram', ['authorized']),
      resourceUrl: string = this.AppCMS.getRequestUrl('telegram', ['profile']);

    return this.oauth.authorize({
      authorizationBaseUrl: '',
      accessTokenEndpoint: accessTokenRoute,
      default: {
        clientId: '',
        redirectUrl: `${window.location.origin}/integrations/integration/telegram/connections`,
        responseType: '',
      },
      resourceUrl: resourceUrl,
      scope: '',
      state: '',
    });
  }

  createConnection(connection: integrationConnection) {
    return this.AppCMS.loadPluginData('telegram', {
      connection: connection,
    }, ['connections', 'create']);
  }

  delete(connectionId: number) {
    return this.deleteConnection(connectionId);
  }

  deleteBot(botId: number) {
    return this.AppCMS.loadPluginData('telegram', {}, ['bot', botId, 'delete']);
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('telegram', {
      uid: connectionId,
    }, ['connections', 'delete']);
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('telegram', options, ['log'], {}, blForceRefresh);
  }

  getBots(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('telegram', options, ['bots'], {}, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return this.getBots(options, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('telegram', options, ['settings'], {}, blForceRefresh);
  }

  importMedia(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("telegram", options, ['import_media']);
  }

  importPosts(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("telegram", options, ['import_posts']);
  }

}
