import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleextendService {

  clientId: string = '134907058904-dm0au364p7h5g1ltk60k5qkggg6pmvq1.apps.googleusercontent.com';

  constructor(
    private AppCMS: AppcmsService,
    private integrations: IntegrationsService,
    private oauth: OauthService,
    private userService: UserService,
  ) {

  }

  authorize() {

    const accessTokenRoute: string = this.AppCMS.getRequestUrl('googleextend', ['authorized']),
      resourceUrl: string = this.AppCMS.getRequestUrl('googleextend', ['profile']);

    return this.oauth.authorize({
      authorizationBaseUrl: "https://accounts.google.com/o/oauth2/auth",
      accessTokenEndpoint: "https://www.googleapis.com/oauth2/v4/token",
      scope: "email profile",
      resourceUrl: "https://www.googleapis.com/userinfo/v2/me",
      web: {
        appId: this.getClientId(),
        responseType: "token", // implicit flow
        accessTokenEndpoint: "", // clear the tokenEndpoint as we know that implicit flow gets the accessToken from the authorizationRequest
        redirectUrl: `${window.location.origin}/integrations/integration/google/connections`,
        windowOptions: "height=600,left=0,top=0"
      },
      /*
      android: {
        appId: this.getClientId(),
        responseType: "code", // if you configured a android app in google dev console the value must be "code"
        redirectUrl: "com.companyname.appname:/" // package name from google dev console
      },
      ios: {
        appId: this.getClientId(),
        responseType: "code", // if you configured a ios app in google dev console the value must be "code"
        redirectUrl: "com.companyname.appname:/" // Bundle ID from google dev console
      }
      */
    });
  }

  calcState() {
    return Math.random().toString(36).substring(2, 12);
  }

  connect(options: any = {}) {
    return new Promise((resolve, reject) => {

      // first, authorize application
      this.authorize().then((authResponse: any) => {
        let pageToken: string | null = null,
          refreshToken: string | null = null,
          userToken: string | null = null;

        console.log('youtube: authResponse', authResponse);

        if (!!authResponse && !!authResponse.access_token) {
          pageToken = authResponse.access_token;
          userToken = authResponse.access_token;
        }

        if (!!authResponse && !!authResponse.refresh_token) {
          refreshToken = authResponse.refresh_token;
        }

        // then, run connect process
        this.integrations.connect(
          Object.assign(options, authResponse)
        ).then((chooseResponse: chooseResponse) => {

          // if connects selected, add them
          if (!!chooseResponse && !!chooseResponse.data && !!chooseResponse.data.items && !!chooseResponse.data.items.length) {
            chooseResponse.data.items.forEach(async (item: integrationConnection) => {
              try {

                const create: any = await this.createConnection({
                  active: true,
                  name: `${item.name || ''}`,
                  page_id: (item.page_id || (item.id || item.uid)),
                  page_token: `${item.page_token || (pageToken || '')}`,
                  photo: `${item.photo || ''}`,
                  platform: 'youtube',
                  refresh_token: `${item.refresh_token || (refreshToken || '')}`,
                  url: `${item.url || ''}`,
                  user: this.userService.getUid(),
                  user_token: `${item.user_token || (userToken || '')}`,
                });

              } catch (e) {
                console.warn('adding connection failed', e);
              }
            });
          }

          resolve(chooseResponse);
        }).catch(reject);
      }).catch(reject);
    });
  }

  createConnection(connection: integrationConnection) {
    return this.AppCMS.loadPluginData('googleextend', {
      connection: connection,
    }, ['connections', 'create']);
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('googleextend', {}, ['connections', connectionId, 'delete']);
  }

  deletePost(postId: number, platform: string) {
    return this.AppCMS.loadPluginData('googleextend', {
      platform: platform,
      uid: postId,
    }, ['posts', 'delete']);
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('googleextend', options, ['log'], {}, blForceRefresh);
  }

  getClientId() {
    return this.clientId;
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('googleextend', options, ['connections'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('googleextend', options, ['settings'], {}, blForceRefresh);
  }

}