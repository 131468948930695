import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { ConfigService } from "src/app/services/core/config.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ToolsService } from 'src/app/services/utils/tools.service';
import { WindowManagerLoaderService } from './window-manager-loader.service';

@Injectable({
    providedIn: 'root'
})
export class WindowManagerService {

    appConfig: pipelineAppConfig;

    constructor(
        private configService: ConfigService,
        private loader: WindowManagerLoaderService,
        private modalService: ModalService,
        private navCtrl: NavController,
        private tools: ToolsService,
    ) {
        this.appConfig = this.configService.getConfig();
    }

    init() {
        return this.loader.init();
    }

    open(params: windowManagerParams) {
        return new Promise(async (resolve, reject) => {
            try {
                console.log('window-manager: params (a)', params);
                console.log('this.appConfig.useWindowManagerExtension', this.appConfig.useWindowManagerExtension);

                const blIsDesktop: boolean = this.tools.isDesktop();
                console.log('blIsDesktop', blIsDesktop);

                // if window manager is enabled and component is not provided, try loading it using loader first
                if (!!this.appConfig.useWindowManagerExtension && !params.component) {
                    const tool: appPage | null = await this.loader.byRoute(params.route);
                    console.log('tool', tool);

                    if (!!tool && !!tool.component) {
                        params.component = tool.component;
                    }
                }

                console.log('window-manager: params (b)', params);

                // if window manager is disabled or no component provided or current device is mobile, just redirect
                if (!this.appConfig.useWindowManagerExtension || !params.component || !blIsDesktop) {
                    this.navCtrl.navigateForward(params.route);
                    return false;
                }

                // else, handle as modal
                const modal: any = await this.modalService.create({
                    component: params.component,
                    componentProps: params.componentProps,
                });

                this.modalService.present(modal);

                return modal;
            } catch (e) {
                reject(e);
            }
        });
    }

}