<ion-card>

  <ion-card-header>
    <ion-card-title [innerHTML]="'ai_audio_translation'|translate"></ion-card-title>
  </ion-card-header>

  <ion-list>

    <!-- Input language -->
    <pipeline-languages-select icon="language-outline" [label]="'input_language'|translate" [options]="languageOptions"
      [(input)]="view.input_language" (changed)="onLanguageChange($event)"></pipeline-languages-select>

    <!-- Output language -->
    <pipeline-languages-select icon="language-outline" [label]="'output_language'|translate" [options]="languageOptions"
      [(input)]="view.output_language" (changed)="onLanguageChange($event)"></pipeline-languages-select>

    <ion-item [hidden]="!view.voiceover_provider" lines="none">

      <ion-select [(ngModel)]="view.voiceover_voice" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
        [label]="'voiceover_voice'|translate" (ionChange)="voiceoverVoiceChanged()">
        <ion-select-option *ngFor="let voice of view.voiceover_voices" [value]="voice"
          [innerHTML]="voice.name|translate"></ion-select-option>
      </ion-select>

    </ion-item>

  </ion-list>

  <ion-card>

    <ion-card-header>
      <ion-card-title [innerHTML]="'voice_settings'|translate"></ion-card-title>
    </ion-card-header>

    <ion-list>
    </ion-list>
  </ion-card>

</ion-card>