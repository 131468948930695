<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

      <ion-button (click)="store()" fill="clear" color="dark">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'import'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="add($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar [(items)]="view.assistants" [options]="selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Mobile intro card -->
  <div class="container" *ngIf="!view.isDesktop" [hidden]="view.introCard.hidden">
    <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
  </div>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <!-- Main container -->
  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

          <!-- Folders -->
          <pipeline-folders-filter-card [(cards)]="cards" [service]="aiTools" location="assistants" [(view)]="view"
            (onItemsChanged)="onFolderLoaded($event)"
            (onChanged)="loadAssistantsByFolder($event)"></pipeline-folders-filter-card>

          <!-- Providers -->
          <ion-card>

            <ion-card-header>
              <ion-card-title>
                <span [innerHTML]="'ai_assistants_provider_headline'|translate"></span>
              </ion-card-title>
            </ion-card-header>

            <!-- No entries -->
            <pipeline-no-entries-card [cta]="cta" [text]="'ai_assistants_cta_text'|translate"
              *ngIf="view.providers && !view.providers.length && !view.loading"></pipeline-no-entries-card>

            <ion-card-content *ngIf="!!view.providers && !!view.providers.length">

              <ion-chip button (click)="filterProvider(provider)" *ngFor="let provider of view.providers">

                <ion-avatar>
                  <ion-img [src]="provider.photo || fallbackImg"
                    (ionError)="thumbnailLoadingFailed(provider)"></ion-img>
                </ion-avatar>

                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="provider.name|translate" class="ion-text-wrap"></h3>
                </ion-label>

              </ion-chip>

            </ion-card-content>

          </ion-card>

        </ion-col>

        <!-- Content -->
        <ion-col class="right" [size]="view.isDesktop ? 9 : 12">

          <!-- Desktop intro card -->
          <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="view.introCard.hidden" class="introCard"
            [(view)]="view.introCard"></pipeline-intro-card>

          <!-- No entries -->
          <pipeline-no-entries-card
            *ngIf="view.assistants && !view.assistants.length && !view.loading"></pipeline-no-entries-card>

          <!-- Loading entries -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- Entries grid -->
          <ion-grid *ngIf="!!view.assistants && !!view.assistants.length" [hidden]="view.loading">
            <ion-row>

              <!-- Single assistant -->
              <ion-col [size]="view.colSize" *ngFor="let assistant of view.assistants" [hidden]="assistant.hidden">
                <ion-card>

                  <ion-item class="ion-text-wrap" lines="none">

                    <ion-thumbnail slot="start" *ngIf="!!assistant.thumbnail || !!assistant.photo">
                      <ion-img *ngIf="!!assistant.uid" [src]="(assistant.thumbnail || assistant.photo) || fallbackImg"
                        (ionError)="thumbnailLoadingFailed(assistant)"></ion-img>
                      <ion-skeleton-text class="image" *ngIf="!assistant.uid" animated></ion-skeleton-text>
                    </ion-thumbnail>

                    <ion-checkbox class="ion-text-wrap" [(ngModel)]="assistant.checked" justify="start"
                      labelPlacement="end" (ionChange)="onItemChecked(assistant)" [disabled]="!assistant.uid">
                      <p [innerHTML]="assistant.name" class="ion-text-wrap"></p>
                      <small [innerHTML]="assistant.provider" class="ion-text-wrap"></small>
                    </ion-checkbox>

                    <!-- Open -->
                    <ion-button slot="end" fill="clear" icon-only (click)="use(assistant)" color="dark">
                      <ion-icon name="open-outline"></ion-icon>
                    </ion-button>

                    <!-- Edit -->
                    <ion-button slot="end" fill="clear" icon-only (click)="edit(assistant)" color="dark"
                      *ngIf="!!assistant.uid">
                      <ion-icon name="settings-outline"></ion-icon>
                    </ion-button>

                    <!-- Delete -->
                    <ion-button (click)="delete(assistant)" fill="clear" color="danger" icon-only slot="end"
                      *ngIf="!!assistant.uid">
                      <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                  <!-- Spline Viewer -->
                  <pipeline-spline-viewer *ngIf="!!splineOptions && !!view.showSplineView"
                    [options]="splineOptions"></pipeline-spline-viewer>

                </ion-card>
              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>