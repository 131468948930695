import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { UserService } from 'src/app/services/core/user.service';

import { apiUrl } from 'src/config/variables';

@Injectable({
  providedIn: 'root'
})
export class ReplicateService {

  constructor(
    private AppCMS: AppcmsService,
    private integrations: IntegrationsService,
    private oauth: OauthService,
    private userService: UserService,
  ) {

  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("replicate", options, ['analyse']);
  }

  authorize() {
    return new Promise((resolve, reject) => {
      const accessTokenRoute: string = this.AppCMS.getRequestUrl('replicate', ['authorized']),
        resourceUrl: string = this.AppCMS.getRequestUrl('replicate', ['profile']);

      this.oauth.authorize({
        authorizationBaseUrl: 'https://www.replicate.com/api/v1/authorize',
        accessTokenEndpoint: accessTokenRoute, // accessTokenRoute,
        default: {
          clientId: 'e0yAYCdF8sTKXFAUSg_Glw',
          redirectUrl: `${apiUrl}/replicate/authorized.json`,
          responseType: 'code',
        },
        duration: 'permanent',
        resourceUrl: resourceUrl,
        scope: 'identity edit flair history read vote wikiread wikiedit',
        state: this.calcState(),
      })
        .then((response: any) => {
          console.log('response', response);
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  calcState() {
    return Math.random().toString(36).substring(2, 12);
  }

  connect(options: any = {}) {
    return new Promise((resolve, reject) => {

      // first, authorize application
      this.authorize().then((authResponse: any) => {

        // then, run connect process
        this.integrations.connect(
          Object.assign(options, authResponse)
        ).then((chooseResponse: chooseResponse) => {

          // if connects selected, add them
          if (!!chooseResponse && !!chooseResponse.data && !!chooseResponse.data.items && !!chooseResponse.data.items.length) {
            chooseResponse.data.items.forEach(async (item: integrationConnection) => {
              try {

                const create: any = await this.createConnection({
                  active: true,
                  name: `${item.name || ''}`,
                  page_id: parseInt(`${(item.uid || item.organization) || item.id}`.replace('urn:li:organization:', '')),
                  url: `${item.url || ''}`,
                  user: this.userService.getUid(),
                });

              } catch (e) {
                console.warn('adding connection failed', e);
              }
            });
          }

          resolve(chooseResponse);
        }).catch(reject);
      }).catch(reject);
    });
  }

  createConnection(connection: integrationConnection) {
    return this.AppCMS.loadPluginData('replicate', {
      connection: connection,
    }, ['connections', 'create']);
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('replicate', {
      uid: connectionId,
    }, ['connections', 'delete']);
  }
  
  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('replicate', options, ['log'], {}, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false): Promise<any> {
    return this.AppCMS.loadPluginData('replicate', options, ['connections'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('replicate', options, ['settings'], {}, blForceRefresh);
  }

  updateConnection(connection: integrationConnection) {
    connection = JSON.parse(JSON.stringify(connection));

    delete connection.checked;

    return this.AppCMS.loadPluginData('replicate', {
      connection: connection,
    }, ['connections', 'update']);
  }

}