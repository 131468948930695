<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">
      <ion-button [hidden]="view.showBackButton" icon-only (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
      <ion-button [hidden]="!view.showBackButton" icon-only (click)="back()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="view.editMode" [innerHTML]="'edit_ad'|translate"></ion-title>
    <ion-title *ngIf="!view.editMode" [innerHTML]="'create_ad'|translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="col-size-left-{{view.colSizes.left}} col-size-right-{{view.colSizes.right}}" [fullscreen]="true">
  
  <ion-grid>
    <ion-row>
      <ion-col class="ad-manager-grid-col" [size]="view.colSizes.left">
        <swiper-container [options]="sliderOptions" id="adsSlider" #adsSlider>

          <swiper-slide class="ion-padding">
      
            <div class="container">
      
              <ion-card class="header-card">
                <ion-card-header>
                  <ion-card-title [innerHTML]="'create_ad_headline_title'|translate"></ion-card-title>
                  <ion-card-subtitle [innerHTML]="'create_ad_headline_subtitle'|translate"></ion-card-subtitle>
                </ion-card-header>
              </ion-card>
      
              <pipeline-intro-card class="introCard" [(view)]="view.introCards[0]"></pipeline-intro-card>
      
              <ion-grid>
                <ion-row>
                  <ion-col [size]="view.colSizes.adTypes" *ngFor="let adType of view.adTypes">
                    <ion-button fill="clear" expand="block" (click)="useAdType(adType)" [class.activated]="adType.checked">
                      <ion-icon [name]="adType.icon" slot="start"></ion-icon>
                      <ion-label class="ion-text-wrap">
                        <p [innerHTML]="adType.title"></p>
                      </ion-label>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </swiper-slide> 
      

          <swiper-slide class="ion-padding" *ngIf="view.locations && view.locations.length">
      
            <div class="container">
              <ion-card>
                <ion-card-header>
                  <ion-card-title [innerHTML]="'ad_location'|translate"></ion-card-title>
                  <ion-card-subtitle [innerHTML]="'ad_location_description'|translate"></ion-card-subtitle>
                </ion-card-header>
              </ion-card>
        
              <pipeline-intro-card class="introCard" [(view)]="view.introCards[2]"></pipeline-intro-card>
        
              <ion-segment [(ngModel)]="view.adLocationDeviceGroup" (ionChange)="onAdLocationDeviceGroupChanged()" scrollable value="app">
                <ion-segment-button value="app">
                  <ion-icon name="phone-portrait-outline"></ion-icon>
                  <ion-label [innerHTML]="'ad_location_device_group_app'|translate" class="ion-text-wrap"></ion-label>
                </ion-segment-button>
                <ion-segment-button value="tablet">
                  <ion-icon name="tablet-portrait-outline"></ion-icon>
                  <ion-label [innerHTML]="'ad_location_device_group_tablet'|translate" class="ion-text-wrap"></ion-label>
                </ion-segment-button>
                <ion-segment-button value="web_desktop">
                  <ion-icon name="laptop-outline"></ion-icon>
                  <ion-label [innerHTML]="'ad_location_device_group_web_desktop'|translate" class="ion-text-wrap"></ion-label>
                </ion-segment-button>
                <ion-segment-button value="web_mobile">
                  <ion-icon name="phone-portrait-outline"></ion-icon>
                  <ion-label [innerHTML]="'ad_location_device_group_web_mobile'|translate" class="ion-text-wrap"></ion-label>
                </ion-segment-button>
              </ion-segment>

              <ion-grid>
                <ion-row>
                  <ion-col [size]="view.colSizes.locations" *ngFor="let location of view.locations" [hidden]="location.hidden">
                    
                    <ion-button expand="block" fill="clear" class="location" [class.activated]="location.checked" (click)="useAdLocation(location)">
                      <ion-thumbnail slot="start" *ngIf="location.image">
                        <ion-img [src]="location.image"></ion-img>
                      </ion-thumbnail>
                      <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="location.name|translate"></h3>
                        <p [innerHTML]="location.description|translate"></p>
                      </ion-label>
                    </ion-button>

                  </ion-col>
                </ion-row>
        
              </ion-grid>
            </div>
      
          </swiper-slide> 

          <swiper-slide class="ion-padding">
      
            <div class="container">
              <ion-card>
                <ion-card-header>
                  <ion-card-title [innerHTML]="'create_ad_general_title'|translate"></ion-card-title>
                  <ion-card-subtitle [innerHTML]="'create_ad_general_subtitle'|translate"></ion-card-subtitle>
                </ion-card-header>
              </ion-card>
      
              <pipeline-intro-card class="introCard" [(view)]="view.introCards[1]"></pipeline-intro-card>
      
              <ion-grid [hidden]="ad.type !== 'text'" class="createOrChooseSetGrid">
                <ion-row>
      
                  <ion-col size="6">
                    <ion-button fill="clear" expand="block" [fill]="view.createOrChooseSet == 'choose_existing' ? 'solid' : 'outline'"
                      color="primary" (click)="chooseExistingContent()">
                      <ion-icon name="search-outline"></ion-icon>
                      <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'choose_existing'|translate" class="ion-text-wrap"></h3>
                      </ion-label>
                    </ion-button>
                  </ion-col>
      
                  <ion-col size="6">
                    <ion-button fill="clear" expand="block" [fill]="view.createOrChooseSet == 'create_new' ? 'solid' : 'outline'"
                      color="primary" (click)="createNewContent()">
                      <ion-icon name="add-outline"></ion-icon>
                      <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'create_new_content'|translate" class="ion-text-wrap"></h3>
                      </ion-label>
                    </ion-button>
                  </ion-col>
      
                </ion-row>
              </ion-grid>
      
              <ion-card id="generalCard" [hidden]="!view.createOrChooseSet">
      
                <ion-list>
      
                  <!-- label input for some ad types -->
                  <ion-item lines="none" [hidden]="(view.createOrChooseSet == 'create_new')" *ngIf="ad.type === 'code'">
                    <ion-label position="stacked" [innerHTML]="'label'|translate"></ion-label>
                    <ion-input name="text" type="text" [(ngModel)]="ad.label" [placeholder]="'ad_label'|translate"></ion-input>
                  </ion-item>

                  <!-- default input -->
                  <ion-item lines="none" [hidden]="(view.createOrChooseSet == 'create_new')" *ngIf="ad.type !== 'code'">
                    <ion-label position="stacked" [innerHTML]="ad.type|translate"></ion-label>
                    <ion-input name="search" type="search" [(ngModel)]="search.query" (ionInput)="runSearch()"
                      [placeholder]="'search_something'|translate"></ion-input>
                  </ion-item>

                  <!-- textarea for html code input -->
                  <ion-item lines="none" [hidden]="(view.createOrChooseSet == 'create_new')" *ngIf="ad.type === 'code'">
                    <ion-label position="stacked" [innerHTML]="ad.type|translate"></ion-label>
                    <ion-textarea type="text" rows="6" [(ngModel)]="ad.value" (ionChange)="onCodePasted()" [placeholder]="'code_paste_placeholder'|translate"></ion-textarea>
                  </ion-item>

                </ion-list>
      
                <ion-spinner *ngIf="!!view.searchRequestInProgress"></ion-spinner>

                <ion-list [lines]="search.results && search.results.length > 1 ? 'true' : 'none'"
                  [hidden]="!!view.searchRequestInProgress || view.createOrChooseSet == 'create_new'" *ngIf="search.results">
                  <ion-item color="danger" *ngIf="!search.results.length">
                    <ion-icon name="warning-outline" slot="start"></ion-icon>
                    <ion-label class="ion-text-wrap">
                      <p [innerHTML]="'no_results_found'|translate"></p>
                    </ion-label>
                  </ion-item>

                  <ion-button fill="clear" expand="block" *ngFor="let result of search.results" [class.selected]="result.selected"
                    (click)="onResultClick(result)">
                    <ion-thumbnail slot="start" *ngIf="result.thumbnail || (result.images && result.images[0])">
                      <ion-img [src]="result.thumbnail || result.images[0].src"></ion-img>
                    </ion-thumbnail>
                    <ion-label class="ion-text-wrap">
                      <h4 [innerHTML]="result.name"></h4>
                      <p [innerHTML]="result.price" *ngIf="result.price"></p>
                      <p [innerHTML]="'@' + result.nickname" *ngIf="result.nickname"></p>
                    </ion-label>
                  </ion-button>

                </ion-list>

              </ion-card>

              <ion-card *ngIf="!!view.attachments && !!view.attachments.length">
                <ion-list lines="none">
                  <ion-item *ngFor="let attachment of view.attachments; let i = index;" [color]="attachment.error ? 'danger' : 'solid'">

                    <ion-thumbnail slot="start" [hidden]="!attachment.url">
                      <ion-img [src]="attachment.url" (ionError)="attachmentPreviewFailed(attachment, i)"></ion-img>
                    </ion-thumbnail>

                    <ion-label position="stacked" [innerHTML]="('attachment'|translate) + ' ' + (i+1)"></ion-label>
                    <ion-input type="text" [(ngModel)]="attachment.url" (ionChange)="onAttachmentUrlPasted(attachment, i)" [placeholder]="'attachment_paste_placeholder'|translate"></ion-input>
                  
                    <ion-button slot="end" icon-only fill="clear" color="dark" (click)="uploadAttachment(attachment, i)">
                      <ion-icon name="image-outline"></ion-icon>
                    </ion-button>

                  </ion-item>
                </ion-list>
              </ion-card>

              <pipeline-url-preview [hidden]="!view.createOrChooseSet || (ad.type !== 'website')" *ngIf="!!(view.website && view.website.metaData)" [data]="view.website"></pipeline-url-preview>

              <ion-card [hidden]="!view.createOrChooseSet || !ad.item">
                <ion-list>
                  
                  <ion-item lines="none" [hidden]="(view.createOrChooseSet == 'create_new')">
                    <ion-label position="stacked" [innerHTML]="'display_profile'|translate"></ion-label>
                    <ion-input name="search" type="search" [(ngModel)]="search.displayProfileQuery"
                      (ionChange)="runDisplayProfileSearch()" [placeholder]="'search_display_profile'|translate"></ion-input>
                  </ion-item>

                </ion-list>
      
                <ion-list [lines]="search.displayProfileResults && search.displayProfileResults.length > 1 ? 'true' : 'none'"
                  [hidden]="view.createOrChooseSet == 'create_new'" *ngIf="search.displayProfileResults">
                  <ion-item color="danger" *ngIf="!search.displayProfileResults || !search.displayProfileResults.length">
                    <ion-icon name="warning-outline" slot="start"></ion-icon>
                    <ion-label class="ion-text-wrap">
                      <p [innerHTML]="'no_results_found'|translate"></p>
                    </ion-label>
                  </ion-item>

                  <ion-button fill="clear" expand="block" *ngFor="let result of search.displayProfileResults" [class.selected]="result.selected"
                    (click)="onDisplayProfileResultClick(result)">
                    <ion-thumbnail slot="start" *ngIf="result.thumbnail || (result.images && result.images[0])">
                      <ion-img [src]="result.thumbnail || result.images[0].src"></ion-img>
                    </ion-thumbnail>
                    <ion-label class="ion-text-wrap">
                      <h4 [innerHTML]="result.name"></h4>
                      <p [innerHTML]="result.price" *ngIf="result.price"></p>
                      <p [innerHTML]="'@' + result.nickname" *ngIf="result.nickname"></p>
                    </ion-label>
                  </ion-button>

                </ion-list>
      
              </ion-card>
      
              <ion-card [hidden]="!view.createOrChooseSet">
      
                <ion-list>
      
                  <ion-item>
                    <ion-label position="stacked" [innerHTML]="'ad_name'|translate"></ion-label>
                    <ion-input name="label" type="text" (ionChange)="validate()" [(ngModel)]="ad.label"></ion-input>
                  </ion-item>
      
                  <pipeline-languages-select [(input)]="view.language"></pipeline-languages-select>
      
                </ion-list>
      
              </ion-card>
            </div>
      
          </swiper-slide> 
      
          <swiper-slide class="ion-padding">
      
            <div class="container">
              <ion-card class="header-card">
                <ion-card-header>
                  <ion-card-title [innerHTML]="'create_ad_addons_title'|translate"></ion-card-title>
                  <ion-card-subtitle [innerHTML]="'create_ad_addons_subtitle'|translate"></ion-card-subtitle>
                </ion-card-header>
              </ion-card>
      
              <pipeline-intro-card class="introCard" [(view)]="view.introCards[3]"></pipeline-intro-card>
      
              <ion-grid>
                <ion-row>
                  <ion-col [size]="view.colSizes.addons" *ngFor="let addon of view.adAddons">
                    <ion-button fill="clear" expand="block" (click)="useAddon(addon)" [class.activated]="addon.checked">
                      
                      <ion-icon [name]="addon.icon" slot="start"></ion-icon>

                      <ion-label class="ion-text-wrap">
                        <h4 class="title" [innerHTML]="addon.title"></h4>
                        <small *ngIf="addon.subtitle" [innerHTML]="addon.subtitle"></small>
      
                        <p *ngIf="addon.price" class="price" [innerHTML]="addon.price + '€'"></p>
                      </ion-label>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
      
          </swiper-slide> 
      
          <swiper-slide class="ion-padding">
      
            <div class="container">
              <ion-card>
                <ion-card-header>
                  <ion-card-title [innerHTML]="'create_ad_reach_title'|translate"></ion-card-title>
                  <ion-card-subtitle [innerHTML]="'create_ad_reach_subtitle'|translate"></ion-card-subtitle>
                </ion-card-header>
              </ion-card>
      
              <pipeline-intro-card class="introCard" [(view)]="view.introCards[4]"></pipeline-intro-card>
      
              <ion-grid>

                <ion-row [hidden]="!ad.type || !ad.location || (!ad.value && !ad.item && !ad.url)">

                  <ion-col>

                    <ion-card>
                      <ion-radio-group [(ngModel)]="view.durationStartMode">
                        <ion-item-divider lines="none">
                          <ion-label [innerHTML]="'date_start'|translate"></ion-label>
                        </ion-item-divider>
                        <ion-item lines="none">
                          <ion-radio slot="start" value="instant"></ion-radio>
                          <ion-label [innerHTML]="'date_start_mode_instant'|translate"></ion-label>
                        </ion-item>
                        <ion-item lines="none">
                          <ion-radio slot="start" value="scheduled"></ion-radio>
                          <ion-label [innerHTML]="'date_start_mode_scheduled'|translate"></ion-label>
                        </ion-item>
                        <ion-item lines="none" [hidden]="view.durationStartMode !== 'scheduled'">
                          <ion-label position="stacked" [innerHTML]="'date'|translate"></ion-label>
                          <ion-datetime name="time_start" presentation="date-time" [doneText]="'done'|translate"
                            [cancelText]="'cancel'|translate" (ionChange)="onChanged()" [(ngModel)]="ad.date_start"
                            [min]="view.date_start_min" locale="de-DE" [max]="view.date_start_max"></ion-datetime>
                        </ion-item>
                      </ion-radio-group>
                    </ion-card>
                  </ion-col>

                  <ion-col>
                    <ion-card>
                      <ion-radio-group [(ngModel)]="view.durationEndMode" [hidden]="!view.durationStartMode">
                        <ion-item-divider lines="none">
                          <ion-label [innerHTML]="'date_end'|translate"></ion-label>
                        </ion-item-divider>
                        <ion-item lines="none">
                          <ion-radio slot="start" value="subscription"></ion-radio>
                          <ion-label [innerHTML]="'date_end_mode_subscription'|translate"></ion-label>
                        </ion-item>
                        <ion-item lines="none">
                          <ion-radio slot="start" value="scheduled"></ion-radio>
                          <ion-label [innerHTML]="'date_end_mode_scheduled'|translate"></ion-label>
                        </ion-item>
                        <ion-item lines="none" [hidden]="view.durationEndMode !== 'scheduled'" lines="none">
                          <ion-label position="stacked" [innerHTML]="'date'|translate"></ion-label>
                          <ion-datetime name="time_end" presentation="date-time" [doneText]="'done'|translate"
                            [cancelText]="'cancel'|translate" (ionChange)="onChanged()" [(ngModel)]="ad.date_end"
                            [min]="view.date_end_min" locale="de-DE" [max]="view.date_end_max"></ion-datetime>
                        </ion-item>
                      </ion-radio-group>
                    </ion-card>
                  </ion-col>

                </ion-row>
                
                <ion-row [hidden]="!ad.type || !ad.location || (!ad.value && !ad.item && !ad.url)">

                  <ion-col [hidden]="!ad.type || !ad.location || (!ad.value && !ad.item && !ad.url)" size="12">

                    <ion-card>

                      <ion-radio-group [(ngModel)]="view.targetGroupMode">

                        <ion-item>
                          <ion-radio slot="start" value="autopilot"></ion-radio>
                          <ion-label class="ion-text-wrap">
                            <h3 [innerHTML]="'target_group_autopilot'|translate"></h3>
                            <p [innerHTML]="'target_group_autopilot_description'|translate"></p>
                          </ion-label>
                        </ion-item>

                        <ion-item [hidden]="(view.targetGroupMode !== 'autopilot') || !!ad.region_uid">
                          <ion-icon name="location-outline" slot="start"></ion-icon>
                          <ion-label [innerHTML]="'ad_region'|translate" position="stacked"></ion-label>
                          <ion-input [(ngModel)]="view.region_query" (ionInput)="lookupRegion()"></ion-input>
                        </ion-item>

                        <!-- show region if set -->
                        <ion-item *ngIf="view.targetGroupMode === 'autopilot' && !!ad.region">
                
                          <ion-label class="ion-text-wrap" [hidden]="ad.region_uid" [innerHTML]="ad.region.city"></ion-label>
                          <ion-label class="ion-text-wrap" [hidden]="!ad.region_uid" [innerHTML]="'region'|translate"></ion-label>
                
                          <ion-note slot="end" [hidden]="!ad.region_uid" [innerHTML]="ad.region.city"></ion-note>
                
                          <ion-button slot="end" icon-only (click)="unsetRegionUid()" fill="clear" color="dark">
                            <ion-icon name="close-outline"></ion-icon>
                          </ion-button>
                
                        </ion-item>
                
                        <!-- list regions results -->
                        <div *ngIf="view.targetGroupMode === 'autopilot' && !!view.cities && (!!view.region_query && (view.region_query.length > 2))">
                          <div *ngFor="let result of view.cities">
                            <ion-button fill="clear" expand="block" (click)="useRegionResult(result)" *ngIf="!result.hidden">
                
                              <ion-label class="ion-text-wrap" [hidden]="ad.region_uid" [innerHTML]="result.city"></ion-label>
                              <ion-label class="ion-text-wrap" [hidden]="!ad.region_uid" [innerHTML]="'region'|translate"></ion-label>
                
                              <ion-note slot="end" [hidden]="!ad.region_uid" [innerHTML]="result.city"></ion-note>
                
                            </ion-button>
                          </div>
                        </div>

                        <ion-item>
                          <ion-radio slot="start" value="strict"></ion-radio>
                          <ion-label class="ion-text-wrap">
                            <h3 [innerHTML]="'target_group_strict'|translate"></h3>
                            <p [innerHTML]="'target_group_strict_description'|translate"></p>
                          </ion-label>
                        </ion-item>

                        <ion-item [hidden]="view.targetGroupMode !== 'strict'" color="warning" *ngIf="!view.target_groups || !view.target_groups.length">
                          <ion-label class="ion-text-wrap">
                            <h4 [innerHTML]="'no_target_group_profiles_message'|translate"></h4>
                          </ion-label>
                        </ion-item>

                        <div [hidden]="view.targetGroupMode !== 'strict'" *ngIf="ad.target_groups">
                          <ion-item *ngFor="let group of view.target_groups">
                            <ion-checkbox [(ngModel)]="ad.target_groups[group.uid]" [value]="group.uid" slot="start"></ion-checkbox>
                            <ion-label [innerHTML]="group.label"></ion-label>
                          </ion-item>
                        </div>

                        <ion-button [hidden]="view.targetGroupMode !== 'strict'" fill="clear" size="small" class="configureTargetGroup" (click)="configureTargetGroup()">
                          <ion-icon name="settings-outline" slot="start"></ion-icon>
                          <ion-label [innerHTML]="'configure'|translate"></ion-label>
                        </ion-button>

                      </ion-radio-group>
                      
                    </ion-card>
                  </ion-col>

                  <ion-col [hidden]="!ad.type || !ad.location || (!ad.value && !ad.item && !ad.url) || !ad.date_start || !ad.date_end">
                    <ion-card>
                      <ion-list>
                        <ion-list-header [innerHTML]="'reach'|translate"></ion-list-header>

                        <ion-item lines="none">
                          <ion-label position="stacked" [innerHTML]="'budget_per_day'|translate"></ion-label>
                          <ion-input type="tel" (ionChange)="onChanged(true)" [(ngModel)]="ad.budgetPerDay"></ion-input>
                        </ion-item>

                        <ion-button fill="clear" size="small" class="configureBudget" (click)="configureBudget()">
                          <ion-icon name="settings-outline" slot="start"></ion-icon>
                          <ion-label [innerHTML]="'configure'|translate"></ion-label>
                        </ion-button>

                      </ion-list>
                    </ion-card>
                  </ion-col>

                  <ion-col *ngIf="ad.stats" [hidden]="!ad.type || !ad.location || (!ad.value && !ad.item && !ad.url) || !ad.date_start || !ad.date_end">
                    <ion-card>
                      <ion-list>
                        <ion-list-header [innerHTML]="'summary'|translate"></ion-list-header>
                        <ion-item>
                          <ion-icon name="eye" slot="start"></ion-icon>
                          <ion-label [innerHTML]="'views_per_day'|translate"></ion-label>
                          <ion-note slot="end" [innerHTML]="ad.stats.viewsPerDay || 0"></ion-note>
                        </ion-item>
                        <ion-item>
                          <ion-icon name="eye-outline" slot="start"></ion-icon>
                          <ion-label [innerHTML]="'views_amount'|translate"></ion-label>
                          <ion-note slot="end" [innerHTML]="ad.stats.viewsOverTime || 0"></ion-note>
                        </ion-item>
                        <ion-item>
                          <ion-icon name="cash" slot="start"></ion-icon>
                          <ion-label [innerHTML]="'price_per_view'|translate"></ion-label>
                          <ion-note slot="end" [innerHTML]="(ad.stats.pricePerView || 0).toFixed(2) + '€'"></ion-note>
                        </ion-item>
                        <ion-item *ngIf="ad.stats.addons && ad.stats.addons > 0">
                          <ion-icon name="cash-outline" slot="start"></ion-icon>
                          <ion-label [innerHTML]="'addons_upsale_price'|translate"></ion-label>
                          <ion-note slot="end" [innerHTML]="(ad.stats.addons.price || 0).toFixed(2) + '€'"></ion-note>
                        </ion-item>
                        
                      </ion-list>
                    </ion-card>
                  </ion-col>

                  <ion-col *ngIf="ad.trackings" [hidden]="!ad.type || !ad.location || (!ad.value && !ad.item && !ad.url) || !ad.date_start || !ad.date_end">
                    <ion-card>
                      <ion-list>
                        <ion-list-header [innerHTML]="'ad_trackings'|translate"></ion-list-header>
                        <ion-item>
                          <ion-label [innerHTML]="'views_delivery'|translate"></ion-label>
                          <ion-note slot="end" [innerHTML]="(ad.trackings.delivery || 0)"></ion-note>
                        </ion-item>
                        <ion-item>
                          <ion-label [innerHTML]="'views_users'|translate"></ion-label>
                          <ion-note slot="end" [innerHTML]="(ad.trackings.users || 0)"></ion-note>
                        </ion-item>
                      </ion-list>
                    </ion-card>
                  </ion-col>

                </ion-row>

              </ion-grid>

            </div>
      
          </swiper-slide> 
      
        </swiper-container>
      </ion-col>
      <ion-col class="ad-manager-grid-col preview-device-col" [size]="view.colSizes.right">
        <div *ngIf="!!ad.item || !!ad.photo || !!ad.url || !!ad.value" class="preview-device" [class.ipad]="view.adLocationDeviceGroup === 'tablet' || view.adLocationDeviceGroup === 'web_desktop'" [class.landscape]="view.adLocationDeviceGroup === 'web_desktop'" [class.iphone]="view.adLocationDeviceGroup === 'app' || view.adLocationDeviceGroup === 'web_mobile'">
          <div class="view">
            <div class="ion-padding">
              <pipeline-ad-preview [ad]="ad"></pipeline-ad-preview>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <svg class="curve curve-pink curve-vertical curve-middle curve-rotated curve-inverted" xmlns="http://www.w3.org/2000/svg" width="64.128" height="931.265" viewBox="0 0 64.128 931.265">
    <path data-name="Pfad 66" d="M465.611,0c257.155,0,466.568,64.415,465.62,64.105S2.48,62.7-.009,64.105,208.456,0,465.611,0Z" transform="translate(64.128 0.031) rotate(90)" fill="#e2d8ff"/>
  </svg>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-no-border container">

    <ion-button [hidden]="!view.canNext" color="primary" expand="block" (click)="next()">
      <ion-label [innerHTML]="'continue'|translate"></ion-label>
      <ion-icon name="arrow-forward" slot="end"></ion-icon>
    </ion-button>

    <ion-button *ngIf="!view.editMode && !view.canNext && view.canSubmit" color="primary" expand="block" (click)="next()">
      <ion-icon name="checkmark" slot="start"></ion-icon>
      <ion-label [innerHTML]="'create_ad'|translate"></ion-label>
    </ion-button>

    <ion-button *ngIf="view.editMode && !view.canNext" color="primary" expand="block" (click)="update()">
      <ion-icon name="checkmark" slot="start"></ion-icon>
      <ion-label [innerHTML]="'update_ad'|translate"></ion-label>
    </ion-button>
    
  </ion-toolbar>
</ion-footer>