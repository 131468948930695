import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { ElevenlabsService } from 'src/app/services/integrations/elevenlabs.service';
import { EventsService } from 'src/app/services/core/events.service';
import { HeygenService } from 'src/app/services/integrations/heygen.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ParamsService } from 'src/app/services/core/params.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';

import { CreateMediaPage } from 'src/app/pages/core/media/media/create-media/create-media.page';

@Component({
  selector: 'pipeline-media-item-actions-toolbar',
  standalone: false,
  templateUrl: './media-item-actions-toolbar.component.html',
  styleUrls: ['./media-item-actions-toolbar.component.scss'],
})
export class MediaItemActionsToolbarComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('actionsPopover') actionsPopover: any;

  @Input() media: any;
  @Input() options: mediaItemActionOptions;
  @Input() view: any;

  @Output() buttonClicked = new EventEmitter();

  cards: any = {
    elevenlabs: { open: false },
    heygen: { open: false },
  };

  fallbackImg: string = './assets/img/fallback.webp';

  isActionsPopoverOpen: boolean = false;

  constructor(
    private elevenlabs: ElevenlabsService,
    private events: EventsService,
    private heygen: HeygenService,
    private integrations: IntegrationsService,
    private modalService: ModalService,
    private paramsService: ParamsService,
    private sd: StablediffusionService,
    private sidebar: SidebarService,
    private zone: NgZone,
  ) {

  }

  async aiTools_addTextOverlay() {
    this.isActionsPopoverOpen = false;

    const view: any = {
      mediaList: [this.media],
      types: [
        {
          checked: true,
          icon: 'image-outline',
          uid: 'image',
          name: 'image',
        },
        {
          checked: true,
          icon: 'film-outline',
          uid: 'video',
          name: 'video',
        }
      ],
    };

    // apply UI view data based on request
    this.paramsService.set('viewData_createMedia', {
      fire: false,
      search: {},
      view: view,
    });

    const modal: any = await this.modalService.create({
      component: CreateMediaPage,
      componentProps: {

      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      console.warn('create_media from dani: dismissed', data);
    });

    this.modalService.present(modal);
  }

  aiTools_audioTranscribing() {
    this.isActionsPopoverOpen = false;

    this.heygen.editVideo(this.media, {
      action: 'audio_transcribe',
    })
      .then((response: any) => {

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  aiTools_audioTranslation() {
    this.isActionsPopoverOpen = false;

    this.heygen.editVideo(this.media, {
      action: 'audio_translate',
    })
      .then((response: any) => {

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  aiTools_elevenlabs_changeVoice() {
    this.isActionsPopoverOpen = false;

    /*
    this.elevenlabs.changeVoice({
      url: this.media.guid,
    })
    .then((response: any) => {
      console.log('heygen response', response);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
    */
  }

  aiTools_elevenlabs_createVoice() {
    this.isActionsPopoverOpen = false;

    this.elevenlabs.createVoice({
      url: this.media.guid,
    })
      .then((response: any) => {
        console.log('heygen response', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  aiTools_heygen_addCaptionToVideo() {
    console.log('aiTools_heygen_addCaptionToVideo');

    this.isActionsPopoverOpen = false;

    /*
    this.heygen.addCaptionToVideo({
      url: this.media.videoSrc,
    })
    .then((response: any) => {
      console.log('heygen response', response);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
    */
  }

  aiTools_heygen_uploadTalkingPhoto() {
    this.isActionsPopoverOpen = false;

    this.heygen.uploadTalkingPhoto({
      url: this.media.thumbnail,
    })
      .then((response: any) => {
        console.log('heygen response', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  aiTools_imageTo3D() {
    this.isActionsPopoverOpen = false;
  }

  aiTools_imageToImage() {
    this.isActionsPopoverOpen = false;

    this.sd.imageToImage(this.media.guid || this.media.thumbnail)
      .then((response: any) => {
        console.log('image to image response', response);

        if (!!response && !!response.images) {
          this.addResponseImagesToHistory(response.images);
        }

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      });
  }

  aiTools_imageToVideo() {
    this.isActionsPopoverOpen = false;
    this.view.attributesConfig = this.view.attributesConfig || {};

    this.events.publish('toast', {
      icon: 'hourglass-outline',
      message: 'ai_image_to_video_process_started',
      color: 'primary',
    });

    this.sd.imageToVideo(this.media.guid || this.media.thumbnail, {
      prompt: `${this.view.attributesConfig.caption || ''}`,
    })
      .then((response: any) => {
        console.log('image to video response', response);

        if (!!response && !!response.item) {
          this.addResponseQueueItemToHistory(response.item);
        }

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      });
  }

  aiTools_removeBackground() {
    this.isActionsPopoverOpen = false;
    this.buttonClicked.emit({ uid: `remove_background`, item: this.media });
  }

  aiTools_sv3d() {
    this.isActionsPopoverOpen = false;

    this.sd.sv3d(this.media.guid || this.media.thumbnail)
      .then((response: any) => {
        console.log('sv3d response', response);

        if (!!response && !!response.item) {
          this.addResponseQueueItemToHistory(response.item);
        }

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      });
  }

  aiTools_sv4d() {
    this.isActionsPopoverOpen = false;

    this.sd.sv4d(this.media.guid || this.media.thumbnail)
      .then((response: any) => {
        console.log('sv4d response', response);

        if (!!response && !!response.item) {
          this.addResponseQueueItemToHistory(response.item);
        }

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      });
  }

  aiTools_upscale() {
    this.view.loading = true;
    this.isActionsPopoverOpen = false;

    this.sd.upscale(this.media.guid)
      .then((response: any) => {

        if (!!response && !!response.images) {
          this.addResponseImagesToHistory(response.images);
        }

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      });
  }

  aiTools_videoFrameToImage() {
    console.log('aiTools_videoFrameToImage', this.view);
  }

  addResponseImagesToHistory(images: any[]) {
    this.buttonClicked.emit({ uid: `addResponseImagesToHistory`, images: images });
  }

  addResponseQueueItemToHistory(item: any) {
    this.buttonClicked.emit({ uid: `addResponseQueueItemToHistory`, item: item });
  }

  async calcAvailableIntegrations() {
    try {
      const enabled: any = await this.integrations.getEnabled();

      const availableNames: string[] = (enabled || []).map((item: integrationConnection) => {
        return `${item.name || ''}`.replace('integration_', '');
      });

      this.view.isElevenlabsReady = !!(availableNames.indexOf('heygen') !== -1);
      this.view.isHeygenReady = !!(availableNames.indexOf('heygen') !== -1);
    } catch (e) {
      console.warn('calculating available integrations failed', e);
    }
  }

  imageVariations() {
    this.zone.run(() => {
      let iCurrent: number = 0, iDone: number = 0, iMax: number = 5;
      this.media.variations = [null, null, null, null, null];
      this.isActionsPopoverOpen = false;

      while (iCurrent < iMax) {
        iCurrent++;

        this.sd.imageVariations(this.media.thumbnail || this.media.guid, {
          prompt: `${this.view.attributesConfig.caption || ''}`,
        })
          .then((response: any) => {
            this.zone.run(() => {

              if (!!response && !!response.images) {
                response.images.forEach((image: any, index: number) => {
                  this.media.variations[iDone] = image;
                  iDone++;
                });
              }

              this.view.ai_tool_selection = null;
              this.view.loading = false;
            });
          })
          .catch((error: any) => {
            this.events.publish('error', error);

            this.view.ai_tool_selection = null;
            this.view.loading = false;
          });
      }
    });
  }

  inpaint() {
    this.view.mode = 'edit';
    this.buttonClicked.emit({ uid: `inpaint` });
    this.isActionsPopoverOpen = false;
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    this.calcAvailableIntegrations();
  }

  outpaint() {
    this.view.mode = 'edit';
    this.buttonClicked.emit({ uid: `outpaint` });
    this.isActionsPopoverOpen = false;
  }

  showActionsPopover(event: any = null) {
    this.actionsPopover.event = event;
    this.isActionsPopoverOpen = true;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  transcribe() {
    this.view.attributesConfig = this.view.attributesConfig || {};
    this.isActionsPopoverOpen = false;

    this.events.publish('toast', {
      icon: 'hourglass-outline',
      message: 'ai_transcription_process_started',
      color: 'primary',
    });

    this.sd.audioToText(this.media.guid || this.media.url, {
      media_uid: (this.media.ID || this.media.uid),
      prompt: `${this.view.attributesConfig.caption || ''}`,
    })
      .then(() => {
        this.view.ai_tool_selection = null;
        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      });
  }

  useAsPrimary() {
    this.view.mode = 'false';
    this.isActionsPopoverOpen = false;

    this.buttonClicked.emit({ uid: `useAsPrimary`, item: this.media });
  }

}