<!-- Popover for information -->
<ion-popover #infoPopover [isOpen]="isInfoPopoverOpen" (didDismiss)="isInfoPopoverOpen = false">
    <ng-template>
        <ion-content>
            <p class="ion-padding" *ngIf="!!view.infoPopoverContent" [innerHTML]="view.infoPopoverContent|translate">
            </p>
        </ion-content>
    </ng-template>
</ion-popover>

<ion-card class="sidebar-accordion-card">

    <ion-card-header>

        <ion-card-title>
            <ion-label [innerHTML]="'attributes'|translate"></ion-label>

            <ion-button (click)="presentInfoPopover($event, 'item_attributes_infotext_general')" size="small" icon-only fill="clear"
                color="dark">
                <ion-icon name="information-circle-outline"></ion-icon>
            </ion-button>

        </ion-card-title>

        <ion-button *ngIf="!!cards && !!cards.item_attributes" class="card-toggle" (click)="toggleCard()" icon-only
            color="dark" fill="clear">
            <ion-icon [name]="!!cards && !!cards.item_attributes && !!cards.item_attributes.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <!--
    <pipeline-no-entries-card *ngIf="!view.loading && (!item.attributes || !item.attributes.length)"></pipeline-no-entries-card>
    -->

    <ion-spinner [hidden]="!view.loadingAttributes"></ion-spinner>
    
    <ion-grid [hidden]="!!view.loadingAttributes || (!!cards && !!cards.item_attributes && !cards.item_attributes.open)">
        <ion-row *ngFor="let attribute of item.attributes">

            <ion-col size="4">

                <ion-item lines="none">
                    <ion-input [(ngModel)]="attribute.key" [placeholder]="'attribute'|translate"></ion-input>
                </ion-item>

            </ion-col>

            <ion-col size="8">

                <ion-item lines="none">
                    <tag-input [(ngModel)]="attribute.value" [identifyBy]="'uid'" [displayBy]="'title'"
                        (onAdd)="onAttributeAdd($event, attribute)" (onRemove)="onAttributeRemove($event, attribute)"
                        [placeholder]="'item_attributes_placeholder'|translate"></tag-input>
                </ion-item>

            </ion-col>

        </ion-row>

        <ion-row>
            <ion-col>

                <ion-button color="primary" fill="clear" (click)="add()">
                    <ion-icon name="add-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'add'|translate" class="ion-text-wrap"></ion-label>
                </ion-button>

            </ion-col>
        </ion-row>

    </ion-grid>

</ion-card>