<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Main export button -->
      <ion-button *ngIf="!!view.startManually && !!view.project && !!view.project.uid"
        [hidden]="!!view.showSplineView || (view.phase === 'loading') || (view.phase === 'presenting')"
        (click)="import()" [disabled]="!!view.loading || !view.canImport" shape="round" color="primary" fill="solid">
        <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'import'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Progress bar -->
  <ion-toolbar class="ion-no-border container second-toolbar progress-toolbar" *ngIf="!!view.progress">
    <pipeline-progress-bar [progress]="view.progress" [view]="view"></pipeline-progress-bar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Create media intro mode -->
  <pipeline-intro-overlay [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions"
    [config]="introOverlayConfig" [hidden]="!!view.startManually" (onIntegrationChanged)="onIntegrationChanged($event)"
    (onSkip)="startManually()" (onSourceChanged)="sourceTypeChanged($event)" [(view)]="view">
  </pipeline-intro-overlay>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container slider-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <div class="main-view">

      <ion-grid class="main-grid">

        <ion-row>

          <!-- Sidebar -->
          <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

            <!-- Source -->
            <div class="col-inner">
              <ion-card>

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('source')">
                    <span [innerHTML]="'source'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('source')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.source && !!cards.source.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.source && !cards.source.open">

                  <ion-card-content>
                    <p [innerHTML]="'data_importer_source_text'|translate"></p>
                  </ion-card-content>

                  <ion-radio-group [(ngModel)]="view.sourceType" (ionChange)="sourceTypeChanged($event)">

                    <!-- Option 1: Integration -->
                    <ion-item>
                      <ion-radio justify="start" labelPlacement="end" value="integration">
                        <ion-label [innerHTML]="'integration'|translate"></ion-label>
                      </ion-radio>
                    </ion-item>

                    <!-- Integrations list -->
                    <ion-item lines="none"
                      *ngIf="(view.sourceType == 'integration') && !!view.integrations && !!view.integrations.length">

                      <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

                      <ion-select interface="popover" [label]="'integration'|translate" [(ngModel)]="view.integration"
                        (ionChange)="onIntegrationChanged($event)">
                        <ion-select-option *ngFor="let integration of view.integrations" [value]="integration"
                          [innerHTML]="integration.name|translate"></ion-select-option>
                      </ion-select>

                    </ion-item>

                    <!-- Option 2: Upload -->
                    <ion-item>
                      <ion-radio justify="start" labelPlacement="end" value="upload">
                        <ion-label [innerHTML]="'upload'|translate"></ion-label>
                      </ion-radio>
                    </ion-item>

                    <!-- Option 3: URL -->
                    <ion-item lines="none">
                      <ion-radio justify="start" labelPlacement="end" value="url">
                        <ion-label [innerHTML]="'url'|translate"></ion-label>
                      </ion-radio>
                    </ion-item>

                    <!-- Source URL -->
                    <ion-item lines="none" *ngIf="view.sourceType == 'url'">
                      <ion-icon name="link-outline" slot="start"></ion-icon>
                      <ion-input labelPlacement="floating" [(ngModel)]="view.sourceUrl"
                        [placeholder]="'paste_url'|translate"></ion-input>
                    </ion-item>

                  </ion-radio-group>

                </div>
              </ion-card>
            </div>

            <!-- Filters -->
            <div class="col-inner" *ngIf="!!data && !!data.length" [hidden]="!view.expertMode">
              <pipeline-items-filters-card [(cards)]="cards" [(view)]="view"
                (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>
            </div>

            <!-- Input settings -->
            <div class="col-inner" *ngIf="!!data && !!data.length">
              <ion-card>

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('input')">
                    <span [innerHTML]="'input'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('input')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.input && !!cards.input.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.input && !cards.input.open">

                  <ion-list>

                    <!-- Format -->
                    <!--
                    <ion-item>

                      <ion-icon name="document-attach-outline" slot="start"></ion-icon>

                      <ion-select interface="popover" labelPlacement="stacked" [(ngModel)]="settings.format"
                        name="format" [label]="'format'|translate" (ionChange)="onFormatChanged()">
                        <ion-select-option value="csv">CSV</ion-select-option>
                        <ion-select-option value="excel">Excel</ion-select-option>
                        <ion-select-option value="html">HTML</ion-select-option>
                        <ion-select-option value="json">JSON</ion-select-option>
                        <ion-select-option value="tsv">TSV</ion-select-option>
                        <ion-select-option value="raw" [innerHTML]="'raw_data'|translate"></ion-select-option>
                        <ion-select-option value="xml">XML</ion-select-option>
                      </ion-select>

                    </ion-item>
                    -->

                    <!-- Seperator -->
                    <ion-item [hidden]="settings.format !== 'csv'" *ngIf="!!view.seperators">
                      <ion-icon name="return-down-back-outline" slot="start"></ion-icon>
                      <ion-select [(ngModel)]="settings.seperator" name="seperator" labelPlacement="stacked"
                        interface="popover" [label]="'seperator'|translate" [okText]="'okay' | translate"
                        [cancelText]="'cancel' | translate" (ionChange)="seperatorChanged()">
                        <ion-select-option *ngFor="let seperator of view.seperators"
                          [innerHTML]="seperator.label|translate" [value]="seperator.indent"></ion-select-option>
                      </ion-select>
                    </ion-item>

                    <ion-item>
                      <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="settings.quote_characters"
                        (ionChange)="seperatorChanged()" [innerHTML]="'quote_characters'|translate"></ion-checkbox>
                    </ion-item>

                    <!-- Quote character -->
                    <ion-item [hidden]="settings.format !== 'csv' || (!settings.quote_characters)"
                      *ngIf="!!view.quote_characters">
                      <ion-icon name="return-down-back-outline" slot="start"></ion-icon>
                      <ion-select [(ngModel)]="settings.quote_character" name="seperator" labelPlacement="stacked"
                        interface="popover" [label]="'quote_character'|translate" [okText]="'okay' | translate"
                        [cancelText]="'cancel' | translate" (ionChange)="seperatorChanged()">
                        <ion-select-option *ngFor="let char of view.quote_characters" [innerHTML]="char.label|translate"
                          [value]="char.indent"></ion-select-option>
                      </ion-select>
                    </ion-item>

                    <!-- Use first row as fields -->
                    <ion-item lines="none">
                      <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="settings.use_first_row_as_fields"
                        (ionChange)="useFirstRowAsFieldsChanged($event)"
                        [innerHTML]="'use_first_row_as_fields'|translate"></ion-checkbox>
                    </ion-item>

                  </ion-list>

                </div>

              </ion-card>
            </div>

            <!-- Target -->
            <div class="col-inner">
              <ion-card>

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('target')">
                    <span [innerHTML]="'data_importer_target'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('target')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.target && !!cards.target.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.target && !cards.source.open">

                  <ion-card-content>
                    <p [innerHTML]="'data_importer_target_text'|translate"></p>
                  </ion-card-content>

                  <ion-list>

                    <!-- Target -->
                    <ion-item>
                      <ion-icon name="server-outline" slot="start"></ion-icon>

                      <ion-select interface="popover" [label]="'storage'|translate" [okText]="'okay' | translate"
                        [cancelText]="'cancel' | translate" [(ngModel)]="view.target"
                        (ionChange)="targetChanged($event)">
                        <ion-select-option value="integration"
                          [innerHTML]="'integration'|translate"></ion-select-option>
                        <ion-select-option value="custom" [innerHTML]="'custom'|translate"></ion-select-option>
                      </ion-select>

                    </ion-item>

                    <!-- Custom object type picker -->
                    <ion-item [hidden]="view.target !== 'custom'">
                      <ion-icon name="file-tray-outline" slot="start"></ion-icon>

                      <ion-select interface="popover" [label]="'object_type'|translate" [okText]="'okay' | translate"
                        [cancelText]="'cancel' | translate" [(ngModel)]="view.object_type_item"
                        (ionChange)="objectTypeChanged($event)">
                        <ion-select-option [value]="view.newItem" [innerHTML]="view.newItem.name|translate"></ion-select-option>
                        <ion-select-option *ngFor="let type of view.object_types" [innerHTML]="type.value.name"
                          [value]="type"></ion-select-option>
                      </ion-select>

                    </ion-item>

                    <!-- New Custom object type creator -->
                    <ion-item [hidden]="(view.target !== 'custom') || (view.object_type !== 'new')">
                      <ion-icon name="text-outline" slot="start"></ion-icon>
                      <ion-input labelPlacement="floating" [label]="'object_name'|translate"
                        [(ngModel)]="view.object_name"></ion-input>
                    </ion-item>

                    <!-- Integration -->
                    <ion-item [hidden]="view.target !== 'integration'">
                      <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

                      <ion-select interface="popover" [label]="'integration'|translate" [okText]="'okay' | translate"
                        [cancelText]="'cancel' | translate" [(ngModel)]="view.target_integration">
                        <ion-select-option *ngFor="let integration of view.integrations" [value]="integration"
                          [innerHTML]="integration.name|translate"></ion-select-option>
                      </ion-select>

                    </ion-item>

                    <!-- Duplicate behaviour -->
                    <ion-item lines="none">
                      <ion-icon name="sync-outline" slot="start"></ion-icon>

                      <ion-select interface="popover" [label]="'duplicate_behaviour'|translate"
                        [okText]="'okay' | translate" [cancelText]="'cancel' | translate"
                        [(ngModel)]="view.duplicate_behaviour">
                        <ion-select-option value="duplicate" [innerHTML]="'duplicate'|translate"></ion-select-option>
                        <ion-select-option value="overwrite" [innerHTML]="'overwrite'|translate"></ion-select-option>
                        <ion-select-option value="skip" [innerHTML]="'skip'|translate"></ion-select-option>
                      </ion-select>

                    </ion-item>

                  </ion-list>

                </div>

              </ion-card>
            </div>

          </ion-col>

          <!-- Content -->
          <ion-col [size]="view.isDesktop ? 9 : 12">

            <!-- Intro card -->
            <pipeline-intro-card [hidden]="view.introCard.hidden" class="introCard"
              [(view)]="view.introCard"></pipeline-intro-card>

            <!-- Loading spinner -->
            <ion-spinner [hidden]="!view.loading"></ion-spinner>

            <!-- Preview -->
            <ion-card class="preview-card" [hidden]="!!view.loading">

              <ion-card-header>
                <ion-card-title [innerHTML]="'data_importer_preview'|translate"></ion-card-title>
              </ion-card-header>

              <!-- File explorer -->
              <pipeline-files-explorer [connection]="view.connection" [(view)]="view"
                *ngIf="!!view.connection && (view.integrationName === 'ftp')"></pipeline-files-explorer>

              <!-- Dynamic data preview -->
              <pipeline-table-view [hidden]="view.viewType !== 'table'"
                *ngIf="(view.integrationName !== 'ftp') && !!view.startManually" (fieldsChanged)="onFieldsChanged($event)" (itemsChanged)="onItemsChanged($event)"
                [(items)]="view.preview_data" [buttons]="buttons" [fields]="view.dataKeys" [(view)]="view" [method]="view.method"
                [mode]="tableViewOptions.mode" [options]="tableViewOptions" [selectionOptions]="selectionOptions"
                [service]="service"></pipeline-table-view>

            </ion-card>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!view.startManually">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>