import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

import { ElevenlabsService } from 'src/app/services/integrations/elevenlabs.service';
import { HeygenService } from 'src/app/services/integrations/heygen.service';
import { ColossyanService } from 'src/app/services/integrations/colossyan.service';

@Component({
  selector: 'pipeline-voiceover-card',
  standalone: false,
  templateUrl: './voiceover-card.component.html',
  styleUrls: ['./voiceover-card.component.scss'],
})
export class VoiceoverCardComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() cards: any;
  @Input() options: voiceoverCardOptions = {};
  @Input() view: any;

  @Output() settingsChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private events: EventsService,
    private sidebar: SidebarService,

    // voiceover providers
    public colossyan: ColossyanService,
    public elevenlabs: ElevenlabsService,
    public heygen: HeygenService,
  ) {

  }

  doRefresh() {

  }

  initEvents() {
    this.view.events = {};
  }

  async loadVoicesByProvider() {

    if (!this.view.voiceover_provider || !this.view.voiceover_provider.uid) {
      return false;
    }

    try {

      switch (this.view.voiceover_provider.uid) {
        case 'amazon':
          break;
        case 'elevenlabs':
          this.view.voiceover_voices = ((await this.elevenlabs.getVoices() || []) as any).map((voice: any) => {
            voice.uid = voice.voice_id;
            return voice;
          });
          break;
        case 'google':
          break;
        case 'heygen':
          this.view.voiceover_voices = await this.heygen.getVoices();
          break;
        case 'microsoft':
          break;
      }

      // set first voice as selected voice
      if (!!this.view.voiceover_voices && !!this.view.voiceover_voices.length) {
        this.view.voiceover_voice = this.view.voiceover_voices[0];
      }

      this.voiceoverVoiceSettingChanged();
    } catch (e) {
      console.warn('loading tts voices failed', e);
    }
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initEvents();

    if (!this.view.hasOwnProperty('voiceover_providers') || !this.view.voiceover_providers || !this.view.voiceover_providers.length) {
      this.view.voiceover_providers = [
        /*
        {
          name: 'integration_amazon',
          uid: 'amazon',
        },
        */
        {
          name: 'integration_colossyan',
          uid: 'colossyan',
        },
        {
          name: 'integration_elevenlabs',
          uid: 'elevenlabs',
        },
        /*
        {
          name: 'integration_google',
          uid: 'google',
        },
        */
        {
          name: 'integration_heygen',
          uid: 'heygen',
        },
        /*
        {
          name: 'integration_microsoft',
          uid: 'microsoft',
        },
        */
      ];
    }

    if (!!this.view.voiceover_providers && !!this.view.voiceover_providers.length) {
      // set first provider as selected provider
      this.view.voiceover_provider = this.view.voiceover_providers[1];
      this.loadVoicesByProvider();
    }

  }

  playPreview() {

    if (!this.view.voiceover_provider || !this.view.voiceover_provider.uid) {
      console.warn('no provider selected', this.view);
      return false;
    }

    if (!this.options || !this.options.input || !this.options.input.length) {
      console.warn('invalid options', this.options);
      return false;
    }

    try {
      this.view.loadingVoiceover = true;
      this.view.voiceover_preview_file = null;

      let service: any = this[this.view.voiceover_provider.uid];

      if (!service) {
        console.warn('not implemented', service);
        return false;
      }

      this.options.voice_uid = (!!this.view.voiceover_voice && !!this.view.voiceover_voice.uid ? this.view.voiceover_voice.uid : null);

      service.textToSpeech(this.options.input, this.options)
        .then((response: any) => {
          this.view.loadingVoiceover = false;

          if (!!response && !!response.url) {
            this.view.voiceover_preview_file = response.url;
          }
        })
        .catch((error: any) => {
          this.view.loadingVoiceover = false;
          this.events.publish('error', error);
        });
    } catch (error) {
      this.view.loadingVoiceover = false;
      this.events.publish('error', error);
    }

  }

  stopPreview() {

  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.voiceover = this.cards.voiceover || {};
    this.cards.voiceover.open = !this.cards.voiceover.open;

    this.sidebar.setCards(this.cards);
  }

  voiceoverProviderChanged() {
    this.loadVoicesByProvider();
  }

  voiceoverVoiceChanged() {
    return this.voiceoverVoiceSettingChanged();
  }

  voiceoverVoiceSettingChanged() {
    this.settingsChanged.emit({
      use_tts: !!this.view.use_tts,
      provider: (!!this.view.voiceover_provider && !!this.view.voiceover_provider.uid ? this.view.voiceover_provider.uid : null),
      voice: (!!this.view.voiceover_voice && !!this.view.voiceover_voice.uid ? this.view.voiceover_voice.uid : null),
      settings: {
        similarity_boost: (this.view.voice_similarity_boost || -1),
        stability: (this.view.voice_stability || -1),
      },
    });
  }

}
