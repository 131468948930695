import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class SnapchatService {

  constructor(
    private AppCMS: AppcmsService,
    private integrations: IntegrationsService,
    private oauth: OauthService,
    private userService: UserService,
  ) {

  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("snapchat", options, ['analyse']);
  }

  authorize() {
    const accessTokenRoute: string = this.AppCMS.getRequestUrl('snapchat', ['authorized']),
      resourceUrl: string = this.AppCMS.getRequestUrl('snapchat', ['profile']);

    return this.oauth.authorize({
      authorizationBaseUrl: '',
      accessTokenEndpoint: accessTokenRoute,
      default: {
        clientId: '702d1912-1b4d-44dd-af26-129f03bdeb65',
        redirectUrl: `${window.location.origin}/integrations/integration/snapchat/connections`,
        responseType: '',
      },
      resourceUrl: resourceUrl,
      scope: '',
      state: '',
    });
  }

  createConnection(connection: integrationConnection) {
    return this.AppCMS.loadPluginData('snapchat', {
      connection: connection,
    }, ['connections', 'create']);
  }

  connect(options: any = {}) {
    return new Promise((resolve, reject) => {

      // first, authorize application
      this.authorize().then((authResponse: any) => {

        // then, run connect process
        this.integrations.connect(
          Object.assign(options, authResponse)
        ).then((chooseResponse: chooseResponse) => {

          // if connects selected, add them
          if (!!chooseResponse && !!chooseResponse.data && !!chooseResponse.data.items && !!chooseResponse.data.items.length) {
            chooseResponse.data.items.forEach(async (item: integrationConnection) => {
              try {

                const create: any = await this.createConnection({
                  active: true,
                  name: `${item.name || ''}`,
                  page_id: parseInt(`${(item.uid || item.organization) || item.id}`.replace('urn:li:organization:', '')),
                  url: `${item.url || ''}`,
                  user: this.userService.getUid(),
                });

              } catch (e) {
                console.warn('adding connection failed', e);
              }
            });
          }

          resolve(chooseResponse);
        }).catch(reject);
      }).catch(reject);
    });
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('snapchat', {
      uid: connectionId,
    }, ['connections', 'delete']);
  }

  deletePost(postId: number, platform: string) {
    return this.AppCMS.loadPluginData('snapchat', {
      platform: platform,
      uid: postId,
    }, ['posts', 'delete']);
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('snapchat', options, ['log'], {}, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('snapchat', options, ['connections'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('snapchat', options, ['settings'], {}, blForceRefresh);
  }

  importMedia(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("snapchat", options, ['import_media']);
  }

  importPosts(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("snapchat", options, ['import_posts']);
  }

  updateConnection(connection: integrationConnection) {
    connection = JSON.parse(JSON.stringify(connection));

    delete connection.checked;

    return this.AppCMS.loadPluginData('snapchat', {
      connection: connection,
    }, ['connections', 'update']);
  }

}