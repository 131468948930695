import { Injectable } from "@angular/core";

import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { UserService } from 'src/app/services/core/user.service';

import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class ViewService {

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private modalService: ModalService,
    private tools: ToolsService,
    private userService: UserService,
  ) {

  }

  calcScreenSizeVars(view: any | null = null) {
    view = view || {};
    view.isDesktop = this.tools.isDesktop();
    view.isUltraWide = this.tools.isUltraWide();
    view.sidebarSize = (!!view.isUltraWide ? 2 : (window.innerWidth > 768 ? 3 : 12));

    return view;
  }

  calcVars(view: any | null = null) {
    view = view || {};

    view.isAdmin = this.userService.isType('Admin');
    view.isModal = this.modalService.isModal();
    view.isWeb = this.tools.isWeb();

    return this.calcScreenSizeVars(view);
  }

  init() {
    this.resizeObservable$ = fromEvent(window, 'resize');

    this.resizeSubscription$ = this.resizeObservable$
      .pipe(debounceTime(1000))
      .subscribe((event: any) => {
        this.events.publish('window:resized', event);
      });
  }

  async isExpertMode(bl: boolean | null = null) {

    // update expert mode if bl is not null
    if (bl !== null) {
      const save: any = await this.cache.set('VIEW_MODE', !!bl);
      this.events.publish('view:mode:changed', bl);
      return save;
    }

    // else, return expert mode
    bl = false;

    try {
      let settings: any = await this.cache.get('VIEW_MODE', -1);
      bl = (!!settings && !!settings.data);
    } catch (e) {
      console.log('loading view mode settings failed', e);
    }

    return bl;
  }

}