<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Options button -->
      <ion-button (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Add new product -->
      <ion-button (click)="add()" shape="round" color="primary" fill="solid" [disabled]="!!view.loading">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar *ngIf="!view.rebuild && !!view.products" [(items)]="view.products"
    [options]="selectionOptions" [(paginationConfig)]="paginationConfig" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Optimize products description modal -->
  <ion-modal class="defaultModal" id="optimizeProductsDescriptionModal" #optimizeProductsDescriptionModal
    [isOpen]="isOptimizeProductsDescriptionModalOpen" [keepContentsMounted]="true">
    <ng-template>

      <!-- Optimization header-->
      <ion-header class="ion-no-border">
        <ion-toolbar class="ion-no-border container">

          <ion-buttons slot="start">
            <ion-button (click)="isOptimizeProductsDescriptionModalOpen = false">
              <ion-icon name="close-outline"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-title [innerHTML]="(view.loading ? 'optimizing' : 'product_optimization_prompt')|translate"></ion-title>

          <ion-buttons slot="end">

            <ion-button (click)="runOptimizeSelectedItems()" shape="round" color="primary" fill="solid">
              <ion-icon name="play-outline" slot="start"></ion-icon>
              <ion-label *ngIf="!view.loading" [innerHTML]="'execute'|translate"></ion-label>
              <ion-label *ngIf="!!view.loading"
                [innerHTML]="(view.iDone || 0) + ' / ' + (!!view.selectedItems ? view.selectedItems.length || 0 : 0)"></ion-label>
            </ion-button>

          </ion-buttons>

        </ion-toolbar>

        <ion-toolbar *ngIf="!!view.progress">
          <ion-progress-bar [value]="view.progress / 100"></ion-progress-bar>
        </ion-toolbar>

      </ion-header>

      <!-- Optimization content -->
      <ion-content>

        <!-- Optimize intro card -->
        <pipeline-intro-card [hidden]="view.introCard.optimize.hidden"
          [view]="view.introCard.optimize"></pipeline-intro-card>

        <ion-grid>
          <ion-row>

            <ion-col>

              <ion-spinner [hidden]="!view.loading"></ion-spinner>

              <ion-icon class="done-icon" name="checkmark-outline" [hidden]="view.progress !== 100"></ion-icon>

              <h3 class="percentage-label" [hidden]="!view.loading && (view.progress !== 100)"
                [innerHTML]="view.progress === 100 ? ('done'|translate) : (view.progress || 0) + '%'"></h3>

              <ion-card [hidden]="!!view.progress">

                <ion-card-header>
                  <ion-card-title [innerHTML]="'text_optimizations'|translate"></ion-card-title>
                </ion-card-header>

                <ion-list>

                  <ion-item *ngIf="!!view.optimizeFields">
                    <ion-select [(ngModel)]="view.optimizeField" interface="popover" [label]="'field'|translate"
                      labelPlacement="floating" [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
                      <ion-select-option *ngFor="let field of view.optimizeFields" [innerHTML]="field|translate"
                        [value]="field"></ion-select-option>
                    </ion-select>
                  </ion-item>

                  <ion-item>

                    <ion-icon name="trash-outline" slot="start"></ion-icon>

                    <ion-checkbox [(ngModel)]="view.remove_old_optimizations" justify="start" labelPlacement="end">
                      <ion-label [innerHTML]="'remove_old_optimizations'|translate"></ion-label>
                    </ion-checkbox>

                  </ion-item>

                  <ion-item>

                    <ion-icon name="sync-outline" slot="start"></ion-icon>

                    <ion-checkbox [(ngModel)]="view.force_rebuild_texts" justify="start" labelPlacement="end">
                      <ion-label [innerHTML]="'force_rebuild_texts'|translate"></ion-label>
                    </ion-checkbox>

                  </ion-item>

                </ion-list>

                <pipeline-editor *ngIf="!!isOptimizeProductsDescriptionModalOpen && !view.rebuild && !view.loading"
                  [(editor)]="view.prompt_editor" [input]="view.input_prompt || ''"
                  [disabled]="!!view.loading"></pipeline-editor>

              </ion-card>

              <ion-card [hidden]="!!view.progress">

                <ion-card-header>
                  <ion-card-title [innerHTML]="'image_optimizations'|translate"></ion-card-title>
                </ion-card-header>

                <ion-list>

                  <ion-item>

                    <ion-icon name="image-outline" slot="start"></ion-icon>

                    <ion-checkbox [(ngModel)]="view.optimize_product_main_image_if_required" justify="start"
                      labelPlacement="end">
                      <ion-label [innerHTML]="'optimize_product_main_image_if_required'|translate"></ion-label>
                    </ion-checkbox>

                  </ion-item>

                  <ion-item [hidden]="!view.optimize_product_main_image_if_required">

                    <ion-icon name="sync-outline" slot="start"></ion-icon>

                    <ion-checkbox [(ngModel)]="view.force_rebuild_images" justify="start" labelPlacement="end">
                      <ion-label [innerHTML]="'force_rebuild_images'|translate"></ion-label>
                    </ion-checkbox>

                  </ion-item>

                  <ion-item lines="none">

                    <ion-icon name="images-outline" slot="start"></ion-icon>

                    <ion-checkbox [(ngModel)]="view.optimize_all_product_images_if_required" justify="start"
                      labelPlacement="end">
                      <ion-label [innerHTML]="'optimize_all_product_images_if_required'|translate"></ion-label>
                    </ion-checkbox>

                  </ion-item>

                </ion-list>

              </ion-card>

            </ion-col>

            <ion-col>

              <pipeline-dynamic-list
                *ngIf="!!isOptimizeProductsDescriptionModalOpen && !!view.selectedItems && !!view.selectedItems.length"
                [(items)]="view.selectedItems" [buttons]="view.list.buttons" [(view)]="view" [lines]="true"
                [method]="view.list.method" [options]="view.list.options" [selectionOptions]="selectionOptions"
                [service]="view.list.service"></pipeline-dynamic-list>

            </ion-col>

          </ion-row>
        </ion-grid>

      </ion-content>

      <!-- Optimization footer -->
      <ion-footer class="ion-no-border">
        <ion-toolbar class="container ion-no-border">

          <!-- View mode picker -->
          <ion-buttons slot="end">
            <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
          </ion-buttons>

        </ion-toolbar>
      </ion-footer>

    </ng-template>
  </ion-modal>

  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Import -->
        <ion-item button (click)="importProducts()" [disabled]="!!view.loading">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'import'|translate"></ion-label>
        </ion-item>

        <!-- Export -->
        <ion-item lines="none" button (click)="export()" [disabled]="!!view.loading"
          *ngIf="!!view.products && !!view.products.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>
      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Selection options popover -->
  <pipeline-selection-options-picker (onSelectionActionChanged)="runItemSelectionOption($event)"
    [options]="selectionOptions" [(view)]="view"></pipeline-selection-options-picker>

  <!-- Share product popover -->
  <ion-popover #shareProductPopover [isOpen]="isShareProductPopoverOpen"
    (didDismiss)="isShareProductPopoverOpen = false">
    <ng-template>
      <pipeline-caption-generator [(post)]="view.shareProduct" [(view)]="view"
        (captionChanged)="onCaptionChanged($event)"></pipeline-caption-generator>
    </ng-template>
  </ion-popover>

  <!-- Refresher -->
  <ion-refresher *ngIf="!view.dragging && (!view.dragula || !view.dragula.drake || !view.dragula.drake.dragging)"
    slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!!view.project && !!view.project.uid" [hidden]="view.loading">

    <!-- Main mobile intro card -->
    <pipeline-intro-card *ngIf="!view.isDesktop" [hidden]="view.introCard.main.hidden"
      [view]="view.introCard.main"></pipeline-intro-card>

    <ion-grid class="media-library-grid">
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="left"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Folders -->
          <pipeline-folders-filter-card [(cards)]="cards" [service]="shop" [(view)]="view"
            (onItemsChanged)="onFolderLoaded($event)"
            (onChanged)="loadProductsByFolder($event)"></pipeline-folders-filter-card>

          <!-- Filters -->
          <pipeline-items-filters-card [(cards)]="cards" [(view)]="view"
            (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>

          <!-- Connections -->
          <pipeline-connections-filter-card [(cards)]="cards" [(view)]="view"
            (onChanged)="onConnectionsFilterChanged($event)"></pipeline-connections-filter-card>

          <!-- Categories -->
          <ion-card class="sidebar-accordion-card" *ngIf="!!view.categories && !!view.categories.length">

            <ion-card-header>
              <ion-card-title [innerHTML]="'categories'|translate"></ion-card-title>
            </ion-card-header>

          </ion-card>

          <!-- Attributes -->
          <pipeline-attributes-filter-card [(cards)]="cards" [(view)]="view" location="product"
            (filtersChanged)="onAttributeFiltersChanged($event)"></pipeline-attributes-filter-card>

          <!-- Tags -->
          <pipeline-tags-filter-card [(cards)]="cards" [service]="shop" [(view)]="view"
            (selectionChanged)="onSelectedTagChanged($event)"></pipeline-tags-filter-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode && !!view.colSize ? (view.colSize.right || 12) : 12" class="right"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Main desktop intro card -->
          <div *ngIf="!!view.isDesktop" [hidden]="view.introCard.main.hidden">
            <pipeline-intro-card [view]="view.introCard.main"></pipeline-intro-card>
          </div>

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- No entries -->
          <pipeline-no-entries-card [cta]="cta" [hidden]="!!view.loading" [text]="'shop_admin_cta_text'|translate"
            *ngIf="!!view.products && !view.products.length"></pipeline-no-entries-card>

          <!-- Products grid -->
          <ion-grid *ngIf="!view.rebuild && (view.products && !!view.products.length)" [hidden]="!!view.loading">

            <ion-row>

              <ion-col *ngFor="let product of view.products; let i = index;" [hidden]="product.hidden"
                [attr.data-location]="'shop'" [attr.data-type]="'product'" [attr.data-uid]="product.uid"
                [draggable]="view.isDesktop" [dragItem]="product" [attr.drag-location]="'SHOP_PRODUCTS'"
                [attr.data-model]="view.products" [size]="view.viewType === 'grid' ? view.colSize.item : 12">

                <ion-card (click)="viewProduct(product)" class="product hasBackground">

                  <ion-item lines="none">

                    <ion-button slot="start" [hidden]="!view.expertMode" class="drag-handle" icon-only fill="clear">
                      <ion-icon name="reorder-two-outline"></ion-icon>
                    </ion-button>

                    <ion-thumbnail [hidden]="view.viewType !== 'list'" slot="start">
                      <ion-img [src]="product.image || fallbackImg"
                        (ionError)="thumbnailLoadingFailed(product)"></ion-img>
                    </ion-thumbnail>

                    <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="product.checked"
                      (ionChange)="onItemCheckboxClicked(product)">
                      <ion-label class="ion-text-wrap">
                        <h3 [hidden]="!product.name" [innerHTML]="product.name"></h3>
                        <p [hidden]="!product.price" class="price" [innerHTML]="product.price + '€'"></p>
                      </ion-label>
                    </ion-checkbox>

                    <!-- Edit -->
                    <ion-button [hidden]="view.viewType !== 'list' || !view.isDesktop"
                      *ngIf="(view.mode !== 'pick') && !!product.uid" fill="clear" color="dark"
                      (click)="edit(product, i)" slot="end">
                      <ion-icon name="create-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'edit'|translate"></ion-label>
                    </ion-button>

                    <!-- Share -->
                    <ion-button [hidden]="view.viewType !== 'list' || !view.isDesktop" *ngIf="!!product.uid"
                      fill="clear" color="dark" (click)="share(product, $event)" slot="end">
                      <ion-icon name="share-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'share'|translate"></ion-label>
                    </ion-button>

                    <!-- Delete -->
                    <ion-button [hidden]="view.viewType !== 'list' || !view.isDesktop"
                      *ngIf="(view.mode !== 'pick') && !!view.canDelete && !!product.uid" fill="clear" color="danger"
                      (click)="delete(product)" slot="end">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'delete'|translate"></ion-label>
                    </ion-button>

                    <!-- Options -->
                    <ion-button slot="end" (click)="presentPopover($event, product)" fill="clear" icon-only color="dark"
                      *ngIf="(view.mode !== 'pick') && !!product.uid">
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                  <ion-thumbnail [hidden]="view.viewType !== 'grid'">
                    <ion-img [src]="product.image || fallbackImg"
                      (ionError)="thumbnailLoadingFailed(product)"></ion-img>
                  </ion-thumbnail>

                  <ion-item lines="none" [hidden]="view.viewType !== 'grid' && !!view.isDesktop">

                    <ion-button *ngIf="(view.mode !== 'pick') && !!product.uid" fill="clear" color="dark"
                      (click)="edit(product)" slot="end">
                      <ion-icon name="create-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'edit'|translate"></ion-label>
                    </ion-button>

                    <ion-button *ngIf="!!product.uid" fill="clear" color="dark" (click)="share(product, $event)"
                      slot="end">
                      <ion-icon name="share-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'share'|translate"></ion-label>
                    </ion-button>

                    <ion-button *ngIf="(view.mode !== 'pick') && !!view.canDelete && !!product.uid" fill="clear"
                      color="danger" (click)="delete(product)" slot="end">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'delete'|translate"></ion-label>
                    </ion-button>

                  </ion-item>

                </ion-card>

              </ion-col>
            </ion-row>

          </ion-grid>

          <!-- Bottom pagination -->
          <pipeline-pagination [hidden]="!!view.loading"
            *ngIf="!view.rebuild && view.products && !!view.products.length" [config]="paginationConfig"
            [(view)]="view"></pipeline-pagination>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadNext($event)">
    <ion-infinite-scroll-content loadingSpinner="circular"></ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>