<ion-header class="ion-no-border">
  <ion-toolbar class="container">

    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <pipeline-selection-toolbar *ngIf="!!view.items && !!view.items.length" [(items)]="view.items" [(view)]="view"
    [options]="selectionOptions"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <pipeline-intro-card [hidden]="view.introCard.hidden" class="introCard"
      [(view)]="view.introCard"></pipeline-intro-card>

    <pipeline-no-entries-card
      *ngIf="(!view.items || (view.items && !view.items.length)) && !view.loading"></pipeline-no-entries-card>

    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <ion-grid *ngIf="!!view.items && !!view.items.length" [hidden]="view.loading">

      <ion-row>

        <ion-col *ngFor="let item of view.items" [size]="view.viewType === 'grid' && !!view.isDesktop ? 3 : 12">

          <ion-card>

            <ion-list>

              <ion-item *ngIf="!!item.label || (!!item.post && !!item.post.post_excerpt)">
                <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="item.checked" [hidden]="!item.uid"
                  (ionChange)="onItemChecked(item)">
                  <ion-label class="ion-text-wrap">
                    <h3 *ngIf="!!item.label" [innerHTML]="item.label"></h3>
                    <p *ngIf="!!item.post && !!item.post.post_excerpt" [innerHTML]="item.post.post_excerpt"></p>
                  </ion-label>
                </ion-checkbox>
              </ion-item>

              <!-- Target -->
              <ion-item [hidden]="!item.target">

                <ion-icon *ngIf="!item.connection || !item.connection.photo" [hidden]="!item.platform_icon"
                  [name]="item.platform_icon" slot="start"></ion-icon>

                <ion-thumbnail *ngIf="!!item.connection && !!item.connection.photo" slot="start">
                  <ion-img [src]="item.connection.photo"></ion-img>
                </ion-thumbnail>

                <ion-label class="ion-text-wrap">
                  <h3
                    [innerHTML]="(!!item.connection && !!item.connection.name ? item.connection.name : item.target)|translate">
                  </h3>
                  <p *ngIf="!!item.platform" [innerHTML]="item.platform|translate"></p>
                </ion-label>

              </ion-item>

              <!-- Progress -->
              <ion-item
                [hidden]="!item.progress || ((!!item.error || !!item.response_text) && (item.progress !== 'done'))"
                lines="none">
                <ion-icon name="information-circle-outline" slot="start"></ion-icon>

                <ion-label>
                  <h3 [innerHTML]="'progress'|translate"></h3>
                  <p [innerHTML]="item.progress|translate"></p>
                </ion-label>

                <ion-spinner slot="end" [hidden]="item.progress != 'starting'"></ion-spinner>

                <ion-icon *ngIf="item.progress === 'waiting'" name="hand-left-outline" color="warning"
                  slot="end"></ion-icon>
                <ion-icon *ngIf="item.progress === 'done'" name="checkmark-outline" color="success"
                  slot="end"></ion-icon>
                <ion-icon *ngIf="item.progress === 'error'" name="warning-outline" color="danger" slot="end"></ion-icon>

              </ion-item>

              <!-- Error -->
              <ion-item *ngIf="!!item.error" color="danger" lines="none">
                <ion-icon name="warning-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="item.error|translate" class="ion-text-wrap"></ion-label>
              </ion-item>

              <!-- URL -->
              <ion-item *ngIf="!!item.response && !!item.response.url" button lines="none" (click)="openLink(item.response.url)">
                <ion-icon name="link-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="item.response.url|translate" class="ion-text-wrap"></ion-label>
              </ion-item>

              <!-- Other response text -->
              <ion-item *ngIf="!!item.response_text && !item.error && (item.progress !== 'done')" lines="none">
                <ion-icon name="terminal-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="'response'|translate"></h3>
                  <p [innerHTML]="item.response_text"></p>
                </ion-label>
              </ion-item>

              <!-- View button -->
              <ion-button *ngIf="!!item.response && !!item.response.url" size="block" fill="outline" color="primary" (click)="openLink(item.response.url)">
                <ion-icon name="open-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'view'|translate" class="ion-text-wrap"></ion-label>
              </ion-button>

            </ion-list>
          </ion-card>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>