import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { StatisticsService } from 'src/app/services/analytics/statistics.service';
import { TrendsService } from 'src/app/services/analytics/trends.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

import { Chart } from 'chart.js';

@Component({
  selector: 'app-creator-statistics',
  standalone: false,
  templateUrl: './creator-statistics.page.html',
  styleUrls: ['./creator-statistics.page.scss'],
})
export class CreatorStatisticsPage implements OnInit {

  // overview
  @ViewChild('postsByCategoryChart', { read: ElementRef, }) postsByCategoryChart: ElementRef;
  @ViewChild('postsByEmployeeChart', { read: ElementRef, }) postsByEmployeeChart: ElementRef;
  @ViewChild('postsByGenreChart', { read: ElementRef, }) postsByGenreChart: ElementRef;
  @ViewChild('postsByHostChart', { read: ElementRef, }) postsByHostChart: ElementRef;
  @ViewChild('postsByRatingChart', { read: ElementRef, }) postsByRatingChart: ElementRef;
  @ViewChild('postsByRegionChart', { read: ElementRef, }) postsByRegionChart: ElementRef;
  @ViewChild('postsByLanguageChart', { read: ElementRef, }) postsByLanguageChart: ElementRef;
  @ViewChild('ratingsByGenreChart', { read: ElementRef, }) ratingsByGenreChart: ElementRef;
  @ViewChild('sourcesByRatingChart', { read: ElementRef, }) sourcesByRatingChart: ElementRef;
  @ViewChild('sourcesByRegionChart', { read: ElementRef, }) sourcesByRegionChart: ElementRef;
  @ViewChild('usersByCategoriesChart', { read: ElementRef, }) usersByCategoriesChart: ElementRef;

  // social
  @ViewChild('followerChart', { read: ElementRef, }) followerChart: ElementRef;
  @ViewChild('followingChart', { read: ElementRef, }) followingChart: ElementRef;
  @ViewChild('likesChart', { read: ElementRef, }) likesChart: ElementRef;

  appConfig: pipelineAppConfig;

  cards: any = {};

  integrationsByType: any;

  selectionOptions: selectionOption[];

  state: state = {};

  user: user;

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'creator_statistics_top_card',
      text: 'creator_statistics_top_card_text',
      title: 'creator_statistics_top_card_title',
    },
    route: 'creator-statistics',
    segment: 'overview',
    showMenuButton: true,
    showProjectsSelect: true,
    statistics: {
      counts: {},
    },
    title: 'statistics',
  };

  constructor(
    private changeDetector: ChangeDetectorRef,
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private navCtrl: NavController,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private statistics: StatisticsService,
    private trends: TrendsService,
    private userService: UserService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();
    this.integrationsByType = this.integrations.getByType();
    this.user = this.userService.getUser() || {};
  }

  calcSegments(boardKeys: string[]) {
    let segments: string[] = ['overview', 'social', 'trends'];

    /*
    boardKeys.forEach((boardKey: string) => {
      const boardKeyExplode = boardKey.split('By');
      segments.push(boardKeyExplode[0]);
    });
    */

    this.view.segments = segments;
    //console.log('segments', segments);

    this.detectChanges();

    return segments;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  detectChanges() {
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
  }

  doRefresh(event: any | null = null) {
    this.loadStatistics(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

        this.detectChanges();
      })
      .catch((error: any) => {

        if (!!event) {
          event.target.complete();
        }

        this.events.publish('error', error);
      });
  }

  expandBoard(board: statisticsBoard) {
    board.expanded = !board.expanded;
    this.detectChanges();
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};

    if (!this.userService.isType('Admin') && !this.userService.isType('Creator')) {
      this.navCtrl.navigateBack('/admin.backend', { animated: true, });
    }

  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadPlatforms(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled({}, blForceRefresh)
        .then((integrations: integration[]) => {

          if (!!integrations && !!integrations.length && !!this.integrationsByType.social) {
            integrations = integrations.filter((integration: integration) => {
              integration.indent = integration.indent || `${integration.name}`.replace('integration_', '');
              return this.integrationsByType.social.indexOf(integration.indent) !== -1;
            });
          }

          this.view.platforms = (integrations || []).map((integration: any) => {
            integration.checked = true;
            return integration;
          });

          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadStatistics(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        reject('error_missing_project_uid');
      } else {
        this.view.loading = true;

        this.statistics.getCreatorStatistics(blForceRefresh, {
          project_uid: this.view.project.uid,
        })
          .then((statistics: statistics) => {
            //console.log('remote statistics', statistics);
            statistics = JSON.parse(JSON.stringify(statistics));

            this.view.loading = false;

            if (statistics && statistics.boards) {
              let boardKeys = Object.keys(statistics.boards),
                segments = this.calcSegments(boardKeys);

              boardKeys.forEach((boardKey: string, index: number) => {
                statistics.boards[boardKey].segment = segments[index];
                statistics.boards[boardKey].indent = boardKey;
              });

              this.view.segments = segments.filter((value, index, self) => {
                return self.indexOf(value) === index;
              });

              statistics.boards = Object.values(statistics.boards);
            }

            this.view.statistics = statistics;

            this.detectChanges();
            this.renderCharts();

            resolve(statistics);
          })
          .catch((error: any) => {
            this.view.loading = false;
            reject(error);
          });
      }
    });
  }

  loadTrends(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        reject('error_missing_project_uid');
      } else {
        this.view.loading = true;

        this.trends.getAll(blForceRefresh)
          .then((response: any) => {
            this.view.loading = false;

            if (!!response && !!response.data && !!response.data.platforms) {
              this.view.trendsByPlatforms = response.data.platforms;
            }

            const trends: any = ((!!response && !!response.length ? response : response.trends) || []);

            this.view.trends = trends;
            resolve(trends);
          })
          .catch((error: any) => {
            this.view.loading = false;
            reject(error);
          });
      }
    });
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    this.loadProject();
    this.loadCards();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

    this.loadPlatforms()
      .then(() => {

        this.loadStatistics()
          .catch((error: any) => {
            this.events.publish('error', error);
          });

        this.loadTrends()
          .catch((error: any) => {
            this.events.publish('error', error);
          });

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};
    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    console.log('this.view.options.filters.connections', this.view.options.filters.connections);
    this.doRefresh();
  }

  onLanguageChanged(event: string | null = null) {
    this.view.language = event;
    this.doRefresh();
  }

  onSelectionActionChanged(event: any | null = null) {

  }

  renderCharts() {
    this.zone.run(() => {
      setTimeout(() => {
        if (this.view.statistics && this.view.statistics.boards) {
          this.view.statistics.boards.forEach((board: statisticsBoard) => {
            let boardConfig = JSON.parse(JSON.stringify(board)), canvas = this[board.indent + 'Chart'];
            delete boardConfig.indent;
            if (canvas) {
              new Chart(canvas.nativeElement, boardConfig);
            }
          });
          this.detectChanges();
        }
      });
    });
  }

  segmentChanged() {

  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}