import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PopoverController } from '@ionic/angular';

import { CardOptionsPage } from 'src/app/pages/core/card-options/card-options.page';
import { EventsService } from 'src/app/services/core/events.service';
import { PostsService } from 'src/app/services/posts/posts.service';

@Component({
  selector: 'pipeline-ad',
  standalone: false,
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss'],
})
export class AdComponent implements OnInit {
  @Input() ad: ad;
  @Input() options: adCardOptions = {};
  @Input() size: number;

  view: any = {
  };

  constructor(
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private posts: PostsService,
    private popoverCtrl: PopoverController,
  ) {
    this.ad = this.ad || {};
    this.ad.post = (this.ad.post || {}) as any;

    this.options = this.options || {};
  }

  calcAdSize() {
    if(this.ad && !!this.ad.location) {
      switch(this.ad.location) {
        case 'ad.location.posts.related':
          this.size = 6;
          this.options.mini = true;
          break;
        case 'ad.location.posts.small':
          this.size = 6;
          this.options.mini = true;
          break;
        default:
          this.size = 12;
          break;
      }
    }
    this.ad.size = this.size;
  }

  calcOptions() {
    this.options = this.options || {};
    this.options.mini = (this.ad.location === 'ad.location.posts.related') || (this.ad.location === 'ad.location.posts.small');
    this.options.show_excerpt = this.ad.location === 'ad.location.posts.large';
    this.options.show_title = this.ad.size === 12;

    this.calcAdSize();

    if(!!this.size && !!this.ad) {
      this.ad.size = this.size;

      if(!!this.ad.post) {
        this.ad.post.size = this.size;
      }

      if(!!this.ad.value) {
        this.options.mini = true;
        this.options.show_header = false;
        
        this.view.adValue = this.domSanitizer.bypassSecurityTrustHtml(this.ad.value);
      }

    }
    
  }

  async cardOptions(col: any, event: any|null = null) {
    event.preventDefault();

    const optionsPopover = await this.popoverCtrl.create({
      animated: true,
      component: CardOptionsPage,
      componentProps: {
        col: col,
        event: event,
      },
      cssClass: 'cardOptionsPopover',
      event: event,
      translucent: true
    });

    await optionsPopover.present();

    return false;
  }

  loadItem() {
    if(typeof this.ad.item === 'number') {
      this.posts.getPostByUid(this.ad.item)
      .then((item: post) => {
        this.ad.post = (item || {}) as post;
        this.ad.post.sponsored = true;
        this.ad.post.vip = false;

        if(!this.ad.photo && !!this.ad.post && !!this.ad.post.thumbnail) {
          this.ad.photo = this.ad.post.thumbnail;
        }

        if(!this.ad.name && (!!this.ad.post.name || this.ad.post.host)) {
          this.ad.name = this.ad.post.name || this.ad.post.host;
        }

        this.calcAdSize();

        this.events.publish('posts:grid:update', {shuffle: false});
        this.events.publish('create:ad:detectChanges');
      })
      .catch((error: any) => {
        console.warn('loading ad error', error);
      });
    }
  }

  ngOnInit() {
    this.ad = this.ad || {};
    this.ad.post = (this.ad.post || {}) as any;

    this.calcOptions();

    if(!!(this.ad && this.ad.item)) {
      this.loadItem();
    }

    this.events.subscribe('ad:updated', (ad: ad) => {
      if(ad.item === this.ad.item) {
        this.ad = ad;
        this.ad.post = (this.ad.post || {}) as any;

        if(!!(this.ad && this.ad.item)) {
          this.calcOptions();
          this.loadItem();
        }
      }
    });
  }

  onColClick(col: any, event: any|null = null) {
    
    if(event && event.target && event.target.classList.contains('optionsButton')) {
      event.preventDefault();
      return false;
    }

    switch (col.type) {
      case "person":
        return this.onPersonClick(col);
      case "post":
        return this.onPostClick(col as post);
      case "product":
        return this.onProductClick(col);
      case "review":
        return this.onPostClick(col as post);
      case "simple":
        return this.onProductClick(col);
      case 'variable':
        return this.onProductClick(col);
      default:
        console.warn('> broken col', col);
        this.events.publish("error", "Unbekannter Typ " + col.type);
        break;
    }
  }
  
  async onPersonClick(user: user) {
    this.events.publish('view:profile', user);
  }

  async onPostClick(post: post) {
    this.events.publish('view:post', post);
  }

  async onProductClick(product: any) {
    this.events.publish('view:product', product);
  }

  openProfile(col: any) {
  }
  
  thumbnailLoadingFailed(ad: ad) {
    ad.hidden = true;

    if(!!ad.post) {
      ad.post.hidden = true;
    }
  }

  async verifiedInfo() {
  }

}