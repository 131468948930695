<!-- Popover for information -->
<ion-popover #infoPopover [isOpen]="isInfoPopoverOpen" (didDismiss)="isInfoPopoverOpen = false">
    <ng-template>
        <ion-content>
            <p class="ion-padding" *ngIf="!!view.infoPopoverContent" [innerHTML]="view.infoPopoverContent|translate">
            </p>
        </ion-content>
    </ng-template>
</ion-popover>

<ion-card class="sidebar-accordion-card">

    <ion-card-header>

        <ion-card-title>

            <ion-label [innerHTML]="'tags'|translate"></ion-label>

            <ion-button (click)="presentInfoPopover($event, 'tags_infotext_general')" size="small" icon-only
                fill="clear" color="dark">
                <ion-icon name="information-circle-outline"></ion-icon>
            </ion-button>

        </ion-card-title>

        <!-- Toggle card -->
        <ion-button *ngIf="!!cards && !!cards.tags" class="card-toggle" (click)="toggleCard()" icon-only color="dark"
            fill="clear">
            <ion-icon [name]="!!cards && !!cards.tags && cards.tags.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <!-- Tags list -->
    <ion-list [hidden]="!!cards && !!cards.tags && !cards.tags.open">
        <ion-item>
            <tag-input [(ngModel)]="media.tags" [identifyBy]="'uid'" [displayBy]="'title'" (onAdd)="onTagAdd($event)"
                (onRemove)="onTagRemove($event)" [placeholder]="'tag_name_placeholder'|translate"></tag-input>
        </ion-item>
    </ion-list>

</ion-card>