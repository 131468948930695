import { ChangeDetectorRef, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { StateService } from 'src/app/services/utils/state.service';

@Component({
  selector: 'pipeline-post-grid',
  standalone: false,
  templateUrl: './post-grid.component.html',
  styleUrls: ['./post-grid.component.scss'],
})
export class PostGridComponent implements OnInit {
  @ViewChild(IonContent) content: IonContent;

  @Input('posts') posts: post[];

  @Input() selectionOptions: selectionOption[];

  state: state = {
    pairs: [
      [{}, {}, {}],
      [{}, {}, {}],
      [{}, {}, {}],
      [{}, {}, {}],
    ]
  };

  @Input() view: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private events: EventsService,
    private stateService: StateService,
    private zone: NgZone,
  ) {

    this.events.subscribe('posts:grid:update', (data: any) => {
      let blShuffle = (data && data.shuffle !== false) && (!data || !data.route || (data.route !== 'local'));
      this.calcPairs(blShuffle);
    });

    this.events.subscribe('orderBy:changed', (orderBy: string) => {
      this.orderBy(orderBy);
    });

  }

  calcPairs(blShuffle: boolean = true) {
    this.view.colSize = this.view.fixedColSize || (window.innerWidth >= 1024 ? 4 : (window.innerWidth >= 768 ? 6 : 12));
    this.state.pairs = this.stateService.calcPairs(this.posts, blShuffle, this.view.colSize);

    if (this.state.pairs && this.state.pairs.length) {
      this.state.pairs.forEach((pair: any) => {
        if (pair && pair.length) {
          pair.forEach((post: post) => {
            post.size = (12 / pair.length);
          });
        }
      });
    }

    this.detectChanges();
  }

  detectChanges() {
    setTimeout(async () => {
      this.zone.run(() => {
        this.changeDetectorRef.detectChanges();
        this.events.publish('scroll:top', { route: this.view.route });
      });
    });
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.calcPairs();
    });
  }

  ngOnInit() {
    this.view = this.view || {};

    this.initEvents();
    this.calcPairs();
  }

  orderBy(orderBy: string) {
    let key = null, blShuffle: boolean = false;

    switch (orderBy) {
      case 'auto':
        blShuffle = true;
        break;
      case 'interests':
        key = 'rating';
        break;
      case 'timestamp':
        this.posts = this.posts.filter((post: post) => {
          return !!(post && post.date_gmt && post.date_gmt.length);
        });
        key = 'date_gmt';
        break;
    }

    if (!!key && this.posts && this.posts.length) {
      this.posts.sort(function (a, b) {
        var keyA = key === 'timestamp' && !!a[key] ? new Date(a[key]) : a[key],
          keyB = key === 'timestamp' && !!b[key] ? new Date(b[key]) : b[key];
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    }

    this.calcPairs(blShuffle);
  }

}