import { Component, OnInit, ViewChild } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { TemplatesService } from 'src/app/services/media/templates.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-template-store',
  standalone: false,
  templateUrl: './template-store.page.html',
  styleUrls: ['./template-store.page.scss'],
})
export class TemplateStorePage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;
  
  fallbackImg: string = './assets/img/fallback.webp';
  
  search: searchOptions = {
    itemsKey: 'templates',
    keys: ['name', 'post_content', 'title', 'description', 'url', 'uid'],
    query: "",
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'download',
      uid: 'download',
    }
  ];

  state: state = {};
  
  view: any = {
    aspect_ratios: [
      {
        uid: '16x9',
        name: '16 x 9',
      },
      {
        uid: '1x1',
        name: '1 x 1',
      },
      {
        uid: '9x16',
        name: '9 x 16',
      },
    ],
    colors: [
      {
        name: 'color_scheme_light',
        uid: 'light',
      },
      {
        name: 'color_scheme_dark',
        uid: 'dark',
      }
    ],
    custom: [
      {
        name: 'all',
        uid: 'all',
      },
      {
        name: 'media_template_format_aep',
        uid: 0,
      },
      {
        name: 'media_template_format_html5',
        uid: 1,
      },
    ],
    filters: {
      aspect_ratios: ['1x1'],
      colors: ['light', 'dark'],
      custom: ['all'],
      types: ['image', 'video'],
    },
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'template_store_top_card',
      text: 'template_store_top_card_text',
      title: 'template_store_top_card_title',
    },
    itemSize: 72,
    multiple: true,
    placeholder: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
    route: 'media/store/templates',
    key: ['name', 'post_content', 'host', 'url', 'uid'],
    templateView: {
      editable: false,
      loop: false,
      running: false,
      maxHeight: 300,
      maxWidth: 300,
    },
    title: 'template_store',
    types: [
      {
        uid: 'image',
        name: 'image',
      },
      {
        uid: 'video',
        name: 'video',
      }
    ],
    videoTemplateOptions: {
      autoplay: true,
      loop: true,
      muted: true,
    },
  };

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private templates: TemplatesService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {

  }

  buy(template: mediaTemplate) {
    this.templates.import(template)
    .then(() => {
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  calcSelectedItems(template: mediaTemplate|null = null) {

    if(this.view.templates && this.view.templates.length) {
      this.view.selectedItems = this.view.templates.filter((_template: mediaTemplate) => {
        return !!_template.checked || (!!template && (_template.uid === template.uid && !!template.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }
 
  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.canDelete = this.userService.isType(['Admin', 'Moderator']);
    this.view.canPublish = this.userService.isType(['Admin', 'Moderator']);
    this.view.canSubmitForReview = this.userService.isType(['Admin', 'Redakteur', 'Moderator']);

    this.detectChanges();
  }

  detectChanges(event: any|null = null) {

  }

  dismiss(data: any|null = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any|null = null) {
    this.loadTemplates(true)
    .then(() => {

      delete this.view.templates_backup;
      
      if(event) {
        event.target.complete();
      }
      
      this.runSearch();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
      if(event) {
        event.target.complete();
      }
      this.detectChanges();
    });
  }

  downloadSelected() {
    
    if(!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'import',
      items: this.view.selectedItems,
      service: this.templates,
      useObjectAsIdentifier: true,
    })
    .then(() => {
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  loadTemplates(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {

      this.view.templates = JSON.parse(JSON.stringify(this.view.placeholder));

      this.templates.getStore(blForceRefresh, this.view.filters)
      .then((templates: mediaTemplate[]) => {
        console.log('store response', templates);

        this.view.templates = templates.map((template: mediaTemplate) => {

          if(!template.photo) {
            if(!!template && !!template.config && !!template.config._meta && !!template.config._meta.logo_src) {
              template.photo = template.config._meta.logo_src;
            } else
            if(!!template && !!template.config && !!template.config._meta && !!template.config._meta.avatar_src) {
              template.photo = template.config._meta.avatar_src;
            }
          }
          
          return template;
        }).sort((a: any, b: any) => {
          if(a.name < b.name) return -1;
          if(a.name > b.name) return 1;
          return 0;
        });

        this.view.templates = JSON.parse(JSON.stringify((this.view.templates || []).map((template: mediaTemplate) => {
          template.view = template.view || JSON.parse(JSON.stringify(this.view.templateView));
          return template;
        })));

        resolve(templates);
      })
      .catch(reject);
    });
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

    this.loadTemplates()
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  onItemCheckboxClicked(template: mediaTemplate) {
    this.calcSelectedItems(template);
  }

  onSearchChanged(searchOptions: any|null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }
  
  onSelectionActionChanged(event: any|null = null) {
    
    if(!event || !event.option || !event.option.uid) {
      return false;
    }
    
    switch(event.option.uid) {
      case 'download':
        this.downloadSelected();
        break;
    }

  }

  playIdea(idea: any) {
    this.events.publish('media:template:preview:run', {
      template: idea,
      view: this.view,
    });
  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch(e) {
      console.error('firing toolbar search failed', e);
    }
  }

  stopIdea(idea: any) {
    this.events.publish('media:template:preview:stop', {
      template: idea,
      view: this.view,
    });
  }

  thumbnailLoadingfailed(template: mediaTemplate) {
    template.photo = this.fallbackImg;
  }
  
  upload() {
    this.media.applyFromWeb(null, {
    })
    .catch((error: any) => {
      if (!!error) {
        this.events.publish('error', error);
      }
    });
  }

  use(template: mediaTemplate) {
    this.templates.import(template)
    .then(() => {
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}
