<ion-card class="sidebar-accordion-card">

    <ion-card-header>

        <ion-card-title>
            <span [innerHTML]="'platforms'|translate"></span>
        </ion-card-title>

        <ion-button *ngIf="!!cards && !!cards.platforms" class="card-toggle" (click)="toggleCard()" icon-only
            color="dark" fill="clear">
            <ion-icon
                [name]="!!cards && !!cards.platforms && cards.platforms.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <!-- Loading spinner -->
    <ion-spinner [hidden]="!cards || !cards.platforms || !cards.platforms.open || !view.loading"></ion-spinner>

    <!-- No entries card -->
    <pipeline-no-entries-card [hidden]="!cards || !cards.platforms || !cards.platforms.open"
        *ngIf="(!view.platforms || (!!view.platforms && !view.platforms.length)) && !view.loading"></pipeline-no-entries-card>

    <ion-list [hidden]="!cards || !cards.platforms || !cards.platforms.open || !!view.loading"
        *ngIf="!!view.platforms && !!view.platforms.length">

        <ion-item *ngFor="let platform of view.platforms" class="platform-btn" [class.active]="platform.checked">

            <ion-thumbnail (click)="openPlatform(platform)" slot="start" class="platform-preview">
                <ion-img [src]="platform.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(platform)"></ion-img>
            </ion-thumbnail>

            <ion-checkbox [(ngModel)]="platform.checked" class="ion-text-wrap" justify="start" labelPlacement="end"
                [disabled]="!platform.uid" (ionChange)="onPlatformItemChecked(platform)">
                <ion-label class="ion-text-wrap" (click)="openPlatform(platform)">
                    <h3 [innerHTML]="platform.name|translate"></h3>
                </ion-label>
            </ion-checkbox>

            <ion-spinner slot="end" [hidden]="!!platform.uid"></ion-spinner>

        </ion-item>

    </ion-list>

</ion-card>