import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CommentsService } from "src/app/services/social/comments.service";
import { FoldersService } from 'src/app/services/utils/folders.service';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  _detailItem: any;

  public fbAppId: number = 2334866243377384;

  constructor(
    private AppCMS: AppcmsService,
    private comments: CommentsService,
    private folders: FoldersService,
  ) {

  }

  addSourcesToConnection(connectionId: number, sourceUids: number[]) {
    return this.AppCMS.loadPluginData('meta', {
      source_uids: sourceUids,
    }, ['connections', connectionId, 'add_sources']);
  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("meta", options, ['analyse']);
  }

  analyseComments(options: any = {}, blForceRefresh: boolean = false) {
    options = options || {};
    options.platform = options.platform || 'meta';

    console.log('meta: analyseComment: options', options);

    return this.comments.analyseComments(options);
  }

  calcState() {
    return Math.random().toString(36).substring(2, 12);
  }

  createConnection(connection: integrationConnection) {
    return this.AppCMS.loadPluginData('meta', {
      connection: connection,
    }, ['connections', 'create']);
  }

  createFolder(folder: folder) {
    folder.location = folder.location || 'meta';
    return this.folders.create(folder);
  }

  debugToken(token: string) {
    return this.AppCMS.loadPluginData('meta', {
      token: token,
    }, ['debug_token']);
  }
  
  deleteComment(commentId: number) {
    return this.AppCMS.loadPluginData('meta', {}, ['comments', commentId, 'delete']);
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('meta', {}, ['connections', connectionId, 'delete']);
  }

  deleteFolder(folderId: number) {
    return this.folders.delete(folderId);
  }

  deleteGroup(groupId: number) {
    return this.AppCMS.loadPluginData('meta', {}, ['groups', groupId, 'delete']);
  }

  deletePost(postId: number, platform: string) {
    return this.AppCMS.loadPluginData('meta', {
      platform: platform,
      uid: postId,
    }, ['posts', 'delete']);
  }

  detailItem(item: any | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('meta', options, ['log'], {}, blForceRefresh)
        .then((entries: metaApiRequestLogEntry[]) => {
          resolve(this.parseApiRequestLogEntries(entries));
        })
        .catch(reject);
    });
  }

  getComments(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('meta', options, ['comments'], {}, blForceRefresh);
  }

  getConnectionByUid(connectionId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('meta', {}, ['connections', connectionId], {}, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('meta', options, ['connections'], {}, blForceRefresh);
  }

  getFbAppId() {
    return this.fbAppId;
  }
  
  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    options.location = options.location || 'meta';
    return this.folders.get(options, blForceRefresh, params);
  }

  getGroups(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('meta', options, ['groups'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('meta', options, ['settings'], {}, blForceRefresh);
  }

  getSubscribedApps(pageId: number, options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('meta', Object.assign(options, {
      page_id: pageId,
    }), ['apps', 'subscribed'], {}, blForceRefresh);
  }

  importMedia(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("meta", options, ['import_media']);
  }

  importPosts(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("meta", options, ['import_posts']);
  }

  parseApiRequestLogEntries(entries: metaApiRequestLogEntry[]) {

    if (entries && entries.length) {
      entries.forEach((entry: metaApiRequestLogEntry) => {
        if (!!entry.data) {
          let dataKeys: string[] = Object.keys(entry.data);

          entry.parsed_data = Object.values(entry.data).map((value: any, index: number) => {
            return {
              index: index,
              label: dataKeys[index],
              value: value,
            };
          });

          entry.response_type = typeof entry.response;
        }
      });
    }

    return entries;
  }

  removeSourceFromConnection(connectionId: number, sourceId: number) {
    return this.AppCMS.loadPluginData('meta', {
      source_uid: sourceId,
    }, ['connections', connectionId, 'remove_source']);
  }

  runValidateConnectionTestsOnResponseData(data: any, connection: integrationConnection) {

    let tests: any[] = [
      {
        label: 'access_token_is_valid',
        value: (!!data.is_valid),
      },
    ];

    if (!!connection && !!connection.platform) {
      switch (connection.platform) {
        case 'facebook':
          tests.push({
            label: 'can_publish_to_groups',
            value: (!!data.scopes && (data.scopes.indexOf('publish_to_groups') !== -1)),
          });
          break;
        case 'instagram':
          tests.push({
            label: 'can_publish_instagram_content',
            value: (!!data.scopes && (data.scopes.indexOf('instagram_basic') !== -1) && (data.scopes.indexOf('instagram_content_publish') !== -1)),
          });
          break;
        case 'whatsapp':
          tests.push({
            label: 'can_publish_to_whatsapp',
            value: (!!data.scopes && (data.scopes.indexOf('whatsapp_business_messaging') !== -1)),
          });
          break;
      }
    }

    return tests;
  }

  subscribeToWebhook(pageId: number, options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('meta', Object.assign(options, {
      page_id: pageId,
    }), ['apps', 'subscribe'], {}, blForceRefresh);
  }

  updateConnection(connection: integrationConnection) {
    connection = JSON.parse(JSON.stringify(connection));

    delete connection.checked;

    return this.AppCMS.loadPluginData('meta', {
      connection: connection,
    }, ['connections', 'update']);
  }

  validateAccessToken(accessToken: string, params: any = {}) {
    return this.AppCMS.loadPluginData('meta', Object.assign(params, {
      token: accessToken,
    }), ['validateMetaAccessToken']);
  }

  validateConnection(connection: integrationConnection) {
    return new Promise((resolve, reject) => {
      if (!connection || !connection.page_token) {
        reject('error_meta_missing_page_token');
      } else {
        let params: any = {};

        if (!!connection.app_id) {
          params.meta_app_id = connection.app_id;
        }

        this.validateAccessToken(connection.page_token, params)
          .then((response: any) => {

            if (!!response.data) {
              response.tests = this.runValidateConnectionTestsOnResponseData(response.data, connection);
            }

            resolve(response);
          })
          .catch(reject);
      }
    });
  }

}