<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Main export button -->
      <ion-button *ngIf="!!view.startManually && !!view.project && !!view.project.uid"
        [hidden]="!!view.showSplineView || (view.phase === 'loading') || (view.phase === 'presenting')"
        (click)="create()" [disabled]="!!view.loading || !data || !data.length" shape="round" color="primary"
        fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <ion-toolbar class="ion-no-border container second-toolbar progress-toolbar" *ngIf="!!view.progress">
    <pipeline-progress-bar [progress]="view.progress" [view]="view"></pipeline-progress-bar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Create media intro mode -->
  <pipeline-intro-overlay [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions" [config]="introOverlayConfig" [hidden]="!!view.startManually"
    (onIntegrationChanged)="onIntegrationChanged($event)" (onSkip)="startManually()" [(view)]="view">
  </pipeline-intro-overlay>

  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container slider-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <div class="main-view">

      <ion-grid class="main-grid">

        <ion-row>

          <!-- Sidebar -->
          <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.expertMode">

            <!-- Source -->
            <div class="col-inner">
              <ion-card>

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('source')">
                    <span [innerHTML]="'source'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('source')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.source && !!cards.source.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.source && !cards.source.open">

                  <ion-card-content>
                    <p [innerHTML]="'data_duplicator_source_text'|translate"></p>
                  </ion-card-content>

                  <ion-item lines="none" *ngIf="!!view.integrations && !!view.integrations.length">

                    <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

                    <ion-select interface="popover" [label]="'integration'|translate" [(ngModel)]="view.integration"
                      (ionChange)="onIntegrationChanged($event)">
                      <ion-select-option *ngFor="let integration of view.integrations" [value]="integration"
                        [innerHTML]="integration.name|translate"></ion-select-option>
                    </ion-select>
                  </ion-item>

                </div>
              </ion-card>
            </div>

            <!-- Filters -->
            <div class="col-inner">
              <pipeline-items-filters-card [(cards)]="cards" [(view)]="view"
                (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>
            </div>

          </ion-col>

          <!-- Content -->
          <ion-col [size]="view.isDesktop && !!view.expertMode ? 9 : 12">

            <!-- Intro card -->
            <pipeline-intro-card [hidden]="view.introCard.hidden" class="introCard"
              [(view)]="view.introCard"></pipeline-intro-card>

            <ion-spinner [hidden]="!view.loading"></ion-spinner>

            <!-- Preview -->

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!view.startManually">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>