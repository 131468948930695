import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EventsService } from 'src/app/services/core/events.service';

import { ReactionsService } from 'src/app/services/social/reactions.service';
import { UserService } from 'src/app/services/core/user.service';
import { WebService } from 'src/app/services/utils/web.service';

@Component({
  selector: 'pipeline-reactions-toolbar',
  standalone: false,
  templateUrl: './reactions-toolbar.component.html',
  styleUrls: ['./reactions-toolbar.component.scss'],
})
export class ReactionsToolbarComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() item: any;
  @Input() mode: string;
  @Input() small: boolean;
  @Input() type: string;
  @Input() view: any;

  user: user;

  constructor(
    private events: EventsService,
    private reactions: ReactionsService,
    private userService: UserService,
    private _sanitizer: DomSanitizer,
    private webService: WebService,
  ) {
    
  }

  detectChanges() {

  }

  loadReactions(blForceRefresh: boolean = true) {
    this.reactions.getByItem({
      type: this.type,
      uid: this.item.uid,
    }, blForceRefresh)
    .then((response: any) => {
      if(!!response && !!response.hasOwnProperty('data')) {
        this.item.reactions = response.data || {};
      }
    })
    .catch((e: any) => {
      console.warn('loading reactions failed', e);
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};
    
    if(!!(this.user && this.user.uid)) {
      this.view.emojis = this.reactions.getEmojis();
    }
    
    this.loadReactions();
  }

  react(emoji: emoji) {

    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    // animate reaction
    this.view.stage = this._sanitizer.bypassSecurityTrustHtml(
      this.reactions.getStageHtml({
        emoji: emoji.emoji,
      })
    );
    this.detectChanges();

    setTimeout(() => {
      this.view.reaction = null;
      this.view.stage = null;
      this.detectChanges();
    }, 3 * 1000);

    // submit reaction to server
    this.reactions.reactOnPost({
      emoji: emoji.name,
      item: this.item.uid,
      type: 'post',
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });

  }

}
