import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { DropService } from 'src/app/services/core/drop.service';
import { EventsService } from "src/app/services/core/events.service";
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-tags-filter-card',
  standalone: false,
  templateUrl: './tags-filter-card.component.html',
  styleUrls: ['./tags-filter-card.component.scss']
})
export class TagsFilterCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() location: string;
  @Input() service: any;
  @Input() title: string = 'tags';
  @Input() view: any;

  @Output() selectionChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private dropService: DropService,
    private events: EventsService,
    private sidebar: SidebarService,
  ) {

  }

  async allTags() {
    this.view.tag = null;

    this.view.tags.forEach((_tag: tag) => {
      _tag.checked = false;
    });

    this.selectionChanged.emit(null);
  }


  expandList(listName: string) {
    this.view.expandedList = listName;
  }

  handleItemDropped(event: any | null = null) {
    console.log('tags-filder-card: handleItemDropped: event', event);

    this.dropService.handleItemDrop(event.dragItem, event.dropItem, 'tag')
      .then((response: any) => {
        console.log('drop response', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnDestroy() {

  }

  ngOnInit() {

  }

  async openTag(tag: tag) {

    this.view.tags.forEach((_tag: tag) => {
      _tag.checked = (tag.uid === _tag.uid);
    });

    this.selectionChanged.emit(tag);
  }

  runSearch() {

  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
    item.thumbnail = this.fallbackImg;
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.tags = this.cards.tags || {};
    this.cards.tags.open = !this.cards.tags.open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}