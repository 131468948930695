<!-- Selection options popover -->
<pipeline-selection-options-picker (onSelectionActionChanged)="runItemSelectionOption($event)" [options]="selectionOptions" [(view)]="view"></pipeline-selection-options-picker>

<!-- Share item popover -->
<ion-popover #sharePostPopover [isOpen]="isSharePostPopoverOpen" (didDismiss)="isSharePostPopoverOpen = false">
  <ng-template>
    <pipeline-caption-generator *ngIf="!!isSharePostPopoverOpen" [(post)]="view.sharePost" [(view)]="view"
      (captionChanged)="onCaptionChanged($event)"></pipeline-caption-generator>
  </ng-template>
</ion-popover>

<!-- Main card -->
<ion-card>

  <!-- Main grid -->
  <ion-grid>
    <ion-row>

      <!-- Desktop list thumbnail -->
      <ion-col [hidden]="view.viewType !== 'list' || !view.isDesktop" [size]="1">

        <!-- Post thumbnail (List view) -->
        <ion-thumbnail *ngIf="!post.preview" (click)="edit(post, index)" [hidden]="view.viewType === 'grid'">
          <ion-img [src]="(post.photo || post.thumbnail) || fallbackImg"
            (ionError)="thumbnailLoadingFailed(post)"></ion-img>
        </ion-thumbnail>

      </ion-col>

      <ion-col [size]="view.viewType === 'list' && !!view.isDesktop ? 11 : 12">

        <!-- Item top header (mobile) -->
        <ion-item-sliding>

          <ion-item lines="none">

            <ion-checkbox [(ngModel)]="post.checked" justify="start" labelPlacement="end" [disabled]="!post.uid"
              (ionChange)="_onPostItemChecked(post)">
              <p [innerHTML]="post.name" class="ion-text-wrap"></p>
              <small class="excerpt-p" [innerHTML]="(post.post_excerpt || post.post_content)"></small>
            </ion-checkbox>

            <ion-skeleton-text [animated]="!post.uid" style="display: block;float:left;width:20%;"
              [hidden]="!!post.uid || !!post.timestamp_formatted"></ion-skeleton-text>

            <ion-skeleton-text [animated]="!post.uid" style="display: block;float:left;width:60%;"
              [hidden]="!!post.uid || !!post.timestamp_formatted"></ion-skeleton-text>

            <ion-button class="drag-handle" slot="end" (click)="presentPopover($event, post)" fill="clear" icon-only
              color="dark" *ngIf="(view.mode !== 'pick') && !!post.uid">
              <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
            </ion-button>

          </ion-item>

          <ion-item-options slot="end" *ngIf="!view.isDesktop && !!post.uid">

            <ion-item-option *ngIf="!!post.uid" (click)="edit(post, index)">
              <ion-icon name="create-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'edit'|translate"></ion-label>
            </ion-item-option>

            <ion-item-option *ngIf="!!view.canSubmitForReview &&
              !!post.uid" (click)="submitForReview(post)" [hidden]="post.type !== 'draft'">
              <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap" [innerHTML]="'submit_for_review'|translate"></ion-label>
            </ion-item-option>

            <ion-item-option *ngIf="!!view.canPublish && !!post.uid" (click)="publishPost(post)"
              [hidden]="post.type !== 'review'">
              <ion-icon name="checkmark-done-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap" [innerHTML]="'publish'|translate"></ion-label>
            </ion-item-option>

            <ion-item-option *ngIf="!!view.canPromote && !!post.uid" color="success" (click)="promote(post)" [hidden]="post.type
              !== 'submit'">
              <ion-icon name="rocket-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'promote'|translate"></ion-label>
            </ion-item-option>

            <ion-item-option *ngIf="!!view.canDelete && !!post.uid" color="danger" (click)="delete(post)">
              <ion-icon name="trash-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'delete'|translate"></ion-label>
            </ion-item-option>

          </ion-item-options>
        </ion-item-sliding>

        <!-- Post preview -->
        <div class="iframe-wrapper" *ngIf="!!post.preview">
          <iframe [srcdoc]="post.preview"></iframe>
        </div>

        <!-- Post thumbnail (Grid view) -->
        <ion-thumbnail *ngIf="!post.preview" (click)="edit(post, index)" class="grid"
          [hidden]="view.viewType !== 'grid'">
          <ion-img [src]="(post.photo || post.thumbnail) || fallbackImg"
            (ionError)="thumbnailLoadingFailed(post)"></ion-img>
        </ion-thumbnail>

        <!-- Post information -->
        <ion-list>

          <ion-item class="top" lines="none">

            <ion-button [hidden]="!post.type" slot="start" fill="clear" color="primary">
              <ion-icon [name]="view.stateIcons[post.type]" slot="start" [hidden]="view.viewType === 'grid'"></ion-icon>
              <ion-label [innerHTML]="('post_type_' + post.type)|translate"></ion-label>
            </ion-button>

            <ion-button [hidden]="!post.uid" fill="clear" [hidden]="!post.type" color="dark" slot="start"
              (click)="copy(post.uid)">
              <ion-icon name="copy-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="post.uid"></ion-label>
            </ion-button>

            <ion-skeleton-text [animated]="!post.uid" style="float:right;width:30%;"
              [hidden]="!!post.uid || !!post.timestamp_formatted"></ion-skeleton-text>
            <ion-note class="ion-text-wrap" slot="end"
              [innerHTML]="post.timestamp_formatted || post.date_gmt"></ion-note>
          </ion-item>

        </ion-list>


        <!-- Footer toolbar -->
        <ion-item lines="none" class="footer-toolbar-item">

          <!-- Rate post -->
          <pipeline-rate-item-buttons slot="start" *ngIf="!!post && !!post.uid" [disabled]="!!view.loading"
            [(item)]="post"></pipeline-rate-item-buttons>

          <!-- Loading skeleton -->
          <ion-skeleton-text [animated]="!post.uid" style="float:left;width:20%;"
            [hidden]="!!post.uid || !!post.timestamp_formatted"></ion-skeleton-text>

          <!-- Edit button -->
          <ion-button *ngIf="!!post.uid" [slot]="view.isDesktop ? 'end' : ''" fill="clear" color="dark"
            (click)="edit(post, index)">
            <ion-icon name="create-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'edit'|translate" [hidden]="view.viewType === 'grid'"></ion-label>
          </ion-button>

          <!-- Submit -->
          <ion-button *ngIf="!!view.canSubmitForReview && !!post.uid" [slot]="view.isDesktop ? 'end' : ''" fill="clear"
            color="dark" (click)="submitForReview(post)"
            [hidden]="(post.type === 'post') || view.isAdmin || (post.type !== 'draft')">
            <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap" [innerHTML]="'submit_for_review'|translate"
              [hidden]="!view.isDesktop || view.viewType === 'grid'"></ion-label>
          </ion-button>

          <!-- Publish -->
          <ion-button *ngIf="!!view.canPublish && !!post.uid" [slot]="view.isDesktop ? 'end' : ''" fill="clear"
            color="dark" (click)="publishPost(post)" [hidden]="(post.type === 'post') || (!view.isAdmin &&
            (post.type !== 'review'))">
            <ion-icon name="checkmark-done-outline" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap" [innerHTML]="'publish'|translate"
              [hidden]="!view.isDesktop || view.viewType === 'grid'"></ion-label>
          </ion-button>

          <!-- Promote -->
          <ion-button *ngIf="!!view.canPromote && !!post.uid" [slot]="view.isDesktop ? 'end' : ''" fill="clear"
            color="dark" (click)="promote(post)" [hidden]="post.type !== 'submit'">
            <ion-icon name="rocket-outline" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap" [innerHTML]="'promote'|translate"
              [hidden]="!view.isDesktop || view.viewType === 'grid'"></ion-label>
          </ion-button>

          <!-- Share -->
          <ion-button *ngIf="!!view.canShare && !!post.uid && (post.type === 'post')"
            [slot]="view.isDesktop ? 'end' : ''" fill="clear" color="dark" (click)="share(post, $event)">
            <ion-icon name="share-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'share'|translate"
              [hidden]="!view.isDesktop || view.viewType === 'grid'"></ion-label>
          </ion-button>

          <!-- Delete -->
          <ion-button *ngIf="!!view.canDelete && !!post.uid" [slot]="view.isDesktop ? 'end' : ''" fill="clear"
            color="danger" (click)="delete(post)">
            <ion-icon name="trash-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'delete'|translate"
              [hidden]="!view.isDesktop || view.viewType === 'grid'"></ion-label>
          </ion-button>

        </ion-item>

      </ion-col>

    </ion-row>

  </ion-grid>

</ion-card>