import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { PaginationService } from 'src/app/services/utils/pagination.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ProjectsService } from 'src/app/services/core/projects.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { AiModelPage } from '../ai-model/ai-model.page';

@Component({
  selector: 'app-ai-models',
  standalone: false,
  templateUrl: './ai-models.page.html',
  styleUrls: ['./ai-models.page.scss'],
})
export class AiModelsPage implements OnInit {
  @ViewChild('headerPopover') headerPopover;
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  isHeaderPopoverOpen: boolean = false;

  appConfig: pipelineAppConfig;

  cards: any = {};

  fallbackImg: string = './assets/img/fallback.webp';

  filter: aiModel = {};

  integrationsByType: any;

  multiple: boolean;

  paginationConfig: paginationConfig = {
    itemsKey: 'models',
    limit: 500,
  };

  search: searchOptions = {
    itemsKey: 'models',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  view: any = {
    categories: [],
    categoryIcons: {},
    filters: {
      category: 'all',
      provider: 'all',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    introCard: {
      uid: 'ai_models_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      text: 'ai_models_top_card_text',
      title: 'ai_models_top_card_title',
    },
    mode: 'view',
    multiple: true,
    output: '',
    providers: [],
    route: 'ai/models',
    showMenuButton: true,
    showPagination: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'ai_models',
    viewType: 'grid',
    viewTypes: [
      { icon: 'list', label: 'list', uid: 'list', expertMode: false },
      { icon: 'grid', label: 'grid', uid: 'grid', expertMode: false },
      { icon: 'apps', label: 'table', uid: 'table', expertMode: false },
    ]
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private ai: AiToolsService,
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private location: Location,
    private media: MediaextendService,
    private modalService: ModalService,
    private pagination: PaginationService,
    private projects: ProjectsService,
    private router: Router,
    private sidebar: SidebarService,
    private tools: ToolsService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.integrationsByType = this.integrations.getByType();
    this.view.categoryIcons = this.ai.getCategoryIcons();
  }

  async add(event: any | null = null) {

    const modal: any = await this.modalService.create({
      component: AiModelPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  afterSearchUpdate() {
    const query: string = `${this.search.query || ''}`.toLowerCase();

    const visibleItems: any[] = this.view.models.filter((model: aiModel) => {
      return !model.hidden;
    });

    console.log('ai-models: runSearch: visibleItems', visibleItems);

    // filter sidebar authors
    this.view.authors = (this.view.authors || []).filter((author: any) => {
      console.log('> author', author);
      return `${author.name || ''}`.toLowerCase().indexOf(query) !== -1;
    });

    // filter sidebar categories
    this.view.categories = (this.view.categories || []).filter((category: any) => {
      console.log('> category', category);
      return `${category.name || ''}`.toLowerCase().indexOf(query) !== -1;
    });

    // filter sidebar providers
    this.view.providers = (this.view.providers || []).filter((provider: any) => {
      console.log('> provider', provider);
      return `${provider.name || ''}`.toLowerCase().indexOf(query) !== -1;
    });

  }

  async applyPaginationFromResponse(models: aiModel[], response: any) {

    if (!!response && !!response.pagination) {
      this.paginationConfig = this.paginationConfig || {};

      if (!!models && !!models.length) {
        this.paginationConfig.backup = models;
      }

      if (!!response.pagination.count) {
        this.paginationConfig.count = response.pagination.count;
      }

      if (!!response.pagination.max) {
        this.paginationConfig.limit = response.pagination.max;
      }
    }

    return response;
  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  calcFilters(models: aiModel[] = [], response: any) {
    console.log('ai-models: calcFilters: models', models);
    console.log('ai-models: calcFilters: response', response);

    const allItem: any = { icon: 'list-outline', name: 'all', uid: 'all' };

    if (!!response && !!response.meta && !!response.meta.categories && !!response.meta.library_names && !!response.meta.providers) {
      this.view.authors = [allItem].concat(response.meta.authors || []);

      this.view.categories = [allItem].concat(response.meta.categories.map((category: any) => {
        category.icon = this.view.categoryIcons[category.uid.toLowerCase()];
        return category;
      }));

      this.view.library_names = [allItem].concat(response.meta.library_names.map((library_name: any) => {
        library_name.icon = this.view.categoryIcons[library_name.uid.toLowerCase()];
        return library_name;
      }));

      this.view.providers = [allItem].concat(response.meta.providers); /* .map((provider: string) => {
        return {
          icon: this.view.categoryIcons[provider.toLowerCase()],
          name: provider,
          uid: provider,
        };
      }));*/

      return false;
    }

    let categoriesStrings: string[] = [],
      categories: any[] = [],
      libraries: any[] = [],
      libraryNames: string[] = [],
      providerStrings: string[] = [],
      providers: any[] = [];

    models.forEach((model: any) => {
      model.category = `${model.category || ''}`;

      const categoryId: string | null = (!!model.category ? model.category.toLowerCase() : null);
      const libraryName: string | null = (!!model.library_name ? `${model.library_name || ''}`.toLowerCase() : null);
      const providerId: string | null = (!!model.provider ? `${model.provider || ''}`.toLowerCase() : null);

      if (!!categoryId && (categoriesStrings.indexOf(categoryId) === -1)) {

        categories.push({
          icon: this.view.categoryIcons[model.category.toLowerCase()],
          name: model.category,
          name_formatted: model.category.split('-').map((part: string) => { return this.tools.capitalize(`${part || ''}`); }).join(' '),
          uid: categoryId,
        });

        categoriesStrings.push(categoryId);
      }

      if (!!libraryName && (libraryNames.indexOf(libraryName)) === -1) {

        libraries.push({
          name: libraryName,
          //photo: model.photo,
          uid: libraryName,
        });

        libraryNames.push(libraryName);
      }

      if (!!providerId && (providerStrings.indexOf(providerId)) === -1) {

        providers.push({
          name: model.provider,
          photo: model.photo,
          uid: providerId,
        });

        providerStrings.push(providerId);
      }

    });

    this.view.categories = [allItem].concat(categories);
    this.view.library_names = [allItem].concat(libraries);
    this.view.providers = [allItem].concat(providers);
  }

  async calculatePagination(posts: post[] | null = null) {
    posts = (posts || this.view.posts_backup) || [];

    this.paginationConfig = this.paginationConfig || {};

    if (!!posts && !!posts.length) {
      this.view.posts_backup = (posts || []);
      this.paginationConfig.backup = JSON.parse(JSON.stringify([...posts as post[]]));
    }

    const calc: any = await this.pagination.calculateConfig(
      Object.assign(this.view, {
        posts: posts,
        posts_backup: posts,
      }),
      this.paginationConfig
    );

    if (!!calc && !!calc.view) {
      this.view = Object.assign(this.view, calc.view);
    }

    //console.log('paginationConfig', this.paginationConfig);

    return this.view;
  }

  calcViewVars() {
    this.view.isModal = this.modalService.isModal();
    this.view.mode = (this.view.isModal ? 'pick' : 'view');

    if (this.multiple !== null && this.multiple !== undefined) {
      this.view.multiple = !!this.multiple;
    }

    this.calcColSize();
  }

  delete(model: aiModel) {

  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
      let i: number = 0;

    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  dismiss(data: any | null = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any | null = null) {
    this.loadModels(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  duplicateSelected() {

  }

  filterAuthor(author: any) {

    switch (author.uid) {
      case 'all':
        delete this.filter.author;
        break;
      default:
        this.filter.author = author.uid;
        break;
    }

    console.log('updated filter', this.filter);

    this.updateUrl();
    this.doRefresh();
  }

  filterCategory(category: any) {

    switch (category.uid) {
      case 'all':
        delete this.filter.category;
        break;
      default:
        if (this.filter.category === category.uid) {
          this.filter.category = 'all';
          delete this.filter.category;
        } else {
          this.filter.category = category.uid;
        }
        break;
    }

    console.log('updated filter', this.filter);

    this.updateUrl();
    this.doRefresh();
  }

  filterLibrary(library: any) {

    switch (library.uid) {
      case 'all':
        delete this.filter.library_name;
        break;
      default:
        if (this.filter.library_name === library.uid) {
          this.filter.library_name = 'all';
          delete this.filter.library_name;
        } else {
          this.filter.library_name = library.uid;
        }
        break;
    }

    console.log('updated filter', this.filter);

    this.updateUrl();
    this.doRefresh();
  }

  filterProvider(provider: any) {

    switch (provider.uid) {
      case 'all':
        delete this.filter.provider;
        break;
      default:
        if (this.filter.provider === provider.uid) {
          this.filter.provider = 'all';
          delete this.filter.provider;
        } else {
          this.filter.provider = provider.uid;
        }
        break;
    }

    console.log('updated filter', this.filter);

    // update url filter parameters
    this.location.go(
      this.router.createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          filter: encodeURIComponent(JSON.stringify(this.filter))
        }
      }).toString()
    );

    this.doRefresh();
  }

  filterUI() {

    if (!this.filter) {
      return false;
    }

    const keys: string[] = Object.keys(this.filter);

    // filter data by category
    if (!!keys && !!keys.length) {
      this.doRefresh();
    }
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.calcColSize();
    });
  }

  ionViewWillEnter() {
    this.initEvents();

    this.loadModels()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadModels(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loading = true;

      const options: any = {
        filter: this.filter || {},
      };

      this.ai.getAvailableModels(options, blForceRefresh)
        .then((response: any) => {
          const models: aiModel[] = (!!response && !!response.data ? response.data : []);

          // apply pagination (count) from response if provided
          this.applyPaginationFromResponse(models, response);

          this.view.models = models.map((model: aiModel) => {

            if (model.downloads === 999999999999) {
              delete model.downloads;
            }

            return model;
          });

          this.view.loading = false;
          this.view.models_backup = models;

          this.calcFilters(models, response);
          //this.filterUI();

          try {
            if (!!this.searchToolbar) {
              this.searchToolbar.updateItems(models);
            }
          } catch (e) {
            console.warn('searching failed', e);
          }

          this.calculatePagination();

          resolve(models);
        })
        .catch((error: any) => {
          this.view.loading = false;
          reject(error);
        });
    });
  }

  async loadPlatforms() {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled()
        .then((integrations: integration[]) => {

          if (!!integrations && !!integrations.length && !!this.integrationsByType.compute) {
            integrations = integrations.filter((integration: integration) => {
              integration.indent = integration.indent || `${integration.name}`.replace('integration_', '');
              return this.integrationsByType.compute.indexOf(integration.indent) !== -1;
            });
          }

          this.view.platforms = (integrations || []).map((integration: any) => {
            integration.checked = true;
            return integration;
          });

          //this.view.connections = (this.view.connections || this.view.platforms);

          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();

    this.loadProject();
    this.loadCards();

    this.loadPlatforms();
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};
    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    console.log('this.view.options.filters.connections', this.view.options.filters.connections);

    this.doRefresh();
  }

  onItemChecked(model: aiModel) {

    this.view.selectedItems = this.view.models.filter((_model: aiModel) => {
      return _model.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any | null = null) {
    this.afterSearchUpdate();
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }

  }

  onSingleSelectedModelChanged() {

    if (!!this.multiple || !this.view.singleSelectedModel) {
      return false;
    }

    this.view.singleSelectedModel.checked = true;
    this.view.selectedItems = [this.view.singleSelectedModel];
    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
      this.afterSearchUpdate();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  submitSelected(event: any | null = null) {
    return this.dismiss({
      item: (!!this.view.selectedItems && !!this.view.selectedItems[0] ? this.view.selectedItems[0] : null),
      items: (this.view.selectedItems || []),
    });
  }

  tabChanged() {

  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  updateUrl() {
    const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: { filter: this.filter } }).toString()
    console.log('filter: url', url);

    this.location.go(url);
  }

  upload(params: any = {}) {
    params.mediaType = 'attachment';
    params.multiple = true;
    params.services = ['upload'];

    this.media.applyFromWeb(null, params)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  use(model: aiModel) {

  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}