<ion-card class="sidebar-accordion-card" *ngIf="!!view">

  <ion-card-header>

    <ion-card-title>
      <span [innerHTML]="'voiceover'|translate"></span>
    </ion-card-title>

    <ion-button *ngIf="!!cards && !!cards.voiceover" class="card-toggle" (click)="toggleCard()" icon-only color="dark"
      fill="clear">
      <ion-icon [name]="!!cards && !!cards.voiceover && cards.voiceover.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
    </ion-button>

  </ion-card-header>

  <ion-card-content [hidden]="!cards || !cards.voiceover || !cards.voiceover.open">
    <p [innerHTML]="'voiceover_card_text'|translate"></p>
  </ion-card-content>

  <ion-list [hidden]="!cards || !cards.voiceover || !cards.voiceover.open">

    <ion-item lines="none">
      <ion-checkbox [(ngModel)]="view.use_tts" justify="start" labelPlacement="end"
        [innerHTML]="'use_voiceover'|translate" class="ion-text-wrap"
        (ionChange)="voiceoverVoiceSettingChanged()"></ion-checkbox>
    </ion-item>

    <!-- Use TTS? -->
    <ion-item [hidden]="!view.use_tts" lines="none">

      <ion-select interface="popover" [(ngModel)]="view.voiceover_provider" [disabled]="!view.use_tts"
        [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [label]="'voiceover_provider'|translate"
        (ionChange)="voiceoverProviderChanged()">
        <ion-select-option *ngFor="let provider of view.voiceover_providers" [value]="provider"
          [innerHTML]="provider.name|translate"></ion-select-option>
      </ion-select>

    </ion-item>

    <!-- Input prompt -->
    <ion-item [hidden]="!view.use_tts" lines="none">
      <ion-icon name="text-outline" slot="start"></ion-icon>
      <ion-textarea [disabled]="options.disabled" [rows]="view.isDesktop ? 3 : 6" [(ngModel)]="options.input"
        [placeholder]="'voiceover_input'|translate"></ion-textarea>
    </ion-item>

    <!-- Voice -->
    <ion-item [hidden]="!view.use_tts || !view.voiceover_provider" [disabled]="!view.use_tts" lines="none"
      *ngIf="!!view.voiceover_voices && !!view.voiceover_voices.length">

      <ion-select interface="popover" [(ngModel)]="view.voiceover_voice" [disabled]="!view.use_tts"
        [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [label]="'voiceover_voice'|translate"
        (ionChange)="voiceoverVoiceChanged()">
        <ion-select-option *ngFor="let voice of view.voiceover_voices" [value]="voice"
          [innerHTML]="voice.name|translate"></ion-select-option>
      </ion-select>

    </ion-item>

    <!-- Voice Stability -->
    <ion-item [hidden]="!view.use_tts || !view.voiceover_voice" [disabled]="!view.use_tts" lines="none">

      <ion-select interface="popover" [(ngModel)]="view.voice_stability" [disabled]="!view.use_tts"
        [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [label]="'voice_stability'|translate"
        (ionChange)="voiceoverVoiceSettingChanged()">
      </ion-select>

    </ion-item>

    <!-- Similarity Boost -->
    <ion-item [hidden]="!view.use_tts || !view.voiceover_voice" [disabled]="!view.use_tts" lines="none">

      <ion-select interface="popover" [(ngModel)]="view.voice_similarity_boost" [disabled]="!view.use_tts"
        [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [label]="'voice_similarity_boost'|translate"
        (ionChange)="voiceoverVoiceSettingChanged()">
      </ion-select>

    </ion-item>

    <!-- Preview -->
    <ion-item [hidden]="!view.use_tts || !view.voiceover_voice" [disabled]="!view.use_tts" lines="none">
      <ion-label [innerHTML]="'audio_preview_label'|translate"></ion-label>

      <ion-spinner [hidden]="!view.loadingVoiceover"></ion-spinner>

      <ion-button slot="end" fill="clear" [disabled]="!view.use_tts" color="primary" (click)="playPreview()"
        [hidden]="!!view.loadingVoiceover || !!view.playing">
        <ion-icon name="play"></ion-icon>
      </ion-button>

      <ion-button slot="end" fill="clear" [disabled]="!view.use_tts" color="primary" (click)="stopPreview()"
        [hidden]="!!view.loadingVoiceover || !view.playing">
        <ion-icon name="stop"></ion-icon>
      </ion-button>

    </ion-item>

  </ion-list>

  <audio #voiceoverAudioPlayer id="voiceoverAudioPlayer" [hidden]="!cards || !cards.voiceover || !cards.voiceover.open"
    *ngIf="!view.loadingVoiceover && !!view.voiceover_preview_file" controls autoplay
    [src]="view.voiceover_preview_file"></audio>

</ion-card>