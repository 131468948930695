<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button icon-only (click)="update()" *ngIf="!!folder.uid" color="primary" shape="round" fill="solid"
        [disabled]="!folder.title">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- Main header-->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Segment toolbar -->
  <ion-toolbar class="container second-toolbar">
    <ion-segment [(ngModel)]="view.segment">

      <!-- General -->
      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate"></ion-label>
      </ion-segment-button>

      <!-- Rules -->
      <ion-segment-button value="rules">
        <ion-icon name="filter-outline"></ion-icon>
        <ion-label [innerHTML]="'rules'|translate"></ion-label>
      </ion-segment-button>

      <!-- Access -->
      <ion-segment-button value="access" [disabled]="!folder.uid || !folder.title">
        <ion-icon name="people-outline"></ion-icon>
        <ion-label [innerHTML]="'access'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <!-- General -->
    <ion-card [hidden]="view.segment != 'general'">

      <ion-list>

        <ion-item lines="none">

          <ion-icon name="eye-outline" slot="start"></ion-icon>

          <ion-label position="floating" [innerHTML]="'folder_name'|translate"></ion-label>

          <ion-input [(ngModel)]="folder.title" [placeholder]="'folder_name_placeholder'|translate"
            (ionInput)="calcViewVars()"></ion-input>

        </ion-item>

        <ion-item lines="none">
          <ion-checkbox slot="start" [(ngModel)]="folder.active"></ion-checkbox>
          <ion-label [innerHTML]="'state_active'|translate"></ion-label>
        </ion-item>

        <pipeline-languages-select icon="flag-outline" [label]="'language'|translate" [(input)]="folder.language"
          (changed)="updateFolderLanguage($event)"></pipeline-languages-select>

      </ion-list>

    </ion-card>

    <!-- Rules -->
    <ion-card [hidden]="view.segment != 'rules'">
    </ion-card>

    <!-- Access -->
    <ion-card [hidden]="view.segment != 'access'">

      <ion-card-header>
        <ion-card-title [innerHTML]="'projects'|translate"></ion-card-title>
      </ion-card-header>

      <ion-card-content>

        <ion-button color="primary" (click)="addProject()">
          <ion-icon name="folder-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'add'|translate"></ion-label>
        </ion-button>

        <ion-button color="primary" (click)="createProject()">
          <ion-icon name="add-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'create_project'|translate"></ion-label>
        </ion-button>

      </ion-card-content>

    </ion-card>

  </div>

</ion-content>