import { Injectable } from '@angular/core';

declare var FB: any;

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { MetaService } from 'src/app/services/integrations/meta.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  appConfig: pipelineAppConfig;

  currentUserId: number;

  _detailItem: any;

  loggedIn: boolean = false;

  tokens: any = {
  };

  constructor(
    private AppCMS: AppcmsService,
    private config: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private meta: MetaService,
    private oauth: OauthService,
    private tools: ToolsService,
    private userService: UserService,
  ) {
    this.appConfig = this.config.getConfig();

    this.init();
  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("facebookextend", options, ['analyse']);
  }

  authorize() {
    const resourceUrl: string = this.AppCMS.getRequestUrl('facebookextend', ['profile']);
    const fbApiVersion = '20.0';

    return this.oauth.authorize({
      appId: this.meta.fbAppId,
      authorizationBaseUrl: `https://www.facebook.com/v${fbApiVersion}/dialog/oauth`,
      default: {
        clientId: this.meta.fbAppId,
        redirectUrl: `${window.location.origin}/integrations/integration/facebook/connections`,
        responseType: 'token',
      },
      web: {
        clientId: this.meta.fbAppId,
        redirectUrl: `${window.location.origin}/integrations/integration/facebook/connections`,
        responseType: 'token',
      },
      resourceUrl: resourceUrl,

      scope: [

        // instagram:
        'instagram_basic',
        //'instagram_business_basic',
        'instagram_content_publish',
        'instagram_manage_comments',
        'instagram_manage_insights',

        // generic:
        'ads_management',
        'ads_read',
        'business_management',
        'email',
        'leads_retrieval',
        'pages_show_list',
        'pages_manage_engagement',
        'pages_manage_metadata',
        'pages_manage_posts',
        'pages_messaging',
        'pages_read_engagement',
        'pages_read_user_content',
        'public_profile',

      ].join(','),

      // add instagram_content_publish?

      state: this.calcState(),

      /*
      android: {
        customHandlerClass: "com.companyname.appname.YourAndroidFacebookOAuth2Handler",
      },
      ios: {
        customHandlerClass: "App.YourIOsFacebookOAuth2Handler",
      }
      */
    });
  }

  calcState() {
    return Math.random().toString(36).substring(2, 12);
  }

  checkLoginState() {               // Called when a person is finished with the Login Button.
    FB.getLoginStatus((response: any) => {   // See the onlogin handler
      this.statusChangeCallback(response);
    });
  }

  connect(options: any = {}) {
    return new Promise((resolve, reject) => {

      // first, authorize application
      this.authorize().then((authResponse: any) => {

        // then, run connect process
        this.integrations.connect(
          Object.assign(options, authResponse)
        ).then((chooseResponse: chooseResponse) => {

          // if connects selected, add them
          if (!!chooseResponse && !!chooseResponse.data && !!chooseResponse.data.items && !!chooseResponse.data.items.length) {
            chooseResponse.data.items.forEach(async (item: integrationConnection) => {
              try {
                let pageToken: string = (item.page_token || '');
                let longLivedUserToken: string = '', userToken: string = '';

                if (!!authResponse && !!authResponse.authorization_response && !!authResponse.authorization_response.long_lived_token) {
                  longLivedUserToken = authResponse.authorization_response.long_lived_token;
                } else
                  if (!!authResponse && (authResponse.access_token || (!!authResponse.access_token_response && !!authResponse.access_token_response.access_token))) {
                    userToken = userToken || (authResponse.access_token || authResponse.access_token_response.access_token);
                  }

                let refreshToken: string = (`${(item.refresh_token || authResponse.refresh_token) || ''}` || '');

                if (!!authResponse && !!authResponse.access_token_response && !!authResponse.access_token_response.refresh_token) {
                  refreshToken = authResponse.access_token_response.refresh_token;
                }

                const create: any = await this.createConnection({
                  active: true,
                  likes: parseInt(`${item.likes || 0}`),
                  name: `${item.name || ''}`,
                  page_id: parseInt(`${item.page_id || (item.uid || item.id)}`),
                  photo: `${item.photo || ''}`,
                  platform: 'facebook',
                  url: `${item.url || ''}`,
                  user: this.userService.getUid(),

                  // tokens:
                  page_token: `${item.page_token || pageToken}`,
                  refresh_token: (refreshToken || item.refresh_token),
                  user_token: `${longLivedUserToken || (item.user_token || userToken)}`,
                });

              } catch (e) {
                console.warn('adding connection failed', e);
              }
            });
          }

          resolve(chooseResponse);

        }).catch(reject);
      }).catch(reject);
    });
  }

  createConnection(connection: integrationConnection) {
    connection.platform = 'facebook';

    return this.meta.createConnection(connection);
  }

  debugToken(token: string) {
    return this.meta.debugToken(token);
  }

  delete(connectionId: number) {
    return this.deleteConnection(connectionId);
  }

  deleteConnection(connectionId: number) {
    return this.meta.deleteConnection(connectionId);
  }

  deletePost(postId: number) {
    return this.meta.deletePost(postId, 'facebook');
  }

  detailItem(item: any | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getAccounts(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.login([
        'email',
        'pages_show_list',
        'business_management',
        //'public_profile',
      ])
        .then((loginResponse: any) => {
          let userId: number = this.getCurrentUserUid();

          if (!userId) {
            reject('error_missing_facebook_user_uid');
          } else {

            FB.api(`/${userId}/accounts`, (response: any) => {
              if (!!response.error) {
                reject(response.error.message ? response.error.message : response.error);
              } else {
                resolve(response);
              }
            });

          }
        })
        .catch(reject);
    });
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.meta.getApiRequestLog({
      filter: Object.assign(options, {
        platform: 'facebook',
      })
    }, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    options = (typeof options === 'object' ? options : {});
    options.filter = (options.filter || {});
    options.filter.platform = 'facebook';

    return this.AppCMS.loadPluginData('meta', options, ['connections'], {}, blForceRefresh);
  }

  getCurrentPageAccessToken() {
    return this.tokens.page;
  }

  async getCurrentUser() {
    /*
    const result: any = await FacebookLogin.getProfile<{
      email: string;
    }>({ fields: ['email'] });

    if (!!result && !!result.id) {
      this.setCurrentUserUid(result.id);
    }

    return result;
    */
  }

  getCurrentUserAccessToken() {
    return this.tokens.user;
  }

  getCurrentUserUid() {
    return this.currentUserId;
  }

  getFbAppId() {
    return this.meta.getFbAppId();
  }

  getSubscribedApps(pageId: number, options: any = {}, blForceRefresh: boolean = false) {
    return this.meta.getSubscribedApps(pageId, options, blForceRefresh);
  }

  importComments(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("facebookextend", options, ['import_comments']);
  }

  importMedia(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("facebookextend", options, ['import_media']);
  }

  importPosts(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("facebookextend", options, ['import_posts']);
  }

  async init() {

    if (this.tools.isWeb()) {
      this.initWeb();
    }

  }

  initWeb() {
    try {
      if (this.appConfig.useSignInWithFacebook) {

        FB.init({
          appId: this.getFbAppId(),
          cookie: true,
          xfbml: true,
          version: 'v14.0'
        });

        FB.getLoginStatus((response: any) => {
          this.statusChangeCallback(response);
        });

      }
    } catch (e) {
      console.warn('> fb web init failed', e);
    }
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  async login(permissions: any | null = null) {
    /*
    if (this.isLoggedIn()) {
      return {
        success: true,
        user: await this.getCurrentUser(),
      };
    }

    const FACEBOOK_PERMISSIONS = [
      'email',
      //'user_birthday',
      //'user_photos',
      //'user_gender',
      'pages_show_list',
      'business_management',
      //public_profile',
    ];

    const result: any = await (
      FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
    );

    let blSuccess: boolean = false, user: any;

    if (!!result && result.accessToken) {
      this.setCurrentUserAccessToken((!!result.accessToken.token ? result.accessToken.token : result.accessToken));
      blSuccess = true;

      await this.preloadUser();
    }

    return {
      success: blSuccess,
      result: result,
      user: await this.getCurrentUser(),
    };
    */
  }

  async preloadUser() {
    console.log('> fb: current user', await this.getCurrentUser());
  }

  setCurrentPageAccessToken(token: string) {
    this.tokens.page = token;
    return this;
  }

  setCurrentUserAccessToken(token: string) {
    this.tokens.user = token;
    return this;
  }

  setCurrentUserUid(id: number) {
    this.currentUserId = id;
    return this;
  }

  statusChangeCallback(response) {
    if (response.status === 'connected') {
      this.loggedIn = true;

      if (!!response && !!response.authResponse && !!response.authResponse.userID) {
        this.setCurrentUserUid(response.authResponse.userID);
      }

      this.testAPI();
    } else {
      this.loggedIn = false;

      this.events.publish('toast', {
        icon: 'logo-facebook',
        message: 'Please log into this webpage.'
      });
    }
  }

  subscribeToWebhook(pageId: number, options: any = {}, blForceRefresh: boolean = false) {
    return this.meta.subscribeToWebhook(pageId, options, blForceRefresh);
  }

  testAPI() {
    FB.api('/me', (response: any) => {
      if (!!response.error) {

        this.events.publish('toast', {
          icon: 'logo-facebook',
          message: (response.error.message ? response.error.message : response.error),
        });

      } else {

        if (!!response.id) {
          this.setCurrentUserUid(response.id);
        }

        this.events.publish('toast', {
          icon: 'logo-facebook',
          message: 'Thanks for logging in, ' + response.name,
        });

      }


    });
  }

  updateConnection(connection: integrationConnection) {
    return this.meta.updateConnection(connection);
  }

}