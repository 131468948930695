<!-- Popover -->
<ion-popover #actionsPopover [isOpen]="isActionsPopoverOpen" (didDismiss)="isActionsPopoverOpen = false">
  <ng-template>

    <!-- Transcribe file -->
    <ion-item button (click)="transcribe()" *ngIf="media.type === 'audio' || media.type === 'video'">
      <ion-icon name="chatbox-ellipses-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'transcribe_' + media.type|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

    <!-- Image inpainting -->
    <ion-item button (click)="inpaint()" *ngIf="media.type === 'image'">
      <ion-icon name="pencil-outline" slot="start" [color]="media.mode === 'inpaint' ? 'primary' : 'dark'"></ion-icon>
      <ion-label [innerHTML]="'inpaint'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

    <!-- Image outpaint -->
    <ion-item button (click)="outpaint()" *ngIf="media.type === 'image'">
      <ion-icon name="expand-outline" slot="start" [color]="media.mode === 'outpaint' ? 'primary' : 'dark'"></ion-icon>
      <ion-label [innerHTML]="'outpaint_image'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>
    
    <!-- Image: Remove background -->
    <ion-item button (click)="aiTools_removeBackground()" *ngIf="media.type === 'image'">
      <ion-icon name="sparkles-outline" slot="start" [color]="media.mode === 'remove_background' ? 'primary' : 'dark'"></ion-icon>
      <ion-label [innerHTML]="'remove_background'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

    <!-- Image to image -->
    <!--
    <ion-item button (click)="aiTools_imageToImage()" *ngIf="media.type === 'image'">
      <ion-icon name="images-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'ai_image_image_to_image'|translate"></ion-label>
    </ion-item>
    -->

    <!-- Image variations -->
    <ion-item button (click)="imageVariations()" *ngIf="media.type === 'image'">
      <ion-icon name="images-outline" slot="start"
        [color]="media.mode === 'variations' ? 'primary' : 'dark'"></ion-icon>
      <ion-label [innerHTML]="'image_variations'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

    <!-- Image to video -->
    <ion-item button (click)="aiTools_imageToVideo()" *ngIf="media.type === 'image'">
      <ion-icon name="film-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'ai_image_image_to_video'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

    <!-- Image to 3D -->
    <ion-item button (click)="aiTools_imageTo3D()" *ngIf="media.type === 'image'">
      <ion-icon name="cube-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'ai_image_to_3d'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

    <!-- SV3D -->
    <ion-item button (click)="aiTools_sv3d()" *ngIf="media.type === 'image'">
      <ion-icon name="cube-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'ai_image_sv3d'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

    <!-- Video: Screenshot frame to image -->
    <ion-item button (click)="aiTools_videoFrameToImage()" *ngIf="media.type === 'video'">
      <ion-icon name="film-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'ai_image_video_frame_to_image'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

    <!-- Video: SV4D -->
    <ion-item button (click)="aiTools_sv4d()" *ngIf="media.type === 'video'">
      <ion-icon name="cube-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'ai_video_sv4d'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

    <!-- Send to media creator -->
    <ion-item button (click)="aiTools_addTextOverlay()" *ngIf="media.type === 'image' || media.type === 'video'">
      <ion-icon name="images-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'send_to_media_creator'|translate" class="ion-text-wrap"></ion-label>
    </ion-item>

  </ng-template>
</ion-popover>

<!-- Open popover fab -->
<ion-fab slot="fixed" vertical="bottom" horizontal="start">
  <ion-fab-button color="primary" (click)="showActionsPopover($event)">
    <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>

<!-- Integrations -->
<ion-item lines="none" *ngIf="!!view && (view.mode === 'edit')">

  <ion-grid>

    <ion-row>

      <!-- Elevenlabs -->
      <ion-col [size]="view.isDesktop ? 6 : 12" *ngIf="!!view.isElevenlabsReady">

        <ion-card class="sidebar-accordion-card">

          <ion-card-header>
            <ion-card-title [innerHTML]="'integration_elevenlabs'|translate"></ion-card-title>

            <ion-item button class="card-toggle" (click)="toggleCard('elevenlabs')" icon-only fill="clear">
              <ion-icon
                [name]="!!cards && !!cards.elevenlabs && cards.elevenlabs.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-item>

          </ion-card-header>

          <ion-grid [hidden]="!!cards && !!cards.elevenlabs && !cards.elevenlabs.open">
            <ion-row>

              <!-- Elevenlabs: Change voice -->
              <ion-col *ngIf="!!view.isElevenlabsReady && (media.type === 'audio' || media.type === 'video')">

                <ion-item button (click)="aiTools_elevenlabs_changeVoice()" size="block">
                  <ion-icon name="mic-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'integration_elevenlabs_change_voice'|translate"
                    class="ion-text-wrap"></ion-label>
                </ion-item>

              </ion-col>

              <!-- Elevenlabs: Create voice -->
              <ion-col *ngIf="!!view.isElevenlabsReady && (media.type === 'audio' || media.type === 'video')">

                <ion-item button (click)="aiTools_elevenlabs_createVoice()" size="block">
                  <ion-icon name="recording-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'integration_elevenlabs_create_voice'|translate"
                    class="ion-text-wrap"></ion-label>
                </ion-item>

              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-card>

      </ion-col>

      <!-- Heygen -->
      <ion-col [size]="view.isDesktop ? 6 : 12" *ngIf="!!view.isHeygenReady && media.type === 'video'">

        <ion-card class="sidebar-accordion-card">

          <ion-card-header>
            <ion-card-title [innerHTML]="'integration_heygen'|translate"></ion-card-title>

            <ion-item button class="card-toggle" (click)="toggleCard('heygen')" icon-only fill="clear">
              <ion-icon
                [name]="!!cards && !!cards.heygen && cards.heygen.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-item>

          </ion-card-header>

          <ion-grid [hidden]="!!cards && !!cards.heygen && !cards.heygen.open">
            <ion-row>

              <!-- Audio: Create transcription -->
              <ion-col *ngIf="media.type === 'audio' || media.type === 'video'">

                <ion-item button (click)="aiTools_audioTranscribing()" size="block">
                  <ion-icon name="text-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'ai_audio_transcribing'|translate" class="ion-text-wrap"></ion-label>
                </ion-item>

              </ion-col>

              <!-- Audio: Create translation -->
              <ion-col *ngIf="media.type === 'audio' || media.type === 'video'">

                <ion-item button (click)="aiTools_audioTranslation()" size="block">
                  <ion-icon name="language-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'ai_audio_translation'|translate" class="ion-text-wrap"></ion-label>
                </ion-item>

              </ion-col>

              <!-- Heygen: Add caption to video -->
              <ion-col *ngIf="media.type === 'video'">

                <ion-item button (click)="aiTools_heygen_addCaptionToVideo()" size="block">
                  <ion-icon name="chatbox-ellipses-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'integration_heygen_add_caption_to_video'|translate"
                    class="ion-text-wrap"></ion-label>
                </ion-item>

              </ion-col>

              <!-- Heygen: Create talking photo -->
              <ion-col *ngIf="media.type === 'image' || media.type === 'video'">

                <ion-item button (click)="aiTools_heygen_uploadTalkingPhoto()" fill="clear">
                  <ion-icon name="happy-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'integration_heygen_upload_talking_photo'|translate"></ion-label>
                </ion-item>

              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-card>

      </ion-col>

    </ion-row>

  </ion-grid>

  <!--
  <ion-item button (click)="integration_heygen_image_faceswap()" [innerHTML]="'integration_heygen_image_faceswap'|translate" *ngIf="media.type === 'image'"></ion-item>
  <ion-item button (click)="integration_heygen_video_faceswap()" [innerHTML]="'integration_heygen_video_faceswap'|translate" *ngIf="media.type === 'video'"></ion-item>
  -->

  <!-- Ratings -->
  <pipeline-rate-item-buttons *ngIf="!!options.showRateItemButtons" [(item)]="media" type="media"
    slot="end"></pipeline-rate-item-buttons>

  <!-- Use as primary -->
  <ion-item button slot="end" *ngIf="!!options.showUseAsPrimary" (click)="useAsPrimary()" icon-only>
    <ion-icon name="checkmark-outline"></ion-icon>
  </ion-item>

</ion-item>