import { Component, OnInit, ViewChild } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { IntroService } from 'src/app/services/utils/intro.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ViewService } from 'src/app/services/core/view.service';

import { TranslationPage } from '../translation/translation.page';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-translations',
  standalone: false,
  templateUrl: './translations.page.html',
  styleUrls: ['./translations.page.scss'],
})
export class TranslationsPage implements OnInit {

  cta: any = {
    handler: () => {
      this.addTranslation();
    },
    icon: 'add-outline',
    label: 'create',
  };

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['title', 'key', 'value', 'input_language', 'input', 'output', 'output_language', 'url', 'value', 'name', 'indent'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  view: any = {
    filters: {
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'translations_admin_top_card',
      text: 'translations_admin_top_card_text',
      title: 'translations_admin_top_card_title',
    },
    languages: [],
    route: 'translations',
    key: ['key', 'value', 'input', 'input_language', 'output', 'output_language', 'language', 'uid'],
    showMenuButton: true,
    showLanguagesSelect: true,
    showProjectsSelect: true,
    tab: 'translations',
    title: 'translations',
    translations: [],
  };

  constructor(
    private events: EventsService,
    private exportService: ExportService,
    private introService: IntroService,
    private modalService: ModalService,
    private translation: TranslationService,
    private viewService: ViewService,
  ) {

  }

  async addTranslation() {
    this.translation.detailItem({} as any);

    const modal: any = await this.modalService.create({
      component: TranslationPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  deleteSelected(event: any | null = null) {
    console.log('delete selected: event', event);
    console.log('delete selected: view', this.view);
  }

  deleteTranslation(translation: translationItem) {

  }

  detectChanges() {

  }

  doRefresh(event: any | null = null) {
    this.loadTranslations(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

        this.detectChanges();
      })
      .catch((error: any) => {
        if (event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  duplicateSelected(event: any | null = null) {
    console.log('duplicate selected: event', event);
    console.log('duplicate selected: view', this.view);
  }

  async editTranslation(translation: translationItem) {
    this.translation.detailItem(translation as any);

    const modal: any = await this.modalService.create({
      component: TranslationPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  async export(event: any | null = null, options: any = {}) {
    this.exportService.exportUsingUI({
      data: (this.view.translations || []),
      service: this.translation,
      source: 'translations',
      type: 'translation',
    })
      .then((response: any) => {
        console.log('export response', response);

        if (!!event) {
          event.target.complete();
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  loadTranslations(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.translation.getRemoteTranslations(blForceRefresh, {
        filter: this.view.filters,
      })
        .then((translations: any) => {
          this.view.instructions = (!!translations.instructions ? translations.instructions : []);
          this.view.translations = (!!translations.data ? translations.data : []);

          delete this.view.items_backup;

          this.tabChanged();

          resolve(translations);
        })
        .catch(reject);
    });
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

    if (!!this.view.project && !!this.view.project.uid) {
      this.loadTranslations()
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }

  }

  onItemCheckboxClicked(translation: translationItem) {
    console.log('onItemCheckboxClicked', translation);
  }

  onLanguageChanged(event: any | null = null) {
    this.view.filters.language = (event || this.view.language);
    this.doRefresh();
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  async tabChanged() {
    this.view.items = (this.view.tab === 'translations' ? this.view.instructions : this.view.translations) || [];

    const introCardUid: string = `${this.view.tab}_admin_top_card`;

    this.view.introCard = {
      hidden: await this.introService.isIntroCardHidden(introCardUid),
      uid: introCardUid,
      lottieSrc: `./assets/lottie/translations.json`,
      subtitle: `${this.view.tab}_admin_top_card_subtitle`,
      text: `${this.view.tab}_admin_top_card_text`,
      title: `${this.view.tab}_admin_top_card_title`,
    };

    delete this.view.items_backup;

    this.runSearch();
  }

  update() {

  }

}