import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class ColossyanService {

  _detailItem: any;

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  createVideo(item: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('colossyan', Object.assign(options, {
      item: item,
    }), ['videos', 'create']);
  }

  detailItem(item: any|null = null) {

    if(item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getActors(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('colossyan', options, ['actors'], {}, blForceRefresh);
  }

  getFonts(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('colossyan', options, ['fonts'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('colossyan', options, ['settings'], {}, blForceRefresh);
  }

  getVoices(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('colossyan', options, ['voices'], {}, blForceRefresh);
  }

  textToSpeech(input: string, options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('colossyan', {
      input: input,
      options: options,
    }, ['textToSpeech'], {}, blForceRefresh);
  }

}