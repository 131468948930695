import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { BrowserService } from 'src/app/services/utils/browser.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ToolsService } from "src/app/services/utils/tools.service";

// Dynamic integration loader
import { IntegrationLoaderService } from 'src/app/services/integrations/integration-loader.service';

// Static integration implementations
import { MetaService } from 'src/app/services/integrations/meta.service';
import { TelegramService } from 'src/app/services/integrations/telegram.service';
import { TiktokService } from 'src/app/services/integrations/tiktok.service';

import { SharingQueuePage } from 'src/app/pages/extensions/sharing/sharing-queue/sharing-queue.page';

@Injectable({
  providedIn: 'root'
})
export class SharingAdminService {

  fallbackSharingProviderConfigs: {
    facebook: {
      url: 'https://www.facebook.com/sharer/sharer.php',
      params: ['url'],
    },
    instagram: {
      url: 'https://www.facebook.com/sharer/sharer.php',
      params: ['url'],
    },
    linkedin: {
      url: 'https://www.linkedin.com/sharing/share-offsite/', // 'https://www.linkedin.com/shareArticle',
      params: ['url'],
    },
    meta: {
      url: '',
      params: ['url'],
    },
    pinterest: {
      url: 'http://pinterest.com/pin/create/button/',
      params: ['url'],
    },
    reddit: {
      url: 'https://www.reddit.com/submit',
      params: ['url'],
    }
    telegram: {
      url: 'https://t.me/share/url',
      params: ['url'],
    },
    tiktok: {
      url: '',
      params: ['url'],
    },
    twitch: {
      url: '',
      params: ['url'],
    },
    twitter: {
      url: 'https://twitter.com/intent/tweet',
      params: ['url'],
    },
    whatsapp: {
      url: 'https://api.whatsapp.com/send',
      params: ['url'],
    },
    xing: {
      url: 'https://www.xing.com/spi/shares/new',
      params: ['url'],
    },
    youtube: {
      url: '',
      params: ['url'],
    },
  };

  queue: sharingQueueItem[] = [];

  constructor(
    private AppCMS: AppcmsService,
    private browser: BrowserService,
    private chooser: ChooserService,
    private events: EventsService,
    private loader: IntegrationLoaderService,
    private modalService: ModalService,
    private tools: ToolsService,

    // social integrations
    private meta: MetaService,
    private telegram: TelegramService,
    private tiktok: TiktokService,
  ) {

  }

  addToQueue(item: sharingQueueItem) {
    item.uid = item.uid || (this.queue.length + 1);

    this.queue.push(item);
    this.events.publish('sharing:queue:updated', this.queue);

    return this;
  }

  executeShare(post: post | mediaItem, options: any) {
    return new Promise((resolve, reject) => {

      // if sharing platform is missing, reject to share
      if (!options.platform || !options.platform.length) {
        reject('error_missing_platform');
        return false;
      } else {

        // otherwise, try to share the content
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          post: post,
        }), ['sharing', 'share', options.platform])
          .then(resolve)
          .catch((error: any) => {
            switch (error) {
              case 'Error_missing_access_token':
                // if already re-authorized, also fail
                if (!!options.reAuthorized) {
                  reject(error.message);
                } else {
                  try {
                    this[options.platform]
                      .authorize()
                      .then((response: any) => {
                        console.log('re-authorized integration', response);

                        options.reAuthorized = true;

                        // after re-auth, run execution again
                        this.executeShare(post, options).then(resolve).catch(reject);
                      })
                      .catch(reject);
                  } catch (e) {
                    reject(error.message);
                  }
                }

                break;
              default:
                reject((!!error && !!error.message ? error.message : `error_unknown`));
            }
          });
      }
    });
  }

  handleFallbackSharing(item: any, options: any) {
    return new Promise((resolve, reject) => {
      const fallbackMethodName: string = `shareTo${this.tools.capitalize(options.platform)}_Fallback`;

      try {
        this[fallbackMethodName](item).then(resolve).catch(reject);
      } catch (e) {
        console.warn('performing fallback share failed', fallbackMethodName, e);

        reject('error_sharing_fallback_not_implemented');
      }
    });
  }

  getQueue() {
    return (this.queue || []).reverse();
  }

  async getSharingTargetsData(options: any) {
    let data: any = [];

    switch (options.platform) {
      case 'facebook':
        data = await this.meta.getConnections({ filter: { platform: 'facebook' } });
        break;
      case 'instagram':
        data = await this.meta.getConnections({ filter: { platform: 'instagram' } });
        break;
      case 'telegram':
        data = await this.telegram.getBots();
        break;
      case 'tiktok':
        data = await this.tiktok.getConnections();
        break;
      default:
        try {
          data = await this[options.platform].getConnections();
          console.log('dynamic targets data', data);
        } catch (e) {
          data = [];
          console.warn('loading dynamic targets data failed', e);
        }
        break;
    }

    return data;
  }

  pickAndShare(post: post | mediaItem, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        let item: any | null = null, value: string | number | null = null;

        let response: any = {
          options: options,
          post: post,
        };

        if (!!options && !!options.connection) {
          item = options.connection;
        } else {
          const pick: any = await this.pickSharingTargets(options);

          if (!!pick && !!pick.data && !!pick.data.item) {
            response.data = pick.data;
            item = pick.data.item;
          }

        }

        value = (!!item && !!item.page_id ? item.page_id : ((item.page_token || item.token) || item.uid));

        if (!!value) {

          const queueItem: sharingQueueItem = {
            connection: item,
            post: post,
            label: post.title,
            platform: options.platform,
            platform_icon: `logo-${options.platform}`,
            progress: 'starting',
            target: value,
          };

          this.addToQueue(queueItem);
          this.showQueue();

          this.executeShare(post, {
            platform: options.platform,
            options: {
              relation: {
                value: value,
              },
            },
          })
            .then((execute: any) => {
              response.execute = execute;
              this.updateQueueResponse(queueItem, (execute || {}));
              resolve(response);
            })
            .catch((error: any) => {
              response.error = error;
              this.updateQueueResponse(queueItem, { error: error });
              reject(error);
            });

        } else {
          reject('error_missing_item');
        }
      } catch (e) {
        console.error('catched e', e);

        switch (e) {
          case 'error_sharing_targets_not_configured':
            this.handleFallbackSharing(post, options);
            break;
          default:
            reject(e);
        }

      }
    });
  }

  pickSharingTargets(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      switch (options.platform) {
        case 'telegram':
          options.subLabelKey = 'indent';
          break;
      }

      this.getSharingTargetsData(options)
        .then((data: any) => {
          if (!!data && !!data.length) {

            const chooseConfig: chooseConfig = Object.assign((options || {}), {
              data: data,
              labelKey: 'name',
              service: this,
              subLabelKey: options.subLabelKey || 'url',
              valueKey: 'uid',
            });

            this.chooser.choose(chooseConfig).then(resolve).catch(reject);

          } else {
            reject('error_sharing_targets_not_configured');
          }
        })
        .catch(reject);
    });
  }

  shareToAirtable(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  shareToClickup(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  shareToFacebook(post: post, connection: integrationConnection | null = null) {
    return this.pickAndShare(post, {
      connection: connection,
      platform: 'facebook',
    });
  }

  shareToFacebook_Fallback(post: post, connection: integrationConnection | null = null) {
    const url: string = `${this.fallbackSharingProviderConfigs.facebook.url}?u=${post.url}`;
    this.browser.create(url);
  }

  shareToInstagram(post: post, connection: integrationConnection | null = null) {
    return this.pickAndShare(post, {
      connection: connection,
      platform: 'instagram',
    });
  }

  shareToInstagram_Fallback(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  shareToLinkedin(post: post, connection: integrationConnection | null = null) {
    return this.pickAndShare(post, {
      connection: connection,
      platform: 'linkedin',
    });
  }

  shareToLinkedin_Fallback(post: post, connection: integrationConnection | null = null) {
    const url: string = `${this.fallbackSharingProviderConfigs.linkedin.url}?url=${post.url}&title=${post.name}&summary=${post.post_excerpt || post.post_content}`; // 2015+ version

    this.browser.create(url);
  }

  shareToPinterest(post: post, connection: integrationConnection | null = null) {
    return this.shareToPinterest_Fallback(post);
  }

  shareToPinterest_Fallback(post: post, connection: integrationConnection | null = null) {
    const url: string = `${this.fallbackSharingProviderConfigs.pinterest.url}?url=${post.url}`;

    this.browser.create(url);
  }

  shareToReddit(post: post, connection: integrationConnection | null = null) {
    return this.shareToReddit_Fallback(post);
  }

  shareToReddit_Fallback(post: post, connection: integrationConnection | null = null) {
    const url: string = `${this.fallbackSharingProviderConfigs.reddit.url}?url=${post.url}`;
    this.browser.create(url);
  }

  shareToSnapchat(post: post, connection: integrationConnection | null = null) {
    return this.pickAndShare(post, {
      connection: connection,
      platform: 'snapchat',
    });
  }

  shareToSnapchat_Fallback(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  shareToTelegram(post: post, connection: integrationConnection | null = null) {
    return this.pickAndShare(post, {
      connection: connection,
      platform: 'telegram',
    });
  }

  shareToTelegram_Fallback(post: post, connection: integrationConnection | null = null) {
    const url: string = `${this.fallbackSharingProviderConfigs.telegram.url}?url=${post.url}`;

    this.browser.create(url);
  }

  shareToThreads(post: post, connection: integrationConnection | null = null) {
    return this.shareToThreads_Fallback(post);
  }

  shareToThreads_Fallback(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  shareToTiktok(post: post, connection: integrationConnection | null = null) {
    return this.pickAndShare(post, {
      connection: connection,
      platform: 'tiktok',
    });
  }

  shareToTiktok_Fallback(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  shareToTwitch(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  shareToTwitch_Fallback(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  shareToTwitter(post: post, connection: integrationConnection | null = null) {
    return this.pickAndShare(post, {
      connection: connection,
      platform: 'twitter',
    });
  }

  shareToTwitter_Fallback(post: post, connection: integrationConnection | null = null) {
    const url: string = `${this.fallbackSharingProviderConfigs.twitter.url}?url=${post.url}&text=${post.post_excerpt || post.post_content}`; // &hashtags= // &via=

    this.browser.create(url);
  }

  shareToWeclapp(post: post, connection: integrationConnection | null = null) {
    return this.shareToWeclapp_Fallback(post);
  }

  shareToWeclapp_Fallback(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  shareToWhatsapp(post: post, connection: integrationConnection | null = null) {
    return this.pickAndShare(post, {
      connection: connection,
      platform: 'whatsapp',
    });
  }

  shareToWhatsapp_Fallback(post: post, connection: integrationConnection | null = null) {
    const url: string = `${this.fallbackSharingProviderConfigs.whatsapp.url}?text=${post.post_excerpt || post.post_content}\n\n${post.url}`;

    this.browser.create(url);
  }

  shareToXing(post: post, connection: integrationConnection | null = null) {
    return this.shareToXing_Fallback(post);
  }

  shareToXing_Fallback(post: post, connection: integrationConnection | null = null) {
    const url: string = `${this.fallbackSharingProviderConfigs.xing.url}?url=${post.url}`;
    this.browser.create(url);
  }

  shareToYoutube(post: post, connection: integrationConnection | null = null) {
    return this.pickAndShare(post, {
      connection: connection,
      platform: 'youtube',
    });
  }

  shareToYoutube_Fallback(post: post, connection: integrationConnection | null = null) {
    this.events.publish('error', 'error_integration_not_configured');
  }

  showQueue() {
    return new Promise(async (resolve, reject) => {

      const chooseModal: any = await this.modalService.create({
        component: SharingQueuePage,
        componentProps: {
          sharingAdmin: this,
        },
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: 'defaultModal'
      });

      chooseModal.onWillDismiss().then((response: any) => {
        resolve(response);
      });

      this.modalService.present(chooseModal);
    });
  }

  updateQueueResponse(queueItem: sharingQueueItem, response: any) {
    try {

      if (!!response && !!response.error) {
        response = response.error;
        queueItem.error = response;
      } else
        if (!!response && !!response.success) {
          queueItem.progress = 'done';
        }

      if (!!queueItem.error) {
        queueItem.progress = 'error';
      }

      queueItem.response = response;
      queueItem.response_text = (typeof queueItem.response === 'object' ? JSON.stringify(queueItem.response) : queueItem.response);

      if (this.queue && this.queue.length) {
        this.queue.forEach((item: sharingQueueItem) => {
          if (item.uid === queueItem.uid) {
            item = queueItem;
          }
        });
      }

      console.log('> queueItem', queueItem);

      this.events.publish('sharing:queue:response', {
        item: queueItem,
        queue: this.queue,
        response: response,
      });

    } catch (e) {
      console.warn('updating queue item response failed', e);
    }
  }

}