<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back butotn -->
      <ion-button (click)="back()" fill="clear" color="dark">
        <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'back'|translate"></ion-label>
      </ion-button>

      <!-- Save -->
      <ion-button shape="round" [fill]="view.isSaveMode ? 'solid' : 'clear'"
        [color]="view.isSaveMode ? 'primary' : 'dark'" (click)="save()" [disabled]="!view.canSave"
        [disabled]="!!view.isSaveMode && !view.task.name">
        <ion-icon [name]="view.isSaveMode ? 'checkmark-outline' : 'save-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate" [hidden]="!view.isSaveMode && !view.isDesktop"></ion-label>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!view.tasks && !!view.tasks.length">

      <!-- Options button -->
      <ion-button *ngIf="!!view.task && !!view.task.uid" (click)="showHeaderPopover($event)" fill="clear" icon-only
        color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Run automation -->
      <ion-button fill="solid" shape="round" *ngIf="!view.isSaveMode && !view.running" color="primary"
        [disabled]="!view.canRun" (click)="runSet()">
        <ion-icon name="play" slot="start"></ion-icon>
        <ion-label [innerHTML]="'run'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

      <!-- Stop execution -->
      <ion-button fill="solid" shape="round" *ngIf="!view.isSaveMode && !!view.running" color="primary"
        (click)="stopSet()">
        <ion-icon name="stop" slot="start"></ion-icon>
        <ion-label [innerHTML]="'stop'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Stored tasks toolbar -->
  <ion-toolbar class="ion-no-border container second-toolbar"
    *ngIf="!!view.isSaveMode || !!view.progress || !!((!view.tasks || !view.tasks.length) && ((!!view.savedTasks && !!view.savedTasks.length) || !!view.isSaveMode))">

    <ion-item lines="none" *ngIf="!!view.isSaveMode">
      <ion-icon name="eye-outline" *ngIf="!!view.isDesktop" slot="start"></ion-icon>
      <ion-input [(ngModel)]="view.task.name" [label]="'task_name'|translate" labelPlacement="floating"
        [placeholder]="'task_name'|translate"></ion-input>
    </ion-item>

    <!-- Progress bar -->
    <pipeline-progress-bar *ngIf="!!view.progress" [progress]="view.progress" [view]="view"></pipeline-progress-bar>

  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Selection options popover -->
  <pipeline-selection-options-picker [options]="selectionOptions" [(view)]="view"></pipeline-selection-options-picker>

  <pipeline-selection-options-picker [options]="selectionOptions" [(view)]="view"></pipeline-selection-options-picker>

  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list *ngIf="!!selectionOptions">

        <ion-item button class="btn-item" *ngFor="let option of selectionOptions; trackBy:trackItems"
          (click)="runItemSelectionOption(view.item, option)" [class.danger]="option.uid === 'delete'">
          <ion-icon [name]="option.icon" slot="start"></ion-icon>
          <ion-label [innerHTML]="option.label|translate"></ion-label>
        </ion-item>

      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Single task ai settings popover -->
  <ion-popover #taskAiSettingsPopover [isOpen]="isTaskAiSettingsPopoverOpen"
    (didDismiss)="isTaskAiSettingsPopoverOpen = false">
    <ng-template>

      <pipeline-ai-settings-card *ngIf="!!view.aiSettingsTask && !!view.aiSettingsTask.config" [(cards)]="cards"
        [(config)]="view.aiSettingsTask.config.ai" [options]="aiSettingsOptions" [(view)]="view"
        (changed)="onTaskAiSettingsChanged($event)"></pipeline-ai-settings-card>

    </ng-template>
  </ion-popover>

  <!-- Create automation intro mode -->
  <pipeline-intro-overlay [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions"
    [config]="introOverlayConfig" [hidden]="!!view.startManually" [(view)]="view" (onContinue)="aiCreate()"
    (onInput)="onOverlayInputChanged($event)" (onSkip)="startManually()">
  </pipeline-intro-overlay>

  <!-- Intro card -->
  <div class="container" *ngIf="!view.introCard.hidden">

    <div class="ion-padding">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

  </div>

  <!-- Caption generator -->
  <ion-popover #sharePostPopover [isOpen]="isSharePostPopoverOpen" (didDismiss)="isSharePostPopoverOpen = false">
    <ng-template>
      <pipeline-caption-generator [(post)]="view.sharePost" [(view)]="view"
        (captionChanged)="onCaptionChanged($event)"></pipeline-caption-generator>
    </ng-template>
  </ion-popover>

  <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReorder($any($event))" [hidden]="view.isSaveMode">

    <div class="task" *ngFor="let task of view.tasks; let i = index; trackBy:trackItems"
      [class.fullscreen]="task.fullscreenView">

      <div class="container-fluid">

        <ion-card>

          <!-- Step header Bar -->
          <ion-item class="task-header-item" lines="none" [class.active]="!!task.active">

            <ion-reorder slot="start"></ion-reorder>

            <ion-input type="text" (click)="editTask(task, i)" [(ngModel)]="task.name"
              [placeholder]="('ai_quick_task'|translate) + ' ' + (i+1)" (ionInput)="onChanged()"></ion-input>

            <ion-note slot="end" color="primary" [hidden]="task.promptType === 'user' || !!view.expertMode">
              <ion-label [innerHTML]="('prompt_type_' + task.promptType)|translate" class="ion-text-wrap"></ion-label>
            </ion-note>

            <ion-select interface="popover" [okText]="'okay'|translate" [label]="'prompt_type'|translate"
              labelPlacement="floating" [placeholder]="'prompt_type'|translate" [cancelText]="'cancel'|translate"
              [(ngModel)]="task.promptType" slot="end" *ngIf="!!view.expertMode" [hidden]="!task.active"
              (ionChange)="onTaskPromptTypeChanged(task)">
              <ion-select-option *ngFor="let promptType of view.promptTypes; trackBy:trackItems"
                [innerHTML]="promptType.name|translate" [value]="promptType.uid"></ion-select-option>
            </ion-select>

            <!--
            <ion-button slot="end" (click)="editTask(task, i)" [disabled]="view.running" color="dark" fill="clear"
              icon-only>
              <ion-icon name="settings-outline"></ion-icon>
            </ion-button>
            -->

            <ion-button slot="end" (click)="deleteTask(task, i)" [disabled]="view.running" color="danger" fill="clear"
              icon-only>
              <ion-icon name="trash-outline"></ion-icon>
            </ion-button>

          </ion-item>
        </ion-card>

        <ion-grid *ngIf="!!task.active">
          <ion-row>

            <ion-col
              [size]="!!view.isDesktop ? (task.promptType === 'function' ? 6 : (task.promptType !== 'user' ? (!!task.children && !!task.children.length ? 9 : 12) : view.colSize)) : 12">

              <!-- Mode Picker Card -->
              <ion-card class="task-type-card" *ngIf="!task.children || !task.children.length"
                [hidden]="task.promptType === 'function'">
                <ion-grid class="task-type-grid">

                  <ion-row>

                    <!-- Mode: Custom input -->
                    <ion-col [size]="!!i || !view.expertMode ? 6 : 4">
                      <ion-button (click)="setTaskType(task, 'custom')" expand="block" fill="clear"
                        [disabled]="view.running" [color]="task.type === 'custom' ? 'primary' : 'dark'">
                        <ion-icon name="create-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'ai_quick_custom_task_label'|translate"
                          class="ion-text-wrap"></ion-label>
                      </ion-button>
                    </ion-col>

                    <!-- Mode: Shortcode -->
                    <ion-col [size]="!!i || !view.expertMode ? 6 : 4">
                      <ion-button (click)="pickTaskShortcode(task)" expand="block" fill="clear"
                        [disabled]="view.running" [color]="task.type === 'shortcode' ? 'primary' : 'dark'">
                        <ion-icon name="code-download-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'ai_shortcode'|translate" class="ion-text-wrap"></ion-label>
                      </ion-button>
                    </ion-col>

                    <!-- Mode: Trigger -->
                    <ion-col size="4" *ngIf="!i" [hidden]="!view.expertMode">
                      <ion-button (click)="setTaskType(task, 'trigger')" expand="block" fill="clear"
                        [disabled]="view.running" [color]="task.type === 'trigger' ? 'primary' : 'dark'">
                        <ion-icon name="funnel-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'trigger'|translate" class="ion-text-wrap"></ion-label>
                      </ion-button>
                    </ion-col>

                  </ion-row>

                </ion-grid>
              </ion-card>

              <ion-card [hidden]="!task.type">

                <!-- Play single task -->
                <ion-item lines="none">

                  <ion-label
                    [innerHTML]="(task.promptType === 'function' ? 'function' : 'ai_quick_input_label')|translate"></ion-label>

                  <ion-button slot="end" icon-only fill="clear" color="primary" (click)="run(task)"
                    [disabled]="view.running"
                    [hidden]="!view.expertMode || task.loading || !task.input || !task.active || (view.tasks.length === 1)">
                    <ion-icon name="play"></ion-icon>
                  </ion-button>

                </ion-item>

                <!-- function form -->
                <ion-list *ngIf="task.promptType === 'function' && (!view.running || !!task.active)">

                  <ion-item>
                    <ion-select interface="popover" [okText]="'okay'|translate" [label]="'action'|translate"
                      [cancelText]="'cancel'|translate" [(ngModel)]="task.promptFunctionAction">
                      <ion-select-option *ngFor="let action of view.promptFunctionActions; trackBy:trackItems"
                        [innerHTML]="('prompt_function_action_' + action)|translate"
                        [value]="action"></ion-select-option>
                    </ion-select>
                  </ion-item>

                </ion-list>

                <!-- custom shortcut form inputs -->
                <ion-list [hidden]="view.expertMode" *ngIf="!!task.inputs">
                  <ion-item *ngFor="let input of task.inputs; trackBy:trackItems">
                    <ion-input type="text" [(ngModel)]="input.value" [label]="input.name|translate"
                      labelPlacement="floating" [disabled]="view.running" (ionInput)="onChanged()"></ion-input>
                  </ion-item>
                </ion-list>

                <!-- trigger form -->
                <ion-grid *ngIf="task.type === 'trigger'">

                  <ion-row>

                    <!-- trigger event type -->
                    <ion-col>

                      <ion-item>

                        <ion-icon name="funnel-outline" slot="start"></ion-icon>

                        <ion-select interface="popover" [okText]="'okay'|translate" [label]="'integration'|translate"
                          [cancelText]="'cancel'|translate" [(ngModel)]="task.triggerEventType">
                          <ion-select-option *ngFor="let type of view.triggerEventTypes"
                            [innerHTML]="(type.label || (type.name || type.title))|translate"
                            [value]="type.uid"></ion-select-option>
                        </ion-select>

                      </ion-item>

                    </ion-col>

                    <!-- trigger event -->
                    <ion-col>

                      <ion-item>

                        <ion-icon name="pulse-outline" slot="start"></ion-icon>

                        <ion-select interface="popover" [okText]="'okay'|translate" [label]="'event'|translate"
                          [cancelText]="'cancel'|translate" [(ngModel)]="task.triggerEvent">
                          <ion-select-option *ngFor="let event of view.triggerEvents"
                            [innerHTML]="('trigger_event_' + event)|translate" [value]="event"></ion-select-option>
                        </ion-select>

                      </ion-item>

                    </ion-col>

                  </ion-row>

                </ion-grid>

                <!-- Input editor -->
                <div class="editor autoheight" *ngIf="!(task.inputs && task.inputs.length && !view.expertMode)">

                  <!-- Input loading spinner -->
                  <ion-spinner *ngIf="!!task.loadingInput"></ion-spinner>

                  <!-- Child item editor -->
                  <pipeline-editor class="child-item-editor"
                    *ngIf="!task.loadingInput && !view.rebuild && !!task.children && !!task.children[task.uiChildIndex]"
                    [(editor)]="task.children[task.uiChildIndex].input_editor"
                    [input]="task.children[task.uiChildIndex].output || ''"
                    (inputChange)="onEditorInputChanged($event, task, task.uiChildIndex)"
                    [disabled]="!!view.running"></pipeline-editor>

                  <!-- Main item editor -->
                  <pipeline-editor class="main-item-editor"
                    *ngIf="!task.loadingInput && !view.rebuild && (!task.children || !task.children[task.uiChildIndex])"
                    [(editor)]="task.input_editor" [input]="task.input || ''"
                    (inputChange)="onEditorInputChanged($event, task, task.uiChildIndex)"
                    [disabled]="!!view.running"></pipeline-editor>

                </div>

                <ion-item lines="none" [hidden]="!view.expertMode">

                  <!-- Model -->
                  <!--<pipeline-ai-settings-picker slot="end" [view]="view"
                    (changed)="modelChanged($event, task, i)"></pipeline-ai-settings-picker>-->

                  <!-- Optimize input prompt -->
                  <ion-button slot="end" (click)="optimizeTaskInputPrompt(task, $event)" fill="clear" icon-only
                    color="dark" [disabled]="!!task.loading || !!view.loading">
                    <ion-icon name="sparkles-outline"></ion-icon>
                  </ion-button>

                  <!-- Task AI settings -->
                  <ion-button slot="end" (click)="showTaskAiSettingsPopover(task, i, $event)" fill="clear" icon-only
                    color="dark" [disabled]="!!task.loading || !!view.loading">
                    <ion-icon name="settings-outline"></ion-icon>
                  </ion-button>

                </ion-item>

              </ion-card>

            </ion-col>

            <!-- Output -->
            <ion-col [hidden]="task.promptType !== 'user' && task.promptType !== 'function'"
              [size]="view.isDesktop ? (!!task.children && task.children.length ? view.colSize / 2 : view.colSize) : 12">
              <ion-card>

                <ion-item lines="none">
                  <ion-label [innerHTML]="'ai_quick_output_label'|translate"></ion-label>
                </ion-item>

                <!-- AI Functions -->
                <div
                  *ngIf="!!appConfig.useAiExtension && (!task.children || !task.children[task.childIndex] || !!task.children[task.childIndex].execute)">

                  <!-- AI: Crawl URL content -->
                  <pipeline-ai-browser [autostart]="true" [config]="browserConfig"
                    (onPageDone)="onBrowserPageDone($event)" (onPageLoaded)="onBrowserPageLoaded($event)"
                    (onPageRendered)="onBrowserPageRendered($event)"
                    [input]="!!task.children && !!task.children[task.childIndex] ? (task.children[task.childIndex].input || task.children[task.childIndex].value) : task.input"
                    *ngIf="task.promptFunctionAction === 'crawl_url_content'">
                  </pipeline-ai-browser>

                  <!-- AI: Image to video -->
                  <pipeline-ai-image-to-video-card [autostart]="true" [colSize]="view.isDesktop ? 4 : 6"
                    [input]="!!task.children && !!task.children[task.childIndex] ? (task.children[task.childIndex].input || task.children[task.childIndex].value) : task.input"
                    *ngIf="task.promptFunctionAction === 'ai_image_to_video'">
                  </pipeline-ai-image-to-video-card>

                  <!-- AI: Text to audio -->
                  <pipeline-ai-text-to-audio-card [autostart]="true"
                    [input]="!!task.children && !!task.children[task.childIndex] ? (task.children[task.childIndex].input || task.children[task.childIndex].value) : task.input"
                    *ngIf="task.promptFunctionAction === 'ai_text_to_audio'">
                  </pipeline-ai-text-to-audio-card>

                  <!-- AI: Text to image -->
                  <pipeline-ai-text-to-image-card [autostart]="true" [colSize]="view.isDesktop ? 4 : 6"
                    [input]="!!task.children && !!task.children[task.childIndex] ? (task.children[task.childIndex].input || task.children[task.childIndex].value) : task.input"
                    *ngIf="task.promptFunctionAction === 'ai_text_to_image'">
                  </pipeline-ai-text-to-image-card>

                  <!-- AI: Text to speech -->
                  <pipeline-ai-text-to-speech-card [autostart]="true"
                    [input]="!!task.children && !!task.children[task.childIndex] ? (task.children[task.childIndex].input || task.children[task.childIndex].value) : task.input"
                    *ngIf="task.promptFunctionAction === 'ai_text_to_speech'">
                  </pipeline-ai-text-to-speech-card>

                  <!-- AI: Upscaler -->
                  <pipeline-ai-upscaler-card [autostart]="true"
                    [input]="!!task.children && !!task.children[task.childIndex] ? (task.children[task.childIndex].input || task.children[task.childIndex].value) : task.input"
                    *ngIf="task.promptFunctionAction === 'ai_upscaler'">
                  </pipeline-ai-upscaler-card>

                </div>

                <!-- Static output preview -->
                <!--
                <ion-card-content *ngIf="!!task.loading || !!task.output" class="output-content">
                  <ion-spinner [hidden]="!!task.output || !task.loading"></ion-spinner>
                  <div *ngIf="!!task.output" [innerHTML]="task.output"></div>
                </ion-card-content>
                -->

                <!-- Editor output preview -->
                <div class="editor autoheight" *ngIf="task.promptType !== 'function'">

                  <ion-spinner
                    [hidden]="!view.rebuild && (!task.loading || (!!task.output && !!task.output.length))"></ion-spinner>

                  <pipeline-editor *ngIf="!view.rebuild && (!!task.output && !!task.output.length || !task.loading)"
                    [(editor)]="task.output_editor" [input]="task.output"
                    (inputChange)="onEditorInputChanged($event, task, null, 'output')"
                    [disabled]="view.running"></pipeline-editor>

                </div>

                <!-- Output Toolbar -->
                <ion-toolbar class="output-toolbar" *ngIf="task.promptType !== 'function'">

                  <ion-buttons slot="start">

                    <!-- Output is list -->
                    <ion-button fill="clear" [color]="task.loop ? 'primary' : 'dark'" icon-only
                      (click)="toggleTaskListOutput(task, i)">
                      <ion-icon name="list-outline"></ion-icon>
                    </ion-button>

                    <!-- Define save paths -->
                    <ion-button fill="clear" [disabled]="view.running"
                      [color]="task.save_paths && !!task.save_paths.length ? 'primary' : 'dark'" icon-only
                      (click)="setTaskSavePath(task, i)">
                      <ion-icon [name]="task.save_paths && !!task.save_paths.length ? 'bookmark' : 'bookmark-outline'"
                        slot="start"></ion-icon>

                      <ion-label *ngIf="!!task.save_paths && !!task.save_paths.length">
                        <span [innerHTML]="'save_as'|translate"></span>
                      </ion-label>

                      <div class="chip-wrapper" *ngIf="!!task.save_paths && !!task.save_paths.length">
                        <ion-chip slot="end" *ngFor="let path of task.save_paths; trackBy:trackItems">
                          <ion-label [innerHTML]="path.name|translate"></ion-label>
                          <ion-icon name="close" (click)="removeTaskSavePath(path, task, i)"></ion-icon>
                        </ion-chip>
                      </div>

                    </ion-button>

                    <!-- Single output actions -->

                    <!-- Pause on current task -->
                    <ion-button fill="clear" [disabled]="view.running" *ngIf="!!view.expertMode"
                      [color]="task.shouldPause ? 'primary' : 'dark'" icon-only (click)="toggleShouldPause(task, i)">
                      <ion-icon [name]="task.shouldPause ? 'hand-left' : 'hand-left-outline'"></ion-icon>
                    </ion-button>

                    <!-- Toggle fullscreen view -->
                    <ion-button fill="clear" *ngIf="!!view.expertMode"
                      [color]="!!task.fullscreenView ? 'primary' : 'dark'" icon-only
                      (click)="expandView(task, i, 'output')">
                      <ion-icon [name]="!!task.fullscreenView ? 'expand' : 'expand-outline'"></ion-icon>
                    </ion-button>

                  </ion-buttons>

                  <ion-buttons slot="end">

                    <!-- Copy output to clipboard -->
                    <ion-button fill="clear" color="dark" *ngIf="!!task.output" (click)="copyText(task.output)">
                      <ion-icon name="clipboard-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'copy_to_clipboard'|translate"></ion-label>
                    </ion-button>

                    <!-- Share output -->
                    <ion-button *ngIf="!!task.output" fill="clear" color="dark" (click)="share(task, $event)">
                      <ion-icon name="share-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'share'|translate"></ion-label>
                    </ion-button>

                    <!-- Word count -->
                    <pipeline-word-counter [input]="task.output" slot="end"></pipeline-word-counter>

                    <!-- Output information -->
                    <ion-button *ngIf="!!task && !!task.info" slot="end" fill="clear" color="dark" icon-only
                      (click)="itemInfo(task, $event)">
                      <ion-icon name="information-circle-outline"></ion-icon>
                    </ion-button>

                  </ion-buttons>

                </ion-toolbar>

              </ion-card>
            </ion-col>

            <!-- Children -->
            <ion-col *ngIf="!!task.children && task.children.length" class="task-children-list"
              [size]="view.isDesktop ? (task.children && !!task.children.length ? view.colSize / 2 : (task.fullScreenMode === 'output' ? 9 : view.colSize)) : 12">

              <!-- Single child -->
              <ion-card *ngFor="let child of task.children; let iChild = index; trackBy:trackItems">

                <!-- Child top item -->
                <ion-item lines="none" class="task-child" [class.active]="task.uiChildIndex === iChild" lines="none"
                  [class.loading]="!!child.loading">

                  <ion-thumbnail slot="start" (click)="onTaskChildClick(child, task, iChild)">
                    <ion-img [src]="(child.thumbnail || child.photo) || fallbackImg"
                      (ionError)="thumbnailLoadingFailed(child)"></ion-img>
                  </ion-thumbnail>

                  <ion-label class="ion-text-wrap" (click)="onTaskChildClick(child, task, iChild)">
                    <h2 [innerHTML]="child.name"></h2>
                  </ion-label>

                  <ion-spinner slot="end" [hidden]="!child.loading"></ion-spinner>

                </ion-item>

                <!-- Child rate item -->
                <ion-item lines="none">
                  <pipeline-rate-item-buttons *ngIf="!!child.done" [(item)]="view.tasks[i].children[iChild]" type="idea"
                    slot="end"></pipeline-rate-item-buttons>
                </ion-item>

              </ion-card>

            </ion-col>

          </ion-row>
        </ion-grid>

      </div>

      <ion-icon name="arrow-down-outline" class="arrowBetween" [hidden]="!view.tasks[i+1]"></ion-icon>

    </div>

  </ion-reorder-group>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="start">

      <!-- Add new step -->
      <ion-button fill="clear" (click)="addTask()">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

    </ion-buttons>

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>