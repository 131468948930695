<ion-card
  [color]="ad.post && !!ad.post.color ? ad.post.color : 'light'"
  class="postCard homeCard adCard hasBackground accelerated card-size-{{ad && ad.post && ad.post.size ? ad.post.size : 12}}"
  [class.admin]="!!(options && options.admin)"
  [class.full-width]="(ad && ad.post && ad.post.size === 12) || !ad.post || !ad.post.size"
  [class.hasHeader]="ad && ad.post && (ad.post.avatar || ad.post.hostLabel)"
  [class.person]="ad && ad.post && (ad.post.type === 'person')"
  [class.mini]="!!(options && options.mini)"
>
  <ion-fab
    class="vipBadge"
    horizontal="end"
    *ngIf="ad && ad.post && !!ad.post.vip"
    [vertical]="ad.post.host ? 'top' : 'bottom'"
    [edge]="ad && ad.post && !!ad.post.host"
  >
    <ion-fab-button color="warning" size="small">
      <ion-icon name="lock-closed"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-fab
    class="verifiedBadge"
    horizontal="end"
    *ngIf="ad.post && (ad.post.verified && !ad.post.sponsored && !ad.post.vip && ad.post.host)"
    vertical="top"
    edge
    (click)="verifiedInfo()"
  >
    <ion-fab-button color="primary" size="small">
      <ion-icon name="shield-checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-card-header (click)="onColClick(ad.post)" *ngIf="!options || (options.show_header !== false)">
    <ion-img *ngIf="!!ad && !!ad.photo" [src]="ad.photo" (ionError)="thumbnailLoadingFailed(ad)"></ion-img>
    <ion-skeleton-text class="image" *ngIf="!ad.photo" [animated]="!ad"></ion-skeleton-text>
  </ion-card-header>

  <ion-chip color="primary" class="sponsored" *ngIf="ad.post && !!ad.post.sponsored">
    <ion-label [innerHTML]="'sponsored' | translate"></ion-label>
  </ion-chip>

  <ion-card-content *ngIf="!(options && options.mini)">

    <ion-item
      class="postHeaderItem"
      lines="none"
      *ngIf="ad.post && (ad.post.avatar || ad.name || ad.post.hostLabel || !ad.post.uid)"
      [color]="ad.post && !!ad.post.color ? ad.post.color : 'light'"
      [class.hasAvatar]="ad.post && !!ad.post.avatar"
    >
      <ion-label (click)="openProfile(ad.post)">
        <h3 *ngIf="ad.post.host" [innerHTML]="ad.post.host"></h3>
        <ion-skeleton-text
          *ngIf="!ad.post || !ad.post.name"
          style="width: 40%"
          animated
        ></ion-skeleton-text>
        <h4
          class="ion-text-wrap"
          [innerHTML]="ad.post.hostLabel | translate"
          *ngIf="ad.post && ad.post.hostLabel"
        ></h4>
      </ion-label>

      <ion-button
        slot="end"
        icon-only
        class="optionsButton"
        (click)="cardOptions(ad.post, $event)"
        [hidden]="!ad.post || !ad.post.uid"
        color="dark"
        fill="clear"
      >
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>
    </ion-item>

    <ion-card-title
      (click)="onColClick(ad.post)"
      *ngIf="ad.post && (ad.name) && (options && options.show_title !== false)"
      [innerHTML]="ad.name"
    ></ion-card-title>

    <ion-skeleton-text
      class="big"
      *ngIf="(!ad.post || !ad.post.name) && (options && options.show_title !== false)"
      style="width: 55%"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="big"
      *ngIf="(!ad.post || !ad.post.name) && (options && options.show_title !== false)"
      style="width: 30%; margin-left: 10px"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="big"
      *ngIf="(!ad.post || !ad.post.name) && (options && options.show_title !== false)"
      style="width: 80%"
      animated
    ></ion-skeleton-text>
    <p
      (click)="onColClick(ad.post)"
      *ngIf="(options && options.show_excerpt !== false) && ad.post.excerpt && ad.post.excerpt.length > 20"
      [innerHTML]="ad.post.excerpt + '...'"
    ></p>
    <ion-skeleton-text
      class="excerpt"
      style="width: 40%"
      *ngIf="(!ad.post || !ad.post.uid) && (options && options.show_title !== false)"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="excerpt"
      style="width: 50%; margin-left: 10px"
      *ngIf="(!ad.post || !ad.post.uid) && (options && options.show_title !== false)"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="excerpt"
      style="width: 70%"
      *ngIf="(!ad.post || !ad.post.uid) && (options && options.show_excerpt !== false)"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="excerpt"
      style="width: 20%; margin-left: 10px"
      *ngIf="(!ad.post || !ad.post.uid) && (options && options.show_excerpt !== false)"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="excerpt margin-bottom"
      style="width: 35%"
      *ngIf="(!ad.post || !ad.post.uid) && (options && options.show_excerpt !== false)"
      animated
    ></ion-skeleton-text>

    <ion-segment
      disabled
      class="reactions"
      *ngIf="ad.post && ad.post.reactions && view.emojis"
    >
      <ion-segment-button
        *ngFor="let emoji of view.emojis"
        [hidden]="!ad.post.reactions[emoji.name]"
        [value]="emoji"
        icon-only
      >
        <ion-label>
          <span [innerHTML]="emoji.emoji"></span>
          <small
            *ngIf="ad && ad.post && ad.post.reactions && ad.post.reactions[emoji.name]"
            [innerHTML]="ad.post.reactions[emoji.name].count"
          ></small>
        </ion-label>
      </ion-segment-button>
    </ion-segment>

  </ion-card-content>

  <div class="ad-value-wrapper" *ngIf="!!ad && !!ad.value" [innerHTML]="view.adValue"></div>

</ion-card>
