<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start" *ngIf="!inSetupEmbedded">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!view.startManually">

      <!-- Dark mode toggle (for look and feel) -->
      <ion-item *ngIf="view.segment == 'look_and_feel'" [hidden]="!view.expertMode" lines="none">
        <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="view.darkMode" slot="start">
          <ion-label class="ion-text-wrap" [innerHTML]="'dark_mode'|translate" [hidden]="!view.isDesktop"></ion-label>
        </ion-checkbox>
      </ion-item>

      <!-- Save -->
      <ion-button (click)="save()" *ngIf="!project.uid" [disabled]="!!view.loading || !project.title" shape="round"
        color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

      <!-- Update -->
      <ion-button (click)="update()" *ngIf="!!project.uid" [disabled]="!!view.loading || !project.title" shape="round"
        color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view" *ngIf="!inSetupEmbedded"></pipeline-header-toolbar>

  <!-- Segment bar -->
  <ion-toolbar class="ion-no-border container second-toolbar" *ngIf="!!view.startManually">
    <ion-segment [(ngModel)]="view.segment" (ionChange)="onSegmentChange($event)">

      <!-- General -->
      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate"></ion-label>
      </ion-segment-button>

      <!-- AI -->
      <ion-segment-button value="ai">
        <ion-icon name="sparkles-outline"></ion-icon>
        <ion-label [innerHTML]="'ai'|translate"></ion-label>
      </ion-segment-button>

      <!-- Look and feel -->
      <ion-segment-button value="look_and_feel" [disabled]="!project.title" *ngIf="!!template">
        <ion-icon name="color-palette-outline"></ion-icon>
        <ion-label [innerHTML]="'look_and_feel'|translate"></ion-label>
      </ion-segment-button>

      <!-- Compute -->
      <ion-segment-button value="compute" [disabled]="!project.title">
        <ion-icon name="server-outline"></ion-icon>
        <ion-label [innerHTML]="'limits'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

  <!-- Search bar -->
  <ion-toolbar class="ion-no-border container second-toolbar"
    *ngIf="!!view.startManually && (view.segment === 'access')">
    <ion-searchbar [(ngModel)]="search.query" [placeholder]="'search_something'|translate"
      (ionInput)="runSearch()"></ion-searchbar>
  </ion-toolbar>

  <!-- Validation message bar -->
  <ion-toolbar class="ion-no-border container second-toolbar"
    *ngIf="!!view.startManually && (!!view.validation && !!view.validation.error)">
    <ion-card color="danger">
      <ion-item color="danger">
        <ion-icon name="warning-outline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">
          <h3 [innerHTML]="'error'|translate"></h3>
          <p [innerHTML]="view.validation.message|translate"></p>
        </ion-label>
      </ion-item>
    </ion-card>
  </ion-toolbar>

</ion-header>

<ion-content [class.inSetupEmbedded]="inSetupEmbedded">

  <!-- Info popover -->
  <ion-popover #infoPopover [isOpen]="isInfoPopoverOpen" (didDismiss)="isInfoPopoverOpen = false">
    <ng-template>
      <ion-content>
        <p class="ion-padding" *ngIf="!!view.infoPopoverContent" [innerHTML]="view.infoPopoverContent|translate"></p>
      </ion-content>
    </ng-template>
  </ion-popover>

  <!-- Create project intro mode -->
  <div class="tool-intro-wrapper" *ngIf="!!project && !project.uid && !view.startManually">
    <div class="container">

      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'create_project_ai_helper_text'|translate"></ion-card-title>
        </ion-card-header>

        <!-- Intro input data -->
        <ion-list>

          <!-- URL -->
          <ion-item [hidden]="!!view.project_has_no_url" lines="none">

            <ion-thumbnail *ngIf="!!project.photo" fill="outline" color="dark" slot="start">
              <ion-skeleton-text class="image" animated [hidden]="!view.loadingPhoto"></ion-skeleton-text>
              <ion-img [src]="project.photo || fallbackImg" [hidden]="!!view.loadingPhoto"
                (ionError)="thumbnailLoadingFailed(project)"></ion-img>
            </ion-thumbnail>

            <ion-icon [hidden]="!!project.photo" name="globe-outline" slot="start"></ion-icon>

            <ion-input [autofocus]="!view.startManually" [label]="'website'|translate" labelPlacement="stacked"
              [debounce]="400" [(ngModel)]="project.link" [placeholder]="'url'|translate"
              (ionInput)="onWebsiteChanged()"></ion-input>

            <ion-icon name="warning-outline" color="warning" slot="end" [hidden]="!view.urlError"></ion-icon>
            <ion-icon name="checkmark-outline" color="success" slot="end"
              [hidden]="!view.hasValidWebsiteResponse"></ion-icon>

          </ion-item>

        </ion-list>

        <!--
        AI Browser component:

        Step 1: onPageLoaded    = Content received
        Step 2: onPageRendered  = View has been rendered
        Step 3: onPageDone      = AI finished handling the current page
        -->
        
        <pipeline-ai-browser [config]="browserConfig" [hidden]="true"
          (onPageDone)="onPageDone($event)" (onPageLoaded)="onPageLoaded($event)" (onPageRendered)="onPageRendered($event)">
        </pipeline-ai-browser>

        <!-- Language picker -->
        <pipeline-languages-select *ngIf="!view.loading && !!view.hasValidWebsiteResponse"
          [label]="'main_language'|translate" [(input)]="project.language"
          (changed)="updateProjectLanguage($event, false)" [options]="mainLanguageOptions"></pipeline-languages-select>

        <ion-list>

          <!-- Project title -->
          <ion-item lines="none" [hidden]="!view.showProjectTitle">

            <ion-input [(ngModel)]="project.title" [label]="'project_name'|translate" labelPlacement="stacked"
              [placeholder]="'project_name_placeholder'|translate"></ion-input>

          </ion-item>

          <!-- Warning: Project has no URL -->
          <ion-item color="warning" lines="none"
            [hidden]="!!view.loading || !!view.hasValidWebsiteResponse || !view.project_has_no_url">
            <ion-icon name="warning-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'project_without_website_warning_text'|translate" class="ion-text-wrap"></ion-label>
          </ion-item>

          <!-- Checkbox: Project has no URL -->
          <ion-item lines="none" [hidden]="!!view.hasValidWebsiteResponse || !!view.loading">
            <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="view.project_has_no_url"
              [innerHTML]="'project_has_no_url'|translate"></ion-checkbox>
          </ion-item>

          <!-- Clone parent -->
          <ion-item [hidden]="!view.expertMode || !view.hasValidWebsiteResponse || !!view.loading"
            *ngIf="!!view.projects && !!view.projects.length" lines="none">
            <ion-select [(ngModel)]="view.cloneProject" interface="popover" [okText]="'okay'|translate"
              [cancelText]="'cancel'|translate" [label]="'project_clone_parent'|translate" labelPlacement="floating">
              <ion-select-option *ngFor="let child of view.projects" [innerHTML]="child.title|translate"
                [value]="child.uid"></ion-select-option>
            </ion-select>
          </ion-item>

          <!-- Clone keys -->
          <ion-item [hidden]="!view.expertMode || !view.hasValidWebsiteResponse || !!view.loading || !view.cloneProject"
            *ngIf="!!view.projects && !!view.projects.length" lines="none">
            <ion-select [(ngModel)]="view.cloneKeys" multiple interface="popover" [okText]="'okay'|translate"
              [cancelText]="'cancel'|translate" [label]="'project_clone_keys'|translate" labelPlacement="floating">
              <ion-select-option *ngFor="let key of view.cloneKeys" [innerHTML]="key|translate"
                [value]="key"></ion-select-option>
            </ion-select>
          </ion-item>

        </ion-list>

        <!-- Interests header -->
        <ion-card-header [hidden]="!!view.project_has_no_url || !view.hasValidWebsiteResponse">
          <ion-card-title [innerHTML]="'create_project_interests_text'|translate"></ion-card-title>
        </ion-card-header>

        <!-- Interests grid -->
        <ion-grid class="types-grid" [hidden]="!!view.project_has_no_url || !view.hasValidWebsiteResponse">
          <ion-row>

            <ion-col [size]="view.isDesktop ? 4 : 6" *ngFor="let type of view.interests; let iType = index;">

              <ion-button class="types-btn" [class.active]="type.checked" size="block" [disabled]="type.disabled"
                [fill]="type.checked ? 'solid' : 'outline'" color="primary" (click)="toggleType(type, iType)">
                <ion-icon [hidden]="view.importStep === type.name" [name]="type.icon"></ion-icon>
                <ion-spinner [hidden]="view.importStep !== type.name"></ion-spinner>
                <ion-label [innerHTML]="type.name|translate"></ion-label>
              </ion-button>

            </ion-col>

          </ion-row>
        </ion-grid>

        <!-- Intro actions -->
        <ion-grid>
          <ion-row>

            <ion-col [size]="view.isDesktop ? 6 : 12" [hidden]="!!view.project_has_no_url">

              <ion-spinner [hidden]="!view.loading"></ion-spinner>

              <ion-button size="block" (click)="aiCreate()" shape="round" [hidden]="view.loading"
                [disabled]="!view.hasValidWebsiteResponse || !project.language">
                <ion-icon name="sparkles-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'generate'|translate"></ion-label>
              </ion-button>

            </ion-col>

            <ion-col [size]="view.isDesktop ? 6 : 12">
              <ion-button class="start-manually-btn" (click)="startManually()" color="dark" size="block" fill="clear">
                <ion-icon name="create-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'create_project_manually'|translate"></ion-label>
              </ion-button>
            </ion-col>

          </ion-row>
        </ion-grid>

      </ion-card>

    </div>
  </div>

  <div class="container" *ngIf="(!!project && !!project.uid) || !!view.startManually">

    <!-- Intro card -->
    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- Loading spinner -->
    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <!-- General -->
    <div [hidden]="!!view.loading || (view.segment != 'general')">

      <ion-card>

        <ion-item lines="none">

          <ion-thumbnail fill="outline" color="dark" slot="start" (click)="uploadPhoto()">
            <ion-skeleton-text class="image" animated [hidden]="!view.loadingPhoto"></ion-skeleton-text>
            <ion-img [src]="project.photo || fallbackImg" [hidden]="!!view.loadingPhoto"
              (ionError)="thumbnailLoadingFailed(project)"></ion-img>
          </ion-thumbnail>

          <ion-input [autofocus]="!!view.startManually" [(ngModel)]="project.title" [label]="'project_name'|translate"
            labelPlacement="stacked" [placeholder]="'project_name_placeholder'|translate"></ion-input>

        </ion-item>

        <ion-item lines="none" [class.disabled]="!project.title" [disabled]="!project.title">

          <ion-icon name="text-outline" slot="start"></ion-icon>

          <ion-textarea rows="6" [label]="'project_description'|translate" labelPlacement="stacked"
            [disabled]="!project.title" [(ngModel)]="project.description"
            [placeholder]="'project_description_placeholder'|translate"></ion-textarea>

        </ion-item>

      </ion-card>

      <ion-card>

        <ion-item [class.disabled]="!project.title" lines="none">

          <ion-spinner slot="start" [hidden]="!view.loading"></ion-spinner>

          <ion-icon name="globe-outline" [hidden]="!!view.loading" slot="start"></ion-icon>

          <ion-input [label]="'website'|translate" labelPlacement="stacked" [disabled]="!project.title" [debounce]="400"
            [(ngModel)]="project.link" [placeholder]="'url'|translate" (ionInput)="onWebsiteChanged()"></ion-input>

          <ion-icon name="warning-outline" color="warning" slot="end" [hidden]="!view.urlError"></ion-icon>
          <ion-icon name="checkmark-outline" color="success" slot="end"
            [hidden]="!view.hasValidWebsiteResponse"></ion-icon>

        </ion-item>

        <ion-item color="warning" lines="none" [hidden]="!!project.link || !project.title">
          <ion-spinner slot="start" [hidden]="!view.loading"></ion-spinner>
          <ion-icon [hidden]="!!view.loading" name="warning-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'project_without_website_warning_text'|translate" class="ion-text-wrap"></ion-label>
        </ion-item>

      </ion-card>

      <ion-card *ngIf="!!project && !!project.uid">

        <!-- Main language -->
        <pipeline-languages-select icon="flag-outline" [label]="'main_language'|translate" [(input)]="project.language"
          (changed)="updateProjectLanguage($event)" [options]="mainLanguageOptions"></pipeline-languages-select>

        <!-- All project languages -->
        <pipeline-languages-select *ngIf="!!project && !!project.config && !!project.config.languages"
          [label]="'languages'|translate" [(input)]="project.config.languages" (changed)="onLanguageChanged($event)"
          [options]="languagesOptions"></pipeline-languages-select>

      </ion-card>

    </div>

    <!-- AI -->
    <div [hidden]="!!view.loading || (view.segment != 'ai')">

      <!-- Models -->
      <!--
      <ion-card [hidden]="!view.expertMode">
        <ion-item lines="none" (click)="chooseModel()" [disabled]="!view.models || !view.models.length">
          <ion-icon name="hardware-chip-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'model'|translate"></ion-label>
          <ion-note [innerHTML]="(project.config.assets.model || 'select')|translate" slot="end"></ion-note>

          <ion-badge slot="end" *ngIf="!!project.config.assets.models && (project.config.assets.models.length > 1)">
            <ion-label [innerHTML]="'+' + (project.config.assets.models.length-1)"></ion-label>
          </ion-badge>

        </ion-item>
      </ion-card>
      -->

      <!-- Provider -->
      <ion-card>
        <ion-item lines="none">

          <ion-avatar *ngIf="!!view.provider" slot="start">
            <ion-img [src]="view.provider.photo || fallbackImg"
              (ionError)="thumbnailLoadingFailed(view.provider)"></ion-img>
          </ion-avatar>

          <ion-select (ionChange)="onProviderChanged($event)" [(ngModel)]="view.provider" interface="popover"
            [label]="'provider'|translate" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
            [placeholder]="'choose'|translate">
            <ion-select-option *ngFor="let provider of view.providers" [innerHTML]="provider.name|translate"
              [value]="provider"></ion-select-option>
          </ion-select>

          <ion-button (click)="presentInfoPopover($event, 'ai_sd_provider_infotext')" icon-only fill="clear"
            color="dark" slot="end">
            <ion-icon name="information-circle-outline"></ion-icon>
          </ion-button>

        </ion-item>
      </ion-card>

      <!-- Instances -->
      <ion-card *ngIf="!!view.provider && !!view.provider.uid" [hidden]="!view.expertMode">

        <ion-item [disabled]="!view.instances || !view.instances.length" lines="none">
          <ion-icon name="cube-outline" slot="start"></ion-icon>

          <ion-select *ngIf="!!view.instances" interface="popover" (ionChange)="onInstancesChanged($event)"
            [(ngModel)]="project.config.assets.instances" multiple [label]="'instances'|translate"
            [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [placeholder]="'choose'|translate">
            <ion-select-option *ngFor="let instance of view.instances" [innerHTML]="instance.name|translate"
              [value]="instance.url"></ion-select-option>
          </ion-select>

          <ion-button (click)="presentInfoPopover($event, 'ai_sd_instances_infotext')" icon-only fill="clear"
            color="dark" slot="end">
            <ion-icon name="information-circle-outline"></ion-icon>
          </ion-button>

        </ion-item>

      </ion-card>

      <!-- Positive Prompt -->
      <ion-card>

        <ion-item lines="none">
          <ion-icon name="thumbs-up-outline" slot="start"></ion-icon>

          <!--<ion-input type="text" [(ngModel)]="project.config.assets.prompt_suffix"
            [label]="'prompt_suffix'|translate" labelPlacement="stacked"
            [placeholder]="'prompt_suffix_placeholder'|translate"></ion-input>-->

          <ion-label [innerHTML]="'prompt_suffix'|translate"></ion-label>

          <ion-button (click)="presentInfoPopover($event, 'ai_sd_prompt_suffix_infotext')" icon-only fill="clear"
            color="dark" slot="end">
            <ion-icon name="information-circle-outline"></ion-icon>
          </ion-button>

        </ion-item>

        <ion-item lines="none">

          <tag-input [(ngModel)]="project.config.assets.prompt_suffix" [identifyBy]="'uid'" [displayBy]="'title'"
            (onAdd)="onAttributeAdd($event, 'prompt_suffix')" (onRemove)="onAttributeRemove($event, 'prompt_suffix')"
            [placeholder]="'prompt_suffix_placeholder'|translate"></tag-input>

          <ion-button (click)="optimizeAttributes('prompt_suffix')" fill="clear" color="primary" slot="end">
            <ion-icon name="sparkles-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'optimize'|translate"></ion-label>
          </ion-button>

        </ion-item>

        <!-- Recommendations -->
        <div
          *ngIf="!!view.recommendations && !!view.recommendations.prompt_suffix && !!view.recommendations.prompt_suffix.length">

          <ion-card-header>
            <ion-card-subtitle [innerHTML]="'recommendations'|translate"></ion-card-subtitle>
          </ion-card-header>

          <ion-card-content *ngIf="!!view.recommendations.prompt_suffix">
            <ion-chip *ngFor="let tag of view.recommendations.prompt_suffix"
              (click)="addRecommendation(tag, 'prompt_suffix')">
              <ion-label [innerHTML]="tag.title|translate"></ion-label>
            </ion-chip>
          </ion-card-content>

        </div>

      </ion-card>

      <!-- Negative Prompt -->
      <ion-card>

        <ion-item lines="none">
          <ion-icon name="thumbs-down-outline" slot="start"></ion-icon>

          <!--<ion-input type="text" [(ngModel)]="project.config.assets.negative_prompt"
            [label]="'negative_prompt'|translate" labelPlacement="stacked"
            [placeholder]="'negative_prompt_placeholder'|translate"></ion-input>-->

          <ion-label [innerHTML]="'negative_prompt'|translate"></ion-label>

          <ion-button (click)="presentInfoPopover($event, 'ai_sd_negative_prompt_infotext')" icon-only fill="clear"
            color="dark" slot="end">
            <ion-icon name="information-circle-outline"></ion-icon>
          </ion-button>

        </ion-item>

        <ion-item lines="none">

          <tag-input [(ngModel)]="project.config.assets.negative_prompt" [identifyBy]="'uid'" [displayBy]="'title'"
            (onAdd)="onAttributeAdd($event, 'negative_prompt')"
            (onRemove)="onAttributeRemove($event, 'negative_prompt')"
            [placeholder]="'negative_prompt_placeholder'|translate"></tag-input>

          <ion-button (click)="optimizeAttributes('negative_prompt')" fill="clear" color="primary" slot="end">
            <ion-icon name="sparkles-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'optimize'|translate"></ion-label>
          </ion-button>

        </ion-item>

        <div
          *ngIf="!!view.recommendations && !!view.recommendations.negative_prompt && !!view.recommendations.negative_prompt.length">

          <ion-card-header>
            <ion-card-subtitle [innerHTML]="'recommendations'|translate"></ion-card-subtitle>
          </ion-card-header>

          <ion-card-content *ngIf="!!view.recommendations.negative_prompt">
            <ion-chip *ngFor="let tag of view.recommendations.negative_prompt"
              (click)="addRecommendation(tag, 'negative_prompt')">
              <ion-label [innerHTML]="tag.title|translate"></ion-label>
            </ion-chip>
          </ion-card-content>

        </div>

      </ion-card>

      <!-- CFG Scale -->
      <!--
      <ion-card [hidden]="!view.expertMode">

        <ion-item lines="none">
          <ion-icon name="thermometer-outline" slot="start"></ion-icon>

          <ion-input type="number" [min]="0" [max]="100" [(ngModel)]="project.config.assets.cfg_scale"
            [label]="'cfg_scale'|translate" labelPlacement="stacked"
            [placeholder]="'cfg_scale_placeholder'|translate"></ion-input>

          <ion-button (click)="presentInfoPopover($event, 'ai_sd_cfg_scale_infotext')" icon-only fill="clear"
            color="dark" slot="end">
            <ion-icon name="information-circle-outline"></ion-icon>
          </ion-button>

        </ion-item>

      </ion-card>
      -->

      <!-- Denoising Strength -->
      <!--
      <ion-card [hidden]="!view.expertMode">

        <ion-item lines="none">
          <ion-icon name="options-outline" slot="start"></ion-icon>

          <ion-input type="number" [min]="0" [max]="100" [(ngModel)]="project.config.assets.denoising_strength"
            [label]="'denoising_strength'|translate" labelPlacement="stacked"
            [placeholder]="'denoising_strength_placeholder'|translate"></ion-input>

          <ion-button (click)="presentInfoPopover($event, 'ai_sd_denoising_strength_infotext')" icon-only fill="clear"
            color="dark" slot="end">
            <ion-icon name="information-circle-outline"></ion-icon>
          </ion-button>

        </ion-item>

      </ion-card>
      -->

      <!-- Steps -->
      <!--
      <ion-card [hidden]="!view.expertMode">

        <ion-item lines="none">
          <ion-icon name="repeat-outline" slot="start"></ion-icon>

          <ion-input type="number" [min]="0" [max]="100" [(ngModel)]="project.config.assets.steps"
            [label]="'steps'|translate" labelPlacement="stacked"
            [placeholder]="'steps_placeholder'|translate"></ion-input>

          <ion-button (click)="presentInfoPopover($event, 'ai_sd_steps_infotext')" icon-only fill="clear" color="dark"
            slot="end">
            <ion-icon name="information-circle-outline"></ion-icon>
          </ion-button>

        </ion-item>

      </ion-card>
      -->

      <!-- AI Settings -->
      <pipeline-ai-settings-card [(cards)]="cards" [(config)]="aiSettings" [options]="aiSettingsOptions"
        [(view)]="view"></pipeline-ai-settings-card>

      <!-- AI Style -->
      <pipeline-ai-style-picker [(cards)]="cards" [(view)]="view"></pipeline-ai-style-picker>

    </div>

    <!-- Look and feel -->
    <div [hidden]="!!view.loading || (view.segment != 'look_and_feel')" *ngIf="!!template" class="ion-padding">

      <pipeline-template-settings [(cards)]="cards" [mini]="true" [(template)]="template" [(view)]="view"
        (colorChanged)="templateColorVarChanged($event)" (templateChanged)="templateVarsChanged($event)">
      </pipeline-template-settings>

    </div>

    <!-- Compute -->
    <div class="compute-wrapper" [hidden]="!!view.loading || (view.segment != 'compute')">

      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'compute_mode'|translate"></ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <p [innerHTML]="'compute_mode_card_description'|translate"></p>
        </ion-card-content>

        <ion-grid>
          <ion-row>

            <!-- Queue mode -->
            <ion-col size="4">
              <ion-button class="btn-item" button (click)="setComputeMode(1)" size="block"
                [class.active]="project.config.compute.mode === 1"
                [fill]="project.config.compute.mode === 1 ? 'solid' : 'clear'" color="primary">
                <ion-icon name="hourglass-outline"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="'compute_mode_1'|translate"></h3>
                  <p [innerHTML]="'compute_mode_1_description'|translate"></p>
                </ion-label>
              </ion-button>
            </ion-col>

            <!-- Balanced -->
            <ion-col size="4">
              <ion-button class="btn-item" button (click)="setComputeMode(0)" size="block"
                [class.active]="project.config.compute.mode === 0"
                [fill]="project.config.compute.mode === 0 ? 'solid' : 'clear'" color="primary">
                <ion-icon name="bulb-outline"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="'compute_mode_0'|translate"></h3>
                  <p [innerHTML]="'compute_mode_0_description'|translate"></p>
                </ion-label>
              </ion-button>
            </ion-col>

            <!-- Performance mode -->
            <ion-col size="4">
              <ion-button class="btn-item" button (click)="setComputeMode(2)" size="block"
                [class.active]="project.config.compute.mode === 2"
                [fill]="project.config.compute.mode === 2 ? 'solid' : 'clear'" color="primary">
                <ion-icon name="rocket-outline"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="'compute_mode_2'|translate"></h3>
                  <p [innerHTML]="'compute_mode_2_description'|translate"></p>
                </ion-label>
              </ion-button>
            </ion-col>

          </ion-row>
        </ion-grid>

        <!-- Admin settings panel -->
        <ion-list *ngIf="!!view.isAdmin">

          <!-- Monthly token budget -->
          <ion-item lines="none">
            <ion-input type="number" [(ngModel)]="project.config.compute.monthly_token_budget" labelPlacement="floating"
              [label]="'monthly_token_budget'|translate"></ion-input>
          </ion-item>

        </ion-list>

      </ion-card>

    </div>

    <!-- Access -->
    <div [hidden]="!!view.loading || (view.segment != 'access')">

      <!-- Add team to project -->
      <ion-button color="primary" (click)="addTeam()">
        <ion-icon name="people-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add_team'|translate"></ion-label>
      </ion-button>

      <!-- Add user to project -->
      <ion-button color="primary" fill="clear" (click)="addMember()">
        <ion-icon name="person-add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add_member'|translate"></ion-label>
      </ion-button>

      <ion-card>

      </ion-card>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!inSetupEmbedded">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="end">

      <!-- View mode picker -->
      <pipeline-view-mode-picker [view]="view"></pipeline-view-mode-picker>

    </ion-buttons>

  </ion-toolbar>
</ion-footer>