import { Injectable } from '@angular/core';

// social loader
import { SocialService } from "src/app/services/social/social.service";

// directly loadable integrations
import { ActivecampaignService } from 'src/app/services/integrations/activecampaign.service';
import { AirtableService } from 'src/app/services/integrations/airtable.service';
import { AnthropicService } from 'src/app/services/integrations/anthropic.service';
import { AwsService } from 'src/app/services/integrations/aws.service';
import { AzureService } from 'src/app/services/integrations/microsoft_azure.service';
import { BookingcomService } from 'src/app/services/integrations/bookingcom.service';
import { BusinessCentralService } from 'src/app/services/integrations/microsoft_business_central.service';
import { CanvaService } from 'src/app/services/integrations/canva.service';
import { ChannableService } from 'src/app/services/integrations/channable.service';
import { CivitaiService } from 'src/app/services/integrations/civitai.service';
import { ClickupService } from 'src/app/services/integrations/clickup.service';
import { CohereService } from 'src/app/services/integrations/cohere.service';
import { ColossyanService } from 'src/app/services/integrations/colossyan.service';
import { ConstantcontactService } from 'src/app/services/integrations/constantcontact.service';
import { DeeplService } from 'src/app/services/integrations/deepl.service';
import { DropboxService } from 'src/app/services/integrations/dropbox.service';
import { ElevenlabsService } from 'src/app/services/integrations/elevenlabs.service';
import { FashioncloudService } from 'src/app/services/integrations/fashioncloud.service';
import { FigmaService } from 'src/app/services/integrations/figma.service';
import { FtpService } from 'src/app/services/integrations/ftp.service';
import { GoogleCloudService } from 'src/app/services/integrations/google_cloud.service';
import { GoogleDriveService } from 'src/app/services/integrations/google_drive.service';
import { HeygenService } from 'src/app/services/integrations/heygen.service';
import { HubspotService } from 'src/app/services/integrations/hubspot.service';
import { HuggingfaceService } from 'src/app/services/integrations/huggingface.service';
import { HyperstackService } from 'src/app/services/integrations/hyperstack.service';
import { LambdalabsService } from 'src/app/services/integrations/lambdalabs.service';
import { MailchimpService } from 'src/app/services/integrations/mailchimp.service';
import { MailerliteService } from 'src/app/services/integrations/mailerlite.service';
import { MicrosoftSharepointService } from 'src/app/services/integrations/microsoft_sharepoint.service';
import { MicrosoftTeamsService } from 'src/app/services/integrations/microsoft_teams.service';
import { MondayService } from 'src/app/services/integrations/monday.service';
import { NextcloudService } from 'src/app/services/integrations/nextcloud.service';
import { OnedriveService } from 'src/app/services/integrations/microsoft_onedrive.service';
import { OpenaiService } from 'src/app/services/integrations/openai.service';
import { PerspectiveService } from 'src/app/services/integrations/perspective.service';
import { ReplicateService } from 'src/app/services/integrations/replicate.service';
import { RundiffusionService } from 'src/app/services/integrations/rundiffusion.service';
import { RunwayService } from 'src/app/services/integrations/runway.service';
import { SalesforceService } from 'src/app/services/integrations/salesforce.service';
import { SapService } from 'src/app/services/integrations/sap.service';
import { ShopifyService } from 'src/app/services/ecommerce/shopify.service';
import { ShopwareService } from 'src/app/services/ecommerce/shopware.service';
import { TelegramService } from 'src/app/services/integrations/telegram.service';
import { TwilioService } from 'src/app/services/integrations/twilio.service';
import { WhatsappService } from 'src/app/services/integrations/whatsapp.service';
import { WordPressService } from 'src/app/services/integrations/wordpress.service';
import { WoocommerceextendService } from 'src/app/services/ecommerce/woocommerceextend.service';

@Injectable({
    providedIn: 'root'
})
export class IntegrationLoaderService {

    // aliases:
    ftp: any;

    constructor(
        // social loader
        private social: SocialService,

        // directly loadable integrations
        private activecampaign: ActivecampaignService,
        private airtable: AirtableService,
        private anthropic: AnthropicService,
        private aws: AwsService,
        private azure: AzureService,
        private bookingcom: BookingcomService,
        private business_central: BusinessCentralService,
        private canva: CanvaService,
        private channable: ChannableService,
        private civitai: CivitaiService,
        private clickup: ClickupService,
        private cohere: CohereService,
        private colossyan: ColossyanService,
        private constantcontact: ConstantcontactService,
        private deepl: DeeplService,
        private dropbox: DropboxService,
        private elevenlabs: ElevenlabsService,
        private fashioncloud: FashioncloudService,
        private figma: FigmaService,
        private googlecloud: GoogleCloudService,
        private googledrive: GoogleDriveService,
        private ftpmanager: FtpService,
        private heygen: HeygenService,
        private hubspot: HubspotService,
        private huggingface: HuggingfaceService,
        private hyperstack: HyperstackService,
        private lambdalabs: LambdalabsService,
        private mailchimp: MailchimpService,
        private mailerlite: MailerliteService,
        private microsoft_teams: MicrosoftTeamsService,
        private monday: MondayService,
        private nextcloud: NextcloudService,
        private onedrive: OnedriveService,
        private openai: OpenaiService,
        private perspective: PerspectiveService,
        private replicate: ReplicateService,
        private rundiffusion: RundiffusionService,
        private runway: RunwayService,
        private salesforce: SalesforceService,
        private sap: SapService,
        private sharepoint: MicrosoftSharepointService,
        private shopify: ShopifyService,
        private shopware: ShopwareService,
        private telegram: TelegramService,
        private twilio: TwilioService,
        private whatsapp: WhatsappService,
        private wordpress: WordPressService,
        private woocommerce: WoocommerceextendService,
    ) {

        // aliases:
        this.ftp = this.ftpmanager;
    }

    getService(serviceName: string) {
        if (!!this[serviceName]) {
            return {
                service: this[serviceName],
                type: 'internal',
            };
        } else {
            const socialService: any = this.social.getService(serviceName);

            if (!!socialService) {
                return {
                    service: socialService,
                    type: 'social',
                };
            }
        }
    }

}