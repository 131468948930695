import { Component, HostListener, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { BrowserService } from 'src/app/services/utils/browser.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-sharing-queue',
  standalone: false,
  templateUrl: './sharing-queue.page.html',
  styleUrls: ['./sharing-queue.page.scss'],
})
export class SharingQueuePage implements OnInit {

  fallbackImg: string = './assets/img/fallback.webp';

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'sync-outline',
      label: 'retry',
      uid: 'retry',
    }
  ];

  sharingAdmin: any;

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'sharing_queue_top_card',
      text: 'sharing_queue_top_card_text',
      title: 'sharing_queue_top_card_title',
    },
    items: [],
    showMenuButton: true,
    showProjectsSelect: true,
    //showViewModeSelect: true,
    title: 'sharing_queue',
  };

  constructor(
    private browser: BrowserService,
    private events: EventsService,
    private modalService: ModalService,
    private navParams: NavParams,
    private viewService: ViewService,
  ) {
    this.sharingAdmin = this.navParams.get('sharingAdmin');
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  deleteSelected() {

  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  initEvents() {

    this.events.subscribe('sharing:queue:updated', (queue: sharingQueueItem[]) => {
      this.view.items = queue;
    });

    this.events.subscribe('sharing:queue:response', (data: any) => {
      console.log('sharing:queue:response', data);

      if (data.hasOwnProperty('queue')) {
        this.view.items = data.queue;
      } else {
        this.loadQueue();
      }
    });

  }

  initWatcher() {
    this.loadQueue();
  }

  async loadQueue() {
    try {
      this.view.items = await this.sharingAdmin.getQueue();
      console.log('this.view.items', this.view.items);
    } catch (e) {
      console.warn('loading sharing queue failed', e);
    }
  }

  ngOnInit() {
    this.initEvents();
    this.initWatcher();

    this.calcViewVars();
  }

  onItemChecked(item: any) {
    console.log('item', item);
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'retry':
        this.retrySelected();
        break;
    }

  }

  openLink(link: string) {

    if (!link || !link.length) {
      return false;
    }

    this.browser.create(link);
  }

  retrySelected() {

  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;

    if (!!item.connection) {
      item.connection.photo = item.photo;
    }

  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}
