import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

import { AlertService } from 'src/app/services/core/alert.service';
import { DropService } from 'src/app/services/core/drop.service';
import { EventsService } from "src/app/services/core/events.service";
import { FoldersService } from 'src/app/services/utils/folders.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from "src/app/services/core/projects.service";
import { SidebarService } from 'src/app/services/utils/sidebar.service';

import { FolderSettingsPage } from 'src/app/pages/core/folders/folder-settings/folder-settings.page';

import { Subscription } from 'rxjs';

@Component({
  selector: 'pipeline-folders-filter-card',
  standalone: false,
  templateUrl: './folders-filter-card.component.html',
  styleUrls: ['./folders-filter-card.component.scss']
})
export class FoldersFilterCardComponent implements OnDestroy, OnInit {

  @Input() breadcrumbs: breadcrumb[] = [
    {
      icon: 'home-outline',
      uid: '/',
    }
  ];

  @Input() breadcrumbsConfig: breadcrumbsConfig = {

  };

  @Input() cards: any;

  @Input() config: foldersFilterCardConfig = {};

  @Input() location: string;

  @Output() onBreadcrumbsChanged = new EventEmitter();
  @Output() onChanged = new EventEmitter();
  @Output() onItemsChanged = new EventEmitter();
  @Output() onRefresh = new EventEmitter();

  @Input() paginationConfig: paginationConfig = {};

  @Input() service: any;

  subs = new Subscription();

  @Input() view: any;

  constructor(
    private alertService: AlertService,
    private dropService: DropService,
    private events: EventsService,
    private folders: FoldersService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private zone: NgZone,
  ) {

  }

  async aiCreate() {

    if (!!this.view.creatingFolderIdeas) {
      return false;
    }

    try {
      this.view.creatingFolderIdeas = true;
      this.view.loadingFolders = true;

      const ideas: any = await this.folders.createIdeas({
        location: this.location,
        project: this.view.project,
      });

      this.view.creatingFolderIdeas = false;
      this.view.loadingFolders = false;

      if (!!ideas && !!ideas.length) {
        this.view.folders = this.view.folders || [];

        ideas.forEach((idea: folder) => {
          this.view.folders.push(Object.assign(idea, {
            icon: idea.icon || 'bulb-outline',
            type: 'folder',
          }));
        })
      }

    } catch (e) {
      this.view.creatingFolderIdeas = false;
      this.view.loadingFolders = false;

      console.warn('folders: aiCreate failed', e);
    }
  }

  allFolders() {
    const key: string = this.view.itemsKey || 'items', backupKey: string = `${key}_backup`;

    // reset items
    if (!!this.view[backupKey]) {
      this.view[key] = this.view[backupKey];

      // overwrite pagination config
      if (!!this.view && !!this.paginationConfig) {
        this.paginationConfig.backup = this.view[key];
      }
    } else
      if (!!this.paginationConfig && !!this.paginationConfig.backup) {
        this.view[key] = this.paginationConfig.backup;
      }

    // reset folders view
    this.view.folders.forEach((_folder: folder) => {
      _folder.active = false;
    });

    this.breadcrumbs = [];
    this.view.currentFolder = null;
    this.view.currentFolderIndex = null;
    this.view.currentFolderParent = null;
    this.view.currentFolderUid = null;

    if (!!this.view.folders_backup) {
      this.view.folders = this.view.folders_backup;
    } else {
      this.doRefresh();
    }

    this.calculateBreadcrumbs();

    this.onChanged.emit(this.view.currentFolder);
  }

  calcFoldersChildren() {

    if (!this.view.folders || !this.view.folders.length) {
      return false;
    }

    const foldersWithChildren = this.view.folders.map((folder: folder) => {

      folder.children = this.view.folders.filter((_folder: folder) => {
        return _folder.parent === folder.uid;
      });

      return folder;
    });

    this.view.folders = foldersWithChildren.filter((folder: folder) => {
      return !folder.parent;
    });

    console.log('this.view.folders (a)', this.view.folders);
    this.view.folders_backup = this.view.folders;
  }

  calcViewVars() {

  }

  calculateBreadcrumbs() {
    let breadcrumbs: breadcrumb[] = this.breadcrumbs || [];

    // home icon
    if (breadcrumbs.length === 0) {
      breadcrumbs.push({
        icon: 'home-outline',
        uid: '/',
      });
    }

    if (!breadcrumbs || !breadcrumbs.length || (!this.view.currentFolder || (!!this.view.currentFolder && (breadcrumbs[breadcrumbs.length - 1].uid !== this.view.currentFolder.uid)))) {

      // deactivate all current breadcrumbs
      breadcrumbs = breadcrumbs.map((breadcrumb: breadcrumb) => {
        breadcrumb.active = false;
        return breadcrumb;
      });

      // add parent folder to breadcrumbs if exist
      /*
      if (!!parent) {
        breadcrumbs.push({
          icon: (parent.icon || 'folder-outline'),
          label: parent.title,
          uid: parent.uid,
        });
      }
      */

      // add current folder to breadcrumbs
      if (!!this.view.currentFolder && !!this.view.currentFolder.title && !!this.view.currentFolder.uid) {
        breadcrumbs.push({
          active: true,
          icon: (this.view.currentFolder.icon || 'folder-outline'),
          label: this.view.currentFolder.title,
          location: this.view.currentFolder.location,
          platform: this.view.currentFolder.platform,
          uid: this.view.currentFolder.uid,
        });
      }
    }

    // update breadcrumbs
    this.breadcrumbs = breadcrumbs;

    this.onBreadcrumbsChanged.emit(this.breadcrumbs);
  }

  cancelFolderMode() {
    this.view.createFolderMode = false;
  }

  createFolder() {
    this.view.createFolderMode = true;
    this.cards.folders.open = true;
  }

  createFolderIdea(folder: folder, index: number) {

    if (!folder || !folder.title) {
      return false;
    }

    this.view.folderName = folder.title;

    this.saveFolder(true, index)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async deleteFolder(folder: folder) {
    try {

      // request confirm
      const confirmed: boolean = !!(await this.alertService.requestConfirm());

      // do not delete if not confirmed
      if (!confirmed) {
        return false;
      }

      // delete if confirmed
      this.service.deleteFolder(folder.uid)
        .then(() => {
          this.loadFolders(true);
        })
        .catch((error: any) => {
          if (!!error) {
            this.events.publish('error', error);
          }
        });

    } catch (e) {
      console.warn('executing button action failed', e);
    }
  }

  detectChanges() {

  }

  async doRefresh() {
    this.onRefresh.emit();

    try {
      await this.loadFolders(true);
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  expandList(listName: string) {
    this.view.expandedList = listName;
  }

  async folderSettings(folder: folder) {

    const modal: any = await this.modalService.create({
      component: FolderSettingsPage,
      componentProps: {
        folder: folder,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: "defaultModal",
    });

    modal.onDidDismiss().then(() => {
      this.calcViewVars();
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  handleItemDropped(event: any | null = null) {
    console.log('folders-filder-card: handleItemDropped: event', event);

    if (!event.dragItem || !event.dropItem) {
      return false;
    }

    this.dropService.handleItemDrop(event.dragItem, event.dropItem, 'folder')
      .then((response: any) => {
        console.log('drop response', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async init() {
    if (!!this.view && (!this.view.folders || !this.view.folders.length)) {
      try {
        await this.loadFolders(true);
      } catch (e) {
        this.events.publish('error', e);
      }
    } else {
      this.calcFoldersChildren();
    }
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });
  }

  public loadByFolder(folder: folder, blForceRefresh: boolean = true) {
    folder.loading = true;
    this.view.loading = true;

    const key: string = this.view.itemsKey || 'items', backupKey: string = `${key}_backup`;

    if (!!this.view[key] && !this.view[backupKey]) {
      this.view[backupKey] = this.view[key];
    }

    if (typeof folder.uid === 'string') {
      // @todo: implement platform-based loading
      folder.loading = false;
      this.view.loading = false;
      return false;
    }

    this.folders.getFolderItems(folder.uid, blForceRefresh)
      .then((response: any) => {
        folder.loading = false;

        this.setFolder(folder);

        this.view[backupKey] = (response || []);
        this.view[key] = (response || []);
        this.view.loading = false;

        this.calculateBreadcrumbs();

        this.onItemsChanged.emit(this.view[key]);
        this.onChanged.emit(this.view.currentFolder);
      })
      .catch((error: any) => {
        folder.loading = false;
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  async loadCards() {
    try {
      if (!this.cards) {
        this.cards = (await this.sidebar.getCards() || (this.cards || {}));
      }
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadFolders(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.loadingFolders = false;
        return false;
      } else {
        this.view.loadingFolders = true;

        try {
          this.view.options = this.view.options || {};

          // build filters
          let filters: any = JSON.parse(JSON.stringify(this.view.options));

          if (!!this.location) {
            filters.location = this.location;
          }

          // remove attributes from folder filters
          delete filters.attributes;

          this.service.getFolders(filters, blForceRefresh, {
            limit: 1000,
          })
            .then((folders: folder[]) => {
              this.view.loadingFolders = false;

              this.view.folders = (folders || [])
                .map((folder: folder) => {
                  folder.active = !!(!!this.view.currentFolder && (this.view.currentFolder.uid === folder.uid));
                  return folder;
                })
                .sort((a: any, b: any) => {

                  const _a: string = `${a.title}`.toLowerCase(),
                    _b: string = `${b.title}`.toLowerCase();

                  if (_a < _b) return -1;
                  if (_b > _a) return 1;
                  return 0;
                });

              if (!this.view.folders || !this.view.folders.length) {
                this.aiCreate();
              }

              this.calcFoldersChildren();
              this.detectChanges();

              resolve(folders);
            })
            .catch((error: any) => {
              this.view.loadingFolders = false;
              reject(error);
            });
        } catch (e) {
          reject(e);
        }
      }

    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnDestroy() {

    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }

    try {
      this.subs.unsubscribe();
    } catch (e) {
      console.warn('unsubscribe failed', e);
    }
  }

  ngOnInit() {
    this.init();
    this.initEvents();
    this.loadCards();
  }

  onBreadcrumbsButtonClick(breadcrumb: breadcrumb) {

    if (!breadcrumb || !breadcrumb.uid) {
      return false;
    }

    try {
      this.openFolder(breadcrumb as folder);
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  onMouseMove(e: any) {
    console.log('onMouseMove', e);

    this.view.dropItem = document.elementFromPoint(e.clientX, e.clientY);

    try {
      e.preventDefault();
    } catch (e) {
      console.warn('prevent failed', e);
    }

    return false;
  }

  public openFolder(folder: folder, folderIndex: number | null = null) {

    console.log('folders-filter-card: openFolder: folder', folder);
    console.log('folders-filter-card: openFolder: folderIndex', folderIndex);

    // ideas cannot be opened
    if (!folder || !folder.uid || (folder.uid === -1) || !!folder.loading || !!this.view.loading) {
      return false;
    }

    if (!!folder && !!folder.location) {
      return this.loadByFolder(folder);
    }

    this.setFolder(folder, folderIndex);
    this.onChanged.emit(this.view.currentFolder);
  }

  async saveFolder(blBackground: boolean = false, index: number | null = null) {

    if (!this.view.folderName || !this.view.folderName.length) {
      return false;
    }

    this.view.folders = this.view.folders || [];

    const folder: folder = {
      active: true,
      location: this.location,
      parent: (this.view.newFolderParent || 0),
      title: this.view.folderName,
    };

    this.view.folders.push(folder);

    try {
      this.service.createFolder(folder)
        .then(async (response: any) => {
          this.view.folderName = '';
          this.cancelFolderMode();

          if (!blBackground) {
            await this.doRefresh();
          } else
            if (!!response && !!response.folder && !!response.folder.uid && (index !== null) && !!this.view.folders[index]) {
              this.view.folders[index].icon = 'folder-outline';
              this.view.folders[index].uid = response.folder.uid;
            }
        })
        .catch(async (error: any) => {
          this.events.publish('error', error);
          await this.doRefresh();
        });
    } catch (e) {
      this.events.publish('error', e);
      await this.doRefresh();
    }
  }

  setFolder(folder: folder, folderIndex: number | null = null) {

    // ideas cannot be opened
    if (!folder || !folder.uid || (folder.uid === -1)) {
      return false;
    }

    let parent: folder | null = null;

    if (!!folder && !!folder.children) {
      this.view.folders = folder.children;
    } else {
      this.view.folders.forEach((_folder: folder) => {

        if (_folder.uid === folder.parent) {
          parent = _folder;
        }

        _folder.active = !!_folder.uid && (folder.uid === _folder.uid) || (_folder.uid === folder.parent);
      });
    }

    this.view.currentFolder = folder;
    this.view.currentFolderIndex = folderIndex;
    this.view.currentFolderParent = folder.parent;
    this.view.currentFolderUid = (folder.guid || folder.uid);
    this.view.newFolderParent = folder.uid;

    // re-calculate breadcrumbs
    this.calculateBreadcrumbs();
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.folders = this.cards.folders || {};
    this.cards.folders.open = !this.cards.folders.open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  up() {

    if (!this.view.currentFolder || !this.view.currentFolder.parent) {
      return this.allFolders();
    }

    if (!!this.breadcrumbs && (this.breadcrumbs.length > 1)) {
      this.breadcrumbs = this.breadcrumbs.slice(0, this.breadcrumbs.length);
      this.onBreadcrumbsChanged.emit(this.breadcrumbs);
    }

    this.loadByFolder({
      uid: this.view.currentFolder.parent,
    });
  }

}