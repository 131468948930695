<ion-grid class="main-grid">

  <ion-row>

    <!-- Sidebar -->
    <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

      <!-- General -->
      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'general'|translate"></ion-card-title>
        </ion-card-header>

        <ion-item lines="none" *ngIf="!!view.integrations && !!view.integrations.length">

          <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

          <ion-select [okText]="'okay'|translate" [cancelText]="'cancel'|translate" interface="popover"
            [label]="'integration'|translate" [(ngModel)]="view.integration" (ionChange)="onIntegrationChanged($event)">
            <ion-select-option *ngFor="let integration of view.integrations" [value]="integration"
              [innerHTML]="integration.name|translate"></ion-select-option>
          </ion-select>

        </ion-item>

        <ion-item *ngIf="!!view.routes && !!view.routes.length">

          <ion-icon name="file-tray-outline" slot="start"></ion-icon>

          <ion-select [okText]="'okay'|translate" [cancelText]="'cancel'|translate" interface="popover"
            [placeholder]="'select'|translate" [label]="'route'|translate" [(ngModel)]="view.route"
            (ionChange)="onRouteChanged($event)">
            <ion-select-option *ngFor="let route of view.routes" [value]="route"
              [innerHTML]="route"></ion-select-option>
          </ion-select>

        </ion-item>

        <!--
        <ion-item lines="none" class="ion-text-wrap">
          <ion-checkbox [(ngModel)]="view.use_proxy" justify="start" labelPlacement="end"
            [innerHTML]="'use_proxy'|translate" class="ion-text-wrap"></ion-checkbox>
        </ion-item>
        -->

      </ion-card>

      <!-- Settings -->
      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'settings'|translate"></ion-card-title>
        </ion-card-header>

        <ion-card *ngFor="let item of view.settings; let i = index; trackBy:trackItems">
          <ion-list lines="none">

            <ion-item-sliding>

              <ion-item lines="none" [hidden]="item.uid === 'enabled'">

                <ion-thumbnail slot="start"
                  *ngIf="!config || (config.showPhoto !== false) && (!!config.photoKey || (!!item.thumbnail || !!item.photo))">
                  <ion-img *ngIf="!!item.uid"
                    [src]="(!!config.photoKey ? item[config.photoKey] : (item.thumbnail || item.photo)) || fallbackImg"
                    (ionError)="thumbnailLoadingFailed(item)"></ion-img>
                  <ion-skeleton-text class="image" *ngIf="!item.uid" animated></ion-skeleton-text>
                </ion-thumbnail>

                <ion-icon [name]="item.icon || 'folder-outline'" slot="start"
                  *ngIf="!!item.uid && !item.photo && !config.photoKey && !!item.icon"></ion-icon>

                <!-- Edit mode -->
                <ion-input [placeholder]="item[config.labelKey]|translate" labelPlacement="stacked"
                  [label]="item[config.labelKey]|translate"
                  [(ngModel)]="item[config.valueKey || config.subLabelKey]"></ion-input>

                <ion-button *ngFor="let button of buttons; trackBy:trackItems" [color]="button.color || 'primary'"
                  [fill]="button.fill || 'clear'" [slot]="button.slot || 'end'" [disabled]="!item.uid"
                  (click)="onButtonClick(button, item)">
                  <ion-icon *ngIf="!!button.icon" [name]="button.icon" slot="start"></ion-icon>
                  <ion-label [innerHTML]="button.label|translate"></ion-label>
                </ion-button>

              </ion-item>

              <ion-item-options
                *ngIf="((!!item && !!item.buttons && !!item.buttons.length) || (!!buttons && !!buttons.length)) && !!item.uid && !view.isDesktop"
                slot="end">

                <ion-item-option *ngFor="let button of (item.buttons || buttons); trackBy:trackItems"
                  [color]="button.color || 'primary'" (click)="onButtonClick(button, item)">
                  <ion-icon *ngIf="!!button.icon" [name]="button.icon" slot="start"></ion-icon>
                  <ion-label [innerHTML]="button.label|translate"></ion-label>
                </ion-item-option>

              </ion-item-options>

            </ion-item-sliding>

          </ion-list>
        </ion-card>

      </ion-card>

    </ion-col>

    <!-- Content -->
    <ion-col [size]="view.isDesktop ? 9 : 12">

      <ion-grid>

        <ion-row>

          <!-- Local files -->
          <ion-col *ngIf="!!config.splitView" [size]="view.isDesktop ? 6 : 12">

            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'local'|translate"></ion-card-title>
              </ion-card-header>

              <!-- Search local files -->
              <pipeline-header-search-toolbar [placeholder]="'search_something'|translate" [(search)]="search"
                [(view)]="views.local" (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

              <!-- Loading spinner -->
              <ion-spinner [hidden]="!view.loading && (!!files && !!files.local && !!files.local.length)"></ion-spinner>

              <!-- Selection toolbar -->
              <pipeline-selection-toolbar *ngIf="!!files.local && !!files.local.length" [(items)]="files.local"
                [(view)]="view" [options]="selectionOptions" (selectedItemsChanged)="onSelectedItemsChanged($event)"
                (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

              <!-- Breadcrumbs -->
              <pipeline-breadcrumbs (onBreadcrumbsButtonClick)="onBreadcrumbsButtonClick($event)" [(view)]="view"></pipeline-breadcrumbs>

              <!-- Array list -->
              <pipeline-dynamic-list *ngIf="!!files.local && !!files.local.length" [(items)]="files.local"
                [buttons]="view.list.buttons" [(view)]="view" [method]="view.list.method" [options]="dynamicLocalItemOptions"
                [selectionOptions]="selectionOptions" [service]="service"></pipeline-dynamic-list>

            </ion-card>

          </ion-col>

          <!-- Remote files -->
          <ion-col [size]="!!config.splitView && view.isDesktop ? 6 : 12">

            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'server'|translate"></ion-card-title>
              </ion-card-header>

              <!-- Search remote files -->
              <pipeline-header-search-toolbar [placeholder]="'search_something'|translate" [(search)]="search"
                [(view)]="views.remote" (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

              <!-- Loading spinner -->
              <ion-spinner
                [hidden]="!view.loading && (!!files && !!files.remote && !!files.remote.length)"></ion-spinner>

              <!-- Selection toolbar -->
              <pipeline-selection-toolbar *ngIf="!!files.remote && !!files.remote.length" [(items)]="files.remote"
                [(view)]="view" [options]="selectionOptions" (selectedItemsChanged)="onSelectedItemsChanged($event)"
                (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

              <!-- Breadcrumbs -->
              <pipeline-breadcrumbs (onBreadcrumbsButtonClick)="onBreadcrumbsButtonClick($event)" [(view)]="view"></pipeline-breadcrumbs>

              <!-- Array list -->
              <pipeline-dynamic-list *ngIf="!!files.remote && !!files.remote.length" [(items)]="files.remote"
                [buttons]="view.list.buttons" [(view)]="view" [method]="view.list.method" [options]="dynamicRemoteItemOptions"
                [selectionOptions]="selectionOptions" [service]="service"></pipeline-dynamic-list>
            </ion-card>

          </ion-col>

        </ion-row>

      </ion-grid>

    </ion-col>

  </ion-row>
</ion-grid>