<!-- Loading comments -->
<ion-spinner *ngIf="!!view.loadingComments"></ion-spinner>

<!-- No comments -->
<pipeline-no-entries-card *ngIf="!!view.comments && !view.comments.length"
    [cta]="cta" [text]="'comments_cta_text'|translate"
    [hidden]="!!view.loadingComments"></pipeline-no-entries-card>

<!-- Comments list -->
<ion-list *ngIf="!!view.comments && !!view.comments.length" [hidden]="!!view.loadingComments">

    <pipeline-comment *ngFor="let comment of view.comments; let i = index;"
        [config]="commentConfig"
        [context]="context"
        [data]="view.comments[i]"
        [selectionOptions]="selectionOptions"
        [service]="service"
        [useReactions]="false"
        [view]="view"
        (onDeleted)="doRefresh()">
    </pipeline-comment>

</ion-list>

<!-- Reply form -->
<form *ngIf="options.allowWriteComment" [hidden]="!!cards && !!cards.comments && !cards.comments.open"
    (submit)="sendComment($event, true)">
    <ion-item lines="none">

        <!-- Write comment textarea -->
        <ion-textarea rows="2" name="comment" [(ngModel)]="view.comment_message" [label]="'comment'|translate"
            labelPlacement="floating" [disabled]="!!view.loadingComments || !!view.sendingComment"
            [placeholder]="'write_comment'|translate"></ion-textarea>

        <!-- Send comment button -->
        <ion-button slot="end" (click)="sendComment($event, false)" fill="clear" color="primary" icon-only
            [disabled]="!!view.loadingComments || !!view.sendingComment">
            <ion-icon name="send-outline"></ion-icon>
        </ion-button>

    </ion-item>
</form>