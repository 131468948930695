<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Options button -->
      <ion-button (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- New training -->
      <ion-button *ngIf="view.mode !== 'pick'" (click)="add($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

</ion-header>

<ion-content>

  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Import -->
        <ion-item button (click)="importData()" [disabled]="!!view.loading">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'import'|translate"></ion-label>
        </ion-item>

        <!-- Export -->
        <ion-item lines="none" button (click)="export()" [disabled]="!!view.loading"
          *ngIf="!!view.log && !!view.log.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>

      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Intro card -->
  <div class="container" [hidden]="view.introCard.hidden">
    <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
  </div>
  
  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <ion-grid>

      <ion-row>

        <!-- Tasks -->
        <ion-col [size]="view.isDesktop ? 6 : 12">
          <ion-card>

            <ion-card-header>

              <ion-card-title [innerHTML]="'ai_training_overview_title'|translate"></ion-card-title>

              <!--
              <ion-button (click)="overview()" fill="clear" color="primary">
                <ion-label [innerHTML]="'all'|translate"></ion-label>
                <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
              </ion-button>
              -->

            </ion-card-header>

            <ion-card-content>
              <p [innerHTML]="'ai_training_overview_text'|translate"></p>
            </ion-card-content>

            <ion-spinner *ngIf="!!view && !view.tasks"></ion-spinner>

            <pipeline-no-entries-card
              *ngIf="view.tasks && !view.tasks.length && !view.loading"></pipeline-no-entries-card>

          </ion-card>

          <ion-grid class="tasks-grid" *ngIf="!!view.tasks && !!view.tasks.length">
            <ion-row>

              <ion-col size="12">
                <div class="ion-padding">
                  <pipeline-selection-toolbar [(items)]="view.tasks" [options]="selectionOptions" [(view)]="view"
                    [sizeFactor]="2"
                    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>
                </div>
              </ion-col>

              <ion-col size="12" *ngFor="let task of view.tasks">
                <ion-card>

                  <ion-item>

                    <ion-label class="ion-text-wrap">
                      <h3 [innerHTML]="task.label|translate"></h3>
                      <p [hidden]="!view.expertMode" [innerHTML]="task.indent|translate"></p>
                    </ion-label>

                    <ion-note slot="end" [innerHTML]="task.timestamp"></ion-note>

                    <ion-icon [name]="task.expanded ? 'chevron-up' : 'chevron-down'" slot="end"></ion-icon>

                  </ion-item>

                  <ion-grid>
                    <ion-row>

                      <!-- Priority -->
                      <ion-col [hidden]="!view.expertMode">

                        <ion-item lines="none">

                          <ion-icon name="speedometer-outline" slot="start"></ion-icon>

                          <ion-label class="ion-text-wrap">
                            <p>
                              <span [innerHTML]="'priority'|translate"></span>: <span
                                [innerHTML]="task.priority|translate"></span>
                            </p>
                          </ion-label>
                        </ion-item>

                      </ion-col>

                      <!-- Progress -->
                      <ion-col>

                        <ion-item lines="none">

                          <ion-icon name="stats-chart-outline" slot="start"></ion-icon>

                          <ion-label class="ion-text-wrap">
                            <p>
                              <span [innerHTML]="'progress'|translate"></span>: <span
                                [innerHTML]="task.progress|translate"></span>%
                            </p>
                          </ion-label>
                        </ion-item>

                      </ion-col>

                      <!-- State -->
                      <ion-col [hidden]="!view.expertMode">

                        <ion-item lines="none">

                          <ion-icon name="pulse-outline" slot="start"></ion-icon>

                          <ion-label class="ion-text-wrap">
                            <p>
                              <span [innerHTML]="'state'|translate"></span>: <span
                                [innerHTML]="task.state|translate"></span>
                            </p>
                          </ion-label>
                        </ion-item>

                      </ion-col>
                    </ion-row>
                  </ion-grid>

                </ion-card>
              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-col>

        <!-- Log -->
        <ion-col [size]="view.isDesktop ? 6 : 12">
          <ion-card>

            <ion-card-header>

              <ion-card-title [innerHTML]="'ai_training_log_title'|translate"></ion-card-title>

              <!--
              <ion-button (click)="log()" fill="clear" color="primary">
                <ion-label [innerHTML]="'all'|translate"></ion-label>
                <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
              </ion-button>
              -->

            </ion-card-header>

            <ion-card-content>
              <p [innerHTML]="'ai_training_log_text'|translate"></p>
            </ion-card-content>

            <ion-spinner *ngIf="!!view && !view.log"></ion-spinner>

            <pipeline-no-entries-card *ngIf="view.log && !view.log.length && !view.loading"></pipeline-no-entries-card>

            <div class="ion-padding">
              <pipeline-selection-toolbar [(items)]="view.log" [options]="selectionOptions" [(view)]="view"
                [sizeFactor]="2"
                (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>
            </div>

            <ion-list *ngIf="!!view.log && !!view.log.length">

              <ion-item *ngFor="let logItem of view.log">

                <ion-thumbnail slot="start" (click)="viewLogItem(logItem)">
                  <ion-img [src]="logItem.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(logItem)"></ion-img>
                </ion-thumbnail>

                <ion-checkbox [(ngModel)]="logItem.checked" justify="start" labelPlacement="end"
                  (ionChange)="onItemChecked(logItem)">
                  <ion-label class="ion-text-wrap">

                    <h3 *ngIf="!!logItem.label || !!logItem.target_type"
                      [innerHTML]="(logItem.label || logItem.target_type)|translate"></h3>

                    <p *ngIf="!!logItem.type">
                      <span [innerHTML]="'type'|translate"></span>: <span [innerHTML]="logItem.type"></span>
                    </p>

                    <p [hidden]="!view.expertMode" *ngIf="!!logItem.url || !!logItem.value"
                      [innerHTML]="logItem.url || logItem.value"></p>

                  </ion-label>
                </ion-checkbox>

                <ion-icon slot="start" *ngIf="!!logItem.target && (logItem.target_type === 'rating')"
                  [color]="logItem.target === 5 ? 'warning' : (logItem.target === 1 ? 'success' : 'danger')"
                  [name]="logItem.target === 5 ? 'star-outline' : (logItem.target === 1 ? 'thumbs-up-outline' : 'thumbs-down-outline')"></ion-icon>

                <ion-note slot="end" [innerHTML]="logItem.timestamp" [hidden]="!view.isDesktop"></ion-note>

                <ion-button slot="end" (click)="viewLogItem(logItem)" fill="clear" color="dark">
                  <ion-icon name="open-outline"></ion-icon>
                </ion-button>

              </ion-item>

            </ion-list>

          </ion-card>
        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>