<ion-card class="sidebar-accordion-card" *ngIf="!!item">

    <ion-card-header>

        <ion-card-title [innerHTML]="'sharing'|translate"></ion-card-title>

        <ion-button *ngIf="!!cards && !!cards.sharing" class="card-toggle" (click)="toggleCard()" icon-only color="dark" fill="clear">
            <ion-icon [name]="!!cards && !!cards.sharing && !!cards.sharing.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <ion-item lines="none" (click)="chooseConnections()" button [hidden]="!!cards && !!cards.sharing && !cards.sharing.open">
        <ion-icon name="share-social-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'connections'|translate"></ion-label>
        <ion-note slot="end" [innerHTML]="'pick'|translate"></ion-note>
    </ion-item>

    <ion-list *ngIf="!!view.captionPlatforms" [hidden]="!!cards && !!cards.sharing && !cards.sharing.open">

        <div *ngFor="let connection of view.captionPlatforms; let i = index;">

            <!-- connection accordion item -->
            <ion-item [disabled]="!!disabled" button (click)="togglePlatform(connection, i)">

                <ion-avatar slot="start" *ngIf="!!connection && !!connection.photo">
                    <ion-img [src]="connection.photo" (ionError)="captionPlatformPhotoLoadingFailed()"></ion-img>
                </ion-avatar>

                <ion-icon *ngIf="!!connection && !!connection.icon && (!connection.photo || !connection.photo.length)"
                    [name]="connection.icon" slot="start"></ion-icon>

                <ion-label class="ion-text-wrap">
                    <h3 [innerHTML]="connection.name|translate"></h3>
                    <p [innerHTML]="connection.platform|translate"></p>
                </ion-label>

                <ion-spinner [hidden]="!connection.blGeneratingCaption"></ion-spinner>

                <ion-button slot="end" fill="clear" color="dark" icon-only>
                    <ion-icon [name]="connection.checked ? 'chevron-up' : 'chevron-down'"></ion-icon>
                </ion-button>

            </ion-item>

            <!-- connection caption -->
            <div [hidden]="!connection.checked">

                <!-- creatives / media list (if enabled) -->
                <div *ngIf="!!config && !!config.allowCreativesGeneration"
                    [hidden]="!connection.caption || !connection.configureMode">

                    <ion-list-header>
                        <ion-label [innerHTML]="'promotional_material'|translate" class="ion-text-wrap"></ion-label>
                    </ion-list-header>

                    <ion-card-content>
                        <p [innerHTML]="'promotional_material_header_text'|translate"></p>
                    </ion-card-content>

                    <ion-grid class="creatives-grid">

                        <ion-row>

                            <ion-col>
                                <ion-button [disabled]="!!disabled" (click)="generateCreatives(connection)"
                                    expand="block" color="primary" fill="outline">
                                    <ion-icon name="brush-outline"></ion-icon>
                                    <ion-label [innerHTML]="'create'|translate"></ion-label>
                                </ion-button>
                            </ion-col>

                            <ion-col>
                                <ion-button [disabled]="!!disabled" (click)="importCreatives(connection)" expand="block"
                                    color="primary" fill="outline">
                                    <ion-icon name="images-outline"></ion-icon>
                                    <ion-label [innerHTML]="'select'|translate"></ion-label>
                                </ion-button>
                            </ion-col>

                        </ion-row>

                        <ion-row *ngIf="!!connection.creatives">
                            <ion-col size="4" *ngFor="let creative of connection.creatives">
                                <ion-card>
                                    <!-- Show when creative is in generation -->
                                    <ion-skeleton-text class="image" [hidden]="creative.photo"
                                        [animated]="creative.loading"></ion-skeleton-text>

                                    <!-- Final creative image -->
                                    <ion-img *ngIf="!!creative.photo" [src]="creative.photo || fallbackImg"></ion-img>
                                </ion-card>
                            </ion-col>
                        </ion-row>

                    </ion-grid>
                </div>

                <!-- caption editor -->
                <ion-item [disabled]="!!disabled" [hidden]="!!connection.configureMode">

                    <ion-textarea [disabled]="!!disabled" [hidden]="!!connection.blGeneratingCaption"
                        [(ngModel)]="connection.caption" [label]="'caption'|translate" labelPlacement="stacked"
                        [placeholder]="(view.captionItemType || 'item') + '_caption_placeholder'|translate"
                        rows="8"></ion-textarea>

                    <ion-button [hidden]="!!connection.configureMode" slot="end" icon-only fill="clear" color="dark"
                        [hidden]="!connection.captionByPlatformCalculated" (click)="copyToClipboard(connection.caption)"
                        [disabled]="!!disabled || (!!connection.blGeneratingCaption || !connection.caption)">
                        <ion-icon name="copy-outline"></ion-icon>
                    </ion-button>

                </ion-item>

                <!-- buttons bar -->
                <ion-item lines="none">

                    <ion-button [disabled]="!!disabled" [hidden]="!connection.configureMode" slot="start" fill="clear"
                        color="dark" (click)="configurePlatform(connection)">
                        <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'back'|translate"></ion-label>
                    </ion-button>

                    <ion-button [disabled]="!!disabled" [hidden]="!!connection.configureMode" slot="start" icon-only
                        fill="clear" color="dark" (click)="configurePlatform(connection)">
                        <ion-icon name="settings-outline"></ion-icon>
                    </ion-button>

                    <ion-button slot="end" icon-only fill="clear" color="dark"
                        [hidden]="!!connection.configureMode || !connection.captionByPlatformCalculated"
                        (click)="recreate(connection)"
                        [disabled]="!!disabled || (!!connection.blGeneratingCaption || !connection.caption)">
                        <ion-icon name="sync-outline"></ion-icon>
                    </ion-button>

                    <ion-button slot="end" icon-only shape="round" color="primary"
                        [disabled]="!!disabled || (!!connection.blGeneratingCaption || !connection.caption)"
                        [hidden]="!!connection.configureMode || !!connection.captionByPlatformCalculated"
                        (click)="loadCaptionByPlatform(connection)">
                        <ion-icon name="play-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'generate'|translate"></ion-label>
                    </ion-button>

                    <ion-button [hidden]="!!connection.configureMode || !connection.captionByPlatformCalculated"
                        [disabled]="!!disabled || (!!connection.blGeneratingCaption || !connection.caption)"
                        (click)="shareToPlatform(connection)" slot="end" shape="round" color="primary">
                        <ion-icon name="share-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'share'|translate"></ion-label>
                    </ion-button>

                </ion-item>

                <!-- configure options -->
                <div *ngIf="!!view.confOptions && !!connection.configureMode">

                    <ion-item *ngFor="let option of view.confOptions">

                        <ion-icon [name]="option.icon" *ngIf="!!option.icon" slot="start"></ion-icon>

                        <ion-checkbox [disabled]="!!disabled" *ngIf="option.type === 'checkbox'"
                            [innerHTML]="option.label|translate"
                            (ionChange)="_onOptionChange(option, $event)"></ion-checkbox>

                        <ion-input [disabled]="!!disabled" [label]="option.label|translate"
                            [placeholder]="option.placeholder|translate" *ngIf="option.type === 'input'"
                            (ionChange)="_onOptionChange(option, $event)"></ion-input>

                    </ion-item>

                </div>

            </div>

        </div>

    </ion-list>

</ion-card>