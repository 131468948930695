import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AttributesService } from 'src/app/services/core/attributes.service';
import { EventsService } from "src/app/services/core/events.service";
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-attributes-filter-card',
  standalone: false,
  templateUrl: './attributes-filter-card.component.html',
  styleUrls: ['./attributes-filter-card.component.scss']
})
export class AttributesFilterCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() location: string;
  @Input() service: any;
  @Input() view: any;

  @Output() attributesChanged = new EventEmitter();
  @Output() filtersChanged = new EventEmitter();

  constructor(
    private attributes: AttributesService,
    private events: EventsService,
    private sidebar: SidebarService,
  ) {

  }

  expandList(listName: string) {
    this.view.expandedList = listName;
  }

  public loadAttributes(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      if (!this.view.project || !this.view.project.uid) {
        return false;
      } else {
        this.view.loadingAttributes = true;

        this.view.options = this.view.options || {};
        this.view.options.location = this.view.options.location || this.location;

        const disabledKeys: string[] = ['project_id', 'project_uid'];
        const rangeKeys: string[] = ['fake_news', 'hate_speech', 'mood', 'nsfw_level', 'spam_level'];

        this.service.getAttributes(this.view.options, blForceRefresh, { limit: 1000 })
          .then((attributes: mediaAttribute[]) => {

            this.view.attributes = (attributes || [])
              .map((attribute: mediaAttribute) => {
                attribute.active = !!(!!this.view.currentFolder && (this.view.currentFolder.uid === attribute.uid));
                attribute.key = attribute.key || `${attribute.title}`.toLowerCase();
                attribute.type = 'select';

                if (rangeKeys.indexOf(attribute.key) !== -1) {
                  attribute.type = 'range';

                  let iMax: number = 0, iMin: number = 0;

                  if (!!attribute.value && !!attribute.value.length) {
                    attribute.value.forEach((value: any) => {
                      const iValue: number = parseFloat(value.title);

                      if (iValue < iMin) {
                        iMin = iValue;
                      }

                      if (iValue > iMax) {
                        iMax = iValue;
                      }

                    });
                  }

                  attribute.max = iMax;
                  attribute.min = iMin;
                  attribute.dualKnobs = (attribute.min !== attribute.max);
                  attribute.value = (!!attribute.dualKnobs ? { lower: iMin, upper: iMax } : iMin);

                  console.log('calc range attribute', attribute);
                }

                return attribute;
              })
              .sort((a: mediaAttribute, b: mediaAttribute) => {

                const _a: string = `${a.title}`.toLowerCase(),
                  _b: string = `${b.title}`.toLowerCase();

                if (_a < _b) return -1;
                if (_b > _a) return 1;

                return 0;
              }).filter((attribute: mediaAttribute) => {

                // remove tags attribute and apply it for tags filter card component
                if (attribute.key === 'tags') {

                  if (!this.view.hasOwnProperty('tags') || !this.view.tags || !this.view.tags.length) {
                    this.view.tags = (attribute.value || []);
                    console.log('> applied tags (b)', this.view.tags);
                  }

                  return false;
                }

                return disabledKeys.indexOf(attribute.key) === -1;
              });

            this.view.loadingAttributes = false;

            this.attributesChanged.emit(this.view.attributes);

            resolve(this.view.attributes);
          })
          .catch((error: any) => {
            this.view.loadingAttributes = false;
            reject(error);
          });
      }
    });
  }

  async loadCards() {
    try {
      if (!this.cards) {
        this.cards = (await this.sidebar.getCards() || (this.cards || {}));
      }
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.service = this.service || this.attributes;

    this.loadCards();

    if (!this.view.hasOwnProperty('attributes')) {
      this.loadAttributes()
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }
  }

  onAttributeChanged(attribute: any, event: any | null = null) {

    if (!this.view.hasOwnProperty('filters')) {
      this.view.filters = {};
    }

    if (!this.view.filters.hasOwnProperty('attributes')) {
      this.view.filters.attributes = {};
    }

    this.view.filters.attributes[attribute.uid] = event.detail.value;

    this.filtersChanged.emit(this.view.filters.attributes);
  }

  openAttribute(attribute: any) {
    this.view.attribute = attribute;
    this.runSearch();
  }

  runSearch() {

  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.attributes = this.cards.attributes || {};
    this.cards.attributes.open = !this.cards.attributes.open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}