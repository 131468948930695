<!-- Header search toolbar -->
<pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
  (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

<!-- Selection toolbar -->
<ion-toolbar class="media-library-top-toolbar" *ngIf="!view.rebuild && !view.isDesktop">
  <pipeline-selection-toolbar [(items)]="view.mediaList" [filters]="view.selectionFilters" [options]="selectionOptions"
    [(paginationConfig)]="paginationConfig" [(view)]="view" (onLanguageChanged)="onLanguageChanged($event)"
    (onSelectionActionChanged)="onSelectionActionChanged($event)" (onViewTypeChanged)="onViewTypeChanged($event)">
  </pipeline-selection-toolbar>
</ion-toolbar>

<!-- Refresher -->
<ion-refresher *ngIf="!view.dragging" slot="fixed" (ionRefresh)="doRefresh($event)">
  <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
</ion-refresher>

<!-- Intro card -->
<div *ngIf="!!view.introCard && !view.isDesktop" class="ion-padding" [hidden]="!!view.introCard.hidden">
  <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
</div>

<ion-grid class="media-library-grid">

  <ion-row>

    <!-- Sidebar -->
    <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="left"
      [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

      <!-- Folders -->
      <pipeline-folders-filter-card [cards]="cards" [config]="foldersFilterCardConfig" [service]="media"
        location="media_library" [(view)]="view" (onBreadcrumbsChanged)="onFoldersBreadcrumbsChanged($event)"
        (onChanged)="loadMediaByFolder($event)" (onItemsChanged)="onFolderLoaded($event)">
      </pipeline-folders-filter-card>

      <!-- Attributes -->
      <pipeline-attributes-filter-card [cards]="cards" [service]="media" [(view)]="view"
        (filtersChanged)="onAttributeFiltersChanged($event)"></pipeline-attributes-filter-card>

      <!-- Tags -->
      <pipeline-tags-filter-card [cards]="cards" [service]="media" [(view)]="view"
        (selectionChanged)="onSelectedTagChanged($event)"></pipeline-tags-filter-card>

      <!-- Storages -->
      <ion-card class="sidebar-accordion-card" [hidden]="!view.expertMode">

        <ion-card-header>

          <ion-card-title>
            <span [innerHTML]="'storages'|translate"></span>
          </ion-card-title>

          <ion-button *ngIf="!!cards && !!cards.storages" class="card-toggle" (click)="toggleCard('storages')" icon-only
            color="dark" fill="clear">
            <ion-icon
              [name]="!!cards && !!cards.storages && !!cards.storages.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
          </ion-button>

        </ion-card-header>

        <ion-list class="storages-list tags-list" [hidden]="!!cards && !!cards.storages && !cards.storages.open"
          [class.expanded]="view.expandedList === 'storages'">

          <!-- All storages -->
          <ion-item [hidden]="!!cards && !!cards.storages && !cards.storages.open" button class="item" lines="none"
            (click)="allStorages()" [class.active]="!view.tag">
            <ion-icon [name]="(!view.tag ? 'pricetag-outline' : 'tag-outline')" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap">
              <p [innerHTML]="'all_storages'|translate" class="ion-text-wrap"></p>
            </ion-label>
          </ion-item>

          <!-- Loading storages -->
          <ion-spinner
            [hidden]="!view.loadingStorages || (!!cards && !!cards.storages && !cards.storages.open)"></ion-spinner>

          <!-- No storages found -->
          <pipeline-no-entries-card [cta]="cta" [hidden]="!!cards && !!cards.storages && !cards.storages.open"
            [text]="'storages_cta_text'|translate"
            *ngIf="(!view.storages || (view.storages && !view.storages.length)) && !view.loadingStorages"></pipeline-no-entries-card>

          <!-- Storages list -->
          <div [hidden]="!!view.loadingStorages || (!!cards && !!cards.storages && !cards.storages.open)"
            *ngIf="!!view.storages && !!view.storages.length">

            <ion-item button *ngFor="let storage of view.storages; trackBy:trackItems" (click)="openStorage(storage)"
              class="btn-item ion-text-wrap" [class.active]="storage.checked">

              <ion-thumbnail *ngIf="!!storage.photo" slot="start" class="tag-preview">
                <ion-img [src]="storage.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(storage)"></ion-img>
              </ion-thumbnail>

              <ion-icon *ngIf="!storage.photo"
                [name]="storage.icon || (storage.checked ? 'pricetag' : 'pricetag-outline')" slot="start"></ion-icon>

              <ion-label class="ion-text-wrap">
                <p [innerHTML]="storage.name|translate" class="ion-text-wrap"></p>
              </ion-label>

              <ion-spinner slot="end" [hidden]="!!storage.uid"></ion-spinner>

            </ion-item>

          </div>

        </ion-list>

      </ion-card>

    </ion-col>

    <!-- Content -->
    <ion-col [size]="view.expertMode && !!view.colSize ? (view.colSize.right || 12) : 12" class="right"
      [hidden]="!view.isDesktop && !!view.showFilters">

      <!-- Breadcrumbs -->
      <pipeline-breadcrumbs (onBreadcrumbsButtonClick)="onBreadcrumbsButtonClick($event)" [breadcrumbs]="breadcrumbs"
        [config]="breadcrumbsConfig" [hidden]="!breadcrumbs || (2 > breadcrumbs.length)" [(view)]="view">
      </pipeline-breadcrumbs>

      <!-- Selection toolbar -->
      <pipeline-selection-toolbar *ngIf="!view.rebuild && !!view.isDesktop" [(items)]="view.mediaList"
        [(filters)]="view.selectionFilters" [options]="selectionOptions" [(paginationConfig)]="paginationConfig"
        [(view)]="view" (onLanguageChanged)="onLanguageChanged($event)"
        (onSelectionActionChanged)="onSelectionActionChanged($event)"
        (onViewTypeChanged)="onViewTypeChanged($event)"></pipeline-selection-toolbar>

      <!-- Intro card -->
      <pipeline-intro-card *ngIf="!!view.introCard && !!view.isDesktop" [hidden]="!!view.introCard.hidden"
        class="introCard" [(view)]="view.introCard"></pipeline-intro-card>

      <!-- Media import card -->
      <pipeline-media-import-card *ngIf="!view.loading && !!view.showMediaImportCard && !view.hideMediaImportCard"
        (hideStateChanged)="mediaImportHideStateChanged($event)"></pipeline-media-import-card>

      <!-- Loading bar -->
      <ion-spinner name="circular" *ngIf="!view.mediaList || !!view.loading"></ion-spinner>

      <!-- No entries card -->
      <pipeline-no-entries-card
        *ngIf="view.mediaList && !view.mediaList.length && !view.loading"></pipeline-no-entries-card>

      <!-- grid & list view -->
      <ion-grid *ngIf="!view.rebuild && !!view.mediaList && !view.loading" class="media-stage-grid"
        [hidden]="view.viewType === 'calendar'">

        <ion-row>

          <ion-col *ngFor="let mediaItem of view.mediaList; let i = index; trackBy:trackItems"
            [size]="view.viewType === 'grid' ? (view.colSize.item || 4) : 12" [attr.data-location]="'media_library'"
            [draggable]="!!view.isDesktop" [dragItem]="mediaItem" [attr.drag-location]="'MEDIA_ADMIN'"
            [attr.data-model]="view.mediaList" [attr.data-type]="'mediaItem'" [attr.data-uid]="mediaItem.uid">

            <!-- Single media item -->
            <pipeline-media-item-card [(item)]="view.mediaList[i]" [selectionOptions]="selectionOptions" [(view)]="view"
              [index]="i" (itemChecked)="onMediaItemChecked($event)" (itemClicked)="onMediaItemClicked($event)"
              (itemDeleted)="onMediaItemDeleted($event)"
              (selectionChanged)="runItemSelectionOption(mediaItem, $event)"></pipeline-media-item-card>

          </ion-col>

        </ion-row>

      </ion-grid>

      <!-- calendar view -->
      <pipeline-calendar *ngIf="!!eventsList && !!eventsList.length && (view.viewType === 'calendar')"
        [(events)]="eventsList" [options]="calendarOptions" [selectionOptions]="selectionOptions"></pipeline-calendar>

    </ion-col>

  </ion-row>
</ion-grid>