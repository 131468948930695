import { Component, OnInit, ViewChild } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { OrdersService } from 'src/app/services/ecommerce/orders.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-orders',
  standalone: false,
  templateUrl: './orders.page.html',
  styleUrls: ['./orders.page.scss'],
})
export class OrdersPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  search: searchOptions = {
    itemsKey: 'orders',
    query: '',
  };

  state: state = {};

  view: any = {
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'orders_top_card',
      text: 'orders_top_card_text',
      title: 'orders_top_card_title',
    },
    orders: [],
    placeholders: [{}, {}, {}, {}, {}],
    key: ['name', 'query'],
    title: 'orders',
  };

  constructor(
    private events: EventsService,
    private orders: OrdersService,
  ) {

  }

  doRefresh(event: any|null = null) {
    this.loadOrders(true)
      .then(() => {
        if (event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  ionViewWillEnter() {
    this.loadOrders()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  loadOrders(blForceRefresh: boolean = false) {
    this.view.orders = this.view.placeholders;

    return new Promise((resolve, reject) => {
      this.orders.getByUser(null, blForceRefresh)
        .then((orders: order[]) => {
          this.view.orders = orders;
        })
        .catch(reject);
    });
  }

  ngOnInit() {
  }

  onSearchChanged(searchOptions: any|null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

}
