<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

      <!-- Options button -->
      <ion-button (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Add button -->
      <ion-button (click)="add($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Segment toolbar -->
  <ion-toolbar class="container second-toolbar">
    <ion-segment [(ngModel)]="view.segment" (ionChange)="segmentChanged()">

      <ion-segment-button value="plans">
        <ion-icon name="calendar-outline"></ion-icon>
        <ion-label [innerHTML]="'plans'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="queue">
        <ion-icon name="hourglass-outline"></ion-icon>
        <ion-label [innerHTML]="'queue'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar *ngIf="!!view.project && !!view.project.uid && (view.segment === 'plans')"
    [(search)]="search" [(view)]="view" (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar *ngIf="!view.isDesktop && view.segment === 'plans'" [(items)]="view.plans"
    [options]="selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Import -->
        <ion-item button (click)="importPlans()" [disabled]="!!view.loading">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'import'|translate"></ion-label>
        </ion-item>

        <!-- Export -->
        <ion-item lines="none" button (click)="export()" [disabled]="!!view.loading"
          *ngIf="!!view.plans && !!view.plans.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>
      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Selection options popover -->
  <pipeline-selection-options-picker (onSelectionActionChanged)="runItemSelectionOption($event)"
    [options]="selectionOptions" [(view)]="view"></pipeline-selection-options-picker>

  <!-- Refresher -->
  <ion-refresher *ngIf="!view.dragging" slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Mobile intro card -->
    <div *ngIf="!view.isDesktop" class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="view.colSize.left" class="left"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Folders -->
          <pipeline-folders-filter-card [(cards)]="cards" [service]="ai" location="plans" [(view)]="view"
            (onItemsChanged)="onFolderLoaded($event)"
            (onChanged)="loadPlansByFolder($event)"></pipeline-folders-filter-card>

          <!-- Connections -->
          <pipeline-connections-filter-card [(cards)]="cards" [(view)]="view"
            (onChanged)="onConnectionsFilterChanged($event)"></pipeline-connections-filter-card>

          <!-- Tags -->
          <pipeline-tags-filter-card [(cards)]="cards" [service]="ai" [(view)]="view" location="plans"
            (selectionChanged)="onSelectedTagChanged($event)"></pipeline-tags-filter-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode ? (view.colSize.right || 12) : 12" class="right"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Selection toolbar -->
          <pipeline-selection-toolbar *ngIf="!!view.isDesktop && view.segment === 'plans'" [(items)]="view.plans"
            [options]="selectionOptions" [(view)]="view"
            (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

          <!-- Desktop intro card -->
          <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="view.introCard.hidden" class="introCard"
            [(view)]="view.introCard">
          </pipeline-intro-card>

          <!-- No entries -->
          <pipeline-no-entries-card [cta]="cta" [text]="'ai_plans_cta_text'|translate"
            *ngIf="!!view.plans && !view.plans.length" [hidden]="view.loading"></pipeline-no-entries-card>

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- plans list -->
          <ion-grid *ngIf="!!view.plans && !!view.plans.length" [hidden]="view.loading || (view.segment !== 'plans')">
            <ion-row>

              <ion-col [size]="view.viewType === 'grid' ? view.colSize.item : 12"
                *ngFor="let plan of view.plans; trackBy:trackItems">
                <ion-card>

                  <ion-item lines="none">

                    <!-- Thumbnail (in list mode) -->
                    <ion-thumbnail (click)="use(plan)" slot="start" *ngIf="(view.viewType !== 'grid')">
                      <ion-skeleton-text class="image" animated [hidden]="!!plan.uid"></ion-skeleton-text>
                      <ion-img *ngIf="!!plan.uid" [src]="plan.photo || fallbackImg"
                        (ionError)="thumbnailLoadingFailed(plan)"></ion-img>
                    </ion-thumbnail>

                    <!-- Checkbox + Label -->
                    <ion-checkbox [(ngModel)]="plan.checked" justify="start" labelPlacement="end"
                      (ionChange)="onItemChecked(plan)" [disabled]="!plan.uid">
                      <p [innerHTML]="plan.title"></p>
                      <small [innerHTML]="plan.timestamp"></small>
                    </ion-checkbox>

                    <!-- Edit -->
                    <ion-button slot="end" fill="clear" icon-only (click)="use(plan)" color="dark"
                      [hidden]="view.viewType !== 'grid'" *ngIf="!!plan.uid">
                      <ion-icon name="settings-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'edit'|translate" *ngIf="view.viewType !== 'grid'"></ion-label>
                    </ion-button>

                    <!-- Delete -->
                    <ion-button (click)="delete(plan)" fill="clear" color="danger" icon-only slot="end"
                      [hidden]="view.viewType !== 'grid' || !view.isDesktop" *ngIf="!!plan.uid">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'delete'|translate" *ngIf="view.viewType !== 'grid'"></ion-label>
                    </ion-button>

                    <!-- Options -->
                    <ion-button slot="end" (click)="presentPopover($event, plan)" fill="clear" icon-only color="dark"
                      *ngIf="(view.mode !== 'pick') && !!plan.uid">
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                  <!-- Thumbnail (in grid mode) -->
                  <ion-thumbnail (click)="use(plan)" *ngIf="view.viewType === 'grid'">
                    <ion-skeleton-text class="image" animated [hidden]="!!plan.uid"></ion-skeleton-text>
                    <ion-img *ngIf="!!plan.uid" [src]="plan.photo || fallbackImg"
                      (ionError)="thumbnailLoadingFailed(plan)"></ion-img>
                  </ion-thumbnail>

                  <ion-card-content (click)="use(plan)" *ngIf="!!plan.description">
                    <p [innerHTML]="plan.description"></p>
                  </ion-card-content>

                  <ion-item lines="none" [hidden]="view.viewType !== 'list'" *ngIf="!!plan.uid">

                    <ion-button slot="end" fill="clear" icon-only (click)="use(plan)" color="dark">
                      <ion-icon name="settings-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'edit'|translate" *ngIf="view.viewType !== 'grid'"></ion-label>
                    </ion-button>

                    <ion-button (click)="delete(plan)" fill="clear" color="danger" icon-only slot="end">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'delete'|translate" *ngIf="view.viewType !== 'grid'"></ion-label>
                    </ion-button>

                  </ion-item>

                </ion-card>
              </ion-col>

            </ion-row>
          </ion-grid>

          <!-- queue calendar -->
          <pipeline-calendar *ngIf="!!view.queue && (view.segment === 'queue')" [(events)]="view.queue"
            [options]="calendarOptions" [selectionOptions]="selectionOptions">
          </pipeline-calendar>

        </ion-col>

      </ion-row>

    </ion-grid>
  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>