import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { IonSearchbar } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { FtpService } from 'src/app/services/integrations/ftp.service';
import { ImagesService } from "src/app/services/media/images.service";
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { IntroService } from 'src/app/services/utils/intro.service';
import { MediaActionsService } from 'src/app/services/media/media-actions.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { PaginationService } from 'src/app/services/utils/pagination.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { TagsService } from "src/app/services/extensions/tags.service";
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';
import { VideosService } from 'src/app/services/media/videos.service';
import { ViewService } from 'src/app/services/core/view.service';

import { AttributesFilterCardComponent } from 'src/app/components/attributes/attributes-filter-card/attributes-filter-card.component';
import { FoldersFilterCardComponent } from 'src/app/components/folders/folders-filter-card/folders-filter-card.component';
import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { AiModelPage } from 'src/app/pages/core/ai/ai-model/ai-model.page';

import { Subscription } from 'rxjs';

@Component({
  selector: 'pipeline-media-library',
  standalone: false,
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss'],
})
export class MediaLibraryComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(AttributesFilterCardComponent) attributesFilterCard: any;

  @Input() breadcrumbs: breadcrumb[] = [
    {
      icon: 'home-outline',
      uid: '/',
    }
  ];

  @Input() breadcrumbsConfig: breadcrumbsConfig = {

  };

  @Input() calendarOptions: calendarOptions = {
    showPagination: true,
    showSelectionToolbar: false,
    showTitle: true,
    showViewModes: true,
    viewModes: ["day", "week", "month"],
  };

  cards: any = {};

  cta: any = {
    icon: 'add-outline',
    label: 'add',
    url: '/integrations',
  };

  defaultSelectionOptions: selectionOption[] = [
    {
      icon: 'search-outline',
      label: 'analyze',
      uid: 'analyze',
    },
    {
      icon: 'film-outline',
      label: 'ai_image_image_to_video',
      requires_type: ['image'],
      uid: 'image_to_video',
    },
    {
      icon: 'cut-outline',
      label: 'combine',
      requires_type: ['video'],
      uid: 'combine',
    },
    {
      icon: 'images-outline',
      label: 'send_to_media_creator',
      requires_type: ['image', 'video'],
      uid: 'send_to_media_creator',
    },
    {
      icon: 'film-outline',
      label: 'send_to_video_creator',
      requires_type: ['image', 'video'],
      uid: 'send_to_video_creator',
    },
    {
      icon: 'download-outline',
      label: 'download',
      uid: 'download',
    },
    {
      icon: 'share-outline',
      label: 'share',
      requires_type: ['image', 'video'],
      uid: 'share',
    },
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
    {
      icon: 'layers-outline',
      label: 'calculate_stacks',
      uid: 'calculate_stacks',
    },
    {
      icon: 'hardware-chip-outline',
      label: 'add_to_training',
      uid: 'add_to_training',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  eventsList: calendarEvent[] = [];

  fallbackImg: string = './assets/img/fallback.webp';

  @ViewChild(FoldersFilterCardComponent) foldersFilterCard: any;

  foldersFilterCardConfig: foldersFilterCardConfig = {

  };

  integrationsByType: any;

  @Output() itemsChanged = new EventEmitter();

  @Input() paginationConfig: paginationConfig = {
    itemsKey: 'mediaList',
  };

  search: searchOptions = {
    itemsKey: 'mediaList',
    keys: ['post_content', 'guid', 'title', 'host', 'url', 'uid'],
    query: "",
  };

  @ViewChild('searchInput') searchInput: IonSearchbar;

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  @Input() selectionOptions: selectionOption[];

  subs = new Subscription();

  @Input() view: any;

  constructor(
    private events: EventsService,
    private ftp: FtpService,
    private images: ImagesService,
    private integrations: IntegrationsService,
    private introService: IntroService,
    public media: MediaextendService,
    private mediaActions: MediaActionsService,
    private modalService: ModalService,
    private pagination: PaginationService,
    private projects: ProjectsService,
    private sd: StablediffusionService,
    private sidebar: SidebarService,
    private tagsService: TagsService,
    private tools: ToolsService,
    private userService: UserService,
    private videos: VideosService,
    private viewService: ViewService,
  ) {
    this.integrationsByType = this.integrations.getByType();
  }

  async add(event: any | null = null, params: any = {}) {
    this.media.applyFromWeb(null, Object.assign(params, {
      multiple: true,
    }))
      .then(() => {
        this.doRefresh(null, false);
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async addSelectedToTraining() {

    const modal: any = await this.modalService.create({
      component: AiModelPage,
      componentProps: {
        mediaList: (this.view.selectedItems || []),
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  async add_to_training(item: any) {
    this.view.selectedItems = [item];
    return this.addSelectedToTraining();
  }

  aiTools_imageToVideo(items: any[] | null = null) {
    items = items || this.view.selectedItems;

    if (!items || !items.length) {
      return false;
    }


    items.forEach((item: any, index: number) => {
      setTimeout(() => {
        item.loading = true;

        this.sd.imageToVideo(item.guid || item.thumbnail)
          .then(() => {
            item.loading = false;
            this.view.loading = false;
          })
          .catch((error: any) => {
            item.loading = false;

            this.events.publish('error', error);

            this.view.ai_tool_selection = null;
            this.view.loading = false;
          });

      }, (index * 250));
    });

    this.events.publish('toast', {
      icon: 'hourglass-outline',
      message: 'ai_image_to_video_process_started',
      color: 'primary',
    });
  }

  allStorages() {
    this.view.currentFolder = null;
    this.view.currentFolderParent = null;
    this.view.currentFolderUid = null;

    this.breadcrumbs = [];

    this.view.storages.forEach((_storage: integrationConnection) => {
      _storage.checked = false;
    });

    this.view.storageConnection = null;

    this.load()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  attributeSettings(attribute: any) {
    console.log('attributeSettings', attribute);
  }

  analyzeSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
      let i: number = 0, iMax: number = this.view.selectedItems.length;

      this.view.loading = true;

      const mediaIds: number[] = this.view.selectedItems.map((item: mediaItem) => {
        return item.ID;
      });

      this.media.analyse(mediaIds)
        .then(() => {
          i++;

          if (i >= iMax) {
            this.view.loading = false;
            resolve(true);
          }
        })
        .catch(() => {
          i++;

          if (i >= iMax) {
            this.view.loading = false;
            resolve(true);
          }
        });
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async applyMedia(media: mediaItem[]) {

    if (!!this.view.mediaType && (this.view.mediaType !== 'all') && (this.view.mediaType !== 'audio')) {
      media = media.filter((mediaItem: any) => {
        return mediaItem.post_mime_type === this.view.mediaType;
      });
    }

    media = media.sort((a: any, b: any) => {
      if (a.timestamp > b.timestamp) return -1;
      if (a.timestamp < b.timestamp) return 1;
      return 0;
    }).map((mediaItem: any) => {
      mediaItem.type = (mediaItem.post_mime_type === 'application' ? 'document' : (mediaItem.post_mime_type === 'video' ? 'video' : 'image'));

      return mediaItem;
    });

    this.view.mediaList_backup = JSON.parse(JSON.stringify(media || []));

    // prepare pagination config
    this.paginationConfig = this.paginationConfig || {};
    this.paginationConfig.backup = JSON.parse(JSON.stringify(this.view.mediaList_backup));

    const view: any = Object.assign(this.view, {
      mediaList: (media || []),
    });

    const calc: any = await this.pagination.calculateConfig(
      view,
      this.paginationConfig,
    );

    if (!!calc && !!calc.view) {
      this.view = Object.assign(this.view, calc.view);
    }

    // update items in search toolbar view
    if (!!this.searchToolbar) {
      this.searchToolbar.updateItems(this.view.mediaList || []);
    }

    // run search / filters
    setTimeout(() => {
      this.runSearch();
    });

    this.itemsChanged.emit(this.view.mediaList);
  }

  applyViewType(type: string | null = null) {
    type = type || this.view.viewType;

    switch (type) {

      case 'calendar':
        this.view.showPagination = false;

        this.eventsList = (this.view.mediaList || []).map((item: mediaItem) => {
          const iEnd: any = new Date(item.post_date); // Math.floor(new Date(post.date_gmt).getTime() / 1000);
          const iStart: any = iEnd;

          const calendarEvent: calendarEvent = {
            title: `${item.title || ''}`,
            start: iStart,
            end: iEnd,
            draggable: true,
            photo: ((item.photo || item.thumbnail) || ''),
            //platform: 'media_library',
            post_content: (item.guid || ''),
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            uid: (item.uid || null),
          };

          return calendarEvent;
        });

        console.log('media-library: eventsList', this.eventsList);
        break;

      default:
        this.view.showPagination = true;
        break;

    }
  }

  aspectRatioChanged(item: any, event: any | null = null) {
    this.view.filters = this.view.filters || {};
    this.view.filters.aspect_ratio = item.value;

    this.saveFilters();
    this.doRefresh();
  }

  calcSelectionActions() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    if (!this.view.hasOwnProperty('selectionOptions_backup')) {
      this.view.selectionOptions_backup = JSON.parse(JSON.stringify(this.selectionOptions));
    }

    const selectedItemTypes: any = [... new Set((this.view.selectedItems || []).map((item: mediaItem) => {
      return (item.type || item.post_mime_type) || item.post_type;
    }))];

    if (!selectedItemTypes || !selectedItemTypes.length) {
      this.selectionOptions = this.view.selectionOptions_backup;
      return false;
    }

    this.selectionOptions = this.view.selectionOptions_backup.filter((option: any) => {
      let bl: boolean = true;

      if (!!option.requires_type && !!option.requires_type.length) {
        bl = false;
        option.requires_type.forEach((type: string) => {
          bl = bl || ((selectedItemTypes.length === 1) && (selectedItemTypes[0] === type));
        });
      }

      return bl;
    });
  }

  calcScreenSizeVars() {

    this.view.colSize = {
      left: (!!this.view.isUltraWide ? 2 : (window.innerWidth > 768 ? 3 : 12)),
      item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? (this.view.expertMode && !this.view.isUltraWide ? 4 : this.view.isUltraWide ? 2 : 3) : 6) : 12),
      right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
    };

    this.view.itemSize = (this.view.isDesktop ? 260 : 315);
  }

  async calcViewVars() {
    this.paginationConfig.limit = this.paginationConfig.limit || 500;

    this.view = this.viewService.calcVars(this.view);

    this.view.canDelete = (this.view.mode !== 'pick') && this.userService.isType(['Admin', 'Creator', 'Moderator', 'Redakteur', 'Moderator']);
    this.view.canPromote = (this.view.mode !== 'pick') && this.userService.isType(['Admin', 'Creator', 'Redakteur', 'Moderator']);
    this.view.canPublish = (this.view.mode !== 'pick') && this.userService.isType(['Admin', 'Moderator']);
    this.view.canSubmitForReview = (this.view.mode !== 'pick') && this.userService.isType(['Admin', 'Redakteur', 'Moderator']);

    this.view.mediaType = this.view.mediaType || 'all';
    this.view.queueViewMode = this.view.queueViewMode || 'card';
    this.view.showViewModeSelect = true;
    this.view.viewType = this.view.viewType || 'grid';

    this.calcScreenSizeVars();
  }

  cancelFolderMode() {
    this.view.createFolderMode = false;
  }

  combineSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.loading = true;

    const parts: string[] = this.view.selectedItems.filter((item: mediaItem) => {
      return !!item.guid && (item.type === 'video');
    }).map((item: mediaItem) => {
      return item.guid;
    });

    if (!parts || !parts.length) {
      this.events.publish('error', 'error_no_videos_selected');
      return false;
    }

    this.tools.bulk({
      action: 'compileVideoParts',
      params: {
        link_to_project: true,
        parts: parts,
      },
      service: this.media,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  createAttribute() {
    this.view.createAttributeMode = true;
  }

  createFolder() {
    this.view.createFolderMode = true;
  }

  createTag() {
    this.view.createTagMode = true;
  }

  creatorChanged(item: any, event: any = null) {
    console.log('creatorChanged: item', item);
  }

  dateRangeChanged(item: any, event: any | null = null) {
    this.saveFilters();
  }

  async delete(mediaItem: mediaItem) {

    if (!mediaItem.uid) {
      return false;
    }

    this.media.deleteMediaItem(mediaItem.uid)
      .then(() => {
        this.applyMedia(this.view.mediaList.filter((_mediaItem: mediaItem) => {
          return mediaItem.uid !== (_mediaItem.uid || _mediaItem.ID);
        }));
        //this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.loading = true;

    this.tools.bulk({
      action: 'deleteMediaItem',
      items: this.view.selectedItems,
      service: this.media,
    })
      .then(() => {
        this.view.loading = false;

        const uids: number[] = (this.view.selectedItems || []).map((item: mediaItem) => {
          return item.ID || item.uid;
        });

        // old: this.doRefresh();
        // rnew: emove deleted items from view without reloading UI
        if (!!uids) {
          this.applyMedia(this.view.mediaList.filter((_mediaItem: mediaItem) => {
            return uids.indexOf(_mediaItem.uid || _mediaItem.ID) === -1;
          }));
        }

      })
      .catch((error: any) => {
        this.view.loading = false;
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  deleteTag(tag: tag) {

  }

  detectChanges() {
  }

  doRefresh(event: any | null = null, blRefreshFolders: boolean = true) {
    const method: string = (!!this.view.currentFolder ? 'loadMediaByFolder' : 'load');

    console.log('doRefresh: method', method);

    this[method](true)
      .then(() => {

        if (event) {
          event.target.complete();
        }

        this.runSearch();
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (method !== 'load') {
          this.loadFolders(true)
            .then(() => {
              this.loadAttributes(true);
            })
            .catch((error: any) => {
              console.warn('loading folders failed', error);
            });
        }

        if (event) {
          event.target.complete();
        }

        this.detectChanges();
      });
  }

  download(mediaItem: mediaItem) {
    new Promise((resolve, reject) => {

      if (!mediaItem.uid) {
        return false;
      }

      this.media.download(mediaItem.uid)
        .catch((error: any) => {
          if (!!error) {
            this.events.publish('error', error);
          }
        });
    });
  }

  downloadSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'download',
      items: this.view.selectedItems,
      service: this.media,
    })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  expandList(listName: string) {
    this.view.expandedList = listName;
  }

  filterStorage(storage: any) {
    console.log('filterStorage', storage);
  }

  image_to_video(item: mediaItem = null) {
    item = item || this.view.item;
    return this.aiTools_imageToVideo([item]);
  }

  import(item: mediaItem) {

  }

  importSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'importFromUrl',
      identifier: 'url',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
        item.downloaded = true;
      },
      service: this.media,
    })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    // removes an item from view if deleted on ViewPage or somewhere else
    this.view.events.mediaItemDeleted = this.events.subscribe('media:item:deleted', (uid: number) => {

      if (!uid) {
        return false;
      }

      this.applyMedia(this.view.mediaList.filter((_mediaItem: mediaItem) => {
        return uid !== (_mediaItem.uid || _mediaItem.ID);
      }));
    });

    this.view.events.mediaLibraryRefresh = this.events.subscribe('media:library:refresh', () => {
      this.doRefresh();
    });

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = this.view.project || {};

      if (!!project && (project.uid !== this.view.project.uid)) {
        this.doRefresh();
      }
    });

    this.view.events.selectionChanged = this.events.subscribe('selection:changed', (data: any) => {
      this.calcViewVars();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
      this.calcScreenSizeVars();
    });
  }

  async initViewVars() {
    this.view = this.viewService.calcScreenSizeVars(this.view);

    if (!this.calendarOptions) {
      this.calendarOptions = {
        showPagination: true,
        showSelectionToolbar: false,
        showTitle: true,
        showViewModes: true,
        view: 'week',
        viewModes: ["day", "week", "month"],
      };
    }

    this.view.itemsKey = this.view.itemsKey || 'mediaList';

    if (!this.paginationConfig) {
      this.paginationConfig = {
        itemsKey: 'mediaList',
        limit: 1000,
      };
    }

    if (!this.view.hasOwnProperty('selectionFilters')) {
      this.view.selectionFilters = [
        {
          handler: (item: any, event: any | null = null) => {
            this.mediaTypeChanged(item, event);
          },
          icon: 'images-outline',
          label: 'media_types',
          type: 'select',
          uid: 'mediaType',
          value: 'all',
          values: [
            {
              icon: 'images-outline',
              uid: 'all',
              label: 'all_files',
            },
            {
              icon: 'image-outline',
              uid: 'image',
              label: 'images',
            },
            {
              icon: 'film-outline',
              uid: 'video',
              label: 'videos',
            },
            {
              icon: 'musical-notes-outline',
              uid: 'audio',
              label: 'audio',
            },
            {
              icon: 'document-outline',
              uid: 'attachment',
              label: 'documents',
            },
          ]
        },
        {
          handler: (item: any, event: any | null = null) => {
            this.overlayChanged(item, event);
          },
          icon: 'text-outline',
          label: 'overlay',
          type: 'select',
          uid: 'blHasOverlay',
          value: 'all',
          values: [
            {
              label: 'all',
              uid: 'all',
            },
            {
              label: 'yes',
              uid: true,
            },
            {
              label: 'no',
              uid: false,
            }
          ],
        },
        {
          handler: (item: any, event: any | null = null) => {
            this.dateRangeChanged(item, event);
          },
          icon: 'calendar-outline',
          label: 'date_range',
          type: 'select',
          uid: 'date',
          value: 'all',
          values: [
            {
              label: 'total',
              uid: 'all',
            },
            {
              label: 'date_range_last_week',
              uid: 'week',
            },
            {
              label: 'date_range_last_month',
              uid: 'month',
            },
            {
              label: 'date_range_last_year',
              uid: 'year',
            },
          ]
        },
        {
          handler: (item: any, event: any | null = null) => {
            this.creatorChanged(item, event);
          },
          icon: 'person-outline',
          label: 'creator',
          type: 'select',
          uid: 'creator',
          value: 'all',
          values: [
            {
              label: 'all',
              uid: 'all',
            },
            {
              label: 'ai',
              uid: 'ai',
            },
            {
              label: 'user',
              uid: 'user',
            },
          ]
        },
      ]
    }

    this.view.showFilters = !!this.view.isDesktop && (!!this.view.selectionFilters && !!this.view.selectionFilters.length);
    this.selectionOptions = this.selectionOptions || this.defaultSelectionOptions;

    if (!this.view.hasOwnProperty('showPagination')) {
      this.view.showPagination = true;
    }

    const introCardUid: string = `${this.view.source || 'media_library'}_top_card`;

    this.view.introCard = {
      hidden: await this.introService.isIntroCardHidden(introCardUid),
      uid: introCardUid,
      text: `${this.view.source || 'media_library'}_top_card_text`,
      title: `${this.view.source || 'media_library'}_top_card_title`,
    };

    await this.loadFilters();
  }

  async loadFilters() {
    const attributes: any = (!!this.view.filters && !!this.view.filters.attributes ? this.view.filters.attributes : {});

    // load back stored filters settings
    this.view.filters = await this.media.getFilters() || {};
    this.view.filters.attributes = (attributes || {});

    const filterKeys: string[] = Object.keys(this.view.filters);

    filterKeys.forEach((filterKey: string) => {
      this.view.selectionFilters.forEach((selectionFilter: any) => {
        if (selectionFilter.uid === filterKey && !!this.view.filters[filterKey]) {
          selectionFilter.value = this.view.filters[filterKey];
        }
      });
    });

    if (!!this.view.filters.language) {
      this.view.language = this.view.filters.language;
    }

    if (!!this.view.filters.mediaType) {
      this.view.mediaType = this.view.filters.mediaType;
    }

  }

  loadFolders(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.project = await this.projects.getCurrent();

      if (!this.view.project || !this.view.project.uid) {
        this.view.loadingFolders = false;
        return false;
      } else {
        this.view.loadingFolders = true;

        this.media.getFolders(this.view.options, blForceRefresh, { limit: 1000 })
          .then((folders: folder[]) => {
            this.view.loadingFolders = false;

            this.view.folders = (folders || [])
              .map((folder: folder) => {
                folder.active = !!(!!this.view.currentFolder && (this.view.currentFolder.uid === folder.uid));
                return folder;
              })
              .sort((a: any, b: any) => {

                const _a: string = `${a.title}`.toLowerCase(),
                  _b: string = `${b.title}`.toLowerCase();

                if (_a < _b) return -1;
                if (_b > _a) return 1;
                return 0;
              });

            this.detectChanges();
            resolve(folders);
          })
          .catch((error: any) => {
            this.view.loadingFolders = false;
            reject(error);
          });
      }

    });
  }

  loadImages(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.images.getAll(this.view.options, blForceRefresh, { limit: 100 })
        .then((mediaList: mediaItem[]) => {

          this.view.images = mediaList.map((image: mediaItem) => {
            return image.type = 'image';
          });

          resolve(mediaList);
        })
        .catch(reject);
    });
  }

  loadTags(blForceRefresh: boolean = true) {
    if (!!this.view.mediaList) {
      this.view.tags = this.tagsService.extractTagsFromItems(this.view.mediaList);
    }
  }

  loadVideos(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.videos.getLibrary(blForceRefresh, this.view.options, { limit: 100 })
        .then((posts: post[]) => {
          this.view.videos = (posts || []).map((image: mediaItem) => {
            return image.type = 'video';
          });;
          resolve(posts);
        })
        .catch(reject);
    });
  }

  load(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadFilters();

      this.view.loading = true;
      this.view.showMediaImportCard = false;

      let methodName: string = 'getMediaList';

      // @todo add improved methods here
      switch (this.view.mediaType) {
        case 'attachment': methodName = 'getDocuments'; break;
        case 'audio': methodName = 'getAudioList'; break;
        case 'image': methodName = 'getImages'; break;
        case 'video': methodName = 'getVideos'; break;
        default: methodName = 'getMediaList'; break;
      }

      if (!!this.view.source) {
        switch (this.view.source) {
          case 'assets': methodName = 'getAssets'; break;
          case 'creatives': methodName = 'getCreatives'; break;
        }
      }

      this.view.project = await this.projects.getCurrent();

      if (!this.view.project || !this.view.project.uid) {
        this.view.loading = false;
        return false;
      }

      this.view.filters = this.view.filters || {};
      this.view.filters.attributes = this.view.filters.attributes || {};
      this.view.filters.language = this.view.language;
      this.view.filters.limit = this.view.filters.limit || 1000;

      try {
        this.view.loading = true;

        const response: any = await this.media[methodName](this.view.filters, blForceRefresh);
        let media: mediaItem[] = [];

        if (!!response && !!response.hasOwnProperty('data')) {
          media = (response.data || []);
        } else
          if (!!response && !!response[0] && !!response[0].ID) {
            media = response;
          }

        this.view.loading = false;
        this.view.showMediaImportCard = !this.view.hideMediaImportCard;

        this.applyMedia(media);

        try {
          this.loadFolders(blForceRefresh);
        } catch (e) {
          console.warn('loading folders failed', e);
        }

        this.loadAttributes(blForceRefresh);
        this.loadStorages(blForceRefresh);

        this.applyViewType();

        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  loadAttributes(blForceRefresh: boolean = true) {
    try {
      this.attributesFilterCard.loadAttributes(blForceRefresh);
    } catch (e) {
      console.warn('media-library: loadAttributes failed', e);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadMediaByFolder(event: any | null = null) {
    return new Promise(async (resolve, reject) => {
      console.log('loadMediaByFolder: event', event);

      if (!!event && !!event.children && !!event.children.length) {
        console.log('a');
        this.applyMedia(event.children).then(resolve).catch(reject);
      } else
        if (!!event && !!event.platform) {
          console.log('b');
          this.loadMediaByFolderAndPlatform(event).then(resolve).catch(reject);
        } else {
          if (!!event && !!event.uid) {
            try {
              if (event.uid === '/') {
                // main library requested
                this.view.currentFolder = null;
                this.load().then(resolve).catch(reject);
              } else {
                // load media by folder uid

                const folder: folder = Object.assign(event, {
                  location: 'media_library',
                });

                // request loading folder items using folders filter card component
                if (!this.view.currentFolder || !this.view.currentFolder.uid || (event.uid !== this.view.currentFolder.uid)) {
                  this.foldersFilterCard.openFolder(folder);
                }

                this.view.currentFolder = event;
                resolve(true);
              }
            } catch (e) {
              console.warn('loadMediaByFolder: openFolder failed:', e);
              reject(e);
            }
          } else
            if (!event) {
              await this.doRefresh();
              resolve(true);
            } else {
              resolve(true);
            }
        }
    })
  }

  async loadMediaByFolderAndPlatform(event: any, connection: integrationConnection | null = null) {
    connection = connection || this.view.storageConnection;
    event = event || {};
    event.guid = event.guid || event.uid;

    if (!event || !event.guid || !connection || !connection.uid) {
      return false;
    }

    switch (event.platform) {
      case 'ftpmanager':

        const browseOptions: any = {
          directory: event.guid,
        };

        this.view.loadingFolders = true;

        const exec: any = await this.ftp.browse(connection, browseOptions);
        const sorted: any = await this.ftp.sortBrowseResponse(exec, connection, browseOptions);
        console.log('media-library: ftp browse sorted', sorted);

        // add missing folders to folders list
        try {

          const existingFolderUids: any[] = (this.view.folders || []).map((folder: folder) => {
            return folder.uid;
          });

          (sorted.folders || []).forEach((folder: folder) => {
            if (existingFolderUids.indexOf(folder.uid) === -1) {

              if (!this.view.folders) {
                this.view.folders = [];
              }

              this.view.folders.push(folder);
            }
          });
        } catch (e) {
        }

        // overwrite folders:
        this.view.folders = (!!sorted.folders && !!sorted.folders.length ? sorted.folders : (this.view.folders || []));
        this.view.loadingFolders = false;

        this.applyMedia(sorted.items || []);
        break;
      default:
        this.events.publish('error', 'not_implemented');
        break;
    }

  }

  async loadStorages(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loadingStorages = true;

      this.integrations.getEnabled()
        .then((integrations: integration[]) => {
          let indents: string[] = [], storages: integrationConnection[] = [];

          if (!!integrations && !!integrations.length && !!this.integrationsByType.storage) {
            indents = integrations.map((integration: integration) => {
              return `${(integration.indent || integration.name) || ''}`.replace('integration_', '');
            });
          }

          indents.forEach(async (indent: string, index: number) => {
            switch (indent) {

              case 'akash':
                break;

              case 'aws':
                break;

              case 'ftp':
                const ftpConnections: any[] = await this.ftp.getConnections({}, blForceRefresh);

                if (!!ftpConnections && !!ftpConnections.length) {
                  storages = storages.concat(ftpConnections);
                }
                break;

              case 'google_colab':
                break;

              case 'google_drive':
                break;

              case 'hyperstack':
                break;

              case 'lambdalabs':
                break;

              case 'microsoft_azure':
                break;
            }

            this.view.storages = (storages || []);

            if (index === (indents.length - 1)) {
              this.view.loadingStorages = false;
              resolve(this.view);
            }
          });
        })
        .catch((error: any) => {
          this.view.loadingStorages = false;
          reject(error);
        });
    });
  }

  mediaImportHideStateChanged(bl: boolean | null = null) {
    if (bl === false) {
      this.view.hideMediaImportCard = true;
    }
  }

  mediaTypeChanged(item: any, event: any | null = null) {
    this.view.mediaType = `${item.value || ''}`;

    this.view.filters = this.view.filters || {};
    this.view.filters.mediaType = this.view.mediaType;

    this.saveFilters();

    try {
      this.load();
    } catch (e) {
      console.warn('loading failed', e);
    }

  }

  move_folder(media: mediaItem) {
    this.media.moveFolder(media)
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedFolders() {
    this.media.moveFolder(this.view.selectedItems)
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedProjects() {

  }

  ngAfterViewInit() {

    if (!!this.view.loading) {
      console.warn('media-library: already loading');
      return false;
    }

    this.load()
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });

  }

  ngOnDestroy() {

    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }

    try {
      this.subs.unsubscribe();
    } catch (e) {
      console.warn('unsubscribe failed', e);
    }

  }

  ngOnInit() {
    this.calcViewVars();
    this.loadCards();

    this.initViewVars();
    this.initEvents();
  }

  onAttributeFiltersChanged(event: any | null = null) {
    this.doRefresh(null, false);
  }

  onBreadcrumbsButtonClick(breadcrumb: breadcrumb) {
    console.log('media-library: onBreadcrumbsButtonClick', breadcrumb);

    if (!breadcrumb || !breadcrumb.uid) {
      return false;
    }

    try {
      this.loadMediaByFolder(breadcrumb as folder);
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  onFolderLoaded(items: any[] | null = null) {
    console.log('media-library: onFolderLoaded: items', items);

    this.view.rebuild = true;
    this.view.mediaList = JSON.parse(JSON.stringify((items || [])));

    this.paginationConfig = this.paginationConfig || {};
    this.paginationConfig.backup = this.view.mediaList;

    setTimeout(() => {
      this.runSearch();
      this.view.rebuild = false;
    }, 100);
  }

  onFoldersBreadcrumbsChanged(breadcrumbs: breadcrumb[]) {
    this.breadcrumbs = breadcrumbs;
  }

  onLanguageChanged(event: string | null = null) {
    if (event !== this.view.language) {
      this.view.language = event;
      this.saveFilters();
      this.doRefresh();
    }
  }

  onMediaItemChecked(mediaItem: mediaItem) {

    this.view.selectedItems = this.view.mediaList.filter((_mediaItem: mediaItem) => {
      return _mediaItem.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.events.publish('media:library:selection:changed', {
      items: this.view.selectedItems,
      last: mediaItem,
    });

    this.calcSelectionActions();
  }

  onMediaItemClicked(mediaItem: mediaItem) {
    console.log('onMediaItemClicked: mediaItem', mediaItem);

    if (!mediaItem || !mediaItem.type) {
      return false;
    }

    switch (mediaItem.type) {
      case 'folder':
        break;
    }
  }

  onMediaItemDeleted(mediaItem: mediaItem) {
    this.applyMedia(this.view.mediaList.filter((_mediaItem: mediaItem) => {
      return mediaItem.uid !== (_mediaItem.uid || _mediaItem.ID);
    }));
  }

  onMouseMove(e: any) {
    this.view.dropItem = document.elementFromPoint(e.clientX, e.clientY);
  }

  onSearchChanged(event: any | null = null) {
    try {
      const blBackground: boolean = (!!event.items && !!event.items.length);

      // run global search in background if items are visible, then merge + update view
      this.runGlobalSearch(false, blBackground, (event.items || []));

    } catch (e) {
      console.warn('global search failed', e);
    }
  }

  async onSelectedTagChanged(event: any | null = null) {
    this.view.tag = event;

    await this.runSearch();

    this.view.mediaList = this.view.mediaList.filter((item: any) => {
      let blShow: boolean = !item.hidden;

      if (!!this.view.tag && !!this.view.tag.uid) {
        if (!!item.tags && !!item.tags.length) {

          const itemTagIds: number[] = item.tags.map((tag: tag) => {
            return tag.uid;
          });

          blShow = (itemTagIds.indexOf(this.view.tag.uid) !== -1);
        } else {
          blShow = false;
        }
      }

      return blShow;
    });
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'add_to_training':
        this.addSelectedToTraining();
        break;
      case 'analyze':
        this.analyzeSelected();
        break;
      case 'combine':
        this.combineSelected();
        break;
      case 'delete':
        this.deleteSelected();
        break;
      case 'download':
        this.downloadSelected();
        break;
      case 'image_to_video':
        this.aiTools_imageToVideo();
        break;
      case 'import':
        this.importSelected();
        break;
      case 'move_folder':
        this.moveSelectedFolders();
        break;
      case 'move_project':
        this.moveSelectedProjects();
        break;
      case 'send_to_media_creator':
        this.sendSelectedToMediaCreator();
        break;
      case 'send_to_video_creator':
        this.sendSelectedToVideoCreator();
        break;
      case 'share':
        this.shareSelected();
        break;
      case 'upscale':
        this.upscaleSelected();
        break;
      default:
        console.warn('not_implemented', event);
        break;
    }

    this.view.selectedItems = [];
    this.view.hasSelectedItems = false;

    return true;
  }

  onViewTypeChanged(event: any = null) {
    this.calcViewVars();
    this.applyViewType(event);
  }

  async openStorage(storage: integrationConnection) {

    if (!storage || !storage.uid || !storage.platform) {
      return false;
    }

    this.view.storages.forEach((_storage: integrationConnection) => {
      _storage.checked = (_storage.uid === storage.uid);
    });

    this.view.currentFolder = null;
    this.view.currentFolderParent = null;
    this.view.currentFolderUid = null;
    this.view.storageConnection = storage;

    this.breadcrumbs = [];

    try {
      let folders: any[] = [], mediaList: mediaItem[] = [];
      this.view.loading = true;

      switch (storage.platform) {
        case 'ftpmanager':
          const exec: any = await this.ftp.browse(storage);
          const sorted: any = await this.ftp.sortBrowseResponse(exec, storage);

          if (!!sorted.folders && !!sorted.folders.length) {
            folders = folders.concat(sorted.folders);
          }

          if (!!sorted.items && !!sorted.items.length) {
            mediaList = mediaList.concat(sorted.items);
          }

          break;
      }

      this.view.folders = folders;

      this.applyMedia(mediaList);

      this.view.loading = false;
    } catch (e) {
      this.view.loading = false;
      this.events.publish('error', e);
    }
  }

  overlayChanged(item: any, event: any | null = null) {

    switch (item.value) {
      case false: this.view.source = 'assets'; break;
      case true: this.view.source = 'creatives'; break;
      default: this.view.source = false; break;
    }

    this.saveFilters();

    try {
      this.load();
    } catch (e) {
      console.warn('loading failed', e);
    }

  }

  runGlobalSearch(blForceRefresh: boolean = false, blBackground: boolean = false, appendChildren: any[] = []) {

    if (!this.search || !this.search.query || !this.search.query.length || (this.search.query.length < 3)) {
      return false;
    }

    if (!blBackground) {
      this.view.loading = true;
    }

    this.media.search(this.search.query, {}, blForceRefresh)
      .then(async (response: any) => {
        this.view.loading = false;

        if (!!response && !!response.length) {
          let items: any[] = [], responseItems: any[] = (response || []);

          console.log('appendChildren', appendChildren);

          if (!!appendChildren && !!appendChildren.length) {
            const existingIds: number[] = responseItems.map((item: mediaItem) => { return item.ID; });
            console.log('existingIds', existingIds);

            appendChildren.forEach((child: any) => {
              if (!!child.ID && existingIds.indexOf(child.ID) === -1) {
                items.push(child);
              }
            });

            items = items.concat(responseItems);
            console.log('final items', items);
          } else {
            items = responseItems;
          }

          const view: any = Object.assign(this.view, {
            mediaList: (items || []),
          });

          const config: any = Object.assign(this.paginationConfig, {
            backup: (response || []),
          });

          const calc: any = await this.pagination.calculateConfig(view, config);

          if (!!calc && !!calc.view) {
            this.view = Object.assign(this.view, calc.view);
          }
        }
      })
      .catch((error: any) => {
        this.view.loading = false;

        if (!!error && error.toLowerCase().indexOf('too_short') === -1 && error.toLowerCase() !== 'error_missing_search_input') {
          this.events.publish('error', error);
        }
      });
  }

  async runItemSelectionOption(item: any, option: any) {
    try {

      if (!option || !option.uid) {
        return false;
      }

      const exec: any = await this[option.uid](item);

    } catch (e) {
      console.warn('executing single selection on item failed', e);
      //this.events.publish('error', e);
    }
  }

  async runSearch() {
    try {

      const searchOptions: any = Object.assign(this.view, {
        paginationConfig: this.paginationConfig,
      });

      await this.searchToolbar.runSearch(null, searchOptions);
      await this.loadTags();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  saveFilters() {
    try {
      this.view.filters = this.view.filters || {};
      this.view.filters.language = this.view.filters.language || this.view.language;
      this.view.filters.mediaType = this.view.filters.mediaType || this.view.mediaType;

      this.media.saveFilters(this.view.filters);

    } catch (e) {
      console.warn('saving media filters failed', e);
    }
  }

  sendSelectedToMediaCreator() {

    if (!this.view.selectedItems || !this.view.selectedItems[0]) {
      return false;
    }

    this.mediaActions.send_to_media_creator(this.view.selectedItems[0], this.view.selectedItems);
  }

  sendSelectedToVideoCreator() {

    if (!this.view.selectedItems || !this.view.selectedItems[0]) {
      return false;
    }

    this.mediaActions.send_to_video_creator(this.view.selectedItems[0], this.view.selectedItems);
  }

  selectAll() {

    this.view.mediaList.forEach((mediaItem: mediaItem) => {
      mediaItem.checked = !mediaItem.hidden && !this.view.allSelected;
    });

    this.view.allSelected = !this.view.allSelected;

    this.view.selectedItems = this.view.mediaList.filter((mediaItem: mediaItem) => {
      return !!mediaItem.checked;
    });

    this.view.hasSelectedItems = !!this.view.selectedItems && !!this.view.selectedItems.length;

    this.events.publish('media:library:selection:changed', {
      items: this.view.selectedItems,
      last: (!!this.view.selectedItems ? this.view.selectedItems[0] : null),
    });

    this.calcSelectionActions();
    this.detectChanges();
  }

  setView(viewType: string) {
    this.view.viewType = viewType;
  }

  shareSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
    });
  }

  tagSettings(tag: tag) {
    console.log('tagSettings', tag);
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  upscaleSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'upscale',
      identifier: 'guid',
      items: this.view.selectedItems,
      service: this.sd,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}