<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Small generate button (if buttonAction = use)-->
      <ion-button icon-only
        *ngIf="!!view.startManually && (view.phase !== 'presenting' && view.buttonAction === 'generate')"
        [hidden]="!!view.showSplineView" (click)="aiCreate()" color="dark" fill="clear"
        [disabled]="!!view.loading || (view.phase === 'loading')">
        <ion-icon name="sync-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'rebuild_all'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

      <!-- Download button -->
      <!--
      <ion-button *ngIf="!!view.finalResultUrl" [href]="view.finalResultUrl" download
        [color]="view.downloaded ? 'success' : 'primary'" fill="clear">
        <ion-icon [name]="view.downloaded ? 'download' : 'download-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="(view.downloaded ? 'downloaded' : 'download')|translate"></ion-label>
      </ion-button>
      -->

      <!-- Use button -->
      <ion-button *ngIf="view.phase !== 'presenting' && view.buttonAction === 'use'"
        [hidden]="!!view.showSplineView || (view.phase === 'loading') || (view.phase === 'presenting')"
        [disabled]="!!view.loading || !view.selectedItems || !view.selectedItems.length" (click)="generate()"
        shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'use'|translate"></ion-label>
      </ion-button>

      <!-- Complete button -->
      <ion-button *ngIf="view.phase === 'presenting'" (click)="compileParts()" [disabled]="!!view.loading" shape="round"
        color="primary" fill="solid">
        <ion-icon name="cut-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'complete'|translate"></ion-label>
      </ion-button>

      <!-- Main generate button -->
      <ion-button *ngIf="!!view.startManually && (view.phase !== 'presenting' && view.buttonAction === 'generate')"
        [hidden]="!!view.showSplineView || (view.phase === 'loading') || (view.phase === 'presenting')"
        (click)="generate()" [disabled]="!!view.loading || !view.canGenerate" shape="round" color="primary"
        fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'generate'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Progress bar -->
  <ion-toolbar class="ion-no-border container second-toolbar progress-toolbar" *ngIf="!!view.progress">
    <pipeline-progress-bar [progress]="view.progress" [view]="view"></pipeline-progress-bar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Single media item information popover -->
  <ion-popover #itemInfoPopover [isOpen]="isItemInfoPopoverOpen" (didDismiss)="isItemInfoPopoverOpen = false">
    <ng-template>
      <ion-content>

        <ion-list *ngIf="!!view.item && !!view.item.info">

          <!-- Item search query -->
          <ion-item [hidden]="!view.item.search_query">
            <ion-icon name="text-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'input'|translate"></h3>
              <p [innerHTML]="view.item.search_query"></p>
            </ion-label>

          </ion-item>

          <!-- Item negative_prompt -->
          <ion-item [hidden]="!view.item.info.negative_prompt">
            <ion-icon name="text-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'negative_prompt'|translate"></h3>
              <p [innerHTML]="view.item.info.negative_prompt"></p>
            </ion-label>

          </ion-item>

          <!-- Model name -->
          <ion-item [hidden]="!view.item.info.sd_model_name">
            <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'sd_model_name'|translate"></h3>
              <p [innerHTML]="view.item.info.sd_model_name"></p>
            </ion-label>

          </ion-item>

          <!-- Seed -->
          <ion-item [hidden]="!view.item.info.seed">
            <ion-icon name="finger-print-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'seed'|translate"></h3>
              <p [innerHTML]="view.item.info.seed"></p>
            </ion-label>

          </ion-item>

          <!-- Sampler -->
          <ion-item [hidden]="!view.item.info.sampler_name">
            <ion-icon name="layers-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'sampler_name'|translate"></h3>
              <p [innerHTML]="view.item.info.sampler_name"></p>
            </ion-label>

          </ion-item>

          <!-- CFG Scale -->
          <ion-item [hidden]="!view.item.info.cfg_scale">
            <ion-icon name="flask-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'cfg_scale'|translate"></h3>
              <p [innerHTML]="view.item.info.cfg_scale"></p>
            </ion-label>

          </ion-item>

          <!-- Denoising -->
          <ion-item [hidden]="!view.item.info.denoising_strength">
            <ion-icon name="flask-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'denoising_strength'|translate"></h3>
              <p [innerHTML]="view.item.info.denoising_strength"></p>
            </ion-label>

          </ion-item>

          <!-- Steps -->
          <ion-item [hidden]="!view.item.info.steps">
            <ion-icon name="sync-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'steps'|translate"></h3>
              <p [innerHTML]="view.item.info.steps"></p>
            </ion-label>

          </ion-item>

          <!-- Instance -->
          <ion-item [hidden]="!view.item.instance && !view.item.info.instance">
            <ion-icon name="server-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'instance'|translate"></h3>
              <p [innerHTML]="view.item.instance || view.item.info.instance"></p>
            </ion-label>

          </ion-item>

        </ion-list>

      </ion-content>
    </ng-template>
  </ion-popover>

  <!-- Create media intro mode -->
  <div class="tool-intro-wrapper" *ngIf="!!media && !media.uid && !view.startManually">
    <div class="container">

      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'create_media_ai_helper_text'|translate"></ion-card-title>
        </ion-card-header>

        <ion-textarea rows="2" [(ngModel)]="view.aiCreateInput" [disabled]="view.loading"
          [placeholder]="'create_media_ai_helper_input_placeholder'|translate"></ion-textarea>

        <ion-card-header>
          <ion-card-title [innerHTML]="'video_length'|translate"></ion-card-title>
        </ion-card-header>

        <!-- Video length -->
        <ion-grid class="types-grid">

          <ion-row>

            <!-- Type: Ad -->
            <ion-col [size]="view.isDesktop ? 6 : 12">
              <ion-button class="types-btn" size="block" [class.active]="view.iVideoLength === 15"
                [fill]="view.iVideoLength === 15 ? 'solid' : 'outline'" color="primary" (click)="setVideoLength(15)">
                <ion-icon name="phone-portrait-outline"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="'ai_video_creator_length_15'|translate"></h3>
                  <p [innerHTML]="'ai_video_creator_length_15_subtext'|translate"></p>
                </ion-label>
              </ion-button>
            </ion-col>

            <!-- Type: Movie -->
            <ion-col [size]="view.isDesktop ? 6 : 12">
              <ion-button class="types-btn" size="block" [class.active]="view.iVideoLength === 60"
                [fill]="view.iVideoLength === 60 ? 'solid' : 'outline'" color="primary" (click)="setVideoLength(60)">
                <ion-icon name="film-outline"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="'ai_video_creator_length_60'|translate"></h3>
                  <p [innerHTML]="'ai_video_creator_length_60_subtext'|translate"></p>
                </ion-label>
              </ion-button>
            </ion-col>

          </ion-row>

        </ion-grid>

        <ion-card-header>
          <ion-card-title [innerHTML]="'aspect_ratio'|translate"></ion-card-title>
        </ion-card-header>

        <!-- Aspect ratio -->
        <ion-grid class="types-grid">
          <ion-row>

            <ion-col *ngFor="let aspect_ratio of view.aspect_ratios; let iAspectRatio = index;"
              [size]="view.isDesktop ? 3 : 6">

              <ion-button class="types-btn" [class.active]="aspect_ratio.checked" size="block"
                [fill]="aspect_ratio.checked ? 'solid' : 'outline'" color="primary"
                (click)="toggleAspectRatio(aspect_ratio, iAspectRatio)">
                <ion-icon [name]="aspect_ratio.icon"></ion-icon>
                <ion-label [innerHTML]="aspect_ratio.name|translate"></ion-label>
                <ion-badge *ngIf="!!aspect_ratio.label" [innerHTML]="aspect_ratio.label|translate"
                  [color]="aspect_ratio.labelColor || 'primary'"></ion-badge>
              </ion-button>

            </ion-col>

          </ion-row>
        </ion-grid>

        <ion-grid>
          <ion-row>

            <!-- AI create -->
            <ion-col [size]="view.isDesktop ? 6 : 12">

              <ion-spinner [hidden]="!view.loading"></ion-spinner>

              <ion-button size="block" (click)="aiCreate()" shape="round" [hidden]="view.loading"
                [disabled]="!view.aiCreateInput || !view.aiCreateInput.length">
                <ion-icon name="sparkles-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'generate'|translate"></ion-label>
              </ion-button>
            </ion-col>

            <!-- AI settings -->
            <ion-col [size]="view.isDesktop ? 6 : 12">

              <pipeline-ai-settings-picker [config]="aiSettings" context="text-generation" size="block" fill="clear"
                [mini]="false" [view]="view" (changed)="aiSettingsChanged($event)"></pipeline-ai-settings-picker>

            </ion-col>

            <!-- Create manually -->
            <ion-col size="12">
              <ion-button class="start-manually-btn" (click)="startManually()" color="dark" size="block" fill="clear">
                <ion-icon name="create-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'create_media_manually'|translate"></ion-label>
              </ion-button>
            </ion-col>

          </ion-row>
        </ion-grid>

      </ion-card>

    </div>
  </div>

  <!-- Select project picker -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container slider-container" [class.expert-mode]="!!view.expertMode"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Mobile Intro card -->
    <pipeline-intro-card *ngIf="!view.isDesktop" [hidden]="view.introCard.hidden" class="introCard"
      [(view)]="view.introCard"></pipeline-intro-card>

    <div class="main-view" [class.expert-mode]="!!view.expertMode">

      <ion-grid class="main-grid">

        <ion-row>

          <!-- Sidebar -->
          <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [class.expert-mode]="!!view.expertMode">

            <!-- Information of current item -->
            <div class="col-inner"
              *ngIf="!!view.sections && !!view.sections[view.sectionIndex]"
              [class.success]="!!view.sections && !!view.sections[view.sectionIndex] && !!view.sections[view.sectionIndex].prompt">

              <ion-card class="sidebar-accordion-card">

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('information')">
                    <span [innerHTML]="'information'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('information')" icon-only color="dark"
                    fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.information && !!cards.information.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <ion-list [hidden]="!!cards && !!cards.information && !cards.information.open">

                  <!-- Positive prompt -->
                  <ion-item lines="none">

                    <ion-icon name="eye-outline" slot="start"></ion-icon>

                    <ion-textarea
                      [disabled]="!!view.sections[view.sectionIndex].analyzing || !!view.sections[view.sectionIndex].loading"
                      [rows]="view.isDesktop ? 3 : 6" [(ngModel)]="view.sections[view.sectionIndex].prompt"
                      [placeholder]="'ai_prompt_section_modification'|translate"></ion-textarea>

                  </ion-item>

                  <!-- Negative prompt -->
                  <ion-item lines="none">

                    <ion-icon name="eye-off-outline" slot="start"></ion-icon>

                    <ion-textarea
                      [disabled]="!!view.sections[view.sectionIndex].analyzing || !!view.sections[view.sectionIndex].loading"
                      [rows]="view.isDesktop ? 3 : 6" [(ngModel)]="view.sections[view.sectionIndex].negative_prompt"
                      [placeholder]="'negative_prompt'|translate"></ion-textarea>

                  </ion-item>

                  <!-- Camera -->
                  <ion-item lines="none">

                    <ion-icon name="videocam-outline" slot="start"></ion-icon>

                    <ion-textarea
                      [disabled]="!!view.sections[view.sectionIndex].analyzing || !!view.sections[view.sectionIndex].loading"
                      [rows]="view.isDesktop ? 3 : 6" [(ngModel)]="view.sections[view.sectionIndex].camera_behaviour"
                      [label]="'camera'|translate" labelPlacement="stacked"
                      [placeholder]="'camera_behaviour'|translate"></ion-textarea>

                  </ion-item>

                  <!-- Optimize input -->
                  <ion-item lines="none">

                    <ion-checkbox [(ngModel)]="aiSettings.creative" justify="start" labelPlacement="end"
                      [innerHTML]="'media_creator_fine_tune_input'|translate" class="ion-text-wrap"></ion-checkbox>

                    <ion-button (click)="presentInfoPopover($event, 'media_creator_fine_tune_input_infotext')" icon-only
                      fill="clear" color="dark" slot="end">
                      <ion-icon name="information-circle-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                </ion-list>

              </ion-card>
            </div>

            <!-- Output settings -->
            <div [class.success]="view.aspect_ratio" class="col-inner">
              <ion-card class="sidebar-accordion-card">

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('output')">
                    <span [innerHTML]="'output'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('output')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.output && cards.output.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.output && !cards.output.open">

                  <!--
                  <ion-card-content>
                    <p [innerHTML]="'media_creator_output_text'|translate"></p>
                  </ion-card-content>
                  -->

                  <!-- Aspect ratio -->
                  <ion-grid class="types-grid">
                    <ion-row>

                      <ion-col size="3" *ngFor="let aspect_ratio of view.aspect_ratios; let iAspectRatio = index;">

                        <ion-button class="types-btn" [class.active]="aspect_ratio.checked" size="block"
                          [fill]="aspect_ratio.checked ? 'solid' : 'outline'" color="primary"
                          (click)="toggleAspectRatio(aspect_ratio, iAspectRatio)">
                          <ion-icon [name]="aspect_ratio.icon"></ion-icon>
                          <ion-label [innerHTML]="aspect_ratio.name|translate"></ion-label>
                        </ion-button>

                      </ion-col>

                    </ion-row>
                  </ion-grid>

                  <ion-list>

                    <!--
                    <ion-item lines="none">
                      <ion-icon [hidden]="!view.isDesktop" name="albums-outline" slot="start"></ion-icon>
                      <ion-input [label]="'amount'|translate" labelPlacement="stacked" type="number" [step]="1"
                        [min]="1" [max]="60" [(ngModel)]="aiSettings.amount"
                        (ionInput)="amountChanged()"></ion-input>
                    </ion-item>

                    <ion-item lines="none" [hidden]="!!view.expertMode || (!view.selectedTypesList || !view.selectedTypesList.video)">
                      <ion-icon [hidden]="!view.isDesktop" name="tv-outline" slot="start"></ion-icon>
                      <ion-select [label]="'video_resolution' | translate" labelPlacement="stacked" (ionChange)="resolutionChanged()" [(ngModel)]="view.resolution" [okText]="'okay' | translate" [cancelText]="'cancel' | translate">
                        <ion-select-option *ngFor="let resolution of view.resolutions" [innerHTML]="resolution.name | translate" [value]="resolution.uid"></ion-select-option>
                      </ion-select>
                    </ion-item>
                    -->

                    <ion-item lines="none" [hidden]="!view.expertMode">
                      <ion-icon [hidden]="!view.isDesktop" name="recording-outline" slot="start"></ion-icon>
                      <ion-input [label]="'fps'|translate" labelPlacement="stacked" type="number" [step]="1" [min]="1"
                        [max]="60" [(ngModel)]="aiSettings.fps"></ion-input>
                    </ion-item>

                  </ion-list>

                </div>

              </ion-card>
            </div>

            <!-- Styles -->
            <div class="col-inner">
              <pipeline-ai-style-picker [(cards)]="cards" [(view)]="view"></pipeline-ai-style-picker>
            </div>

            <!-- Voiceover -->
            <div class="col-inner">
              <pipeline-voiceover-card [(cards)]="cards" [(view)]="view"
                [options]="{ input: (!!view.sections && !!view.sections[view.sectionIndex] && !!view.sections[view.sectionIndex].speaker_text ? view.sections[view.sectionIndex].speaker_text : '')}"
                (settingsChanged)="onVoiceoverSettingsChanged($event)"></pipeline-voiceover-card>
            </div>

            <!-- Music -->
            <div class="col-inner">
              <pipeline-music-card [(cards)]="cards" [(view)]="view"
                [options]="{ input: (!!view.sections && !!view.sections[view.sectionIndex] && !!view.sections[view.sectionIndex].background_sound ? view.sections[view.sectionIndex].background_sound : '')}"
                (settingsChanged)="onMusicSettingsChanged($event)"></pipeline-music-card>
            </div>

            <!-- AI Settings -->
            <div class="col-inner">
              <pipeline-ai-settings-card [(cards)]="cards" [(config)]="aiSettings" [options]="aiSettingsOptions"
                [(view)]="view"></pipeline-ai-settings-card>
            </div>

          </ion-col>

          <!-- Content -->
          <ion-col [size]="view.isDesktop ? 9 : 12">

            <!-- Desktop Intro card -->
            <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="view.introCard.hidden" class="introCard"
              [(view)]="view.introCard"></pipeline-intro-card>

            <!-- Final video -->
            <ion-card class="finalVideoCard" *ngIf="!!view.finalResultUrl">

              <ion-card-header>
                <ion-card-title>
                  <span [innerHTML]="'final_video'|translate"></span>
                </ion-card-title>
              </ion-card-header>

              <video controls autoplay playsinline webkit-playsinline loop muted="muted"
                [src]="view.finalResultUrl"></video>

              <pipeline-media-item-actions-toolbar *ngIf="!!media.uid" [media]="media" [view]="view"
                [options]="{ showRateItemButtons: true, showUseAsPrimary: false, }"
                (buttonClicked)="mediaItemActionsToolbarButtonClicked($event)"></pipeline-media-item-actions-toolbar>

              <!-- Final video actions -->
              <ion-grid class="types-grid">

                <ion-row>

                  <ion-col [size]="view.isDesktop ? 3 : 6">
                    <ion-button class="types-btn" size="block" fill="outline" color="primary"
                      (click)="editVideoParts()">
                      <ion-icon name="arrow-back-outline"></ion-icon>
                      <ion-label [innerHTML]="'edit_video'|translate"></ion-label>
                    </ion-button>
                  </ion-col>

                  <ion-col [size]="view.isDesktop ? 3 : 6">
                    <ion-button class="types-btn" size="block" fill="outline" color="primary" (click)="newProject()">
                      <ion-icon name="add-outline"></ion-icon>
                      <ion-label [innerHTML]="'new_video'|translate"></ion-label>
                    </ion-button>
                  </ion-col>

                  <ion-col [size]="view.isDesktop ? 3 : 6">
                    <ion-button class="types-btn" size="block" fill="outline"
                      [color]="view.imported ? 'success' : 'primary'" (click)="importToLibrary()">
                      <ion-icon name="cloud-download-outline"></ion-icon>
                      <ion-label [innerHTML]="(view.imported ? 'imported' : 'import')|translate"></ion-label>
                    </ion-button>
                  </ion-col>

                  <ion-col [size]="view.isDesktop ? 3 : 6">
                    <ion-button class="types-btn" size="block" fill="outline" color="primary"
                      (click)="shareFinalVideo()">
                      <ion-icon name="share-outline"></ion-icon>
                      <ion-label [innerHTML]="'share'|translate"></ion-label>
                    </ion-button>
                  </ion-col>

                </ion-row>

              </ion-grid>

            </ion-card>

            <!-- DANI -->
            <pipeline-spline-viewer [hidden]="!!view.finalResultUrl" *ngIf="!!splineOptions && !!view.showSplineView"
              [options]="splineOptions"></pipeline-spline-viewer>

            <!-- Main swiper -->
            <div class="swiper-wrapper" [hidden]="!!view.finalResultUrl || !!view.showSplineView">

              <swiper-container *ngIf="!!view.startManually" [options]="sliderOptions"
                [pagination]="view.sections.length < 30" [slidesPerView]="sliderOptions.slidesPerView"
                class="step-{{ view.step }}" #aiVideoCreatorSwiper id="aiVideoCreatorSwiper"
                (slidechangetransitionend)="slidechangetransitionend($event)"
                (slidechangetransitionstart)="slidechangetransitionstart($event)">

                <swiper-slide *ngFor="let section of view.sections; let iSection = index;">
                  <ion-card [class.edit-mode]="section.mode === 'edit'">

                    <ion-item lines="none">

                      <ion-label class="ion-text-wrap" *ngIf="!!section.headline || !!section.subtext">
                        <h3 *ngIf="!!section.headline" [innerHTML]="section.headline"></h3>
                        <p *ngIf="!!section.subtext" [innerHTML]="section.subtext"></p>
                      </ion-label>

                      <ion-button [disabled]="!!section.loading" slot="end" color="danger" fill="clear"
                        (click)="delete(section, iSection)">
                        <ion-icon name="trash-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'delete'|translate" [hidden]="!view.isDesktop"></ion-label>
                      </ion-button>

                      <ion-button [disabled]="!!section.loading" *ngIf="!!section.url"
                        [hidden]="section.mode === 'edit'" slot="end" color="dark" fill="clear"
                        (click)="edit(section, iSection)">
                        <ion-icon name="brush" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'edit'|translate" [hidden]="!view.isDesktop"></ion-label>
                      </ion-button>

                      <ion-button [disabled]="!!section.loading" *ngIf="!!section.url"
                        [hidden]="section.mode === 'edit'" (click)="rebuildSection(section, iSection)" slot="end"
                        color="dark" fill="clear">
                        <ion-icon name="sync-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'rebuild'|translate" [hidden]="!view.isDesktop"></ion-label>
                      </ion-button>

                      <ion-button [hidden]="section.type === 'video'" [disabled]="!!section.loading" slot="end"
                        color="primary" fill="clear" (click)="accept(section, iSection)">
                        <ion-icon name="checkmark" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'accept'|translate" [hidden]="!view.isDesktop"></ion-label>
                      </ion-button>

                      <ion-button [hidden]="!section.guid || !section.uid" [disabled]="!!section.loading" slot="end"
                        color="primary" fill="clear" (click)="viewMedia(section)">
                        <ion-icon name="eye-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'view'|translate" [hidden]="!view.isDesktop"></ion-label>
                      </ion-button>

                    </ion-item>

                    <!-- Empty section -->
                    <ion-card class="select-section-type-wrapper" *ngIf="!section.type" [hidden]="section.loading">

                      <ion-card-header>
                        <ion-card-title>
                          <span [innerHTML]="'ai_section_input_mode_headline'|translate"></span>
                        </ion-card-title>
                      </ion-card-header>

                      <ion-card-content>
                        <p [innerHTML]="'ai_section_input_mode_text'|translate"></p>
                      </ion-card-content>

                      <ion-grid class="types-grid">
                        <ion-row>

                          <ion-col size="6" *ngFor="let mode of view.inputModes; let iMode = index;">

                            <ion-button class="types-btn" [class.active]="mode.checked" size="block"
                              [fill]="mode.checked ? 'solid' : 'outline'" color="primary"
                              (click)="toggleInputMode(mode, section)">
                              <ion-icon [name]="mode.icon"></ion-icon>
                              <ion-label [innerHTML]="mode.name|translate"></ion-label>
                            </ion-button>

                          </ion-col>

                        </ion-row>
                      </ion-grid>

                    </ion-card>

                    <!-- Preview container -->
                    <ion-grid>

                      <ion-row>

                        <ion-col [size]="view.isDesktop ? 1 : 2">
                          <ion-button fill="clear" [color]="view.isDesktop ? 'primary' : 'white'" shape="round"
                            icon-only (click)="slideBack()" [disabled]="!view.canSlideBack">
                            <ion-icon name="chevron-back-outline"
                              [color]="view.isDesktop ? 'primary' : 'white'"></ion-icon>
                          </ion-button>
                        </ion-col>

                        <ion-col [size]="view.isDesktop ? 10 : 8">

                          <div class="preview-wrapper" [class.square]="view.aspect_ratio === '1x1'"
                            [class.three-to-two]="view.aspect_ratio === '3x2'"
                            [class.four-to-three]="view.aspect_ratio === '4x3'"
                            [class.nine-to-sixteen]="view.aspect_ratio === '9x16'"
                            [class.sixteen-to-nine]="view.aspect_ratio === '16x9'">

                            <!-- Loading -->
                            <div class="loading-overlay" [hidden]="!section.loading">
                              <ion-skeleton-text animated class="image"></ion-skeleton-text>
                              <ion-spinner></ion-spinner>
                              <h3 *ngIf="!!section.loading_text" [innerHTML]="section.loading_text|translate"></h3>
                            </div>

                            <!-- Image -->
                            <ion-thumbnail *ngIf="!!section.url && section.type === 'image'">
                              <ion-img [src]="section.url || fallbackImg"
                                (ionError)="thumbnailLoadingFailed(section, 'url')"></ion-img>
                            </ion-thumbnail>

                            <!-- Video -->
                            <video controls autoplay playsinline webkit-playsinline loop muted="muted"
                              *ngIf="!!section.url && section.type !== 'image'" [src]="section.url"></video>

                          </div>

                          <!-- Rating & info bar -->
                          <ion-item lines="none" *ngIf="section.state === 'done'">

                            <pipeline-rate-item-buttons [(item)]="view.sections[iSection]"></pipeline-rate-item-buttons>

                            <ion-button [disabled]="!section.url" slot="end" fill="clear" color="dark" icon-only
                              (click)="itemInfo(section, $event)">
                              <ion-icon name="information-circle-outline"></ion-icon>
                            </ion-button>

                          </ion-item>

                        </ion-col>

                        <ion-col [size]="view.isDesktop ? 1 : 2">
                          <ion-button fill="clear" [color]="view.isDesktop ? 'primary' : 'white'" shape="round"
                            icon-only (click)="slideNext()" [disabled]="!view.canSlideNext">
                            <ion-icon name="chevron-forward-outline"
                              [color]="view.isDesktop ? 'primary' : 'white'"></ion-icon>
                          </ion-button>
                        </ion-col>

                      </ion-row>

                    </ion-grid>

                  </ion-card>

                  <ion-toolbar class="container ion-no-border" *ngIf="section.mode === 'edit'">

                    <!-- Run ai prompt -->
                    <pipeline-ai-prompt-card type="section" [disabled]="view.loading || !!section.loading"
                      (onSubmit)="runAiPrompt($event)"></pipeline-ai-prompt-card>

                  </ion-toolbar>

                </swiper-slide>

              </swiper-container>

            </div>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

    <!-- Thumbnails swiper (simple mode) -->
    <swiper-container [hidden]="!!view.finalResultUrl" *ngIf="!!view.startManually && !view.expertMode"
      [options]="thumbsSliderOptions" [pagination]="false" [slidesPerView]="thumbsSliderOptions.slidesPerView"
      class="step-{{ view.step }}" #aiVideoCreatorThumbsSwiper id="aiVideoCreatorThumbsSwiper"
      (slidechangetransitionend)="slidechangetransitionend($event)"
      (slidechangetransitionstart)="slidechangetransitionstart($event)">

      <swiper-slide>
        <ion-button class="add-btn" shape="round" (click)="addSection()" size="block" fill="outline" color="primary">
          <ion-icon name="add-outline"></ion-icon>
          <ion-label [innerHTML]="'add_section'|translate"></ion-label>
        </ion-button>
      </swiper-slide>

      <swiper-slide *ngFor="let section of view.sections; let iSection = index;" [draggable]="view.isDesktop"
        [dragItem]="section" [attr.drag-location]="'thumbSwiperDragula'">
        <ion-card (click)="focusSection(section, iSection)" class="thumb-card" [class.loading]="section.loading"
          [class.active]="!section.loading && section.active"
          [class.success]="!section.loading && !!section.url && (section.type === 'video')">

          <ion-item class="prompt-overlay" lines="none">

            <ion-textarea [disabled]="!!section.loading" rows="6" [(ngModel)]="section.prompt"
              [placeholder]="'ai_section_prompt_placeholder'|translate"></ion-textarea>

            <ion-button slot="end" color="danger" fill="clear" icon-only (click)="delete(section, iSection)">
              <ion-icon name="trash-outline"></ion-icon>
            </ion-button>

            <ion-button slot="end" color="white" class="drag-handle" icon-only fill="clear">
              <ion-icon name="reorder-two-outline" color="white"></ion-icon>
            </ion-button>

          </ion-item>

          <!-- Loading -->
          <div class="loading-overlay" [hidden]="!section.state || (section.state === 'done')">
            <ion-skeleton-text
              [animated]="((!section.type || section.type === 'image') && (!section.state || section.state === 'waiting' || section.state === 'starting'))"
              class="image"></ion-skeleton-text>
            <ion-icon name="warning-outline" [hidden]="section.state !== 'failed'"></ion-icon>
            <ion-icon name="hourglass-outline" [hidden]="section.state !== 'waiting'"></ion-icon>
            <ion-spinner [hidden]="section.state !== 'starting'"></ion-spinner>
          </div>

          <!-- Image -->
          <ion-thumbnail *ngIf="section.type === 'image'">
            <ion-img [src]="section.url || fallbackImg" (ionError)="thumbnailLoadingFailed(section, 'url')"></ion-img>
          </ion-thumbnail>

          <!-- Video -->
          <video autoplay playsinline webkit-playsinline loop muted="muted"
            *ngIf="!!section.url && section.type !== 'image'" [src]="section.url"></video>

        </ion-card>

        <div class="swipe-between">

          <ion-icon *ngIf="!!view.sections[iSection+1]" (click)="toggleLinkSectionWithNext(section)" class="link-icon" [name]="section.linkWithNext ? 'unlink-outline' : 'link-outline'"></ion-icon>

          <ion-icon *ngIf="!!view.sections[iSection+1]" class="thumbs-arrow" name="arrow-forward"></ion-icon>

        </div>

      </swiper-slide>

    </swiper-container>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!(!!media && !media.uid && !view.startManually)">

  <!-- video toolbar (expert mode) -->
  <pipeline-template-timeline *ngIf="!!view.expertMode" #timelineToolbar
    [(template)]="template" [(ticks)]="view.ticks" [(view)]="view" class="template-timeline"
    (onTimelineLayerClick)="onTimelineLayerClick($event)">
  </pipeline-template-timeline>

  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>