<ion-header class="ion-no-border" [translucent]="appConfig.translucentHeaders">

  <!-- Top header -->
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Dismiss -->
      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Update -->
      <ion-button (click)="update()" *ngIf="customer.uid" fill="solid" color="primary" shape="round">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

      <!-- Create -->
      <ion-button (click)="create()" *ngIf="!customer.uid" fill="solid" color="primary" shape="round">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Segmenet bar -->
  <ion-toolbar class="container second-toolbar" *ngIf="view.mode === 'edit'">
    <ion-segment [(ngModel)]="view.segment" scrollable value="general">

      <!-- General -->
      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate"></ion-label>
      </ion-segment-button>

      <!-- Permissions -->
      <ion-segment-button value="permissions">
        <ion-icon name="lock-closed-outline"></ion-icon>
        <ion-label [innerHTML]="'permissions'|translate"></ion-label>
      </ion-segment-button>

      <!-- Connections -->
      <ion-segment-button value="connections">
        <ion-icon name="link-outline"></ion-icon>
        <ion-label [innerHTML]="'connections'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <div class="container">

    <!-- General -->
    <div [hidden]="view.segment !== 'general'">

      <!-- Information -->
      <ion-card>
        <ion-grid>

          <ion-row>

            <!-- Photo + Firstname -->
            <ion-col size="6">
              <ion-item>

                <ion-avatar slot="start" class="cover" (click)="uploadPhoto()">
                  <ion-img [src]="customer.photo || fallbackAvatarImg"
                    (ionError)="thumbnailLoadingFailed(customer)"></ion-img>
                </ion-avatar>

                <ion-input labelPlacement="floating" [label]="'firstname'|translate" [(ngModel)]="customer.firstname"
                  [placeholder]="'firstname'|translate"></ion-input>

              </ion-item>
            </ion-col>

            <!-- Lastname -->
            <ion-col size="6">
              <ion-item>
                <ion-input labelPlacement="floating" [label]="'lastname'|translate" [(ngModel)]="customer.lastname"
                  [placeholder]="'lastname'|translate"></ion-input>
              </ion-item>
            </ion-col>

          </ion-row>

          <ion-row>

            <!-- Street -->
            <ion-col size="6">
              <ion-item>
                <ion-icon name="home-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="floating" [label]="'street'|translate" [(ngModel)]="customer.street"
                  [placeholder]="'street'|translate"></ion-input>
              </ion-item>
            </ion-col>

            <!-- Street No -->
            <ion-col size="6">
              <ion-item>
                <ion-input labelPlacement="floating" [label]="'nr'|translate" [(ngModel)]="customer.streetNo"
                  [placeholder]="'street_no'|translate"></ion-input>
              </ion-item>
            </ion-col>

          </ion-row>

          <ion-row>

            <!-- ZIP -->
            <ion-col size="6">
              <ion-item>
                <ion-icon name="location-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="floating" [label]="'zip'|translate" [(ngModel)]="customer.plz"
                  [placeholder]="'zip_code'|translate"></ion-input>
              </ion-item>
            </ion-col>

            <!-- City -->
            <ion-col size="6">
              <ion-item>
                <ion-input labelPlacement="floating" [label]="'city'|translate" [(ngModel)]="customer.city"
                  [placeholder]="'city'|translate"></ion-input>
              </ion-item>
            </ion-col>

          </ion-row>

          <!-- Phone number -->
          <ion-row>
            <ion-col size="12">
              <ion-item>
                <ion-icon name="call-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="floating" [label]="'phone_number'|translate" [(ngModel)]="customer.phone"
                  [placeholder]="'phone_number'|translate"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <!-- Email address -->
          <ion-row>
            <ion-col size="12">
              <ion-item lines="none">
                <ion-icon name="mail-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="floating" [label]="'email'|translate" [(ngModel)]="customer.email"
                  [placeholder]="'email'|translate"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

        </ion-grid>
      </ion-card>

      <!-- Security -->
      <ion-card>

        <ion-card-header>
          <ion-card-subtitle [innerHTML]="'security'|translate"></ion-card-subtitle>
        </ion-card-header>

        <ion-card-content [hidden]="!customer.uid">

          <ion-button (click)="resetPassword()" size="block" fill="solid" color="primary">
            <ion-icon name="mail-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'reset_password'|translate"></ion-label>
          </ion-button>

          <ion-button (click)="resetPasswordManually()" size="block" fill="clear" color="primary">
            <ion-icon name="create-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'reset_password_manually'|translate"></ion-label>
          </ion-button>

        </ion-card-content>

        <ion-list [hidden]="!!customer.uid && !view.resetPasswordManuallyMode">

          <ion-item [hidden]="!customer || !customer.uid">
            <ion-icon name="lock-open-outline" slot="start"></ion-icon>
            <ion-label position="floating" [innerHTML]="'password_old'|translate"></ion-label>
            <ion-input type="password" [(ngModel)]="view.password_old"
              [placeholder]="'password_old_placeholder'|translate"></ion-input>
          </ion-item>

          <ion-item>
            <ion-icon name="lock-closed-outline" slot="start"></ion-icon>
            <ion-label position="floating" [innerHTML]="'password'|translate"></ion-label>
            <ion-input type="password" [(ngModel)]="view.password"
              [placeholder]="'password_placeholder'|translate"></ion-input>
          </ion-item>

          <ion-item lines="none">
            <ion-icon name="checkmark-done-outline" slot="start"></ion-icon>
            <ion-label position="floating" [innerHTML]="'password_repeat'|translate"></ion-label>
            <ion-input type="password" [(ngModel)]="view.password_repeat"
              [placeholder]="'password_repeat_placeholder'|translate"></ion-input>
          </ion-item>

        </ion-list>
      </ion-card>

    </div>

    <!-- Permissions -->
    <div [hidden]="view.segment !== 'permissions'">

      <ion-grid>
        <ion-row>

          <!-- General -->
          <ion-col>
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'general'|translate"></ion-card-title>
              </ion-card-header>

              <ion-list>

                <!-- Is account active? -->
                <ion-item>

                  <ion-icon [color]="customer.active ? 'success' : 'danger'"
                    [name]="!!customer.active ? 'checkmark-outline' : 'warning-outline'" slot="start"></ion-icon>

                  <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="customer.active">
                    <ion-label [innerHTML]="'account_active'|translate"></ion-label>
                  </ion-checkbox>

                </ion-item>

                <!-- Is account public? -->
                <ion-item>

                  <ion-icon [color]="!customer.public ? 'success' : 'warning'"
                    [name]="!!customer.public ? 'alert-outline' : 'checkmark-outline'" slot="start"></ion-icon>

                  <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="customer.public">
                    <ion-label [innerHTML]="'account_public'|translate"></ion-label>
                  </ion-checkbox>

                </ion-item>

                <!-- Select customer's user group -->
                <ion-item lines="none" *ngIf="customer.classifications">

                  <ion-icon
                    [color]="userService.isType('Admin', customer) ? 'danger' : (userService.isType('Redaktion', customer) || userService.isType('Moderator', customer) || userService.isType('Vertrieb', customer)) ? 'warning' : 'success'"
                    [icon]="userService.isType('Admin', customer) ? 'warning-outline' : (userService.isType('Redaktion', customer) || userService.isType('Moderator', customer) || userService.isType('Vertrieb', customer)) ? 'alert-outline' : 'checkmark-outline'"
                    slot="start"></ion-icon>

                  <ion-select [label]="'account_type'|translate" interface="popover" [okText]="'okay'|translate"
                    [cancelText]="'cancel'|translate" [(ngModel)]="customer.classifications.type">
                    <ion-select-option *ngFor="let userGroup of view.userGroups" [value]="userGroup.uid"
                      [innerHTML]="userGroup.title"></ion-select-option>
                  </ion-select>

                </ion-item>

              </ion-list>

            </ion-card>
          </ion-col>

          <!-- Teams -->
          <ion-col>

            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'teams'|translate"></ion-card-title>
              </ion-card-header>

              <ion-spinner *ngIf="!view.teams && !!view.loading"></ion-spinner>

              <pipeline-no-entries-card *ngIf="!view.loading && !view.teams"></pipeline-no-entries-card>

              <ion-card-content>

                <ion-button color="primary" (click)="addTeam()">
                  <ion-icon name="person-add-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'add_to_team'|translate"></ion-label>
                </ion-button>

                <ion-button color="primary" (click)="createTeam()" fill="clear">
                  <ion-icon name="add-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'add_team'|translate"></ion-label>
                </ion-button>

              </ion-card-content>

              <ion-list *ngIf="!!view.teams">

                <div class="cdk-wrapper">
                  <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
                    [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">
                    <div *cdkVirtualFor="let team of view.teams" [hidden]="team.visible === false">
                      <ion-item-sliding>
                        <ion-item>

                          <ion-avatar slot="start" (click)="viewTeam(team)">
                            <ion-img [src]="team.photo || fallbackImg"
                              (ionError)="thumbnailLoadingFailed(team)"></ion-img>
                          </ion-avatar>

                          <ion-label (click)="viewTeam(team)">
                            <h3 [innerHTML]="team.title || team.name"></h3>
                            <p *ngIf="!!team.members" [innerHTML]="team.members.length + ' ' + ('members'|translate)">
                            </p>
                          </ion-label>

                          <ion-button slot="end" fill="clear" color="danger" [hidden]="!view.isDesktop"
                            (click)="removeUserFromTeam(team)">
                            <ion-icon name="trash-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
                          </ion-button>

                        </ion-item>

                        <ion-item-options slot="end" *ngIf="!view.isDesktop">
                          <ion-item-option color="danger" (click)="removeUserFromTeam(team)">
                            <ion-icon name="trash-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
                          </ion-item-option>
                        </ion-item-options>

                      </ion-item-sliding>

                    </div>
                  </cdk-virtual-scroll-viewport>
                </div>

              </ion-list>

            </ion-card>

          </ion-col>

          <!-- Abonnements -->
          <ion-col>

            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'abonnements'|translate"></ion-card-title>
              </ion-card-header>

              <ion-card-content>

                <ion-button color="primary" (click)="addAbonnement()">
                  <ion-icon name="add-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'add_abonnement'|translate"></ion-label>
                </ion-button>

              </ion-card-content>

              <ion-spinner *ngIf="!view.abonnements && !!view.loading"></ion-spinner>

              <pipeline-no-entries-card *ngIf="!view.loading && !view.abonnements"></pipeline-no-entries-card>

              <ion-list *ngIf="!!view.abonnements">

                <div class="cdk-wrapper">
                  <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
                    [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">
                    <div *cdkVirtualFor="let abonnement of view.abonnements" [hidden]="abonnement.visible === false">
                      <ion-item-sliding>
                        <ion-item button (click)="viewAbo(abonnement)">

                          <ion-avatar slot="start">
                            <ion-img [src]="abonnement.photo || fallbackImg"
                              (ionError)="thumbnailLoadingFailed(abonnement)"></ion-img>
                          </ion-avatar>

                          <ion-label>
                            <h3 [innerHTML]="(abonnement.title || abonnement.name)|translate"></h3>
                            <p *ngIf="!!abonnement.price" [innerHTML]="abonnement.price"></p>
                          </ion-label>

                          <ion-button slot="end" fill="clear" color="danger" [hidden]="!view.isDesktop"
                            (click)="removeUserFromAbo(abonnement)">
                            <ion-icon name="trash-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'delete'|translate"></ion-label>
                          </ion-button>

                        </ion-item>

                        <ion-item-options slot="end" *ngIf="!view.isDesktop">
                          <ion-item-option color="danger" (click)="removeUserFromAbo(abonnement)">
                            <ion-icon name="trash-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'delete'|translate"></ion-label>
                          </ion-item-option>
                        </ion-item-options>
                      </ion-item-sliding>

                    </div>
                  </cdk-virtual-scroll-viewport>
                </div>

              </ion-list>

            </ion-card>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

    <!-- Connections -->
    <div [hidden]="view.segment !== 'connections'">

      <ion-card>
        <ion-list lines="none">

          <ion-item button (click)="connectBlog()">
            <ion-icon [color]="view.blogConnected ? 'success' : 'danger'"
              [name]="view.blogConnected ? 'link' : 'link-outline'" slot="start"></ion-icon>
            <ion-label>
              <h2 [innerHTML]="'blog_connection'|translate"></h2>
              <p [innerHTML]="(view.blogConnected ? 'connected' : 'not_connected')|translate"></p>
            </ion-label>
          </ion-item>

        </ion-list>

      </ion-card>

      <ion-card>
        <ion-list lines="none">

          <ion-item button (click)="connectShop()">
            <ion-icon [color]="view.shopConnected ? 'success' : 'danger'"
              [name]="view.shopConnected ? 'link' : 'link-outline'" slot="start"></ion-icon>
            <ion-label>
              <h2 [innerHTML]="'shop_connection'|translate"></h2>
              <p [innerHTML]="(view.shopConnected ? 'connected' : 'not_connected')|translate"></p>
            </ion-label>
          </ion-item>

        </ion-list>
      </ion-card>

    </div>

  </div>

</ion-content>