import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-media-attributes-card',
  standalone: false,
  templateUrl: './media-attributes-card.component.html',
  styleUrls: ['./media-attributes-card.component.scss']
})
export class MediaAttributesCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() media: mediaItem;
  @Input() view: any;

  @ViewChild('infoPopover') infoPopover: any;

  isInfoPopoverOpen: boolean = false;

  constructor(
    private events: EventsService,
    private mediaService: MediaextendService,
    private sidebar: SidebarService,
  ) {

  }

  add() {
    this.media.attributes = this.media.attributes || [];

    this.media.attributes.push({
      active: true,
      key: '',
      value: '',
    });
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    try {

      // format attributes (@todo: make multiple configurable)
      if(!!this.media && !!this.media.attributes && !!this.media.attributes.length) {
        this.media.attributes = (this.media.attributes || []).map((attribute: mediaAttribute) => {

          // string not supported in ngx-chips, so make it a list with one item
          if(typeof attribute.value === 'string') {
            attribute.value = [{
              title: attribute.value,
              uid: attribute.value,
            }];
          }
          
          return attribute;
        });
      }

    } catch (e) {
      console.warn('loading attributes failed', e);
    }
  }

  onAttributeAdd(event: any, attribute: any = {}) {

    if (!this.media.uid) {
      return false;
    }

    const attributeData: any = {
      key: `${attribute.key}`,
      value: `${event.title}`,
      uid: `${attribute.uid || event.uid}`,
    };

    console.log('add attributeData', attributeData);

    this.mediaService.addAttributeToMedia(attributeData, this.media.uid)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  onAttributeClicked(attribute: mediaAttribute) {
    console.log('on attribute clicked', attribute);
  }

  onAttributeRemove(event: any, attribute: any = {}) {

    if (!this.media.uid) {
      return false;
    }

    const attributeData: any = {
      key: `${attribute.key}`,
      value: `${event.title}`,
      uid: `${attribute.uid || event.uid}`,
    };

    console.log('remove attributeData', attributeData);

    this.mediaService.removeAttributeFromMedia(attributeData, this.media.uid)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.item_attributes = this.cards.item_attributes || {};
    this.cards.item_attributes.open = !this.cards.item_attributes.open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}