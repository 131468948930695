import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';

@Component({
  selector: 'pipeline-selection-options-picker',
  standalone: false,
  templateUrl: './selection-options-picker.component.html',
  styleUrls: ['./selection-options-picker.component.scss']
})
export class SelectionOptionsPickerComponent implements AfterViewInit, OnInit {

  @Input() event: any;

  isSelectionOptionsPickerOpen: boolean = false;

  @Input() item: any;

  @Output() onSelectionActionChanged = new EventEmitter();

  @Input() options: selectionOption[];

  @ViewChild('selectionOptionsPicker') selectionOptionsPicker;

  @Input() view: any;

  constructor(
    private events: EventsService,
  ) {

  }

  calcViewVars() {
    this.view.hasSelectedItems = !!(!!this.view && !!this.view.selectedItems && !!this.view.selectedItems.length);
    this.view.isLargeScreen = (window.innerWidth > 1080);
  }

  public close() {
    this.isSelectionOptionsPickerOpen = false;
  }

  dateChanged(event: any | null = null) {
  }

  doRefresh() {
    this.calcViewVars();
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.view.isLargeScreen = (window.innerWidth > 1080);
    });
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.initEvents();
    this.calcViewVars();
  }

  onSelectionOptionsActionSheetDismiss(event: any) {
    if (!!event && !!event.detail) {
      console.log('selection is', event.detail);
    }
  }

  presentSelectionActionPicker(event: any | null = null) {
    this.selectionOptionsPicker.event = event;
    this.isSelectionOptionsPickerOpen = true;
  }

  async runSelectionOption(option: any, event: any) {

    if (!option.uid) {
      return false;
    }

    const response: any = {
      event: this.event,
      item: this.item,
      option: option,
    };

    if (option.hasOwnProperty('handler')) {
      option.handler(response);
    } else {

      this.onSelectionActionChanged.emit(response);

      this.isSelectionOptionsPickerOpen = false;

      try {
        if (!!event) {
          event.target.complete();
        }
      } catch (e) {

      }
    }
  }

  public show(options: any) {
    this.event = options.event;
    this.item = options.item;

    this.selectionOptionsPicker.event = this.event;
    this.isSelectionOptionsPickerOpen = true;
  }

}