import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { IonContent, LoadingController, NavController, NavParams } from '@ionic/angular';

import { AdsService } from "src/app/services/ads/ads.service";
import { CitiesService } from 'src/app/services/geo/cities.service';
import { EventsService } from "src/app/services/core/events.service";
import { SearchService } from 'src/app/services/core/search.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { UserService } from 'src/app/services/core/user.service';
import { TargetgroupsService } from 'src/app/services/target-groups/target-groups.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ModalService } from "src/app/services/core/modal.service";
import { SharingService } from 'src/app/services/sharing/sharing.service';

import { TargetGroupsPage } from 'src/app/pages/extensions/target_groups/target-groups/target-groups.page';


import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-create-ad',
  standalone: false,
  templateUrl: './create-ad.page.html',
  styleUrls: ['./create-ad.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateAdPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;

  ad: ad = {
    budgetPerDay: 15,
    location: 'ad.location.posts.between',
    stats: {},
    target_groups: {},
  };

  search: any = {
    query: '',
  };

  @ViewChild('adsSlider') slider: ElementRef | undefined;

  sliderOptions: any = {
    autoHeight: false,
    loop: false,
    zoom: false,
    virtual: false,
    allowTouchMove: false,
  };

  user: user;

  view: any = {
    adLocationDeviceGroup: 'app',
    adTypes: ([] as adType[]),
    attachments: [],
    canNext: false,
    canSubmit: false,
    colSizes: {
      addons: (window.innerWidth > 768 ? 6 : 12),
      adTypes: (window.innerWidth > 768 ? 4 : 6),
      left: (window.innerWidth > 768 ? 8 : 12),
      locations: (window.innerWidth > 768 ? 4 : 12),
      right: (window.innerWidth > 768 ? 4 : 12),
    },
    createOrChooseSet: 'choose_existing',
    date_end_min: '',
    date_end_max: '',
    date_start_min: '',
    date_start_max: '',
    durationEndMode: 'subscription',
    durationStartMode: 'instant',
    editMode: false,
    introCards: [
      {
        uid: 'create_ad_step_1_top_card',
        subtitle: 'create_ad_step_1_top_card_subtitle',
        text: 'create_ad_step_1_top_card_text',
        title: 'create_ad_step_1_top_card_title',
      },
      {
        uid: 'create_ad_step_2_top_card',
        subtitle: 'create_ad_step_2_top_card_subtitle',
        text: 'create_ad_step_2_top_card_text',
        title: 'create_ad_step_2_top_card_title',
      },
      {
        uid: 'create_ad_step_3_top_card',
        subtitle: 'create_ad_step_3_top_card_subtitle',
        text: 'create_ad_step_3_top_card_text',
        title: 'create_ad_step_3_top_card_title',
      },
      {
        uid: 'create_ad_step_4_top_card',
        subtitle: 'create_ad_step_4_top_card_subtitle',
        text: 'create_ad_step_4_top_card_text',
        title: 'create_ad_step_4_top_card_title',
      },
      {
        uid: 'create_ad_step_5_top_card',
        subtitle: 'create_ad_step_5_top_card_subtitle',
        text: 'create_ad_step_5_top_card_text',
        title: 'create_ad_step_5_top_card_title',
      }
    ],
    lastStep: 4,
    region_query: '',
    shouldShowAddOnsScreen: true,
    showAddOnsScreen: false,
    showBackButton: false,
    step: 1,
    targetGroupMode: 'autopilot',
  };

  constructor(
    private ads: AdsService,
    private changeDetectorRef: ChangeDetectorRef,
    private cities: CitiesService,
    private events: EventsService,
    private loadingCtrl: LoadingController,
    private media: MediaextendService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private navParams: NavParams,
    private searchService: SearchService,
    private sharing: SharingService,
    private targetGroupsService: TargetgroupsService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.user = this.userService.getUser() || {};
  }

  applySearchResults(results: any[], query: string|null = null, applyKey = 'results', queryKey = 'query') {
    query = query || this.search[queryKey];

    this.zone.run(() => {

      if (
        query === this.search[queryKey] &&
        (
          (!this.search[applyKey] || !this.search[applyKey].length) ||
          (this.search[applyKey].length !== results.length)
        )
      ) {
        this.search[applyKey] = results;
      }

      if (this.slider) {
        this.slider.nativeElement.swiper.update();
      }

      this.detectChanges();
    });
  }

  attachmentPreviewFailed(attachment: any, i: number) {
    attachment.error = true;
  }

  autofill() {
    this.ad.budgetPerDay = this.ad.budgetPerDay || 15;
    this.ad.location = this.ad.location || 'ad.location.posts.between';

    if (this.ad.type === 'website' && !!this.ad.url) {
      this.search.query = this.ad.url;
      this.runSearchOnWebsites();
    }

    this.calcViewLocations();
  }

  async back() {
    let activeIndex = await this.slider.nativeElement.swiper.activeIndex;

    switch (activeIndex) {
      case 1:
      case 2:
      default:

        try {
          this.slider.nativeElement.swiper.slidePrev();
        } catch (e) {
          console.warn('> slide prev failed', e);
        }

        this.view.canSubmit = false;

        if (activeIndex === 1) {
          this.view.showBackButton = false;
        }

        this.updateSlider();
        this.detectChanges();
        break;
    }
  }

  calcAttachments() {
    if (!!this.ad.location) {
      switch (this.ad.location) {
        case 'ad.location.web.skyscraper':
          if (this.view.attachments.length !== 2) {
            this.view.attachments = [
              { error: false, url: '' },
              { error: false, url: '' }
            ];
          }
          break;
        default:
          if (this.view.attachments.length !== 1) {
            this.view.attachments = [{ error: false, url: '' }];
          }
          break;
      }
    } else {
      switch (this.ad.type) {
        default:
          if (this.view.attachments.length !== 1) {
            this.view.attachments = [{ error: false, url: '' }];
          }
          break;
      }
    }

  }

  calcColSizes(blSendUpdateEvents: boolean = false) {
    this.view.colSizes = {
      addons: (window.innerWidth > 768 ? 4 : 6),
      adTypes: (window.innerWidth > 768 ? 4 : 6),
      left: (window.innerWidth > 768 ? 8 : 12),
      locations: (window.innerWidth > 768 ? 6 : 12),
      right: (window.innerWidth > 768 ? 4 : 12),
    };
    this.detectChanges(blSendUpdateEvents);
  }

  calcPreviewDevice() {
    if ((this.view.adLocationDeviceGroup === 'tablet' || this.view.adLocationDeviceGroup === 'web_desktop') && this.tools.isDesktop()) {
      this.view.colSizes.left = 6;
      this.view.colSizes.right = 6;

      this.detectChanges(true);
    } else {
      this.calcColSizes(true);
    }
  }

  calcStats(blBackground: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.ad.stats = this.ad.stats || {};

      let loading: any;

      if (!blBackground) {
        loading = await this.loadingCtrl.create({
          backdropDismiss: false,
          spinner: 'circular',
        });
      }

      if (!this.ad.type) {
        console.warn('missing ad type');
        this.detectChanges();
        resolve({});
      } else {

        if (!blBackground) {
          loading.present();
        }

        this.ads.calcStats(this.ad, this.view.adAddons)
          .then((stats: adStats) => {

            if (!blBackground) {
              loading.dismiss();
            }

            this.zone.run(() => {
              this.ad.stats = stats;
              this.detectChanges();
            });

            resolve(stats);
          })
          .catch((error: any) => {

            if (!blBackground) {
              loading.dismiss();
            }

            this.detectChanges();
            reject(error);
          });
      }
    });
  }

  calcViewDates() {
    let now = new Date(),
      year = now.getFullYear(),
      tomorrow = new Date(now);

    tomorrow.setDate(tomorrow.getDate() + 1);

    this.view.date_end_min = tomorrow.toISOString().split("T")[0],
      this.view.date_end_max = new Date((year + 2) + '-12-31').toISOString().split("T")[0];
    this.view.date_start_min = now.toISOString().split("T")[0];
    this.view.date_start_max = new Date((year + 1) + '-12-31').toISOString().split("T")[0];

    this.view.editMode = !!(this.ad && this.ad.uid);
  }

  calcViewLocations(adType: string|null = null) {
    adType = (!!adType ? adType : this.ad.type);

    if (adType === 'product') {
      adType = 'products';
    }

    if (!!this.view.allLocations && this.view.allLocations[adType]) {
      this.view.locations = this.view.allLocations[adType];
      this.onAdLocationDeviceGroupChanged();
    }

  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async configureBudget() {

  }

  async configureTargetGroup() {
    
    const targetGroupsModal: any = await this.modalService.create({
      component: TargetGroupsPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    targetGroupsModal.onWillDismiss().then(() => {
      this.loadTargetGroups();
    });

    this.modalService.present(targetGroupsModal);
  }

  chooseExistingContent() {
    this.view.createOrChooseSet = 'choose_existing';
  }

  create() {

    if (!this.ad.photo && !!this.view.attachments && !!this.view.attachments[0] && !!this.view.attachments[0].url) {
      this.ad.photo = this.view.attachments[0].url;
    }

    if (!!this.view.attachments && !!this.view.attachments.length && !this.ad.value) {
      this.ad.value = this.view.attachments.map((attachment: any) => {
        return attachment.url;
      });
    }

    this.ads.create(this.ad)
      .then(() => {
        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  createNewContent() {
    this.dismiss(null, 'done');
    this.navCtrl.navigateForward('/hub');
  }

  detectChanges(blSendUpdateEvents: boolean = false) {
    this.changeDetectorRef.detectChanges();

    if (!!blSendUpdateEvents) {
      setTimeout(() => {
        this.events.publish('ad:preview:updated', this.ad);
        this.events.publish('ad:updated', this.ad);
        this.events.publish('posts:grid:update', { shuffle: false });
      }, 100);
    }
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};

    this.calcViewDates();

    this.ad.user = this.user.uid;
    this.onDisplayProfileResultClick(this.user, false);

    this.validate();
    this.loadDataBackToView();
  }

  loadAdTypeToView() {
    if (this.ad && this.ad.type && this.view.adTypes) {
      this.view.adTypes.forEach((adType: adType) => {
        adType.checked = !!(adType.type === this.ad.type);
      });
    }
  }

  loadCities() {
    this.cities.get()
      .then((cities: city[]) => {
        this.view.cities = cities;
      })
      .catch((error: any) => {
        console.warn('> loading cities failed', error);
      });
  }

  loadDataBackToView() {
    if (this.view.editMode) {
      this.loadAdTypeToView();
    }
  }

  async loadTargetGroups() {
    this.view.target_groups = await this.targetGroupsService.getAll();
    this.detectChanges();
  }

  async loadWebsiteUrlMetaData() {
    if (!!this.view.website && this.view.website.url) {
      try {
        this.view.website.metaData = await this.sharing.getURLMetaData(this.view.website.url);

        const photo: string = (!!this.view.website.metaData && !!this.view.website.metaData['og:image'] ? this.view.website.metaData['og:image'] : null);

        if (!!photo) {
          this.ad.photo = photo;
        }

        if (!!this.view.website.metaData) {
          const url = (!!this.view.website.metaData['og:url'] ? this.view.website.metaData['og:url'] : this.view.website.url);

          this.ad.url = url;
          this.view.canNext = true;
        }

        this.detectChanges(true);
      } catch (e) {
        console.warn('-> fetch url meta data failed', e);
      }
    }
  }

  lookupRegion() {
    let key = 'cities', backupKey = `${key}_backup`;
    let searchKeys = ['name', 'city', 'state', 'title'];

    if (!this.view.region_query || (this.view.region_query.length < 3)) {
      return false;
    }

    if (!this.view.hasOwnProperty(backupKey) && this.view[key] && this.view[key].length) {
      this.view[backupKey] = JSON.parse(JSON.stringify(this.view[key]));
    } else
    if (this.view[backupKey] && this.view[backupKey].length) {
      this.view[key] = JSON.parse(JSON.stringify(this.view[backupKey]));
    }

    let query: string = this.search.query.toLowerCase();

    if (this.view[key] && this.view[key].length) {
      this.view[key] = this.view[key].filter((item: any) => {
        let blShow: boolean = (!query || !query.length);
        
        if(!!query) {
          searchKeys.forEach((searchKey: string) => {
            if (item.hasOwnProperty(searchKey)) {
              blShow = blShow || `${item[searchKey] ? item[searchKey] : ''}`.toLowerCase().indexOf(this.view.region_query.toLowerCase()) !== -1;
            }
          });
        }

        return blShow;
      });
    }
  }

  async next() {
    this.view.step = await this.slider.nativeElement.swiper.activeIndex;

    this.calcAttachments();
    this.detectChanges();

    if (this.view.step === 0) {
      this.view.showBackButton = true;
    } else
      if (this.view.step >= 2) {
        this.calcStats();
      }

    if (this.view.step === this.view.lastStep) {
      this.create();
    } else
      if (this.view.shouldShowAddOnsScreen && (this.view.step !== 3)) {
        this.showAddOnsScreen();
        this.updateSlider();
      } else {
        this.slideNext();
      }
  }

  ngOnInit() {
    //this.ad.date_end = this.ad.date_end || moment().add(1, 'months').toISOString();
    //this.ad.date_start = this.ad.date_start || moment().toISOString();
    this.ad.language = this.ad.language || this.userService.getLanguage();

    this.view.adAddons = this.ads.getAdAddons();
    this.view.adTypes = this.ads.getAdTypes();
    this.view.allLocations = this.ads.getLocations();

    let ad = this.navParams.get('ad');

    if (ad) {
      this.ad = ad;
      this.ad.target_groups = this.ad.target_groups || {};
    }

    this.user = this.userService.getUser() || {};
    this.view.editMode = !!ad;

    this.calcViewDates();
    this.calcColSizes();
    this.calcPreviewDevice();
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcColSizes();
      this.calcViewVars();
    });

    this.events.subscribe('create:ad:detectChanges', () => {
      this.detectChanges();
    });

    setTimeout(() => {
      this.autofill();

      this.loadCities();
      this.loadTargetGroups();
    });
  }

  onAdLocationDeviceGroupChanged() {

    if (this.view.locations && this.view.locations.length && !!this.view.adLocationDeviceGroup) {
      this.view.locations.forEach((adLocation: adLocation) => {
        adLocation.hidden = (adLocation.device_groups.indexOf(this.view.adLocationDeviceGroup) === -1);
      });
    }

    this.calcPreviewDevice();
  }

  onAdPhotoChanged() {
    this.view.website.metaData = this.view.website.metaData || {};
    this.view.website.metaData.thumbnail = this.ad.photo;
    this.detectChanges(true);
  }

  onAttachmentUrlPasted(attachment: any, index: number) {
    this.view.attachments[index].error = false;
    this.view.attachments[index].url = `${attachment.url}`;

    if (!!attachment.url) {
      this.ad.photo = attachment.url;
    }
    
  }

  onChanged(blBackground: boolean = false) {
    setTimeout(() => {
      this.zone.run(() => {
        this.calcStats(blBackground)
          .then(() => {
            this.validate();
          })
          .catch((error: any) => {
            this.events.publish('error', error);
          });
      });
    });
  }

  onCodePasted() {
    const code: string = `${this.ad.value}`;
    const validate: any = this.ads.validateEmbedCode(code);

    this.view.isValidEmbedCode = (validate && validate.success ? validate.success : false);
    this.view.canNext = !!this.view.isValidEmbedCode;

    this.detectChanges(true);
  }

  onDisplayProfileResultClick(result: any, blScrollDown: boolean = true) {
    result.selected = true;

    if (this.search.displayProfileResults && this.search.displayProfileResults.length) {
      this.search.displayProfileResults = this.search.displayProfileResults.filter((_result: any) => {
        return result.uid === _result.uid;
      });
    }

    this.search.display_user = result.uid;
    this.ad.display_user = result.uid;

    if (blScrollDown) {
      this.scrollToBottom();
    }

  }

  onResultClick(result: any) {

    this.search.results = this.search.results.filter((_result: any) => {
      return result.uid === _result.uid;
    });

    result.selected = !result.selected;
    this.ad.item = (result.uid || result.id);

    if (result.name && result.name.length) {
      this.ad.label = this.tools.decodeEntities(result.name);
    }

    if (result.category) {
      this.ad.category = result.category;
    }

    this.scrollToBottom();

    this.calcStats(true)
      .then(() => {
        this.validate();

        // refresh view after loadItem in ad component finished
        // @todo listen to event here
        setTimeout(() => {
          this.detectChanges(true);
        }, 500);

      })
      .catch((error: any) => {
        this.validate();
        this.events.publish('error', error);
      });

  }

  onSearchChanged(searchOptions: any|null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }
  
  runDisplayProfileSearch() {
    let name = this.search.displayProfileQuery;
    this.searchService.run({
      itemsKey: 'items',
      keys: this.search.keys,
      query: name,
      filter: {
        active: true,
      },
      search_people: true,
      search_posts: false,
      search_products: false,
    })
      .then((results: searchResult) => {
        results.people.forEach((person: any) => {
          person.thumbnail = person.photo;
          person.name = `${person.firstname} ${person.lastname}`;
        });
        this.applySearchResults(
          results.people,
          name,
          'displayProfileResults',
          'displayProfileQuery'
        );
      })
      .catch((error: any) => {
        error = (error || '').toLowerCase();
        
        if (
          error !== 'error_query_too_short' &&
          error !== 'error_query_empty'
        ) {
          this.events.publish('error', error);
        } else {
          this.search.displayProfileResults = null;
          this.detectChanges();
        }
      });

  }

  runSearch(event: any|null = null) {
    switch (this.ad.type) {
      case 'story':
        this.runSearchOnPosts();
        break;
      case 'text':
        this.runSearchOnPosts();
        break;
      case 'podcast':
        this.runSearchOnPosts();
        break;
      case 'product':
        this.runSearchOnProducts();
        break;
      case 'profile':
        this.runSearchOnProfiles();
        break;
      case 'shop':
        this.runSearchOnShops();
        break;
      case 'video':
        this.runSearchOnPosts();
        break;
      case 'website':
        this.runSearchOnWebsites();
        break;
      default:
        break;
    }
  }

  runSearchOnPosts() {

    if (!!this.view.searchRequestInProgress) {
      console.warn('in progress');
      return false;
    }

    this.view.searchRequestInProgress = true;
    this.detectChanges();

    let name = this.search.query;
    this.searchService.run({
      itemsKey: 'items',
      query: name,
      filter: {
        active: true,
      },
      search_people: false,
      search_posts: true,
      search_products: false,
    })
      .then((results: searchResult) => {
        this.view.searchRequestInProgress = false;
        this.applySearchResults(results.posts, name);
      })
      .catch((error: any) => {
        this.view.searchRequestInProgress = false;

        console.warn('> error', error);
        error = (error || '').toLowerCase();

        if (
          error !== 'error_query_too_short' &&
          error !== 'error_query_empty'
        ) {
          this.events.publish('error', error);
        } else {
          this.search.results = null;
        }

        this.detectChanges();
      });
  }

  runSearchOnProducts() {
    let name = this.search.query;
    this.searchService.run({
      itemsKey: 'items',
      query: name,
      filter: {
        active: true,
      },
      search_people: false,
      search_posts: false,
      search_products: true,
    })
      .then((results: searchResult) => {
        if (
          (results.products && results.products.length) ||
          (results.woocommerceextend && results.woocommerceextend.products && results.woocommerceextend.products.length)
        ) {
          this.applySearchResults(results.products || (results.woocommerceextend ? results.woocommerceextend.products : []), name);
        }
      })
      .catch((error: any) => {
        error = (error || '').toLowerCase();
        if (
          error !== 'error_query_too_short' &&
          error !== 'error_query_empty'
        ) {
          this.events.publish('error', error);
        } else {
          this.search.results = null;
        }
      });
  }

  runSearchOnProfiles() {
    let name = this.search.query;
    this.searchService.run({
      itemsKey: 'items',
      query: name,
      filter: {
        active: true,
      },
      search_people: true,
      search_posts: false,
      search_products: false,
    })
      .then((results: searchResult) => {
        results.people.forEach((person: any) => {
          person.thumbnail = person.photo;
          person.name = `${person.firstname} ${person.lastname}`;
        });
        this.applySearchResults(results.people, name);
      })
      .catch((error: any) => {
        error = (error || '').toLowerCase();
        if (
          error !== 'error_query_too_short' &&
          error !== 'error_query_empty'
        ) {
          this.events.publish('error', error);
        } else {
          this.search.results = null;
        }
      });
  }

  runSearchOnShops() {
  }

  runSearchOnWebsites() {
    const validate: any = this.tools.validateUrl(this.search.query);
    this.view.isValidWebsiteUrl = validate.success;

    if (!!this.view.isValidWebsiteUrl) {
      this.view.website = {
        sponsored: true,
        url: validate.url,
      };
      this.loadWebsiteUrlMetaData();
    }
  }

  scrollToBottom() {
    this.detectChanges();
    this.slider.nativeElement.swiper.updateAutoHeight();

    setTimeout(() => {
      this.content.scrollToBottom();
    });
  }

  scrollToTop() {
    this.detectChanges();
    this.slider.nativeElement.swiper.updateAutoHeight();

    setTimeout(() => {
      this.content.scrollToTop(0);
    });
  }

  

  showAddOnsScreen() {
    this.zone.run(() => {
      this.view.shouldShowAddOnsScreen = false;
      this.view.showAddOnsScreen = true;

      this.slideNext();
    });
  }

  async slideNext() {
    if (this.slider) {
      this.slider.nativeElement.swiper.slideNext();
      this.view.canNext = false;
      this.view.step = await this.slider.nativeElement.swiper.activeIndex;

      this.validate();
      this.scrollToTop();
      this.slider.nativeElement.swiper.updateAutoHeight();
      this.detectChanges();
    }
  }

  update() {
    this.ads.update(this.ad)
      .then(() => {
        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  uploadAdPhoto() {

  }

  uploadAttachment(attachment: any, index: number) {
    

    this.media.applyFromWeb(null, {
    }).then((response: any) => {
      let imageUrl: string|null = (typeof response === 'string' ? response : null);
      
      if(!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
        imageUrl = response.items[0].thumbnail;
      }
      
      attachment.url = imageUrl;
      this.detectChanges();
    })
    .catch((error: any) => {
      if (!!error) {
        this.events.publish('error', error);
      }
    });
  }

  updateSlider() {
    try {
      if (this.slider) {
        this.slider.nativeElement.swiper.updateAutoHeight();
        this.slider.nativeElement.swiper.update();
        this.detectChanges();
      }
    } catch (e) {
      console.warn('e', e);
    }
  }

  unsetRegionUid() {
    this.view.region_query = '';

    delete this.ad.region;
    delete this.ad.region_uid;
  }

  useAddon(addon: adAddon) {
    this.zone.run(() => {
      addon.checked = !addon.checked;
    });
    this.validate();
  }

  useAdLocation(adLocation: adLocation) {
    this.zone.run(() => {

      this.view.locations.forEach((location: adLocation) => {
        location.checked = !!(location.indent === adLocation.indent);
        if (location.checked) {
          this.ad.location = location.indent;
        }
      });

      if (!!this.ad.location) {
        switch (this.ad.location) {
          case 'ad.location.posts.related':
            this.ad.size = 6;
            break;
          case 'ad.location.posts.small':
            this.ad.size = 6;
            break;
          default:
            this.ad.size = 12;
            break;
        }
      }

      this.detectChanges();

      this.calcStats(true)
        .then(() => {
          this.validate();
          this.detectChanges(true);
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    });
  }

  useAdType(adType: adType) {
    this.zone.run(() => {

      this.view.adTypes.forEach((_adType: adType) => {
        _adType.checked = !!(adType.type === _adType.type);
        if (_adType.checked) {
          this.ad.type = _adType.type;
        }
      });

      let locationKey = this.ad.type;

      if (locationKey === 'product') {
        locationKey = 'products';
      }

      this.calcViewLocations(locationKey);

      this.search.results = null;

      this.view.introCards[0].subtitle = `create_ad_type_${locationKey}_top_card_subtitle`,
        this.view.introCards[0].text = `create_ad_type_${locationKey}_top_card_text`,
        this.view.introCards[0].title = `create_ad_type_${locationKey}_top_card_title`,

        this.calcStats(false)
          .then(() => {
            this.validate();
          })
          .catch((error: any) => {
            this.events.publish('error', error);
          });

    });
  }

  useRegionResult(result: city) {
    this.view.region_query = '';

    this.ad.region = result;
    this.ad.region_uid = result.uid;
  }

  validate() {
    setTimeout(async () => {
      let index = await this.slider.nativeElement.swiper.activeIndex;
      this.view.step = index;
      this.view.canSubmit = false;

      this.zone.run(() => {
        switch (this.view.step) {
          case 0:
            this.view.canNext = !!(this.ad.type);
            break;
          case 1:
            this.view.canNext = !!(this.ad.location);
            break;
          case 2:
            this.view.canNext = !!((!!this.ad.item || !!this.ad.value || !!this.ad.url) && !!this.ad.label);
            break;
          case 3:
            this.view.canNext = !this.view.editMode;
            this.view.canSubmit = !!this.view.editMode;
            break;
          case 4:
            this.view.canNext = false;
            this.view.canSubmit = true;
            break;
          default:
            break;
        }
      });

      this.detectChanges();
    });
  }

}