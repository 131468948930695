<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Save -->
      <ion-button (click)="save()" *ngIf="!assistant.uid" [disabled]="!!view.loading || !assistant.name" shape="round"
        color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

      <!-- Update -->
      <ion-button (click)="update()" *ngIf="!!assistant.uid" [disabled]="!!view.loading || !assistant.name"
        shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Segment bar -->
  <ion-toolbar class="ion-no-border container second-toolbar">
    <ion-segment [(ngModel)]="view.segment" (ionChange)="onSegmentChange($event)">

      <!-- General -->
      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate"></ion-label>
      </ion-segment-button>

      <!-- AI Settings -->
      <ion-segment-button value="ai" [disabled]="!assistant.name">
        <ion-icon name="sparkles-outline"></ion-icon>
        <ion-label [innerHTML]="'ai'|translate"></ion-label>
      </ion-segment-button>

      <!-- Data -->
      <ion-segment-button value="data" [disabled]="!assistant.name">
        <ion-icon name="server-outline"></ion-icon>
        <ion-label [innerHTML]="'data'|translate"></ion-label>
      </ion-segment-button>

      <!-- Look and feel -->
      <ion-segment-button value="look_and_feel" [disabled]="!assistant.name" *ngIf="!!template">
        <ion-icon name="color-palette-outline"></ion-icon>
        <ion-label [innerHTML]="'look_and_feel'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- Intro card -->
    <pipeline-intro-card [hidden]="view.introCard.hidden" class="introCard"
      [(view)]="view.introCard"></pipeline-intro-card>

    <!-- General -->
    <div [hidden]="view.segment !== 'general'">

      <!-- General -->
      <ion-card>

        <ion-card-header>
          <ion-card-subtitle [innerHTML]="'general'|translate"></ion-card-subtitle>
        </ion-card-header>

        <ion-list>

          <ion-item lines="none">
            <ion-input [(ngModel)]="assistant.name" [label]="'assistant_name'|translate" labelPlacement="stacked"
              [placeholder]="'assistant_name_placeholder'|translate"></ion-input>
          </ion-item>

        </ion-list>
      </ion-card>

      <!-- Chat -->
      <ion-card *ngIf="!!assistant.config.chat">

        <ion-card-header>
          <ion-card-subtitle [innerHTML]="'chat'|translate"></ion-card-subtitle>
        </ion-card-header>

        <ion-list>

          <ion-item>
            <ion-textarea [(ngModel)]="assistant.config.chat.intro_message" [label]="'intro_message'|translate"
              labelPlacement="stacked" [placeholder]="'intro_message'|translate"></ion-textarea>
          </ion-item>

        </ion-list>

      </ion-card>

      <!-- Visibility -->
      <ion-card>

        <ion-card-header>
          <ion-card-subtitle [innerHTML]="'visibility'|translate"></ion-card-subtitle>
        </ion-card-header>

        <ion-list>

          <!-- Active -->
          <ion-item>
            <ion-checkbox [(ngModel)]="assistant.active" [innerHTML]="'active'|translate" justify="start"
              labelPlacement="end" class="ion-text-wrap"></ion-checkbox>
          </ion-item>

          <!-- Public -->
          <ion-item lines="none">
            <ion-checkbox [(ngModel)]="assistant.public" [innerHTML]="'public'|translate" justify="start"
              labelPlacement="end" class="ion-text-wrap"></ion-checkbox>
          </ion-item>

        </ion-list>
      </ion-card>

    </div>

    <!-- AI: Settings -->
    <div [hidden]="view.segment !== 'ai'">

      <!-- AI Settings -->
      <pipeline-ai-settings-card *ngIf="!!assistant.config.ai" [(cards)]="cards" [(config)]="assistant.config.ai"
        [options]="aiSettingsOptions" [(view)]="view"></pipeline-ai-settings-card>

      <!-- AI Style -->
      <pipeline-ai-style-picker [(cards)]="cards" [(view)]="view"></pipeline-ai-style-picker>

    </div>

    <!-- Data -->
    <div [hidden]="view.segment !== 'data'">

      <ion-grid>
        <ion-row>

          <!-- Knowledgebase -->
          <ion-col>

            <ion-card>

              <ion-card-header>
                <ion-card-subtitle [innerHTML]="'ai_knowledgebase'|translate"></ion-card-subtitle>
              </ion-card-header>

              <ion-card-content>

                <!-- Add knowledgebase entry -->
                <ion-button color="primary" (click)="addKnowledgebaseEntry()">
                  <ion-icon name="add-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'add_entry'|translate"></ion-label>
                </ion-button>

              </ion-card-content>

              <!-- No entries -->
              <pipeline-no-entries-card
                *ngIf="assistant.config && assistant.config.knowledgebase && !assistant.config.knowledgebase.length && !view.loading"></pipeline-no-entries-card>

              <!-- Loading spinner -->
              <ion-spinner name="circular" *ngIf="!!view.loading"></ion-spinner>

              <!-- Items list -->
              <div
                *ngIf="!!assistant.config && !!assistant.config.knowledgebase && !!assistant.config.knowledgebase.length">

                <!-- Single knowledgebase entry -->
                <ion-card class="entry-card" *ngFor="let entry of assistant.config.knowledgebase"
                  [hidden]="entry.hidden">

                  <!-- Top item -->
                  <ion-item>

                    <ion-checkbox class="ion-text-wrap" justify="start" labelPlacement="end" [(ngModel)]="entry.checked"
                      (ionChange)="onItemCheckboxClicked(entry)" [disabled]="entry.disabled">

                      <span class="active-span" (click)="toggleActive(entry)">
                        <ion-icon [name]="entry.active ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                          [color]="entry.active ? 'success' : 'danger'" slot="start"></ion-icon>
                        <span [hidden]="entry.active" [innerHTML]="'not_active'|translate"></span>
                        <span [hidden]="!entry.active" [innerHTML]="'active'|translate"></span>
                      </span>

                    </ion-checkbox>

                    <ion-button slot="end" icon-only (click)="deleteKnowledgebaseEntry(entry)" fill="clear"
                      color="danger">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'delete'|translate" [hidden]="!view.isDesktop"></ion-label>
                    </ion-button>

                  </ion-item>

                  <!-- Item input / output preview -->
                  <ion-grid>
                    <ion-row>

                      <!-- Input -->
                      <ion-col [size]="view.isDesktop ? 6 : 12">

                        <ion-item lines="none" class="ion-text-wrap">
                          <ion-label>
                            <p [innerHTML]="entry.input" class="ion-text-wrap"></p>
                          </ion-label>
                        </ion-item>

                      </ion-col>

                      <!-- Output -->
                      <ion-col [size]="view.isDesktop ? 6 : 12">

                        <ion-item lines="none">

                          <ion-label class="ion-text-wrap">
                            <h3 [innerHTML]="entry.output" class="ion-text-wrap"></h3>
                          </ion-label>

                        </ion-item>

                      </ion-col>

                    </ion-row>
                  </ion-grid>

                </ion-card>

              </div>

            </ion-card>

          </ion-col>

          <!-- URLs -->
          <ion-col>

            <ion-card>

              <ion-card-header>
                <ion-card-subtitle [innerHTML]="'url_embed_list'|translate"></ion-card-subtitle>
              </ion-card-header>

              <ion-card-content>

                <!-- Add url button -->
                <ion-button color="primary" (click)="addUrl()" [disabled]="view.addUrlMode">
                  <ion-icon name="link-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'add_url'|translate"></ion-label>
                </ion-button>

              </ion-card-content>

              <!-- Add url input -->
              <ion-item lines="none" [hidden]="!view.addUrlMode">

                <ion-icon name="link-outline" slot="start"></ion-icon>

                <ion-input [(ngModel)]="view.addUrlInput" [label]="'url'|translate" labelPlacement="stacked"
                  [placeholder]="'paste_url'|translate"></ion-input>

                <ion-button slot="end" shape="round" color="primary" (click)="submitUrl()"
                  [disabled]="!view.addUrlInput">
                  <ion-icon name="checkmark-outline" slot="start"></ion-icon>
                </ion-button>

              </ion-item>

              <!-- No entries -->
              <pipeline-no-entries-card
                *ngIf="assistant.config && assistant.config.urls && !assistant.config.urls.length && !view.loading"></pipeline-no-entries-card>

              <!-- Loading spinner -->
              <ion-spinner name="circular" *ngIf="!!view.loading"></ion-spinner>

              <!-- Items list -->
              <ion-list *ngIf="!!assistant.config && !!assistant.config.urls && !!assistant.config.urls.length">

                <ion-item *ngFor="let entry of assistant.config.urls" [hidden]="entry.hidden">

                  <ion-checkbox class="ion-text-wrap" justify="start" labelPlacement="end" [(ngModel)]="entry.checked"
                    (ionChange)="onItemCheckboxClicked(entry)" [disabled]="entry.disabled">

                    <ion-label class="ion-text-wrap">                      
                      <h3 [innerHTML]="entry.url"></h3>
                    </ion-label>

                    <span class="active-span" (click)="toggleActive(entry)">
                      <ion-icon [name]="entry.active ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                        [color]="entry.active ? 'success' : 'danger'" slot="start"></ion-icon>
                      <span [hidden]="entry.active" [innerHTML]="'not_active'|translate"></span>
                      <span [hidden]="!entry.active" [innerHTML]="'active'|translate"></span>
                    </span>
                    
                  </ion-checkbox>

                  <ion-button slot="end" icon-only (click)="deleteKnowledgebaseEntry(entry)" fill="clear"
                    color="danger">
                    <ion-icon name="trash-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'delete'|translate" [hidden]="!view.isDesktop"></ion-label>
                  </ion-button>

                </ion-item>

              </ion-list>

            </ion-card>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

    <!-- Look and Feel -->
    <div [hidden]="view.segment !== 'look_and_feel'">

      <pipeline-template-settings [(cards)]="cards" [mini]="true" [(template)]="template" [(view)]="view"
        (colorChanged)="templateColorVarChanged($event)" (templateChanged)="templateVarsChanged($event)">
      </pipeline-template-settings>

    </div>

    <!-- Limits -->
    <div [hidden]="view.segment !== 'limits'">

    </div>

  </div>

</ion-content>