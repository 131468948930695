//export var apiUrl: string = 'https://staging.pipeline.page/api';
export var apiUrl: string = 'https://api.getgenius.ai/api';
export var hostUrl: string = 'https://api.getgenius.ai';
export var proxyUrl: string = 'https://api.getgenius.ai/api/searchengine/proxy.raw?url=';

export var pipelineAppConfig: pipelineAppConfig = {
    allowAuthUsingCredentials: false,
    allowAuthUsingToken: true,
    allowUserCreateChat: true,
    allowUserCreateChatGroup: true,
    allowUserEditPostSettings: false,
    allowUserEditProfileDetails: true,
    allowUserEditSidebar: true,
    allowUserForgotPassword: false,
    allowUserLogin: true,
    allowUserPostContent: false,
    allowUserPostContentAsCreator: true,
    allowUserPostContentAsGuest: false,
    allowUserPostContentAsUser: false,
    allowUserRegister: true,
    allowUserTranslatePost: false,
    allowUserWriteChatMessage: true,
    appDescription: 'thenex',
    appId: 'app.getgenius.thenex',
    appName: 'thenex',
    appPackageName: 'thenex-creator-studio',
    appPages: {
        
        creators_shop_admin: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creators_shop_admin",
                url: "/creators-shops",
                icon: "storefront-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "shop_products",
                url: "/shop/products",
                icon: "grid-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "shop_categories",
                url: "/shop/categories",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "orders",
                url: "/orders",
                icon: "card-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            }
        ],

        integrations: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integrations",
                url: "/integrations",
                icon: "extension-puzzle-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "apps",
                url: "/whitelabel-apps",
                icon: "phone-portrait-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
                key: "creators_blog_admin",
                url: "/sources/blogs",
                icon: "folder-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creators_shop_admin",
                url: "/sources/shops",
                icon: "storefront-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        /*
        integration_facebook: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_facebook",
                url: "/integrations/integration/facebook/connections",
                icon: "logo-facebook",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "facebook_api_log",
                url: "/integrations/integration/facebook",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_groups",
                url: "/integrations/integration/meta/groups",
                icon: "people-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_instagram: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_instagram",
                url: "/integrations/integration/instagram/connections",
                icon: "logo-instagram",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "instagram_api_log",
                url: "/integrations/integration/instagram",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_linkedin: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_linkedin",
                url: "/integrations/integration/linkedin/connections",
                icon: "logo-linkedin",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "linkedin_api_log",
                url: "/integrations/integration/linkedin",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_pinterest: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_pinterest",
                url: "/integrations/integration/pinterest/connections",
                icon: "logo-pinterest",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "pinterest_api_log",
                url: "/integrations/integration/pinterest",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_reddit: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_reddit",
                url: "/integrations/integration/reddit/connections",
                icon: "logo-reddit",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "reddit_api_log",
                url: "/integrations/integration/reddit",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_snapchat: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_snapchat",
                url: "/integrations/integration/snapchat/connections",
                icon: "logo-snapchat",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "snapchat_api_log",
                url: "/integrations/integration/snapchat",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_tiktok: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_tiktok",
                url: "/integrations/integration/tiktok/connections",
                icon: "logo-tiktok",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "tiktok_api_log",
                url: "/integrations/integration/tiktok",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_twitch: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_twitch",
                url: "/integrations/integration/twitch/connections",
                icon: "logo-twitch",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "twitch_api_log",
                url: "/integrations/integration/twitch",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_twitter: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_twitter",
                url: "/integrations/integration/twitter/connections",
                icon: "logo-twitter",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "twitter_api_log",
                url: "/integrations/integration/twitter",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_whatsapp: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_whatsapp",
                url: "/integrations/integration/whatsapp/connections",
                icon: "logo-whatsapp",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "whatsapp_api_log",
                url: "/integrations/integration/whatsapp",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_xing: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_xing",
                url: "/integrations/integration/xing/connections",
                icon: "logo-xing",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "xing_api_log",
                url: "/integrations/integration/xing",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_youtube: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_youtube",
                url: "/integrations/integration/youtube/connections",
                icon: "logo-youtube",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "youtube_api_log",
                url: "/integrations/integration/google",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        telegram_bots: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "telegram_bots",
                url: "/integrations/integration/telegram/bots",
                icon: "chatbubble-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "telegram_api_log",
                url: "/integrations/integration/telegram",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        */
        
        main: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creator_dashboard",
                url: "/dashboard",
                icon: "grid-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_mail",
                url: "/mail/inbox",
                icon: "mail-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "mail_crawlers",
                        url: "/mail/crawlers",
                        icon: "link-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "mail_blacklist",
                        url: "/mail/blacklist",
                        icon: "hand-left-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
                    },
                ]
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "shop_products",
                url: "/shop/products",
                icon: "grid-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            /*
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "content_hub",
                url: "/hub",
                icon: "document-text-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "ai_url_lookup",
                        url: "/ai/url-lookup",
                        icon: "search-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                ],
            },
            */
            {
                allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                key: "ai_knowledgebase",
                url: "/ai/training/knowledgebase",
                icon: "library-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "ai_tasks",
                url: "/ai/tasks",
                icon: "terminal-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "media",
                url: "/media/library",
                icon: "images-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "media_templates",
                        url: "/media/templates",
                        icon: "eye-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                ]
            },
            /*
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "campaigns",
                url: "/campaigns",
                icon: "calendar-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                key: "statistics_admin",
                url: "/statistics",
                icon: "stats-chart-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['marketing', 'enterprise'],
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "statistics_social_media",
                        url: "/statistics/social-media",
                        icon: "share-social-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "statistics_trends",
                        url: "/statistics/trends",
                        icon: "trending-up-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                ],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creators_marketplace",
                url: "/marketplace/dashboard",
                icon: "people-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['marketplace', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creators_shop_admin",
                url: "/sources/shops",
                icon: "storefront-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['shop', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                key: "ads",
                url: "/ads-admin",
                icon: "megaphone-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['ads', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "websites_admin",
                url: "/creator/websites",
                icon: "desktop-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['websites', 'enterprise'],
            },
            */

            {
                allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                key: "ai_training",
                url: "/ai/training/log",
                icon: "hardware-chip-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                        key: "ai_training_log",
                        url: "/ai/training/log",
                        icon: "pulse-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                        key: "translations_admin",
                        url: "/translations",
                        icon: "language-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                ]
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integrations",
                url: "/integrations",
                icon: "extension-puzzle-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "settings",
                label: 'getgenius_menu_settings',
                url: "/settings",
                icon: "settings-outline",
                hideIfLoggedOut: true,
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Moderator'],
                        key: "teams",
                        url: "/teams",
                        icon: "people-circle-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
                        key: "projects_admin",
                        url: "/projects",
                        icon: "briefcase-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
                        key: "account",
                        url: "/account",
                        icon: "person-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                ]
            },
        ],
        meta_connections: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_facebook",
                url: "/integrations/integration/facebook/connections",
                icon: "logo-facebook",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_instagram",
                url: "/integrations/integration/instagram/connections",
                icon: "logo-instagram",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_whatsapp",
                url: "/integrations/integration/whatsapp/connections",
                icon: "logo-whatsapp",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_comments",
                url: "/integrations/integration/meta/comments",
                icon: "chatbubble-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_log",
                url: "/integrations/integration/meta/log",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        social_media: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_linkedin",
                url: "/integrations/integration/linkedin/connections",
                icon: "logo-linkedin",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_pinterest",
                url: "/integrations/integration/pinterest/connections",
                icon: "logo-pinterest",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_reddit",
                url: "/integrations/integration/reddit/connections",
                icon: "logo-reddit",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_snapchat",
                url: "/integrations/integration/snapchat/connections",
                icon: "logo-snapchat",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_connections",
                url: "/integrations/integration/facebook/connections",
                icon: "logo-facebook",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_tiktok",
                url: "/integrations/integration/tiktok/connections",
                icon: "logo-tiktok",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_twitch",
                url: "/integrations/integration/twitch/connections",
                icon: "logo-twitch",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_twitter",
                url: "/integrations/integration/twitter/connections",
                icon: "logo-twitter",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_xing",
                url: "/integrations/integration/xing/connections",
                icon: "logo-xing",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_youtube",
                url: "/integrations/integration/youtube/connections",
                icon: "logo-youtube",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        teams: [
            {
                allowedUserTypes: ['Admin', 'Moderator'],
                key: "teams",
                url: "/teams",
                icon: "people-circle-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin'],
                key: "customers",
                url: "/customers-admin",
                icon: "person-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin'],
                key: "user_groups_admin",
                url: "/user-groups",
                icon: "hand-right-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
        ]
    },
    appSlogan: 'thenex Creator Studio',
    appVersionId: 102030,
    appVersionName: '1.2.30',
    contentHostUrl: 'https://thenex.app/',
    crud: {
        services: [
            {
                name: 'thenex',
                tables: [
                    {
                        name: 'Abkürzungen',
                        uid: -1,
                        url: '/crud/list/thenex/abbreviations',
                    },
                    {
                        name: 'Betäubungsmittel',
                        uid: -1,
                        url: '/crud/list/thenex/narcotics',
                    },
                    {
                        name: 'Erste Hilfe',
                        uid: -1,
                        url: '/crud/list/thenex/first_aid',
                    },
                    /*
                    {
                        name: 'fundus_items',
                        uid: -1,
                        url: '/fundus_items',
                    },
                    {
                        name: 'fundus_links',
                        uid: -1,
                        url: '/fundus_links',
                    },
                    */
                    {
                        name: 'Fahrerlaubnis',
                        uid: -1,
                        url: '/crud/list/thenex/driving_licence',
                    },
                    {
                        name: 'Gefahrstoffe',
                        uid: -1,
                        url: '/crud/list/thenex/hazardous_substances',
                    },
                    {
                        name: 'Jugendschutz',
                        uid: -1,
                        url: '/crud/list/thenex/youth_protection',
                    },
                    {
                        name: 'KFZ-Kennzeichen',
                        uid: -1,
                        url: '/crud/list/thenex/licence_plates',
                    },
                    {
                        name: 'Schichtpläne',
                        uid: -1,
                        url: '/crud/list/thenex/service_plans',
                    },
                    {
                        name: 'Tatbestandskatalog',
                        uid: -1,
                        url: '/crud/list/thenex/catalog_of_facts',
                    },
                    {
                        name: 'Verkehrszeichen',
                        uid: -1,
                        url: '/crud/list/thenex/traffic_signs',
                    },
                    {
                        name: 'Versicherungskennzeichen',
                        uid: -1,
                        url: '/crud/list/thenex/insurance_traffic_signs',
                    },
                    {
                        name: 'Waffentabelle',
                        uid: -1,
                        url: '/crud/list/thenex/weapons_table',
                    },
                    {
                        name: 'Waffenübersicht',
                        uid: -1,
                        url: '/crud/list/thenex/weapons_overview',
                    },
                ],
                uid: 'thenex',
            }
        ],
    },
    environments: {
        api: {
            local: 'https://api-services.thenex.local/api',
            production: 'https://api.getgenius.ai/api',
            staging: 'https://staging.api.getgenius.ai/api',
            testing: 'https://testing.api.getgenius.ai/api',
        },
        app: {
            local: 'https://admin.thenex.local',
            production: 'https://thenex.whitelabel.getgenius.net/',
            staging: 'https://staging.thenex.whitelabel.getgenius.net/',
            testing: 'https://testing.thenex.whitelabel.getgenius.net/',
        },
        rendering: {
            production: 'https://api.getgenius.ai/api',
        }
    },
    filterDiscoverPostsByContentHostUrl: false,
    filterHomePostsByContentHostUrl: true,
    filterLocalPostsByContentHostUrl: false,
    filterPeopleByContentHostUrl: true,
    filterSearchResultsByContentHostUrl: true,
    filterUserByTeamUids: true,
    footerBottomText: `<p>
        Erstellt mit <a href="https://pipeline.page" target="_blank">pipeline</a><br>
        &copy; ${new Date().getFullYear()} pipeline App GmbH<br><br>
        <a href="https://pipeline.page/de/agb" target="_blank">AGB</a><br>
        <a href="https://pipeline.page/de/datenschutz" target="_blank">Datenschutzerklärung</a>
    </p>`,
    forceAbonnementAfterRegistration: true,
    forceProjectToBeSet: true,
    globalCreateItems: [
      {
        icon: 'terminal-outline',
        name: 'ai_task',
        url: '/ai/task',
      },
    ],
    includeRatingsInFeed: false,
    includeReactionsInFeed: false,
    includeRelatedArticlesInFeed: false,
    isApiURLConfigurable: false,
    isWhitelabel: true,
    links: {
        imprint: 'https://pipeline.page/unternehmen/impressum/',
        privacy_policy: 'https://pipeline.page/datenschutz/',
        settings: [
            {
                icon: 'book-outline',
                description: 'getgenius_docs_description',
                label: 'getgenius_docs',
                url: 'https://docs.getgenius.ai',
            },
        ],
        terms_of_use: 'https://pipeline.page/agb/',
    },
    loginHeadlineText: 'Creator Studio',
    loginTopLogoSize: 'large',
    loginTopLogoUrl: './assets/img/logo-dark.webp',
    maxPostAge: 365,
    menuSideDesktop: 'start',
    menuSideMobile: 'start',
    multiLanguageSupport: true,
    multiUser: true,
    orderBy: {
        discover: 'post_date_gmt desc',
        home: 'post_date_gmt desc',
        local: 'post_date_gmt desc',
        people: 'RAND()',
        shop: 'name',
        showroom: 'post_date_gmt desc',
    },
    postsVipFactor: 0,
    pushAppId: '',
    registerRequiresAbonnement: true,
    routes: {
        discover: '/tabs/shop/discover',
        home: '/creators-dashboard',
        local: '/tabs/local',
        people: '/tabs/people',
        shop: '/tabs/shop-categories-list',
    },
    showAccountBecomeCreator: false,
    showAccountClearCaches: true,
    showAccountSecurity: true,
    showAppearanceColorSchemes: true,
    showAppearanceFontsSelector: false,
    showAppearanceFontSizeSelector: false,
    showAppearancePreferredImageSize: false,
    showAppearanceSidebarSettings: true,
    showAppearanceTabs: true,
    showCheckoutPage: false,
    showFeedBottomShadow: false,
    showFooterAppVersion: true,
    showFooterBottomText: true,
    showGetGeniusDaniInDashboard: true,
    showGetGeniusDaniInSidebar: false,
    showHeaderLargeTitle: true,
    showHeaderMenuButton: true,
    showHeaderToolbarLogo: true,
    showHeaderToolbarLogoInApp: true,
    showHeaderToolbarLogoInWeb: false,
    showHeaderToolbarTitle: false,
    showJobPostsInFeeds: false,
    showJobPostsInSearchResults: false,
    showLocalSourcesSuggestions: false,
    showLoginCredentialsSkipAccount: true,
    showLoginPage: false,
    showLoginPageLeftImg: true,
    showLoginPageLeftImgCase: false,
    showLoginTopLogo: true,
    showLoomaBlanketPurchaseOrders: false,
    showLoomaCustomersPersonTypeOrganization: true,
    showLoomaCustomersPersonTypePerson: true,
    showLoomaOrdersSegmentToolbar: false,
    showLoomaProductionOrders: false,
    showLoomaPurchaseOrders: false,
    showLoomaSalesOrders: true,
    showMenuAddButton: true,
    showMenuGlobalSearch: true,
    showMenuHeaderLogo: true,
    showMenuHeaderTitle: false,
    showMenuIfLoggedOut: false,
    showMenuPagesInApp: true,
    showMenuPagesInWeb: true,
    showPodcastsSuggestions: false,
    showPodcastsSuggestionsInFeed: false,
    showPostAuthorCard: false,
    showPostCardAdminFab: true,
    showPostCardExcerpt: true,
    showPostCardHostLabel: false,
    showPostCardOptions: false,
    showPostCardVerifiedBadges: false,
    showPostPagination: false,
    showPostReadInAppCard: false,
    showPostRelatedArticles: true,
    showProfileDisplayName: true,
    showProfileEvents: false,
    showProfileFriends: false,
    showProfileSocialInformation: false,
    showProfileSocialInformationLabels: false,
    showProfileUserGroup: false,
    showProfileUserGroupAsPrimaryLabel: false,
    showProfileUsername: true,
    showRegisterProjectSettings: false,
    showRegisterVideos: false,
    showSalut: false,
    showSettingsLegalLinks: true,
    showSettingsLinks: true,
    showShopCategories: true,
    showShopNewestProducts: true,
    showShopRelatedProductsOnDetailPage: true,
    showShopSaleProducts: true,
    showShopSources: true,
    showShopSuggestedProducts: true,
    showShopTrendingProducts: true,
    showSuggestedProfilesOnFeedPage: false,
    showSuggestedProfilesOnHomePage: false,
    showSuggestedProfilesOnLocalPage: false,
    showTabBar: true,
    showTabBarAsDock: false,
    showTabBarInMenuOnApp: true,
    showTabBarInMenuOnWeb: true,
    showTabBarLabels: true,
    showTabMenu: false,
    sidebar: true,
    tabBarActiveStyle: 'solid',
    tabs: [
        {
            uid: 'weclapp_connections',
            checked: true,
            icon: 'link-outline',
            indent: 'weclapp_connections',
            index: 0,
            name: 'weclapp_connections',
            route: 'weclapp-connections',
            url: '/tabs/weclapp-connections',
        },
        {
            uid: 'looma_articles',
            checked: true,
            icon: 'storefront-outline',
            indent: 'looma_articles',
            index: 4,
            name: 'articles',
            route: 'looma-articles',
            url: '/tabs/looma-articles',
        },
        {
            uid: 'looma_customers',
            checked: true,
            icon: 'people-outline',
            indent: 'looma_customers',
            index: 1,
            name: 'looma_customers',
            route: 'looma-customers',
            url: '/tabs/looma-customers',
        },
        /*
        {
            uid: 'shopAccount',
            checked: true,
            icon: 'person-outline',
            indent: 'shop-account',
            index: 4,
            name: 'account',
            route: 'shop-account',
            url: '/tabs/shop-account',
        },
        */
        {
            uid: 'looma_offers',
            checked: true,
            icon: 'document-outline',
            indent: 'looma_offers',
            index: 2,
            name: 'looma_offers',
            route: 'looma-offers',
            url: '/tabs/looma-offers',
        },
        {
            uid: 'looma_orders',
            checked: true,
            icon: 'basket-outline',
            indent: 'looma_orders',
            index: 3,
            name: 'salesOrders',
            route: 'looma-orders',
            url: '/tabs/looma-orders',
        },
        {
            uid: 'looma_history',
            checked: true,
            icon: 'list-outline',
            indent: 'looma_history',
            index: 5,
            name: 'history',
            route: 'looma-history',
            url: '/tabs/looma-history',
        },
    ],
    translucentFooters: false,
    translucentHeaders: false,
    useAbonnements: false,
    useAdsExtension: false,
    useAiExtension: true,
    useAppearance: true,
    useAppleWatchExtension: false,
    useArchive: false,
    useAssetsExtension: true,
    useAuthExtension: false,
    useAvatars: false,
    useBionicReadingExtension: true,
    useBlogsExtension: false,
    useCalendar: false,
    useCategories: true,
    useCategoriesOnDiscoverPage: false,
    useCategoriesOnFeedPages: true,
    useCategoriesOnHomePage: true,
    useCategoriesOnLocalPage: false,
    useCategoriesOnSearchPage: true,
    useChatExtension: false,
    useCompaniesExtension: false,
    useCommentsExtension: true,
    useComplexityExtension: false,
    useControlCenterExtension: true,
    useCookiesAlertInApp: false,
    useCookiesAlertInWebApp: true,
    useCouponsExtension: true,
    useCreativesExtension: true,
    useCrudExtension: true,
    useDashboardCardsWizard: true,
    useDashboardGetGeniusSearch: true,
    useDatabaseExtension: true,
    useDatingExtension: false,
    useDeathAnnouncementsExtension: false,
    useDevicesExtension: true,
    useDiscover: true,
    useDocumentsExtension: false,
    useEventsList: false,
    useExperiments: false,
    useFeedback: false,
    useFeeds: false,
    useFiltersExtension: false,
    useFollow: false,
    useGetGeniusDashboardAiShortcuts: true,
    useGetGeniusExtension: true,
    useGetGeniusWalletExtension: false,
    useGoogleMapsExtension: false,
    useGroups: false,
    useHeadlineProfileAvatar: true,
    useHelpCenterExtension: false,
    useHome: false,
    useInbox: false,
    useInterests: false,
    useIntro: false,
    useIntroCards: true,
    useIntroPeople: false,
    useIntroReadingSettings: false,
    useInviteFriends: false,
    useJobPosts: false,
    useJobTitlesExtension: false,
    useLocal: false,
    useLoomaExtension: false,
    useMailExtension: true,
    useMarketplaceExtension: false,
    useMediaExtension: true,
    useMoviesExtension: false,
    useNewsletterExtension: true,
    useOrderByFilters: false,
    usePayPalExtension: false,
    usePeople: false,
    usePointsSystem: false,
    usePostPaywallExtension: false,
    usePostsAdmin: true,
    useProfile: false,
    useProfileDisplayNames: true,
    useProfileTitleExtension: false,
    useProfileUsernames: true,
    useProjectsExtension: true,
    usePushNotifications: false,
    usePushNotificationsChannels: true,
    useQuizExtension: true,
    useReactions: false,
    useRegionSearch: true,
    useRegionSearchSuggestions: false,
    useReportingExtension: false,
    useSearch: false,
    useSecurityExtension: true,
    useSettings: false,
    useSharingExtension: true,
    useShop: true,
    useShopAccount: true,
    useShopCategories: true,
    useShopInternalPaymentProcess: true,
    useShortcuts: false,
    useShowroom: true,
    useSignInWithApple: false,
    useSignInWithFacebook: false,
    useSignInWithGoogle: false,
    useSimplyLocalExtension: false,
    useSplineExtension: true,
    useStatisticsExtension: false,
    useStories: true,
    useStoriesCameraExtension: true,
    useStoriesOnDiscoverPage: true,
    useStoriesOnHomePage: false,
    useStoriesOnLocalPage: false,
    useStoriesOnPeoplePage: true,
    useSurveyExtension: true,
    useTagsExtension: true,
    useTeamsExtension: true,
    useTesting: false,
    useThemes: true,
    useThreeDeeTouch: false,
    useTransparencyExtension: false,
    useTvExtension: false,
    useVideosExtension: true,
    useWallet: false,
    useWebsitesExtension: false,
    useWeclappExtension: false,
    useWeloveWalletExtension: false,
    useWhitelabelAppsExtension: false,
};