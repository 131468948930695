import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
    providedIn: 'root'
})
export class ChannableService {

    constructor(
        private AppCMS: AppcmsService,
        private integrations: IntegrationsService,
        private oauth: OauthService,
        private userService: UserService,
    ) {

    }

    analyse(connectionIds: number[], options: any = {}) {
        options = options || {};
        options.uids = (connectionIds || []);
        return this.AppCMS.loadPluginData("channable", options, ['analyse']);
    }

    createConnection(connection: integrationConnection) {
        return this.AppCMS.loadPluginData('channable', {
            connection: connection,
        }, ['connections', 'create']);
    }

    deleteConnection(connectionId: number) {
        return this.AppCMS.loadPluginData('channable', {
            uid: connectionId,
        }, ['connections', 'delete']);
    }

    getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('channable', options, ['log'], {}, blForceRefresh);
    }

    getConnections(options: any = {}, blForceRefresh: boolean = false): Promise<any> {
        return this.AppCMS.loadPluginData('channable', options, ['connections'], {}, blForceRefresh);
    }

    getSettings(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('channable', options, ['settings'], {}, blForceRefresh);
    }

    updateConnection(connection: integrationConnection) {
        connection = JSON.parse(JSON.stringify(connection));

        delete connection.checked;

        return this.AppCMS.loadPluginData('channable', {
            connection: connection,
        }, ['connections', 'update']);
    }

}