<!-- Header -->
<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" [hidden]="!view.startManually">

      <!-- Use button -->
      <ion-button *ngIf="view.buttonAction === 'use'"
        [hidden]="(view.phase === 'loading') || (view.phase === 'presenting')"
        [disabled]="!view.selectedItems || !view.selectedItems.length" (click)="aiCreate()" shape="round"
        color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'use'|translate"></ion-label>
      </ion-button>

      <!-- Main generate button -->
      <ion-button *ngIf="view.buttonAction === 'search'"
        [hidden]="(view.phase === 'loading') || (view.phase === 'presenting')" (click)="aiCreate()"
        [disabled]="!!view.loading || (!search || !search.query || !search.query.length) || !view.isValidWebsiteUrl"
        shape="round" color="primary" fill="solid">
        <ion-icon name="search-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'search'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <!-- Preview iframe -->
  <iframe #urlLookupFrame *ngIf="!!view.frameSrc" hidden [src]="view.frameSrc" (load)="iFrameLoaded($event)"></iframe>

  <!-- Create media intro mode -->
  <pipeline-intro-overlay *ngIf="!view.executed && !view.startManually" [aiSettings]="aiSettings"
    [aiSettingsOptions]="aiSettingsOptions" [config]="introOverlayConfig" [hidden]="!!view.startManually"
    [(view)]="view" (onContinue)="aiCreate()" (onInput)="onOverlayInputChanged($event)" (onSkip)="startManually()">
  </pipeline-intro-overlay>

  <!-- Intro card -->
  <div class="container" [hidden]="view.introCard.hidden">
    <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
  </div>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container main-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

          <!-- Input -->
          <ion-card>

            <ion-card-header>
              <ion-card-title>
                <span [innerHTML]="'ai_url_lookup_ai_helper_text'|translate"></span>
              </ion-card-title>
            </ion-card-header>

            <ion-list>

              <!-- Lookup url -->
              <ion-item>

                <ion-icon name="link-outline" slot="start"></ion-icon>

                <ion-textarea [label]="'url'|translate" [(ngModel)]="search.query" (ionInput)="runSearch($event)"
                  rows="4" [placeholder]="'ai_url_lookup_search_placeholder'|translate"
                  labelPlacement="stacked"></ion-textarea>

              </ion-item>

            </ion-list>

            <!-- Shortlink Generator -->
            <pipeline-shortlink-generator [config]="shortlinkConfig" [link]="view.smart_link"
              [url]="search.query"></pipeline-shortlink-generator>

          </ion-card>

          <!-- Media -->
          <pipeline-media-picker-card [(item)]="view.media" [(items)]="view.mediaList" [options]="{}" [(view)]="view"
            [hidden]="!view.executed"></pipeline-media-picker-card>

          <!-- URL information -->
          <ion-card class="url-information-card" [hidden]="!view.expertMode">

            <ion-card-header>
              <ion-card-title>
                <span [innerHTML]="'information'|translate"></span>
              </ion-card-title>
            </ion-card-header>

            <!-- Loading spinner -->
            <ion-spinner [hidden]="!view.loading"></ion-spinner>

            <ion-grid class="information-grid" *ngIf="!!view.ci && !!view.infoKeys" [hidden]="view.loading">
              <ion-row *ngFor="let key of view.infoKeys">

                <ion-col size="4">
                  <p class="ion-text-wrap" [innerHTML]="key|translate"></p>
                </ion-col>

                <ion-col size="8">
                  <p class="ion-text-wrap" [innerHTML]="view.ci[key]"></p>
                </ion-col>

              </ion-row>
            </ion-grid>

          </ion-card>

        </ion-col>

        <!-- Content -->
        <ion-col class="content" [size]="view.isDesktop ? 9 : 12">

          <!-- Errors -->
          <ion-card [hidden]="!!view.loading" *ngIf="!!view.errorKeys && !!view.errorKeys.length">

            <ion-card-header>
              <ion-card-title [innerHTML]="'errors'|translate"></ion-card-title>
            </ion-card-header>

            <ion-list>

              <ion-item *ngFor="let key of view.errorKeys" [hidden]="!view.errors[key]">
                <ion-label>
                  <h3 [innerHTML]="(key|translate) + ':'"></h3>
                  <p [innerHTML]="view.errors[key]|translate"></p>
                </ion-label>
              </ion-item>

            </ion-list>

          </ion-card>

          <!-- Summary -->
          <ion-card [hidden]="!view.summary || !!view.loading">

            <ion-card-header>
              <ion-card-title [innerHTML]="'summary'|translate"></ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <ion-skeleton-text animated [hidden]="!!view.summary"></ion-skeleton-text>
              <p *ngIf="!!view.summary" [innerHTML]="view.summary"></p>
            </ion-card-content>

          </ion-card>

          <!-- Tabs -->
          <ion-toolbar class="ion-no-border container second-toolbar"
            *ngIf="!!view.project && !!view.project.uid && !!view.ideas && !!view.ideas.length">
            <ion-segment scrollable [(ngModel)]="view.segment" (ionChange)="segmentChanged()">

              <ion-segment-button value="ideas">
                <ion-icon name="bulb-outline"></ion-icon>
                <ion-label [innerHTML]="'ai_ideas'|translate"></ion-label>
              </ion-segment-button>

              <ion-segment-button value="posts">
                <ion-icon name="document-text-outline"></ion-icon>
                <ion-label [innerHTML]="'posts'|translate"></ion-label>
              </ion-segment-button>

              <ion-segment-button value="media">
                <ion-icon name="images-outline"></ion-icon>
                <ion-label [innerHTML]="'media'|translate"></ion-label>
              </ion-segment-button>

              <ion-segment-button value="newsletters">
                <ion-icon name="newspaper-outline"></ion-icon>
                <ion-label [innerHTML]="'newsletters'|translate"></ion-label>
              </ion-segment-button>

            </ion-segment>
          </ion-toolbar>

          <!-- Selection toolbar -->
          <pipeline-selection-toolbar [(items)]="view.ideas" [options]="selectionOptions" [(view)]="view"
            (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

          <!-- No entries -->
          <pipeline-no-entries-card
            *ngIf="!view.loading && view.ideas && !view.ideas.length"></pipeline-no-entries-card>

          <!-- Ideas -->
          <div class="ideas-view" [hidden]="view.segment !== 'ideas'">

            <ion-grid *ngIf="!!view.ideas && !!view.ideas.length">
              <ion-row>

                <ion-col [size]="view.colSize || 4" *ngFor="let idea of view.ideas" [hidden]="idea.hidden">
                  <ion-card>

                    <ion-item class="ion-text-wrap">

                      <ion-checkbox class="ion-text-wrap" [(ngModel)]="idea.checked" justify="start"
                        labelPlacement="end" (ionChange)="onItemCheckboxClicked(idea)">
                        <p [innerHTML]="idea.headline || ('no_headline'|translate)" class="ion-text-wrap"></p>
                      </ion-checkbox>

                      <ion-button slot="end" (click)="use(idea)" color="primary" shape="round">
                        <ion-icon name="hammer-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'use'|translate" class="ion-text-wrap"></ion-label>
                      </ion-button>

                    </ion-item>

                    <ion-thumbnail>
                      <ion-img [src]="idea.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(idea)"></ion-img>
                    </ion-thumbnail>

                    <ion-card-content>
                      <p [innerHTML]="idea.text || ('no_text'|translate)"></p>
                    </ion-card-content>

                  </ion-card>
                </ion-col>

              </ion-row>
            </ion-grid>

          </div>

          <!-- Media results -->
          <div class="media-view" [hidden]="view.segment !== 'media'">

            <!-- Media items -->
            <pipeline-media-items [hidden]="view.phase === 'queue'" [(view)]="view"></pipeline-media-items>

            <!-- Media queue -->
            <pipeline-media-queue [hidden]="view.phase !== 'queue'" [options]="{ autofill: true }"
              [selectionOptions]="selectionOptions" [(view)]="view"></pipeline-media-queue>

            <!--
            <getgenius-queue-footer [hidden]="view.phase !== 'queue'"></getgenius-queue-footer>
            -->

          </div>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!view.executed || !!view.startManually">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>