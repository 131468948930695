<ion-card class="aiPromptCard">

  <form (ngSubmit)="runAiPrompt()">

    <ion-item lines="none">

      <ion-textarea name="aiPrompt" #aiPromptPostInput rows="2" [(ngModel)]="input" [disabled]="!!disabled"
        [placeholder]="'ai_prompt_' + type + '_modification'|translate"></ion-textarea>

      <ion-button (click)="runAiPrompt()" expand="block" [disabled]="view.loading"
        [size]="view.isDesktop ? 'medium' : 'small'" color="primary" shape="round">
        <ion-label [innerHTML]="'continue' | translate" [hidden]="!view.isDesktop"></ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-button>

    </ion-item>

  </form>

</ion-card>