<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>
      
    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">
      <ion-button (click)="add($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <pipeline-select-project-card *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension"></pipeline-select-project-card>

    <pipeline-media-library *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)" [(view)]="view.libraryView"></pipeline-media-library>

  </div>

</ion-content>