import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { AttributesService } from 'src/app/services/core/attributes.service';
import { EventsService } from "src/app/services/core/events.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-item-attributes-card',
  standalone: false,
  templateUrl: './item-attributes-card.component.html',
  styleUrls: ['./item-attributes-card.component.scss']
})
export class ItemAttributesCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() item: any;
  @Input() location: string;
  @Input() type: string;
  @Input() view: any;

  @ViewChild('infoPopover') infoPopover: any;

  isInfoPopoverOpen: boolean = false;

  constructor(
    private attributes: AttributesService,
    private events: EventsService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
  ) {

  }

  add() {
    this.item.attributes = this.item.attributes || [];

    this.item.attributes.push({
      active: true,
      key: '',
      value: '',
    });
  }

  async load() {
    await this.loadProject();

    try {
      if (!!this.item && (this.item.uid || this.item.ID) && !this.item.attributes || !this.item.attributes.length) {
        this.loadAttributes();
      }
    } catch (e) {
      console.warn('loading attributes failed', e);
    }
  }

  loadAttributes(blForceRefresh: boolean = true) {
    return new Promise(async (resolve, reject) => {
      //console.log('loadAttributes', this.view);

      if (!this.view.project || !this.view.project.uid) {
        return false;
      } else {
        this.view.loadingAttributes = true;

        // prepare filters
        this.view.options = this.view.options || {};
        this.view.options.filter = this.view.options.filter || {};
        this.view.options.filter.location = (this.location || this.type);
        this.view.options.filter.source = (this.item.uid || this.item.ID);

        this.attributes.get(this.view.options, blForceRefresh, { limit: 1000 })
          .then((attributes: attribute[]) => {
            this.view.loadingAttributes = false;

            //console.log('item attributes', attributes);
            
            this.item.attributes = (attributes || [])
              .map((attribute: attribute) => {
                attribute.active = !!(!!this.view.currentFolder && (this.view.currentFolder.uid === attribute.uid));
                return attribute;
              })
              .sort((a: any, b: any) => {

                const _a: string = `${a.title}`.toLowerCase(),
                  _b: string = `${b.title}`.toLowerCase();

                if (_a < _b) return -1;
                if (_b > _a) return 1;
                return 0;

              });

            resolve(this.item.attributes);
          })
          .catch((error: any) => {
            this.view.loadingAttributes = false;
            reject(error);
          });
      }
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.load();
  }

  onAttributeAdd(event: any, attribute: any = {}) {

    if (!this.item.uid) {
      return false;
    }

    const attributeData: any = {
      location: (this.location || this.type),
      key: `${attribute.key}`,
      value: `${event.title}`,
      uid: `${attribute.uid || event.uid}`,
    };

    this.attributes.addToItem(attributeData, this.item.uid)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  onAttributeClicked(attribute: attribute) {
    console.log('on attribute clicked', attribute);
  }

  onAttributeRemove(event: any, attribute: any = {}) {

    if (!this.item.uid) {
      return false;
    }

    const attributeData: any = {
      location: (this.location || this.type),
      key: `${attribute.key}`,
      value: `${event.title}`,
      uid: `${attribute.uid || event.uid}`,
    };

    this.attributes.removeFromItem(attributeData, this.item.uid)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.item_attributes = this.cards.item_attributes || {};
    this.cards.item_attributes.open = !this.cards.item_attributes.open;

    this.sidebar.setCards(this.cards);
  }

}