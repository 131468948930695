import { Component, Input, OnInit } from '@angular/core';

import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-post-statistics-card',
  standalone: false,
  templateUrl: './post-statistics-card.component.html',
  styleUrls: ['./post-statistics-card.component.scss'],
})
export class PostStatisticsCardComponent implements OnInit {
  @Input() cards: any;
  @Input() post: post;
  @Input() type: string;
  @Input() view: any;

  constructor(
    private sidebar: SidebarService,
  ) {
    
  }
  
  ngOnInit() {
    this.view.loaded = true;
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.statistics = this.cards.statistics || {};
    this.cards.statistics.open = !this.cards.statistics.open;

    this.sidebar.setCards(this.cards);
  }

}
