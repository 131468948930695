import { Component, Input, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { SuggestionsService } from 'src/app/services/pipeline/suggestions.service';

@Component({
  selector: 'pipeline-header-suggestions-toolbar',
  standalone: false,
  templateUrl: './header-suggestions-toolbar.component.html',
  styleUrls: ['./header-suggestions-toolbar.component.scss'],
})
export class HeaderSuggestionsToolbarComponent implements OnInit {
  @Input() state: state;
  @Input() view: any;

  constructor(
    private events: EventsService,
    private suggestions: SuggestionsService,
  ) {
  }

  calcColSizes() {
    try {
      if (!!this.view) {
        this.view.suggestionsColSize = window.innerWidth >= 1920 ? 1 : (window.innerWidth > 768 ? 2 : 3);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.calcColSizes();
    });
  }

  ngOnInit() {
    this.view.placeholders = this.view.placeholders || [{}, {}, {}, {}, {}, {}];

    this.initEvents();
    this.calcColSizes();
    this.loadSuggestions();
  }

  loadSuggestions() {
    this.view.suggestions = JSON.parse(JSON.stringify(this.view.placeholders));

    this.suggestions.getAll()
      .then((suggestions: any) => {
        this.view.suggestions = suggestions || [];
      })
      .catch((error: any) => {
        console.warn('> suggestions error', error);

        this.view.suggestions = [];
      });
  }

  async onSuggestionClicked(user: user) {
    this.events.publish('view:profile', user);
  }

}
