import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { MetaService } from 'src/app/services/integrations/meta.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  constructor(
    private AppCMS: AppcmsService,
    private meta: MetaService,
    private integrations: IntegrationsService,
    private oauth: OauthService,
    private userService: UserService,
  ) {

  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("whatsapp", options, ['analyse']);
  }

  authorize() {
    const resourceUrl: string = this.AppCMS.getRequestUrl('whatsapp', ['profile']);
    const fbApiVersion = '20.0';
    
    return this.oauth.authorize({
      appId: this.meta.fbAppId,
      authorizationBaseUrl: `https://www.facebook.com/v${fbApiVersion}/dialog/oauth`,

      default: {
        clientId: this.meta.fbAppId,
        redirectUrl: `${window.location.origin}/integrations/integration/whatsapp/connections`,
        responseType: 'token',
      },

      web: {
        clientId: this.meta.fbAppId,
        redirectUrl: `${window.location.origin}/integrations/integration/whatsapp/connections`,
        responseType: 'token',
      },

      resourceUrl: resourceUrl,

      scope: [
        
        // whatsapp:
        'whatsapp_business_management',
        'whatsapp_business_messaging',

        // generic:
        'business_management',
        'email',
        'public_profile',
        
      ].join(','),
      
      // add instagram_content_publish?

      state: this.calcState(),

      /*
      android: {
        customHandlerClass: "com.companyname.appname.YourAndroidFacebookOAuth2Handler",
      },
      ios: {
        customHandlerClass: "App.YourIOsFacebookOAuth2Handler",
      }
      */
    });
  }

  calcState() {
    return Math.random().toString(36).substring(2, 12);
  }

  connect(options: any = {}) {
    return new Promise((resolve, reject) => {
      
      // first, authorize application
      this.authorize().then((authResponse: any) => {

        // then, run connect process
        this.integrations.connect(
          Object.assign(options, authResponse)
        ).then((chooseResponse: chooseResponse) => {

          // if connects selected, add them
          if(!!chooseResponse && !!chooseResponse.data && !!chooseResponse.data.items && !!chooseResponse.data.items.length) {
            chooseResponse.data.items.forEach(async (item: integrationConnection) => {
              try {
                let pageToken: string = (item.page_token || ''), userToken: string = (item.user_token || '');

                if (!!authResponse && !!authResponse.access_token) {
                  pageToken = (pageToken || authResponse.access_token);
                }

                if (!!authResponse && !!authResponse.authorization_response && !!authResponse.authorization_response.long_lived_token) {
                  userToken = authResponse.authorization_response.long_lived_token;
                }

                if (!!authResponse && (!!authResponse.access_token_response && !!authResponse.access_token_response.access_token)) {
                  pageToken = (pageToken || authResponse.access_token_response.access_token);
                }

                let refreshToken: string = (`${(item.refresh_token || authResponse.refresh_token) || ''}` || '');

                if (!!authResponse && !!authResponse.access_token_response && !!authResponse.access_token_response.refresh_token) {
                  refreshToken = authResponse.access_token_response.refresh_token;
                }

                const create: any = await this.createConnection({
                  active: true,
                  name: `${item.name || ''}`,
                  page_id: parseInt(`${item.page_id || (item.uid || item.id)}`),
                  photo: `${item.photo || ''}`,
                  url: `${item.url || ''}`,
                  user: this.userService.getUid(),

                  // tokens:
                  page_token: `${item.page_token || pageToken}`,
                  refresh_token: (refreshToken || item.refresh_token),
                  user_token: `${userToken || (item.user_token || '')}`,
                });

              } catch(e) {
                console.warn('adding connection failed', e);
              }
            });
          }

          resolve(chooseResponse);
        }).catch(reject);
      }).catch(reject);
    });
  }

  createConnection(connection: integrationConnection) {
    connection.platform = 'whatsapp';
    return this.meta.createConnection(connection);
  }
  
  deleteBot(botId: number) {
    return this.AppCMS.loadPluginData('whatsapp', {}, ['bot', botId, 'delete']);
  }

  deleteConnection(connectionId: number) {
    return this.meta.deleteConnection(connectionId);
  }
  
  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.meta.getApiRequestLog({
      filter: Object.assign(options, {
        platform: 'whatsapp',
      }),
    }, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('whatsapp', options, ['connections'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('whatsapp', options, ['settings'], {}, blForceRefresh);
  }

  importMedia(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("whatsapp", options, ['import_media']);
  }

  importPosts(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("whatsapp", options, ['import_posts']);
  }

}
