import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { GetgeniusDaniComponent } from './getgenius-dani.component';

import { TranslateModule } from '@ngx-translate/core';

import { IntroCardComponentModule } from 'src/app/components/intro/intro-card/intro-card.component.module';
import { SplineViewerComponentModule } from 'src/app/components/generic/spline/spline-viewer/spline-viewer.component.module';

import { ChatMessageComponentModule } from 'src/app/components/community/chat/chat-message/chat-message.component.module';

import { HighlightModule } from 'ngx-highlightjs';

@NgModule({
  declarations: [
    GetgeniusDaniComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    IntroCardComponentModule,
    SplineViewerComponentModule,
    ChatMessageComponentModule,
    HighlightModule,
  ],
  exports: [
    GetgeniusDaniComponent,
  ]
})
export class GetgeniusDaniComponentModule { }