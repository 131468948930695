<ion-modal #modifiedGmtModal [keepContentsMounted]="true">
  <ng-template>
    <ion-datetime [(ngModel)]="view.filterDate" id="filterDatePicker" #filterDatePicker
      (ionChange)="dateChanged($event)" (ionInput)="dateChanged($event)"></ion-datetime>
  </ng-template>
</ion-modal>

<ion-popover #selectionActionPopover [isOpen]="isSelectionActionPopoverOpen"
  (didDismiss)="isSelectionActionPopoverOpen = false">
  <ng-template>
    <ion-content>
      <ion-list *ngIf="!!options">

        <ion-item button class="btn-item" *ngFor="let option of options"
          (click)="runSelectionOption(option, $event)"
          [class.danger]="option.color == 'danger' || option.uid === 'delete'"
          [class.success]="option.color == 'success'" [class.warning]="option.color == 'warning'">
          <ion-icon [name]="option.icon" slot="start"></ion-icon>
          <ion-label [innerHTML]="option.label|translate"></ion-label>
        </ion-item>

      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>

<ion-toolbar class="container second-toolbar"
  *ngIf="(!!view.multiple && (!!items && !!items.length)) || !!view.showLanguagesSelect">

  <ion-grid>
    <ion-row>

      <!-- Selection -->
      <ion-col *ngIf="!view.hideSelectAll"
        [size]="(view.mode === 'widget' ? (view.isDesktop ? 9 : 6) : (view.isLargeScreen ? 2 : 8) * sizeFactor)">

        <!-- Select all button -->
        <ion-button (click)="selectAll()" class="select-all-btn" color="dark" fill="clear" [disabled]="view.loading">
          <ion-icon [name]="view.allSelected ? 'square-outline' : 'checkbox-outline'" slot="start"></ion-icon>
          <ion-label [innerHTML]="'all'|translate"></ion-label>
        </ion-button>

        <!-- Choose selection action -->
        <ion-button *ngIf="(view.mode !== 'pick') && (!!options && !!options.length)"
          (click)="presentSelectionActionPicker($event)" icon-only fill="clear" color="dark"
          [disabled]="!options || !options.length || view.loading || !view.hasSelectedItems || !(!!items && !!items.length)">

          <ion-label class="ion-text-wrap">

            <p [innerHTML]="'selection'|translate"></p>

            <small>
              <span>
                <span
                  [innerHTML]="!!view.selectedItems && !!view.selectedItems.length ? (view.selectedItems.length || 0) : 0"></span>
                /
              </span>
              <span [innerHTML]="(!!items && !!items.length ? items.length : 0)"></span>
            </small>

          </ion-label>

          <ion-icon name="chevron-down-outline" slot="end"></ion-icon>

        </ion-button>

      </ion-col>

      <!-- language picker -->
      <ion-col *ngIf="(view.mode !== 'pick') && !!view.showLanguagesSelect"
        [size]="(view.isDesktop ? view.isLargeScreen ? 4 : 6 : 4) * sizeFactor">

        <pipeline-languages-select [(input)]="view.language" [options]="languageOptions"
          (changed)="_onLanguageChanged($event)">
        </pipeline-languages-select>

      </ion-col>

      <!-- Pagination -->
      <ion-col *ngIf="!!view.showPagination && !!paginationConfig && !view.loading">
        <pipeline-pagination [config]="paginationConfig" [(view)]="view"></pipeline-pagination>
      </ion-col>

      <!-- view modes -->
      <ion-col *ngIf="(view.mode !== 'pick') && !!view.showViewModeSelect"
        [size]="(view.isDesktop ? view.isLargeScreen ? 2 : 3 : 8) * sizeFactor">
        <div class="view-modes-wrapper">

          <ion-button size="small" icon-only [hidden]="!!viewType.expertMode && !view.expertMode"
            *ngFor="let viewType of view.viewTypes" [disabled]="view.loading || !(items && items.length)"
            (click)="setView(viewType.uid)" fill="clear" color="dark">
            <ion-icon [name]="view.viewType === viewType.uid ? viewType.icon : (viewType.icon + '-outline')"></ion-icon>
          </ion-button>

          <!-- filters toggle button (mobile) -->
          <ion-button
            *ngIf="!view.isDesktop && ((!!filters && !!filters.length) || (view.mode !== 'pick' && !!view.showViewModeSelect))"
            class="filter-btn" shape="round" size="small" icon-only [fill]="view.showFilters ? 'solid' : 'clear'"
            color="primary" (click)="toggleFilters()">
            <ion-icon [name]="view.showFilters ? 'filter' : 'filter-outline'"></ion-icon>
          </ion-button>

        </div>

      </ion-col>

    </ion-row>

    <!-- filters-->
    <ion-row *ngIf="!!filters && !!filters.length" [hidden]="!view.isDesktop && !view.showFilters"
      class="filters-wrapper">

      <ion-col *ngFor="let filter of filters" [size]="(view.isDesktop ? 3 : 6) * sizeFactor">

        <ion-item lines="none" *ngIf="filter.type === 'checkbox' || filter.type === 'date' || filter.type === 'select'"
          class="ion-text-wrap">

          <!-- if icon is set -->
          <ion-icon *ngIf="!!filter.icon && (filter.type !== 'date')" [name]="filter.icon" slot="start"></ion-icon>

          <!-- checkbox -->
          <ion-checkbox *ngIf="filter.type === 'checkbox'" [(ngModel)]="filter.value"
            [innerHTML]="filter.label|translate" (ionChange)="_onFilterClick(filter, $event)"></ion-checkbox>

          <!-- date / dange range -->
          <ion-icon *ngIf="filter.type === 'date'" (click)="_onFilterClick(filter, $event)"
            [name]="filter.icon || 'calendar-outline'" slot="start"></ion-icon>
          <ion-label *ngIf="filter.type === 'date'" [innerHTML]="(filter.value || filter.label)|translate"
            (click)="_onFilterClick(filter, $event)"></ion-label>

          <!-- select -->
          <ion-select interface="popover" *ngIf="filter.type === 'select' && !!filter.values" [(ngModel)]="filter.value"
            [label]="filter.label|translate" labelPlacement="floating" (ionChange)="_onFilterChange(filter, $event)"
            [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
            <ion-select-option *ngFor="let option of filter.values" [value]="option.uid"
              [innerHTML]="option.label|translate"></ion-select-option>
          </ion-select>

        </ion-item>

        <ion-button *ngIf="filter.type === 'button'" size="small" (click)="_onFilterClick(filter, $event)" shape="round"
          color="dark" fill="clear">
          <ion-label [innerHTML]="filter.label|translate"></ion-label>
        </ion-button>

      </ion-col>

    </ion-row>

  </ion-grid>

</ion-toolbar>