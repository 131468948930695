import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { AudioService } from 'src/app/services/media/audio.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { SidebarService } from 'src/app/services/utils/sidebar.service';

import { AudioLibraryPage } from 'src/app/pages/core/media/audio/library/audio-library.page';

// Providers
import { DaniService } from 'src/app/services/getgenius/dani.service';

@Component({
  selector: 'pipeline-music-card',
  standalone: false,
  templateUrl: './music-card.component.html',
  styleUrls: ['./music-card.component.scss'],
})
export class MusicCardComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() cards: any;
  @Input() options: musicCardOptions = {};
  @Input() view: any;

  @Output() settingsChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private audio: AudioService,
    private events: EventsService,
    private modalService: ModalService,
    private sidebar: SidebarService,

    // providers:
    public dani: DaniService,
  ) {
  }

  async chooseTrack() {

    const audioLibraryModal: any = await this.modalService.create({
      component: AudioLibraryPage,
      componentProps: {
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'defaultModal'
    });

    this.modalService.present(audioLibraryModal);
  }

  doRefresh() {

  }

  initEvents() {
    this.view.events = {};
  }

  async loadTracksByProvider() {

    if (!this.view.music_provider || !this.view.music_provider.uid) {
      return false;
    }

    try {

      switch (this.view.music_provider.uid) {
        /*
        case 'amazon':
          break;
        case 'elevenlabs':
          this.view.music_tracks = ((await this.elevenlabs.getTracks() || []) as any).map((track: any) => {
            track.uid = track.track_id;
            return track;
          });
          break;
        case 'google':
          break;
        case 'heygen':
          this.view.music_tracks = await this.heygen.getTracks();
          break;
        case 'microsoft':
          break;
        */

        case 'dani':
          this.view.music_tracks = [];
          break;

        default:
          this.view.music_tracks = this.audio.getAudioTracks();
          break;
      }


      this.musicSettingChanged();
    } catch (e) {
      console.warn('loading tts tracks failed', e);
    }
  }

  musicProviderChanged() {
    this.loadTracksByProvider();
  }

  musicChanged() {

    if (!!this.view.music_track && !!this.view.music_track.url) {
      this.view.music_preview_file = this.view.music_track.url;
    }

    return this.musicSettingChanged();
  }

  musicSettingChanged() {
    this.settingsChanged.emit({
      use_tts: !!this.view.use_tts,
      provider: (!!this.view.music_provider && !!this.view.music_provider.uid ? this.view.music_provider.uid : null),
      track: (!!this.view.music_track && !!this.view.music_track.uid ? this.view.music_track.uid : null),
      settings: {
        similarity_boost: (this.view.track_similarity_boost || -1),
        stability: (this.view.track_stability || -1),
      },
    });
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.view = this.view || {};

    this.view.music_providers = this.view.music_providers || [
      /*
      {
        name: 'Adiamo GmbH',
        uid: 'integration_adiamo',
      },
      */
      {
        name: 'GetGenius',
        uid: 'dani',
      },
      {
        name: 'stock_database',
        uid: 'stock_database',
      },
    ];

    this.view.music_tracks = this.view.music_tracks || [];

    this.view.music_types = this.view.music_types || [
      {
        icon: 'sparkles-outline',
        name: 'generate',
      },
      {
        icon: 'cloud-download-outline',
        name: 'select',
      },
    ];

    this.initEvents();

    if (!!this.view.music_providers && !!this.view.music_providers.length) {
      // set first provider as selected provider
      this.view.music_provider = this.view.music_providers[0];
      this.musicProviderChanged();
    }
  }

  playPreview() {

    if (!this.view.music_provider || !this.view.music_provider.uid) {
      console.warn('no provider selected', this.view);
      return false;
    }

    if (!this.options || !this.options.input || !this.options.input.length) {
      console.warn('invalid options', this.options);
      return false;
    }

    try {
      this.view.loadingMusic = true;
      this.view.music_preview_file = null;

      let service: any = this[this.view.music_provider.uid];

      if (!service) {
        console.warn('not implemented', service);
        return false;
      }

      this.options.track_uid = (!!this.view.music_track && !!this.view.music_track.uid ? this.view.music_track.uid : null);
      
      service.executeCreateTextToAudio({
        input: `${this.options.input || ''}`,
      })
        .then((response: any) => {
          this.view.loadingMusic = false;

          if (!!response && !!response.url) {
            this.view.music_preview_file = response.url;
          }
        })
        .catch((error: any) => {
          this.view.loadingMusic = false;
          this.events.publish('error', error);
        });
    } catch (error) {
      this.view.loadingMusic = false;
      this.events.publish('error', error);
    }

  }

  stopPreview() {

  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.music = this.cards.music || {};
    this.cards.music.open = !this.cards.music.open;

    this.sidebar.setCards(this.cards);
  }

  toggleType(type: any, iType: number) {
    this.view.music_types[iType].checked = !this.view.music_types[iType].checked;
  }

}