<ion-card>

  <ion-card-header>
    <ion-card-title [innerHTML]="'ai_audio_transcribing'|translate"></ion-card-title>
  </ion-card-header>

  <!-- Input language -->
  <pipeline-languages-select icon="language-outline" [label]="'input_language'|translate" [options]="languageOptions"
    [(input)]="view.input_language" (changed)="onLanguageChange($event)"></pipeline-languages-select>

  <!-- Output language -->
  <pipeline-languages-select icon="language-outline" [label]="'output_language'|translate" [options]="languageOptions"
    [(input)]="view.output_language" (changed)="onLanguageChange($event)"></pipeline-languages-select>

  <ion-card>

    <ion-card-header>
      <ion-card-title [innerHTML]="'settings'|translate"></ion-card-title>
    </ion-card-header>

    <ion-list>
    </ion-list>
  </ion-card>

</ion-card>