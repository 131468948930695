import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ItemReorderEventDetail } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { TemplateEditorService } from 'src/app/services/media/template-editor.service';

@Component({
  selector: 'pipeline-template-timeline',
  standalone: false,
  templateUrl: './template-timeline.component.html',
  styleUrls: ['./template-timeline.component.scss'],
})
export class TemplateTimelineComponent implements OnInit {
  @Input() cards: any = {};
  @Input() template: any;
  @Input() ticks: any[] = [];
  @Input() mini: boolean;
  @Input() view: any;

  @Output() onTimelineEndChanged = new EventEmitter();
  @Output() onTimelineLayerClick = new EventEmitter();
  @Output() onTimelineLayerMove = new EventEmitter();
  @Output() onTimelineLayerTouchEnd = new EventEmitter();
  @Output() onTimelineLayerTouchMove = new EventEmitter();
  @Output() onTimelineLayerTouchStart = new EventEmitter();
  @Output() onTimelineTickColClick = new EventEmitter();

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private editor: TemplateEditorService,
    private events: EventsService,
    private media: MediaextendService,
    private zone: NgZone,
  ) {
  }

  addLayer(options: any = {}) {
    this.template = this.template || {};
    this.template.config = this.template.config || {};
    this.template.config.aspect_ratios = this.template.config.aspect_ratios || {};
    this.template.config.aspect_ratios[this.template.config.aspect_ratio] = this.template.config.aspect_ratios[this.template.config.aspect_ratio] || [];

    options.layers = this.template.config.aspect_ratios[this.template.config.aspect_ratio];

    this.editor.addLayer(options)
      .then((response: any) => {
        if (!!response && !!response.layer) {
          this.template.config.aspect_ratios[this.template.config.aspect_ratio] = [response.layer].concat(options.layers);

          this.initGestures_Layers();
          this.calcViewVars();
          this._onTimelineLayerClick(this.template.config.aspect_ratios[this.template.config.aspect_ratio][0], 0);
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  calcCurrentLayerStyle() {

  }

  calcEditorSize() {

  }

  calcViewVars() {

  }

  chooseLayerEffect(layer: any, i: number) {
    this.media.chooseEffect({
      multiple: false,
    })
      .then((response: any) => {
        if (!!response && !!response.data && !!response.data.item) {
          const layerId: string = (layer && layer.settings && layer.settings.id ? layer.settings.id : `stage-layer-component-${i}`);
          //let templateWrapperString: string = (!!this.template.uid ? `.stage-template-uid-${this.template.uid} ` : '');

          layer.settings.css = `${response.data.item.css || ''}`.replace('.animation-wrapper > *', `#${layerId}`); //.replace(/.animation-wrapper/g, `${templateWrapperString}#${layerId} .animation-wrapper`);
          layer.settings.html = `${response.data.item.html || ''}`;
          layer.settings.js = `${response.data.item.js || ''}`;

          this.events.publish('template:preview:layer:effect:changed', {
            index: this.view.selectedLayerIndex,
            layer: this.template.config.aspect_ratios[this.template.config.aspect_ratio][this.view.selectedLayerIndex],
            template: this.template,
          });

          this.calcCurrentLayerStyle();
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  deleteLayer(layer: any, i: number) {
    this.zone.run(() => {

      if (!!this.template.config.aspect_ratios && !!this.template.config.aspect_ratios[this.template.config.aspect_ratio]) {
        this.template.config.aspect_ratios[this.template.config.aspect_ratio] = this.template.config.aspect_ratios[this.template.config.aspect_ratio]
          .filter((_layer, _i: number) => {
            return _i !== i;
          });
      }

      this.calcViewVars();
    });
  }

  duplicateLayer(layer: any, i: number) {
    this.zone.run(() => {
      this.template.config.aspect_ratios[this.template.config.aspect_ratio] = [JSON.parse(JSON.stringify(layer))].concat(this.template.config.aspect_ratios[this.template.config.aspect_ratio]);
      this.calcViewVars();
    });
  }

  handleReorderLayers(ev: CustomEvent<ItemReorderEventDetail>) {
    this.zone.run(() => {
      this.template.config.aspect_ratios[this.template.config.aspect_ratio] = ev.detail.complete(this.template.config.aspect_ratios[this.template.config.aspect_ratio]);

      this.initGestures();
      this.updatePreview();
    });
  }

  initGestures() {
    this.initGestures_Layers();
  }

  initGestures_Layers() {

  }

  loadLayerCustomSettingsToCard() {

  }

  ngOnInit() {
    console.log('template-timeline: init template', this.template);
    console.log('template-timeline: init view', this.view);
  }

  _onTimelineEndChanged() {
    this.calcViewVars();

    this.onTimelineEndChanged.emit(true);
  }

  _onTimelineLayerClick(layer: any, i: number) {
    this.zone.run(() => {
      this.stopSet();

      if (!this.template.config.aspect_ratios[this.template.config.aspect_ratio]) {
        return false;
      }

      this.template.config.aspect_ratios[this.template.config.aspect_ratio].forEach((_layer, _i: number) => {
        _layer.active = (_i === i);
      });

      this.view.animation_editor = this.view.animation_editor || {};
      this.view.animation_editor.type = (!!layer.keyframes && !!layer.keyframes.length ? 'custom' : 'preset');

      this.view.selectedLayer = layer;
      this.view.selectedLayerIndex = i;

      this.template.config.timeline.current = layer.settings.start || 0;

      if (!!this.view.cards && !!this.view.cards.length) {
        this.view.cards.forEach((card: any) => {
          if (card.settings && card.settings.length) {
            card.settings.forEach((setting: any) => {
              if (layer.settings && this.template.config.aspect_ratios[this.template.config.aspect_ratio][i].settings.hasOwnProperty(setting.uid)) {
                setting.value = this.template.config.aspect_ratios[this.template.config.aspect_ratio][i].settings[setting.uid];
              } else
                if (!!setting.default) {
                  setting.value = setting.default;
                } else {
                  setting.value = '';
                }
            });
          }
        });
      }

      this.loadLayerCustomSettingsToCard();
      this.prepareTemplateForSave();
      //this.calcCurrentLayerStyle(i);

      this.onTimelineLayerClick.emit({
        layer: layer,
        index: indexedDB,
      });
    });
  }

  _onTimelineLayerMove(layer: any, i: number, event: any) {
    console.log('> layer: move', layer, i, event);

    this.onTimelineLayerMove.emit({
      event: event,
      index: i,
      layer: layer,
    });
  }

  _onTimelineLayerTouchEnd(layer: any, i: number, event: any) {
    console.log('> layer: touch end', layer, i, event);

    this.onTimelineLayerTouchEnd.emit({
      event: event,
      index: i,
      layer: layer,
    });
  }

  _onTimelineLayerTouchMove(layer: any, i: number, event: any) {
    console.log('> layer: touch move', layer, i, event);

    this.onTimelineLayerTouchMove.emit({
      event: event,
      index: i,
      layer: layer,
    });
  }

  _onTimelineLayerTouchStart(layer: any, i: number, event: any) {
    console.log('> layer: touch start', layer, i, event);

    this.onTimelineLayerTouchStart.emit({
      event: event,
      index: i,
      layer: layer,
    });
  }

  _onTimelineTickColClick(iTick: number) {
    this.pauseSet();

    this.zone.run(() => {
      this.template.config.timeline.current = iTick;
      this.updatePreview();
    });

    this.onTimelineTickColClick.emit({
      index: iTick,
    });
  }

  pauseSet() {
    this.events.publish('media:template:preview:pause', {
      template: this.template,
      view: this.view,
    });
  }

  prepareTemplateForSave() {

  }

  runSet() {

  }

  stopSet() {

  }

  toggleLayerVisibility(layer: any, i: number) {
    this.zone.run(() => {
      this.template.config.aspect_ratios[this.template.config.aspect_ratio][i].hidden = !this.template.config.aspect_ratios[this.template.config.aspect_ratio][i].hidden;
    });
  }

  toggleTimeline(bl: boolean | null = null) {

    this.zone.run(() => {
      this.template.config.timeline.hidden = (bl !== null ? !!bl : !this.template.config.timeline.hidden);
    });

    setTimeout(() => {
      this.calcEditorSize();
    });

  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  updatePreview() {

  }

}