<!-- Input searchbar -->
<ion-searchbar [debounce]="400" [(ngModel)]="input" (ionInput)="onUrlChanged($event)"
  [hidden]="!!hidden"></ion-searchbar>

<!-- Loading spinner -->
<ion-spinner [hidden]="!view.rebuild" [hidden]="!!hidden"></ion-spinner>

<!-- View -->
<iframe #browserFrame *ngIf="!rebuild && !!safe_url" [hidden]="!!hidden" [src]="safe_url" [srcdoc]="srcDocument" (load)="iFrameLoaded($event)">
  <ion-spinner></ion-spinner>
  <p>Loading page: {{input}}</p>
</iframe>