import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ModalService } from 'src/app/services/core/modal.service';

import { HeygenEditVideoPage } from 'src/app/pages/integrations/integration/custom/heygen/edit-video/heygen-edit-video.page';

@Injectable({
  providedIn: 'root'
})
export class HeygenService {

  constructor(
    private AppCMS: AppcmsService,
    private modalService: ModalService,
  ) {

  }

  addCaptionToVideo(videoId: number, options: any = {}) {
    return this.AppCMS.loadPluginData('heygen', {
      video: videoId,
      options: options,
    }, ['videos', 'add_caption']);
  }

  createAvatarVideo(item: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('heygen', Object.assign(options, {
      item: item,
    }), ['talking_photos', 'create']);
  }
  
  createFaceSwap(item: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('heygen', Object.assign(options, {
      item: item,
    }), ['face_swap', 'create']);
  }

  createVideo(item: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('heygen', Object.assign(options, {
      item: item,
    }), ['videos', 'create']);
  }

  createWebmVideo(item: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('heygen', Object.assign(options, {
      item: item,
    }), ['videos', 'create_webm']);
  }

  deleteVideo(videoId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', {}, ['videos', videoId, 'delete'], {}, blForceRefresh);
  }

  editVideo(item: any, options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const modal: any = await this.modalService.create({
        component: HeygenEditVideoPage,
        componentProps: Object.assign(options, {
          heygen: this,
          media: item,
        }),
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });
      
      modal.onWillDismiss().then((response: any) => {
        resolve(response);
      });

      this.modalService.present(modal);
    });
  }

  generateFromTemplate(templateId: number, options: any = {}) {
    return this.AppCMS.loadPluginData('heygen', {
      template: templateId,
      options: options,
    }, ['videos', 'create']);
  }

  getAvatars(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', options, ['avatars'], {}, blForceRefresh);
  }

  getCaptionVideo(videoId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', {}, ['caption_videos', videoId], {}, blForceRefresh);
  
  }
  
  getConnections(options: any = {}, blForceRefresh: boolean = false): Promise<any> {
    return this.AppCMS.loadPluginData('heygen', options, ['connections'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', options, ['settings'], {}, blForceRefresh);
  }

  getTemplate(templateId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', {}, ['templates', templateId], {}, blForceRefresh);
  }

  getTemplates(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', options, ['templates'], {}, blForceRefresh);
  }

  getVideo(videoId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', {}, ['videos', videoId], {}, blForceRefresh);
  }

  getVoices(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', options, ['voices'], {}, blForceRefresh);
  }

  transcribe(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', options, ['transcribe'], {}, blForceRefresh);
  }

  translate(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('heygen', options, ['translate'], {}, blForceRefresh);
  }

  uploadAsset(item: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('heygen', Object.assign(options, {
      item: item,
    }), ['assets', 'upload']);
  }

  uploadFace(item: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('heygen', Object.assign(options, {
      item: item,
    }), ['face_swap', 'upload']);
  }

  uploadTalkingPhoto(item: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('heygen', Object.assign(options, {
      item: item,
    }), ['talking_photos', 'upload']);
  }

}