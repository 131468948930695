import { Component, OnInit } from '@angular/core';

import { AiKnowledgebaseService } from 'src/app/services/ai/ai-knowledgebase.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-ai-knowledgebase-entry',
  standalone: false,
  templateUrl: './ai-knowledgebase-entry.page.html',
  styleUrls: ['./ai-knowledgebase-entry.page.scss'],
})
export class AiKnowledgebaseEntryPage implements OnInit {

  contexts: aiContext[] = [];

  daniConfig: daniConfig = {
    chat: [
      {
        mode: 'view',
        input: `getgenius_ai_knowledgebase_test_effects_intro_message`,
        role: 'assistant',
      },
    ],
    hasChat: true,
    mini: true,
    uiMode: 'widget',
    userCanWrite: true, // false
    zoom: (window.outerWidth > 768 ? 0.4 : 0.25),
  };

  entry: any = {};

  state: state = {};

  view: any = {
    dani: {
      state: {
        inConversation: true,
        speaking: false,
      },
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'ai_knowledgebase_entry_admin_top_card',
      text: 'ai_knowledgebase_entry_admin_top_card_text',
      title: 'ai_knowledgebase_entry_admin_top_card_title',
    },
    route: 'ai_knowledgebase_entry',
    title: 'ai_knowledgebase_entry',
  };

  constructor(
    private aiTools: AiToolsService,
    private events: EventsService,
    private knowledgeBase: AiKnowledgebaseService,
    private modalService: ModalService,
    private viewService: ViewService,
  ) {
    this.contexts = this.aiTools.getContexts();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.title = (!!this.entry && this.entry.uid ? 'edit_ai_knowledgebase_entry' : 'create_ai_knowledgebase_entry');
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ngOnInit() {

    if (!!this.entry && !this.entry.contexts) {
      this.entry.contexts = [this.contexts[0].uid];
    }

    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  async optimizePrompt(key: string = 'input', event: any = null) {

    if (!this.entry || !this.entry[key]) {
      return false;
    }

    this.view.loadingInput = true;

    try {

      const optimize: any = await this.aiTools.optimizePrompt(this.entry[key], {
        history: [
          {
            role: 'system',
            content: `Optimize / rewrite only the following AI knowledgebase entry and return the improved / summarized prompt (plain text string, no HTML) only.`,
          }
        ]
      });

      if (!!optimize && !!optimize.output) {
        this.entry[key] = `${optimize.output || ''}`;
      }

      this.view.loadingInput = false;
    } catch (e) {
      this.view.loadingInput = false;
      this.events.publish('error', e);
    }
  }

  save() {
    this.knowledgeBase.save(this.entry)
      .then(() => {
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  update() {
    this.knowledgeBase.update(this.entry)
      .then(() => {
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}