import { Injectable } from "@angular/core";

import { ModalService } from 'src/app/services/core/modal.service';
import { ParamsService } from 'src/app/services/core/params.service';

import { AiVideoCreatorPage } from 'src/app/pages/core/ai/ai-video-creator/ai-video-creator.page';
import { CreateMediaPage } from 'src/app/pages/core/media/media/create-media/create-media.page';

@Injectable({
    providedIn: "root",
})
export class MediaActionsService {

    constructor(
        private modalService: ModalService,
        private paramsService: ParamsService,
    ) {

    }


    async send_to_media_creator(mediaItem: mediaItem, mediaList: mediaItem[] | null = null) {

        if (!mediaItem.uid) {
            return false;
        }

        const view: any = {
            mediaList: (mediaList || [mediaItem]),
            types: [
                {
                    checked: true,
                    icon: 'image-outline',
                    uid: 'image',
                    name: 'image',
                },
                {
                    checked: true,
                    icon: 'film-outline',
                    uid: 'video',
                    name: 'video',
                }
            ],
        };

        // apply UI view data based on request
        this.paramsService.set('viewData_createMedia', {
            fire: false,
            search: {},
            view: view,
        });

        const modal: any = await this.modalService.create({
            component: CreateMediaPage,
            componentProps: {

            },
            animated: true,
            presentingElement: await this.modalService.getTop(),
            cssClass: 'defaultModal',
        });

        modal.onWillDismiss().then((data: any) => {
            console.warn('create_media from dani: dismissed', data);
        });

        this.modalService.present(modal);
    }

    async send_to_video_creator(mediaItem: mediaItem, mediaList: mediaItem[] | null = null) {

        if (!mediaItem.uid) {
            return false;
        }

        const view: any = {
            mediaList: (mediaList || [mediaItem]),
        };

        // apply UI view data based on request
        this.paramsService.set('viewData_createVideo', {
            fire: false,
            search: {},
            view: view,
        });

        const modal: any = await this.modalService.create({
            component: AiVideoCreatorPage,
            componentProps: {

            },
            animated: true,
            presentingElement: await this.modalService.getTop(),
            cssClass: 'defaultModal',
        });

        modal.onWillDismiss().then((data: any) => {
            console.warn('create_video from dani: dismissed', data);
        });

        this.modalService.present(modal);
    }

}