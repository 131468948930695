import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

// Social services
import { DiscordService } from 'src/app/services/integrations/discord.service';
import { FacebookService } from 'src/app/services/integrations/facebook.service';
import { InstagramService } from 'src/app/services/integrations/instagram.service';
import { LinkedinService } from 'src/app/services/integrations/linkedin.service';
import { MetaService } from 'src/app/services/integrations/meta.service';
import { PinterestService } from 'src/app/services/integrations/pinterest.service';
import { RedditService } from 'src/app/services/integrations/reddit.service';
import { SnapchatService } from 'src/app/services/integrations/snapchat.service';
import { ThreadsService } from 'src/app/services/integrations/threads.service';
import { TiktokService } from 'src/app/services/integrations/tiktok.service';
import { TwitchService } from 'src/app/services/integrations/twitch.service';
import { TwitterService } from 'src/app/services/integrations/twitter.service';
import { WhatsappService } from 'src/app/services/integrations/whatsapp.service';
import { XingService } from 'src/app/services/integrations/xing.service';
import { YoutubeService } from 'src/app/services/integrations/youtube.service';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  key: string = 'social_information';

  constructor(

    // Generic services
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private loading: LoadingController,
    private translations: TranslationService,
    private userService: UserService,

    // Integration services
    public discord: DiscordService,
    public facebook: FacebookService,
    public instagram: InstagramService,
    public linkedin: LinkedinService,
    public meta: MetaService,
    public pinterest: PinterestService,
    public reddit: RedditService,
    public snapchat: SnapchatService,
    public threads: ThreadsService,
    public tiktok: TiktokService,
    public twitch: TwitchService,
    public twitter: TwitterService,
    public whatsapp: WhatsappService,
    public x: TwitterService,
    public xing: XingService,
    public youtube: YoutubeService,
  ) {

  }

  addSocial(network: any) {
    return new Promise((resolve, reject) => {
      let userId = this.userService.getUid();

      if (!userId) {
        reject('error_missing_user_uid');
      }

      let item = {
        type: this.key,
        indent: 'social_' + userId + '_network_' + network.value,
        parent: userId,
        value: network,
      };

      this.AppCMS.loadPluginData('pipeline', {
        item: item
      }, ['users', userId, 'social', 'add'])
        .then((response: any) => {
          this.cache.remove('social_information_' + userId);
          resolve(response);
        }).catch(reject);
    });
  }


  addSourcesToConnection(connectionId: number, sourceUids: number[]) {
    return this.AppCMS.loadPluginData('socialprofiles', {
      source_uids: sourceUids,
    }, ['connections', connectionId, 'add_sources']);
  }

  createConnection(connection: integrationConnection) {
    return this.AppCMS.loadPluginData('socialprofiles', {
      connection: connection,
    }, ['connections', 'create']);
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('socialprofiles', {}, ['connections', connectionId, 'delete']);
  }

  get(profileId: number | null = null, blForceRefresh: boolean = false, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      profileId = (profileId || this.userService.getUid());

      if (!profileId) {
        reject('error_missing_profileId');
      } else {
        this.AppCMS.loadPluginData('pipeline', options, ['users', profileId, 'social'], {}, blForceRefresh)
          .then((socialItems: any) => {
            resolve(socialItems);
          })
          .catch(reject);
      }
    });
  }

  getConnectionByUid(connectionId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('socialprofiles', {}, ['connections', connectionId], {}, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('socialprofiles', options, ['connections'], {}, blForceRefresh)
        .then((connections: any[]) => {

          connections = (connections || []).map((connection: integrationConnection) => {
            connection.platform = (connection.platform || options.platform);
          });

          resolve(connections);
        })
        .catch(reject);
    });
  }

  getService(serviceName: string) {
    return (!!this[serviceName] ? this[serviceName] : null);
  }

  remove(network: any) {
    return new Promise(async (resolve, reject) => {
      let userId = this.userService.getUid();
      const loading: any = await this.loading.create({
        spinner: 'circular',
      });
      loading.present();

      if (!userId) {
        loading.dismiss();
        reject('error_missing_user_uid');
      }

      let item = {
        type: this.key,
        indent: 'social_' + userId + '_network_' + network.value,
        parent: userId,
      };

      this.AppCMS.loadPluginData('pipeline', {
        item: item
      }, ['users', userId, 'social', 'delete'])
        .then((response) => {
          this.cache.remove('social_information_' + userId);
          loading.dismiss();
          resolve(response);
        }).catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  removeSourceFromConnection(connectionId: number, sourceId: number) {
    return this.AppCMS.loadPluginData('socialprofiles', {
      source_uid: sourceId,
    }, ['connections', connectionId, 'remove_source']);
  }

  async requestInputData(network: any) {
    return new Promise((resolve, reject) => {
      let placeholder: string, prefix: string = '', headline: string = ('add_social_information_' + network.value);

      switch (network.value) {
        case 'email':
          placeholder = 'enter_email_address';
          break;
        case 'instagram':
          placeholder = 'enter_username';
          prefix = 'https://instagr.am/';
          break;
        default:
          placeholder = 'enter_profile_url';
          break;
      }

      this.translations.get([
        'cancel',
        headline,
        'okay',
        placeholder,
      ])
        .subscribe(async (translations: any) => {

          const alert: any = await this.alertCtrl.create({
            header: translations[headline] || headline,
            inputs: [
              {
                type: 'text',
                name: 'url',
                placeholder: translations[placeholder] || placeholder,
              }
            ],
            buttons: [
              {
                text: translations.okay || 'Okay',
                handler: (data: any) => {
                  if (data) {
                    network = Object.assign(network, data);
                  }
                  network.url = prefix && network.url ? prefix + network.url : network.url;
                  if (!network.url || !network.url.length) {
                    reject('error_missing_url');
                  } else {
                    this.addSocial(network).then(resolve).catch(reject);
                  }
                }
              },
              {
                text: translations.cancel || 'Cancel',
                role: 'cancel',
              }
            ],
          });
          alert.onWillDismiss().then((response: any) => {
            if (response.data.values) {
              network = Object.assign(network, response.data.values);
            }
          });
          await alert.present();

        });
    });
  }

  showAddSocial(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      this.translations.get([
        'add_social_information',
        'email',
        'facebook',
        'instagram',
        'pinterest',
        'youtube',
      ])
        .subscribe(async (translations: any) => {

          let buttons = [], networks = [
            {
              name: translations.email || 'E-Mail',
              value: 'email'
            },
            {
              name: translations.facebook || 'Facebook',
              value: 'facebook'
            },
            {
              name: translations.instagram || 'Instagram',
              value: 'instagram'
            },
            {
              name: translations.pinterest || 'Pinterest',
              value: 'pinterest'
            },
            {
              name: translations.youtube || 'YouTube',
              value: 'youtube'
            },
          ];

          networks.forEach((network: any) => {
            let icon = 'logo-' + network.value;
            switch (network.value) {
              case 'email': icon = 'mail-outline'; break;
            }
            network.icon = icon;

            buttons.push({
              text: network.name,
              value: network.value,
              icon: icon,
              handler: () => {
                this.requestInputData(network).then(resolve).catch(reject);
              },
            });

          });

          let actionSheet = await this.actionSheetCtrl.create({
            header: translations.add_social_information || 'add_social_information',
            cssClass: 'left-align-buttons',
            buttons: buttons,
          });

          actionSheet.onWillDismiss().then((data: any) => {
            resolve(data);
          });

          await actionSheet.present();

        });
    });
  }

  updateConnection(connection: integrationConnection) {
    connection = JSON.parse(JSON.stringify(connection));

    delete connection.checked;

    return this.AppCMS.loadPluginData('socialprofiles', {
      connection: connection,
    }, ['connections', 'update']);
  }

  validateConnection(connection: integrationConnection) {
    return new Promise((resolve, reject) => {

    });
  }

}