<ion-header class="ion-no-border">

  <ion-toolbar class="container">

    <ion-buttons slot="start">
      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button (click)="submit()" color="primary" fill="solid" shape="round">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'submit'|translate"></ion-label>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">
    
    <ion-grid>

      <ion-row>

        <ion-col>

          <div class="ion-padding" [hidden]="view.introCard.hidden">
            <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
          </div>
          
          <ion-card>
            <ion-card-content>

            </ion-card-content>
          </ion-card>

        </ion-col>

        <ion-col>
          
          <heygen-audio-transcribe *ngIf="view.action === 'audio_transcribe'"></heygen-audio-transcribe>

          <heygen-audio-translate *ngIf="view.action === 'audio_translate'"></heygen-audio-translate>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>