import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { UserService } from 'src/app/services/core/user.service';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class TiktokService {

  apiUrl: string = 'https://open-api.tiktok.com/';

  constructor(
    private AppCMS: AppcmsService,
    private integrations: IntegrationsService,
    private oauth: OauthService,
    private userService: UserService,
  ) {

  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("tiktok", options, ['analyse']);
  }

  authorize() {
    const accessTokenRoute: string = this.AppCMS.getRequestUrl('tiktok', ['authorized']),
      //authorizationBaseUrl: string = this.AppCMS.getRequestUrl('tiktok', ['authorize']),
      resourceUrl: string = this.AppCMS.getRequestUrl('tiktok', ['profile']),
      state: string = this.calcState();

    return this.oauth.authorize({
      authorizationBaseUrl: 'https://www.tiktok.com/v2/auth/authorize/', //authorizationBaseUrl
      accessTokenEndpoint: accessTokenRoute,

      default: {
        clientId: 'awidmwnrbokm8ujp',
        pkceEnable: true,
        redirectUrl: `${window.location.origin}/integrations/integration/tiktok/connections`,
        responseType: 'code',
        additionalParameters: {
          client_key: 'awidmwnrbokm8ujp',
          code_challenge: this.calcCodeVerifier(state),
          code_challenge_method: 'S256',
        },
      },
      resourceUrl: resourceUrl,

      scope: 'user.info.basic,user.info.stats,video.list,video.publish', // removed: user.info.profile
      // added video.list,video.publish

      state: state,
    });
  }

  calcCodeVerifier(code_verifier: string) {
    return CryptoJS.SHA256(code_verifier).toString(CryptoJS.enc.Hex);
  }

  calcState() {
    return Math.random().toString(36).substring(2, 12);
  }

  connect(options: any = {}) {
    return new Promise((resolve, reject) => {

      // first, authorize application
      this.authorize().then((authResponse: any) => {
        console.log('authResponse', authResponse);

        // then, run connect process
        this.integrations.connect(
          Object.assign(options, authResponse)
        ).then((chooseResponse: chooseResponse) => {

          // if connects selected, add them
          if (!!chooseResponse && !!chooseResponse.data && !!chooseResponse.data.items && !!chooseResponse.data.items.length) {
            chooseResponse.data.items.forEach(async (item: integrationConnection) => {
              try {
                let token: string = (item.page_token || '');

                if (!!authResponse && !!authResponse.authorization_response && !!authResponse.authorization_response.long_lived_token) {
                  token = authResponse.authorization_response.long_lived_token;
                } else
                  if (!!authResponse && (authResponse.access_token || (!!authResponse.access_token_response && !!authResponse.access_token_response.access_token))) {
                    token = token || (authResponse.access_token || authResponse.access_token_response.access_token);
                  }

                let refreshToken: string = (`${(item.refresh_token || authResponse.refresh_token) || ''}` || '');

                if (!!authResponse && !!authResponse.access_token_response && !!authResponse.access_token_response.refresh_token) {
                  refreshToken = authResponse.access_token_response.refresh_token;
                }

                const create: any = await this.createConnection({
                  active: true,
                  name: `${item.name || ''}`,
                  page_id: `${item.uid}`,
                  photo: `${item.photo || ''}`,
                  url: `${item.url || ''}`,
                  user: this.userService.getUid(),

                  // tokens:
                  page_token: `${item.page_token || token}`,
                  refresh_token: (refreshToken || item.refresh_token),
                  user_token: `${item.user_token || ''}`,
                });

              } catch (e) {
                console.warn('adding connection failed', e);
              }
            });
          }

          resolve(chooseResponse);
        }).catch(reject);
      }).catch(reject);
    });
  }

  createConnection(connection: integrationConnection) {
    
    delete connection.platform;

    return this.AppCMS.loadPluginData('tiktok', {
      connection: connection,
    }, ['connections', 'create']);
  }

  debugToken(token: string) {
    return this.AppCMS.loadPluginData('tiktok', {
      token: token,
    }, ['debug_token']);
  }

  delete(connectionId: number) {
    return this.deleteConnection(connectionId);
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('tiktok', {
      uid: connectionId,
    }, ['connections', 'delete']);
  }

  deletePost(postId: number, platform: string) {
    return this.AppCMS.loadPluginData('tiktok', {
      platform: platform,
      uid: postId,
    }, ['posts', 'delete']);
  }

  async execute(route: string, data: any = {}) {
    let accessToken: any = await this.getAccessToken();
    let url = `${this.getApiUrl()}${route}?access_token=${accessToken}`;

    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response: any) => response.json())
        .then((response: any) => {
          resolve(response);
        })
        .catch(reject);
    });
  }

  getAccessToken() {
    return this.execute(`/oauth/access_token`);
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('tiktok', options, ['log'], {}, blForceRefresh);
  }

  getApiUrl() {
    return this.apiUrl;
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('tiktok', options, ['connections'], {}, blForceRefresh)
        .then((connections: any[]) => {

          connections = (connections || []).map((connection: integrationConnection) => {
            connection.platform = (connection.platform || 'tiktok');
            return connection;
          });

          resolve(connections);
        })
        .catch(reject);
    });
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('tiktok', options, ['settings'], {}, blForceRefresh);
  }

  importMedia(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("tiktok", options, ['import_media']);
  }

  importPosts(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("tiktok", options, ['import_posts']);
  }

  updateConnection(connection: integrationConnection) {
    connection = JSON.parse(JSON.stringify(connection));

    delete connection.checked;
    delete connection.platform;

    return this.AppCMS.loadPluginData('tiktok', {
      connection: connection,
    }, ['connections', 'update']);
  }

  uploadVideo(video: mediaItem) {
    return this.execute(`/share/video/upload/`, {
      video: video,
    });
  }

}