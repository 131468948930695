import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';

import { ClipboardService } from "src/app/services/utils/clipboard.service";
import { CrudService } from 'src/app/services/core/crud.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ExternalService } from 'src/app/services/extensions/external.service';
import { NewslettersService } from "src/app/services/newsletters/newsletters.service";
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';
import { ReactionsService } from 'src/app/services/social/reactions.service';
import { UserService } from 'src/app/services/core/user.service';

import { CardOptionsPage } from 'src/app/pages/core/card-options/card-options.page';

import { SelectionOptionsPickerComponent } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component';

@Component({
  selector: 'pipeline-post-card',
  standalone: false,
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
})
export class PostCardComponent implements OnInit {
  @Input() index: number = 0;
  @Input() options: postCardOptions = {};
  @Input() post: post;
  @Input() posts: post[];
  @Input() selectionOptions: selectionOption[];
  @Input() size: number;
  @Input() view: any = {};

  @Output() onPostItemChecked = new EventEmitter();
  @Output() onShouldRefresh = new EventEmitter();
  @Output() onShouldRunSearch = new EventEmitter();

  @ViewChild(SelectionOptionsPickerComponent) selectionOptionsPicker: any;

  @ViewChild('sharePostPopover') sharePostPopover;

  fallbackImg: string = './assets/img/fallback.webp';

  isSharePostPopoverOpen: boolean = false;

  constructor(
    private clipboard: ClipboardService,
    private crud: CrudService,
    private events: EventsService,
    private external: ExternalService,
    private navCtrl: NavController,
    private newsletters: NewslettersService,
    private popoverCtrl: PopoverController,
    private postsAdmin: PostsAdminService,
    private reactions: ReactionsService,
    private userService: UserService,
  ) {
    this.view.emojis = this.reactions.getEmojis();
  }

  accept() {
    this.postsAdmin.accept(this.post as post)
      .then((response: any) => {
        this.events.publish('toast', {
          message: 'post_accepted',
          color: 'primary',
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async cardOptions(col: any, event: any | null = null) {
    event.preventDefault();

    const optionsPopover = await this.popoverCtrl.create({
      animated: true,
      component: CardOptionsPage,
      componentProps: {
        col: col,
        event: event,
      },
      cssClass: 'cardOptionsPopover',
      event: event,
      translucent: true
    });

    await optionsPopover.present();

    return false;
  }

  copy(text: string | number) {
    this.clipboard.copyText(`${text}`);
  }

  async delete(item: any) {
    switch (this.view.type) {
      case 'blog':
        return this.deletePost(item as post);
      case 'newsletter':
        return this.deleteNewsletter(item as newsletter);
      case 'social':
        return this.deletePost(item as post);
      default:
        return this.deletePost(item as post);
    }
  }

  deleteCategory(category: category) {

  }

  deleteNewsletter(newsletter: newsletter) {
    this.newsletters.delete(newsletter.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deletePost(post: post) {

    if (!this.view.canDelete) {
      this.events.publish('error', 'error_permission_user_group_not_allowed');
      return false;
    }

    this.postsAdmin.delete(post.uid)
      .then(() => {

        this.view.posts = [...this.view.posts.filter((_post: post) => {
          return _post.uid !== post.uid;
        })];

        this.view.posts_backup = [...this.view.posts];
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  doRefresh() {
    this.onShouldRefresh.emit(true);
  }

  async edit(item: any, index: number = 0) {

    if (!item || !item.uid) {
      return false;
    }

    switch (this.view.type) {
      case 'blog':
        return this.editPost();
      case 'newsletter':
        return this.editNewsletter(item as newsletter, index);
      case 'social':
        return this.editPost();
      default:
        return this.editPost();
    }
  }

  async editNewsletter(newsletter: newsletter, index: number = 0) {
    this.newsletters.detailItem(newsletter);

    this.crud.edit('newsletter', newsletter, {
      index: index,
      posts: this.view.posts,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        console.warn('editing post failed', error);
      });
  }

  async editPost() {

    if (!this.userService.isType(['Admin', 'Moderator'])) {
      this.navCtrl.navigateForward('/create-contents-banner');
      return false;
    }

    this.postsAdmin.detailItem(this.post as post);

    this.crud.edit('post', this.post, {
      index: (this.index || 0),
      posts: (this.posts || []),
    });
  }

  ngOnInit() {
  }

  onCaptionChanged(caption: string | null = null) {
    console.log('onCaptionChanged', caption);
  }

  onColClick(col: any, event: any | null = null) {

    if (event && event.target && event.target.classList.contains('optionsButton')) {
      event.preventDefault();
      return false;
    }

    switch (col.type) {
      case "person":
        return this.onPersonClick(col);
      case "post":
        return this.onPostClick(col as post);
      case "product":
        return this.onProductClick(col);
      case "review":
        return this.onPostClick(col as post);
      case "simple":
        return this.onProductClick(col);
      case 'variable':
        return this.onProductClick(col);
      default:
        this.events.publish("error", "Unbekannter Typ " + col.type);
        break;
    }
  }

  async onPersonClick(user: user) {
    this.events.publish('view:profile', user);
  }

  async onPostClick(post: post) {
    this.events.publish('view:post', post);
  }

  _onPostItemChecked(post: post) {
    this.onPostItemChecked.emit(post);
  }

  async onProductClick(product: any) {
    this.events.publish('view:product', product);
  }

  openProfile(col: any) {
  }

  presentPopover(e: Event, item: any) {
    this.selectionOptionsPicker.show({
      event: e,
      item: item,
    });
  }

  promote(post: post) {

    if (!this.view.canPromote) {
      this.events.publish('error_permission_user_group_not_allowed');
      return false;
    }

  }

  publishPost(post: post) {

    if (!this.view.canPublish) {
      this.events.publish('error_permission_user_group_not_allowed');
      return false;
    }

    this.postsAdmin.accept(post)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  reject() {
    this.external.reject(this.post as post)
      .then(() => {
        this.events.publish('toast', {
          message: 'post_rejected',
          color: 'primary',
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async runItemSelectionOption(event: any) {
    console.log('post-card: runItemSelectionOption', event);

    try {

      if (!event || !event.option || !event.option.uid) {
        return false;
      }

      if (typeof this[event.option.uid] === 'function') {
        const exec: any = await this[event.option.uid](event.item);
      }

      this.selectionOptionsPicker.close();
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  runSearch() {
    this.onShouldRunSearch.emit(true);
  }

  share(post: post, event: any | null = null) {
    this.view.sharePost = post;

    this.sharePostPopover.event = event;
    this.isSharePostPopoverOpen = true;
  }

  submitForReview(post: post) {

    if (!this.view.canSubmitForReview) {
      this.events.publish('error_permission_user_group_not_allowed');
      return false;
    }

    this.postsAdmin.submitForReview(post)
      .then(() => {
        this.doRefresh();

        this.events.publish('toast', {
          message: 'post_submitted_to_review',
          color: 'primary',
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  thumbnailLoadingFailed(col: any) {
    col.photo = this.fallbackImg;
    //col.hidden = true;
  }

  async verifiedInfo() {
  }

}