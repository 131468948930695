import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { ClipboardService } from 'src/app/services/utils/clipboard.service';
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { EventsService } from "src/app/services/core/events.service";
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { SharingService } from 'src/app/services/sharing/sharing.service';
import { SharingAdminService } from 'src/app/services/sharing/sharing-admin.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Component({
  selector: 'pipeline-caption-generator',
  standalone: false,
  templateUrl: './caption-generator.component.html',
  styleUrls: ['./caption-generator.component.scss']
})
export class CaptionGeneratorComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() config: any;
  @Input() disabled: boolean;
  @Input() media: mediaItem;
  @Input() options: any = {};
  @Input() post: post;
  @Input() view: any;

  @Output() captionChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  integrationsByType: any;

  item: any;

  constructor(
    private ai: AiToolsService,
    private clipboard: ClipboardService,
    private dani: DaniService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private mediaService: MediaextendService,
    private sharing: SharingService,
    private sharingAdmin: SharingAdminService,
    private sidebar: SidebarService,
    private tools: ToolsService,
  ) {
    this.integrationsByType = this.integrations.getByType();
  }

  captionPlatformPhotoLoadingFailed() {
    this.view.captionPlatform.photo = null;
  }

  chooseConnections() {
    this.sharing.pick({
      multiple: true,
    })
    .then((response: any) => {
      this.view.captionPlatforms = (!!response && !!response.data ? response.data.items || [] : []);
      console.log('this.view.captionPlatforms', this.view.captionPlatforms);

      if(!!this.view.captionPlatforms[0]) {
        this.view.captionPlatforms[0].checked = false;
        this.togglePlatform(this.view.captionPlatforms[0], 0);
      }
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  configurePlatform(platform: any) {
    platform.configureMode = !platform.configureMode;
  }

  copyToClipboard(string: string) {
    this.clipboard.copyText(`${string}`);
  }

  generateCreatives(connection: integrationConnection) {
    console.log('generateCreatives', connection);

    this.dani.create_media({
      input: `${connection.caption || ''}`,
      mediaList: (!!this.view && !!this.view.mediaList ? this.view.mediaList : []),
    })
      .then((response: any) => {
        console.log('creatives generation response', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  importCreatives(connection: integrationConnection, params: any = {}) {
    console.log('importCreatives', connection);

    params.mediaType = 'creative';
    params.multiple = true;
    params.services = ["ai", "upload", "media_library"];

    if (!!this.view.folder_uid) {
      params.folder_uid = this.view.folder_uid;
    }

    this.mediaService.applyFromWeb(null, params)
      .then((response: any) => {
        console.log('pick response', response);
        connection.creatives = [];

        // apply creatives
        if(!!response && !!response.data && !!response.data.items) {
          connection.creatives = response.data.items;
        }

      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  loadCaptionByPlatform(platform: any) {
    return new Promise((resolve, reject) => {

      if (!platform || !platform.uid) {
        reject('caption platform missing');
        return false;
      }

      let input: string | null = (!!this.media ? (this.media as any).post_excerpt : (!!this.post ? (this.post as any).post_content : null));
      input = `${(input || platform.caption) || ''}`;

      if (!input || !input.length && (input != 'undefined')) {
        reject('no caption input');
        return false;
      }

      platform.blGeneratingCaption = true;

      let captionParams: any = {
        input: input,
        language: (this.item.language || ''),
        platform: platform.name,
      };

      if(!!this.post && !!this.post.uid) {
        captionParams.post_uid = this.post.uid;
      }

      console.log('captionParams', captionParams);

      this.ai.caption(captionParams, true)
        .then((response: any) => {
          if (!!response && !!response.output) {

            // apply to item
            if (!!this.item) {
              platform.caption = `${response.output}`;
            }

            // apply to original object
            if (!!this.media) {
              this.media.post_excerpt = `${response.output}`;
            } else
              if (!!this.post) {
                this.post.post_excerpt = `${response.output}`;
              }

            // apply to variations object
            this.view.variationsByPlatform = this.view.variationsByPlatform || {};
            this.view.variationsByPlatform[platform.uid] = platform.caption;
          }

          platform.blGeneratingCaption = false;
          platform.captionByPlatformCalculated = true;

          this.captionChanged.emit(`${response.output || ''}`);

          resolve(platform);
        })
        .catch((error: any) => {
          platform.blGeneratingCaption = false;
          platform.captionByPlatformCalculated = false;

          reject(error);
        });
    });
  }

  loadCaptionsByPlatforms() {

    if (!this.view.captionPlatforms || !this.view.captionPlatforms.length) {
      return false;
    }

    this.view.captionPlatforms.forEach(async (platform: any) => {
      try {
        platform.creatives = [{loading: true}, {loading: true}, {loading: true}];

        this.generateCreatives({
          platform: platform,
        });

        platform.response = await this.loadCaptionByPlatform(platform);
        console.log('platform caption response', platform.response);
      } catch (e) {
        console.log('generating platform caption failed', e);
      }
    });

  }

  loadConfigurationOptions() {
    if (!this.view.hasOwnProperty('confOptions')) {
      this.view.confOptions = [
        {
          icon: 'open-outline',
          label: 'use_cta',
          type: 'checkbox',
          uid: 'use_cta',
        },
        {
          icon: 'link-outline',
          label: 'cta_link',
          placeholder: 'cta_link_placeholder',
          type: 'input',
          uid: 'cta_link',
        },
        {
          icon: 'text-outline',
          label: 'cta_text',
          placeholder: 'cta_text_placeholder',
          type: 'input',
          uid: 'cta_text',
        }
      ];
    }
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.item = (!!this.media ? this.media : (!!this.post ? this.post : null));
    this.view.captionItemType = (!!this.media ? 'media' : (!!this.post ? 'post' : null));

    this.loadConfigurationOptions();
    //this.loadCaptionsByPlatforms();
  }

  _onOptionChange(option, event: any|null = null) {

  }

  recreate(platform: any) {
    return this.loadCaptionByPlatform(platform);
  }

  async shareToPlatform(connection: sharingConnection) {

    if (!connection) {
      return false;
    }

    const post: any = {
      name: (this.item.post_title || this.item.name),
      photo: ((this.item.photo || this.item.thumbnail) || this.item.guid),
      post_content: `${this.item.post_content || ''}`,
      post_excerpt: `${this.item.post_excerpt || ''}`,
      url: (this.item.url || this.item.guid),
    };

    try {
      const methodName: string = `shareTo${this.tools.capitalize(connection.platform)}`;
      console.log('methodName', methodName);

      const shareExec: any = await this.sharingAdmin[methodName](post, connection);
      console.log('shareExec', shareExec);

      // if response has message, alert it
      if(!!shareExec && (shareExec.error || shareExec.message)) {
        this.events.publish('error', shareExec.error || shareExec.message);
      }

    } catch (error) {
      console.warn('sharing to platform failed', error);

      // error handling
      switch (error) {
        case 'error_integration_not_implemented':
          console.warn('not configured! show alert');
          break;
        case 'error_missing_item':
          break;
        default:
          this.events.publish('error', error);
      }

    }
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.sharing = this.cards.sharing || {};
    this.cards.sharing.open = !this.cards.sharing.open;

    this.sidebar.setCards(this.cards);
  }

  togglePlatform(platform: any, i: number) {

    if (!!platform.checked) {
      platform.checked = false;
      return false;
    }

    this.view.captionPlatforms.forEach((_platform: any, _i: number) => {
      _platform.checked = (i === _i);

      if(!!_platform.checked && !_platform.captionByPlatformCalculated) {
        this.loadCaptionByPlatform(_platform);
      }
    });

  }

}