import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class AttributesService {

  constructor(
    private AppCMS: AppcmsService,
  ) {
  }

  addToItem(attribute: attribute, itemId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        attribute: attribute,
        itemId: itemId,
      },
      ['attributes', 'add']
    );
  }

  get(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ['attributes', 'list'],
      params,
      blForceRefresh
    );
  }

  getAttributes(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.get(options, blForceRefresh, params);
  }

  removeFromItem(attribute: attribute, itemId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        attribute: attribute,
        itemId: itemId,
      },
      ['attributes', 'remove']
    );
  }

}