import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';

@Component({
  selector: 'heygen-audio-translate',
  standalone: false,
  templateUrl: './audio-translate.component.html',
  styleUrls: ['./audio-translate.component.scss'],
})
export class AudioTranslateComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() media: mediaItem[];

  fallbackImg: string = './assets/img/fallback.webp';

  languageOptions: languagesSelectOptions = {
    cached: false,
    default: null,
    mode: 'request',
  };

  view: any = {

  };

  constructor(
    private events: EventsService,
  ) {

  }

  create() {
  }

  doRefresh() {
  }

  initEvents() {
    this.view.events = {};
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initEvents();
  }

  onLanguageChange(event: any|null = null) {

  }

  thumbnailLoadingFailed(item: any) {
    item.thumbnail = this.fallbackImg;
  }

  voiceoverVoiceChanged() {

  }

}