import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { AiAssistantPage } from '../ai-assistant/ai-assistant.page';
import { AiStorePage } from '../ai-store/ai-store.page';

@Component({
  selector: 'app-ai-assistants',
  standalone: false,
  templateUrl: './ai-assistants.page.html',
  styleUrls: ['./ai-assistants.page.scss'],
})
export class AiAssistantsPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  cards: any = {
    folders: { open: true },
  };

  cta: any = {
    handler: () => {
      this.add();
    },
    icon: 'add-outline',
    label: 'create',
  };
  
  fallbackImg: string = './assets/img/fallback.webp';

  search: searchOptions = {
    itemsKey: 'assistants',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    },
    {
      icon: 'stats-chart-outline',
      label: 'create_benchmark',
      uid: 'create_benchmark',
    },
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_idle.splinecode',
    zoom: 0.5,
  };

  state: state = {};

  view: any = {
    categories: [],
    categoryIcons: {},
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    introCard: {
      uid: 'ai_assistants_top_card',
      text: 'ai_assistants_top_card_text',
      title: 'ai_assistants_top_card_title',
    },
    multiple: true,
    output: '',
    providers: [],
    route: 'ai/assistants',
    showLanguagesSelect: true,
    showMenuButton: true,
    showProjectsSelect: true,
    showSplineView: true,
    showViewModeSelect: false,
    title: 'ai_assistants',
    viewType: 'grid',
  };

  constructor(
    public aiTools: AiToolsService,
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private projects: ProjectsService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  async add(event: any | null = null) {

    const modal: any = await this.modalService.create({
      component: AiAssistantPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  delete(assistant: aiAssistant) {
    this.aiTools.deleteAssistant(assistant)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'deleteAssistant',
      items: this.view.selectedItems,
      service: this.aiTools,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  doRefresh(event: any | null = null) {
    this.loadAssistants(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  duplicate(assistant: aiAssistant) {
    this.aiTools.duplicateAssistant(assistant)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  duplicateSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'duplicateAssistant',
      items: this.view.selectedItems,
      service: this.aiTools,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async edit(assistant: aiAssistant) {
    //this.navCtrl.navigateForward(`/ai/assistant/${assistant.uid}/settings`);

    const modal: any = await this.modalService.create({
      component: AiAssistantPage,
      componentProps: {
        assistant: assistant,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  filterProvider(provider) {

  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  ionViewWillEnter() {
    this.initEvents();

    this.loadAssistants()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  loadAssistants(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        resolve([]);
      } else {
        this.view.loading = true;

        this.aiTools.getAssistants({}, blForceRefresh)
          .then((response: any) => {
            this.view.loading = false;

            if (!!response && !!response.data) {
              this.view.assistants = response.data;
              this.view.assistants_backup = response.data;

              let categoriesStrings: string[] = [],
                categories: any[] = [],
                providerStrings: string[] = [],
                providers: any[] = [];

              this.view.assistants.forEach((assistant: any) => {
                //console.log('assistant', assistant);

                if (!!assistant.category && (categoriesStrings.indexOf(assistant.category) === -1)) {
                  categories.push({
                    icon: this.view.categoryIcons[assistant.category],
                    name: assistant.category,
                  });
                  categoriesStrings.push(assistant.category);
                }

                if (!!assistant.provider && (providerStrings.indexOf(assistant.provider)) === -1) {
                  providers.push({
                    name: assistant.provider,
                    photo: assistant.photo,
                  });
                  providerStrings.push(assistant.provider);
                }

              });

              this.view.categories = categories;
              //console.log('categories', categories);

              this.view.providers = providers;
            }

            resolve(this.view);
          })
          .catch((error: any) => {
            this.view.loading = false;
            reject(error);
          });
      }
    });
  }

  loadAssistantsByFolder(event: any | null = null) {
    if (!event) {
      this.doRefresh();
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();
  }

  onFolderLoaded(items: any[] | null = null) {
    console.log('onFolderLoaded', items);
  }

  onItemChecked(assistant: aiAssistant) {

    this.view.selectedItems = this.view.assistants.filter((_assistant: aiAssistant) => {
      return _assistant.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
    }
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  async store() {

    const modal: any = await this.modalService.create({
      component: AiStorePage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  use(assistant: aiAssistant) {
    this.navCtrl.navigateForward(`/ai/assistant/${assistant.uid}`);
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}