<ion-card class="sidebar-accordion-card">

    <!-- Header -->
    <ion-card-header>

        <!-- Card title -->
        <ion-card-title [innerHTML]="title|translate"></ion-card-title>

        <!-- Toggle card -->
        <ion-button *ngIf="!!cards && !!cards.tags" class="card-toggle" (click)="toggleCard()" icon-only color="dark"
            fill="clear">
            <ion-icon [name]="!!cards && !!cards.tags && cards.tags.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <!-- Tags list -->
    <ion-list class="tags-list" [hidden]="!!cards && !!cards.tags && !cards.tags.open"
        [class.expanded]="view.expandedList === 'tags'">

        <ion-item button class="item" lines="none" (click)="allTags()" [class.active]="!view.tag">
            <ion-icon [name]="(!view.tag ? 'pricetag-outline' : 'tag-outline')" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap">
                <p [innerHTML]="('all_' + title)|translate" class="ion-text-wrap"></p>
            </ion-label>
        </ion-item>

        <!-- Tags loading -->
        <ion-spinner [hidden]="!view.loading"></ion-spinner>

        <!-- No tags -->
        <pipeline-no-entries-card
            *ngIf="(!view.tags || (view.tags && !view.tags.length)) && !view.loading"></pipeline-no-entries-card>

        <!-- Iterate tags -->
        <div [hidden]="!!view.loading" *ngIf="!!view.tags && !!view.tags.length">

            <ion-item button *ngFor="let tag of view.tags; trackBy:trackItems" (click)="openTag(tag)"
                [drop]="!!view.isDesktop" [dropItem]="tag" dropItemType="tag" (itemDropped)="handleItemDropped($event)"
                class="btn-item ion-text-wrap" [class.active]="tag.checked">

                <ion-thumbnail *ngIf="!!tag.photo" slot="start" class="tag-preview">
                    <ion-img [src]="tag.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(tag)"></ion-img>
                </ion-thumbnail>

                <ion-icon *ngIf="!tag.photo" [name]="tag.icon || (tag.checked ? 'pricetag' : 'pricetag-outline')"
                    slot="start"></ion-icon>

                <ion-label class="ion-text-wrap">
                    <p [innerHTML]="tag.title|translate" class="ion-text-wrap"></p>
                </ion-label>

                <ion-spinner slot="end" [hidden]="!!tag.uid"></ion-spinner>

            </ion-item>

        </div>

    </ion-list>

    <!-- Show more -->
    <ion-fab [hidden]="!cards || !cards.tags || !cards.tags.open">
        <ion-fab-button size="small" (click)="expandList('tags')">
            <ion-icon name="chevron-down"></ion-icon>
        </ion-fab-button>
    </ion-fab>

</ion-card>