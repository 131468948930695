import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

import { IconsService } from "src/app/services/utils/icons.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-icon-picker',
  standalone: false,
  templateUrl: './icon-picker.page.html',
  styleUrls: ['./icon-picker.page.scss'],
})
export class IconPickerPage implements OnInit {
  @ViewChild('searchInput') searchInput: IonSearchbar;

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};
  
  view: any = {
    colSize: 2,
    hideOrderByBtn: true,
    hideSearch: true,
    iconTypes: {
      outline: '-outline',
      filled: '',
      sharp: '-sharp'
    },
    iconType: 'outline',
    title: 'pick_icon',
  };

  user: user;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private iconsService: IconsService,
    private modalService: ModalService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = (this.view.isDesktop ? 1 : 2);
  }
  
  detectChanges() {
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  iconTypeChanged() {
    this.detectChanges();
  }

  ionViewWillEnter() {
  }

  ngOnInit() {
    this.view.iconTypesNames = Object.keys(this.view.iconTypes);
    this.view.ionicons = this.iconsService.getIonicons();

    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  onSearchChanged(searchOptions: any|null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }
  
  runSearch(event: any|null = null) {

    if(!this.view._ionicons) {
      this.view._ionicons = JSON.parse(JSON.stringify(this.view.ionicons));
    }

    if(this.search.query && this.search.query.length) {
      this.view.ionicons = this.view._ionicons.filter((iconName: string) => {
        return (iconName || '' as string).toLowerCase().indexOf((this.search.query || '' as string).toLowerCase()) !== -1;
      });
    } else {
      this.view.ionicons = this.view._ionicons;
    }

    this.detectChanges();
  }

  select(icon: string) {
    this.view.icon = (icon + this.view.iconTypes[this.view.iconType]);
    this.detectChanges();
  }

  submit() {
    this.dismiss({
      icon: this.view.icon
    }, 'done');
  }
  
  toggleSearch() {
    this.search.visible = !this.search.visible;

    if(this.searchInput) {
      this.searchInput.setFocus();
    }

    this.detectChanges();
  }

}
