<ion-toolbar class="template-timeline">
  <div class="divider row" data-target="#template-editor-footer" data-counterpart="#editorContent" data-reverse="true"
    data-var="--template-editor-footer-height"></div>

  <!-- timeline top bar -->
  <ion-grid class="template-timeline-head-grid">

    <ion-row *ngIf="!!ticks && (template.type === 'video')" [style.min-width]="(ticks.length * 150) + 'px'"
      class="template-timeline-head-row">

      <ion-col class="pointer-col" cdkDrag cdkDragBoundary=".template-timeline-head-row"
        [style.transform]="'translate3d(' + (template.config.timeline.current * 150) + 'px,0,0)'">
        <ion-icon class="pointer now" name="location-outline"></ion-icon>
      </ion-col>

      <ion-col class="tick-col" *ngFor="let tick of ticks; let iTick = index; trackBy:trackItems"
        [style.left]="(iTick * 150) + 'px'" (click)="_onTimelineTickColClick(iTick)">
        <span class="i-tick" [innerHTML]="iTick"></span>
      </ion-col>

      <ion-col class="fixed-col end">
        <ion-item>
          <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
          [placeholder]="'length'|translate" [label]="'length'|translate" [(ngModel)]="template.config.timeline.end" slot="end" (ionChange)="_onTimelineEndChanged()">
            <ion-select-option [value]="1" [innerHTML]="'single_frame'|translate"></ion-select-option>
            <ion-select-option [value]="15" [innerHTML]="15"></ion-select-option>
            <ion-select-option [value]="60" [innerHTML]="60"></ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>

    </ion-row>

    <ion-row [hidden]="template.type !== 'video'">
      <ion-col>
        <ion-item>

          <ion-button slot="start" icon-only fill="clear" *ngIf="template.type === 'video' && !!view.running"
            color="primary" (click)="pauseSet()">
            <ion-icon name="pause"></ion-icon>
          </ion-button>

          <ion-button slot="start" icon-only fill="clear" *ngIf="template.type === 'video' && !view.running"
            color="primary" (click)="runSet()">
            <ion-icon name="play"></ion-icon>
          </ion-button>

          <ion-button slot="start" icon-only fill="clear" *ngIf="template.type === 'video'"
            [disabled]="!view.running || (template.config.timeline.current === 0)" color="primary" (click)="stopSet()">
            <ion-icon name="stop"></ion-icon>
          </ion-button>

          <ion-button (click)="addLayer()" fill="outline" color="dark" size="small" slot="start">
            <ion-icon name="add-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'add_layer'|translate"></ion-label>
          </ion-button>

          <ion-button (click)="toggleTimeline()" fill="clear" icon-only color="dark" size="small" slot="end">
            <ion-icon
              [name]="!!template.config.timeline.hidden ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
          </ion-button>

        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- layers list -->
  <ion-list
    *ngIf="!!ticks && !!template && !!template.config && !!template.config.aspect_ratio && !!template.config.aspect_ratios && !!template.config.aspect_ratios[template.config.aspect_ratio] && !!template.config.aspect_ratios[template.config.aspect_ratio].length"
    [hidden]="!!template.config.timeline.hidden" class="layers-list">
    <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReorderLayers($any($event))">

      <ion-item
        *ngFor="let layer of template.config.aspect_ratios[template.config.aspect_ratio]; let i = index; trackBy:trackItems"
        [style.min-width]="(ticks.length * 150) + 'px'">
        <ion-grid class="layers-grid" [style.min-width]="(ticks.length * 150) + 'px'">

          <ion-row [style.min-width]="(ticks.length * 150) + 'px'">
            <ion-col class="layer-col">

              <ion-card class="layer-card" [style.width]="((layer.settings.end - layer.settings.start) * 150) + 'px'"
                (touchstart)="_onTimelineLayerTouchStart(layer, i, $event)"
                (touchmove)="_onTimelineLayerTouchMove(layer, i, $event)"
                (touchend)="_onTimelineLayerTouchEnd(layer, i, $event)"
                [style.margin-left]="(layer.settings.start * 150) + 'px'" (click)="_onTimelineLayerClick(layer, i)">

                <ion-thumbnail *ngIf="layer.type === 'image' && !!layer.settings.src">
                  <ion-img [src]="layer.settings.src"></ion-img>
                </ion-thumbnail>

                <ion-item lines="none" [class.active]="layer.active">

                  <ion-reorder slot="start"></ion-reorder>

                  <ion-button slot="start" fill="clear" class="visibility-btn" icon-only
                    (click)="toggleLayerVisibility(layer, i)">
                    <ion-icon [name]="layer.hidden ? 'eye-off-outline' : 'eye-outline'"></ion-icon>
                  </ion-button>

                  <ion-button slot="start" fill="clear" class="delete-btn" color="danger" icon-only
                    (click)="deleteLayer(layer, i)">
                    <ion-icon name="trash-outline"></ion-icon>
                  </ion-button>

                  <ion-button slot="start" fill="clear" class="duplicate-btn" color="dark" icon-only
                    (click)="duplicateLayer(layer, i)">
                    <ion-icon name="copy-outline"></ion-icon>
                  </ion-button>

                  <ion-button slot="start" fill="clear" class="effect-btn" color="dark" icon-only
                    (click)="chooseLayerEffect(layer, i)">
                    <ion-icon name="sparkles-outline"></ion-icon>
                  </ion-button>

                  <ion-button slot="start" shape="round" fill="dark" class="enlarge-btn" color="primary" icon-only>
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  </ion-button>

                  <ion-input type="text" [(ngModel)]="layer.name" [placeholder]="'layer_name'|translate"></ion-input>

                </ion-item>

              </ion-card>

            </ion-col>
          </ion-row>

        </ion-grid>
      </ion-item>

    </ion-reorder-group>
  </ion-list>

</ion-toolbar>