import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';

@Component({
  selector: 'heygen-audio-transcribe',
  standalone: false,
  templateUrl: './audio-transcribe.component.html',
  styleUrls: ['./audio-transcribe.component.scss'],
})
export class AudioTranscribeComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() media: mediaItem[];

  fallbackImg: string = './assets/img/fallback.webp';

  languageOptions: languagesSelectOptions = {
    cached: false,
    default: null,
    mode: 'request',
  };

  view: any = {

  };

  constructor(
    private events: EventsService,
    private navCtrl: NavController,
  ) {

  }

  create() {
    this.navCtrl.navigateForward('/media/assets/create');
  }

  doRefresh() {
  }

  initEvents() {
    this.view.events = {};
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initEvents();
  }

  onLanguageChange(event: any|null = null) {

  }

  thumbnailLoadingFailed(item: any) {
    item.thumbnail = this.fallbackImg;
  }

}