import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { TemplatesService } from 'src/app/services/media/templates.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { AiKnowledgebasePage } from '../ai-knowledgebase/ai-knowledgebase.page';

@Component({
  selector: 'app-ai-assistant',
  standalone: false,
  templateUrl: './ai-assistant.page.html',
  styleUrls: ['./ai-assistant.page.scss'],
})
export class AiAssistantPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'chat_completions',
      'image_to_video',
      'text_generation',
      'text_to_image',
    ],
  };

  appConfig: pipelineAppConfig;

  assistant: aiAssistant = {};

  assistantId: number | string;

  cards: any = {

  };

  search: searchOptions = {
    itemsKey: 'assistant',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  template: mediaTemplate;

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    introCard: {
      uid: 'ai_assistant_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      text: 'ai_assistant_top_card_text',
      title: 'ai_assistant_top_card_title',
    },
    multiple: true,
    output: '',
    route: 'ai/assistant',
    segment: 'general',
    showMenuButton: true,
    showProjectsSelect: false,
    showViewModeSelect: false,
    tab: 'idea',
    tabs: [
      {
        icon: 'bulb-outline',
        name: 'recommendations',
        uid: 'idea',
      },
      {
        icon: 'image-outline',
        name: 'assets',
        uid: 'assets',
      },
      {
        icon: 'film-outline',
        name: 'creatives',
        uid: 'creatives',
      },
      {
        icon: 'newspaper-outline',
        name: 'posts',
        uid: 'posts',
      },
    ],
    title: 'ai_assistant',
    viewType: 'grid',
  };

  constructor(
    private ai: AiToolsService,
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private route: ActivatedRoute,
    private templates: TemplatesService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    
    this.assistantId = this.route.snapshot.paramMap.get('assistantId');
  }

  async addKnowledgebaseEntry() {

    const modal: any = await this.modalService.create({
      component: AiKnowledgebasePage,
      componentProps: {
        mode: 'pick',
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then((event: any | null = null) => {
      this.assistant.config = this.assistant.config || {};

      const existingItems: aiKnowledgebaseEntry[] = (this.assistant.config.knowledgebase || []);
      console.log('addKnowledgebaseEntry: existingItems', existingItems);

      const existingItemUids: number[] = existingItems.map((item: aiKnowledgebaseEntry) => {
        return item.uid;
      });
      console.log('addKnowledgebaseEntry: existingItemUids', existingItemUids);

      const items: aiKnowledgebaseEntry[] = (!!event && !!event.data && !!event.data.items ? event.data.items : []);
      console.log('addKnowledgebaseEntry: selected items', items);

      this.assistant.config.knowledgebase = this.assistant.config.knowledgebase || [];

      if (!!items && !!items.length) {
        items.forEach((item: aiKnowledgebaseEntry) => {
          console.log('> item', item);

          if (existingItemUids.indexOf(item.uid) === -1) {
            this.assistant.config.knowledgebase.push(item);
          }
        });
      }

      console.log('addKnowledgebaseEntry: updated assistant.config.knowledgebase', this.assistant.config.knowledgebase);
      //console.log('updated assistant.config.knowledgebase', this.assistant.config.knowledgebase);
    });

    this.modalService.present(modal);
  }

  addUrl() {
    this.view.addUrlMode = true;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);

    this.assistant = this.assistant || {};
    this.assistant.config = this.assistant.config || {};
    this.assistant.config.ai = this.assistant.config.ai || {};
    this.assistant.config.chat = this.assistant.config.chat || {};
    this.assistant.config.knowledgebase = this.assistant.config.knowledgebase || [];
    this.assistant.config.template = this.assistant.config.template || {};
    this.assistant.config.urls = this.assistant.config.urls || [];

    console.log('> assistant', this.assistant);
  }

  deleteKnowledgebaseEntry(entry: aiKnowledgebaseEntry) {

    if (!entry || !entry.uid || !this.assistant.config || !this.assistant.config.knowledgebase) {
      return false;
    }

    this.assistant.config.knowledgebase = this.assistant.config.knowledgebase.filter((_entry: aiKnowledgebaseEntry) => {
      return entry.uid !== _entry.uid;
    });
  }

  deleteSelected() {

  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any | null = null) {
    this.loadAssistant(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  duplicateSelected(event: any | null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'duplicateAssistant',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
      },
      service: this.ai,
    })
      .then(() => {
        //this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  ionViewWillEnter() {
    this.initEvents();

    this.loadAssistant()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  loadAssistant(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      /*
      this.ai.getAssistantByUid(blForceRefresh)
      .then((assistant: aiIdea[]) => {
        this.view.loading = false;
        this.view.assistant = assistant;

        resolve(assistant);
      })
      .catch((error: any) => {
        this.view.loading = false;
        reject(error);
      });
      */
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadTemplate() {
    let template = this.templates.getDefaultConfig();

    //template.compositions = this.templates.getDefaultCompositions();
    template.name = this.assistant.name;

    if (!!this.assistant && !!this.assistant.config && !!this.assistant.config.template) {
      template.config = this.assistant.config.template;
    } else
      if (!!this.view.project && !!this.view.project.config && !!this.view.project.config.Comp) {
        template.config = this.view.project.config;
      }

    this.template = template;
    //console.log('ai-assistant: template', this.template);
  }

  ngOnInit() {
    //console.log('ai-assistant: id', this.assistantId);

    this.calcViewVars();
    this.loadProject();
    this.loadTemplate();
  }

  onItemCheckboxClicked(item: aiKnowledgebaseEntry) {
    this.view.rebuild = true;

    this.view.selectedItems = this.view.items.filter((_item: aiKnowledgebaseEntry) => {
      return _item.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    setTimeout(() => {
      this.view.rebuild = false;
    }, 500);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  async onSegmentChange(event: any) {
    //console.log('onSegmentChange', event);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  save() {
    this.ai.createAssistant(this.assistant)
      .then((response: any) => {
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  submitUrl() {
    this.assistant.config.urls = this.assistant.config.urls || [];

    this.assistant.config.urls.push({
      active: true,
      url: this.view.addUrlInput,
    });

    this.view.addUrlMode = false;
  }

  templateColorVarChanged(event: any | null = null) {
    //console.log('templateColorVarChanged', event);
  }

  templateVarsChanged(event: any | null = null) {

    if (!event || !event.config) {
      return false;
    }

    this.assistant.config.template = event.config;
  }

  toggleActive(entry: any) {
    entry.active = !entry.active;
  }

  update() {
    this.ai.updateAssistant(this.assistant)
      .then((response: any) => {
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}