import { Directive, EventEmitter, Input, ElementRef, HostListener, Output, Renderer2 } from '@angular/core';

import { DropService } from 'src/app/services/core/drop.service';

@Directive({
    selector: '[draggable]',
    standalone: false
})
export class DragDirective {

    private dragClone: HTMLElement | null = null;

    @Input() dragItem: any;

    private offsetX = 0;
    private offsetY = 0;

    @Output() onDragged: any = new EventEmitter();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private dropService: DropService
    ) {
    }

    @HostListener('mousedown', ['$event'])
    onDragStart(event: MouseEvent): void {
        // Prevent default drag behavior
        event.preventDefault();

        // Type guard for event target
        if (!(event.target instanceof HTMLElement)) {
            return;
        }

        // Set dragged item in service
        this.dropService.setDraggedItem(this.dragItem);

        // Get original element dimensions and position
        const originalElement = this.el.nativeElement;
        const elementRect = originalElement.getBoundingClientRect();

        // Calculate offset from cursor to element edge
        this.offsetX = event.clientX - elementRect.left;
        this.offsetY = event.clientY - elementRect.top;

        // Create clone for dragging
        this.dragClone = originalElement.cloneNode(true) as HTMLElement;

        // Set initial styles for clone
        const initialStyles = {
            position: 'fixed', // Use fixed instead of absolute for better positioning
            zIndex: '1000',
            left: '0',
            top: '0',
            width: `${elementRect.width}px`,
            height: `${elementRect.height}px`,
            margin: '0',
            padding: originalElement.style.padding,
            pointerEvents: 'none',
            willChange: 'transform',
            transform: 'translateZ(0)',
            opacity: '0.8',
            transition: 'none',
            boxSizing: 'border-box'
        };

        // Apply styles to clone
        Object.assign(this.dragClone.style, initialStyles);
        this.dragClone.classList.add('dragged-placeholder');
        document.body.appendChild(this.dragClone);

        // Update clone position immediately
        this.updateClonePosition(event);

        // Setup move and up listeners
        const moveListener = this.renderer.listen('document', 'mousemove',
            (moveEvent: MouseEvent) => this.updateClonePosition(moveEvent)
        );

        const upListener = this.renderer.listen('document', 'mouseup', () => {
            moveListener();
            upListener();

            if (this.dragClone) {
                document.body.removeChild(this.dragClone);
                this.dragClone = null;
            }

            this.dropService.clearDraggedItem();

            this.onDragged.emit(true);
        });
    }

    private updateClonePosition(event: MouseEvent): void {
        if (!this.dragClone) return;

        // Calculate new position based on cursor position minus offset
        const x = event.clientX - this.offsetX;
        const y = event.clientY - this.offsetY;

        // Apply transform for smooth movement
        this.dragClone.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    }

}