import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-audio-library',
  standalone: false,
  templateUrl: './audio-library.page.html',
  styleUrls: ['./audio-library.page.scss'],
})
export class AudioLibraryPage implements OnInit {
  @ViewChild('searchInput') searchInput: IonSearchbar;

  appConfig: pipelineAppConfig;

  fallbackImg: string = './assets/img/fallback.webp';

  selectionOptions: selectionOption[] = [
    {
      icon: 'download-outline',
      label: 'download',
      uid: 'download',
    },
    {
      icon: 'cloud-download-outline',
      label: 'import',
      uid: 'import',
    },
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
  ];

  state: state = {};

  view: any = {
    libraryView: {
      colSize: {},
      hideMediaImportCard: true,
      mediaCreator: 'all',
      mediaType: 'audio',
      mediaTypes: [],
      mode: 'full', // full, select
      multiple: true,
      queueViewMode: 'card',
      showLanguagesSelect: true,
      showViewModeSelect: true,
      source: 'audio_library',
      viewType: 'grid',
    },
    options: {
      include_items: false,
      limit: 50,
    },
    route: 'audio/library',
    showLanguagesSelect: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'audio_library',
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  async add(event: any|null = null, params: any = {}) {
    params.audioType = 'creative';
    params.multiple = true;
    params.services = ['ai', 'database', 'upload'];

    if (!!this.view.folder_uid) {
      params.folder_uid = this.view.folder_uid;
    }

    this.media.applyFromWeb(null, params)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  detectChanges() {

  }

  dismiss() {
    this.modalService.dismiss();
  }

  doRefresh() {

  }

  initEvents() {
    this.view.events = {};

    this.view.events.mediaFolderCurrent = this.events.subscribe('audio:folder:current', (folder: folder) => {
      this.view.folder_uid = (!!folder && !!folder.uid ? folder.uid : null);
    });

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.loadProject();
    this.initEvents();
  }

  ionViewWillLeave() {
    this.events.stop(this.view.events);
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngAfterViewInit() {
    this.calcViewVars();
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

}