import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { EventsService } from "src/app/services/core/events.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-ai-prompt-card',
  standalone: false,
  templateUrl: './ai-prompt-card.component.html',
  styleUrls: ['./ai-prompt-card.component.scss']
})
export class AiPromptCardComponent implements AfterViewInit, OnDestroy, OnInit {

  @Input() aiSettings: aiSettings = {};

  @Input() autostart: boolean = false;

  @Input() disabled: boolean = false;

  @Input() input: string = '';

  @Output() onSubmit = new EventEmitter();

  @Input() type: string = 'item';

  view: any = {};

  constructor(
    public ai: AiToolsService,

    private events: EventsService,
    private viewService: ViewService,
  ) {

  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  initEvents() {
    this.view.events = {};
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    if (!!this.autostart && !!this.input) {
    }
  }

  runAiPrompt(event: any = null) {
    this.onSubmit.emit(`${this.input || ''}`);
  }

}