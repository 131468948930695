import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { MetaService } from 'src/app/services/integrations/meta.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  _detailItem: any;

  customSelectionOptions: selectionOption[] = [
    {
      icon: 'logo-facebook',
      label: 'authorize_using_facebook',
      uid: 'authorizeUsingFacebook',
    },
    {
      icon: 'logo-instagram',
      label: 'authorize_using_instagram',
      uid: 'authorizeUsingInstagram',
    }
  ];

  instagramAppId: number = 8247038648651883;

  constructor(
    private AppCMS: AppcmsService,
    private integrations: IntegrationsService,
    private meta: MetaService,
    private oauth: OauthService,
    private userService: UserService,
  ) {

  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("instagram", options, ['analyse']);
  }

  authorize(options: any = {}) {
    return this.authorizeUsingFacebook(options);
    //return this.authorizeUsingInstagram(options);
  }

  authorizeUsingFacebook(options: any = {}) {
    return new Promise((resolve, reject) => {
      const resourceUrl: string = this.AppCMS.getRequestUrl('instagram', ['profile']);
      const fbApiVersion = '20.0';
      
      this.oauth.authorize({
        appId: options.appId || this.meta.fbAppId,
        authorizationBaseUrl: options.authorizationBaseUrl || `https://www.facebook.com/v${fbApiVersion}/dialog/oauth`,

        app: options.app || {
          clientId: this.meta.fbAppId,
          redirectUrl: `${window.location.origin}/integrations/integration/instagram/connections`,
          responseType: 'token',
        },

        default: options.default || {
          clientId: this.meta.fbAppId,
          redirectUrl: `${window.location.origin}/integrations/integration/instagram/connections`,
          responseType: 'token',
        },

        web: options.web || {
          clientId: this.meta.fbAppId,
          redirectUrl: `${window.location.origin}/integrations/integration/instagram/connections`,
          responseType: 'token',
        },

        resourceUrl: options.resourceUrl || resourceUrl,

        scope: options.scope || [

          // instagram:
          'instagram_basic',
          //'instagram_business_basic',
          'instagram_content_publish',
          'instagram_manage_comments',
          'instagram_manage_insights',

          // generic:
          'ads_management',
          'ads_read',
          'business_management',
          'email',
          'leads_retrieval',
          'pages_show_list',
          'pages_manage_engagement',
          'pages_manage_metadata',
          'pages_manage_posts',
          'pages_messaging',
          'pages_read_engagement',
          'pages_read_user_content',
          'public_profile',

        ].join(','),

        state: this.calcState(),
      })
        .then((response: any) => {

          if (!!response && !!response.authorization_response && !!response.authorization_response.long_lived_token) {
            response.access_token = response.authorization_response.long_lived_token;
          }

          resolve(response);
        })
        .catch(reject);
    });
  }

  authorizeUsingInstagram(options: any = {}) {
    return this.authorizeUsingFacebook(Object.assign(options, {
      appId: this.instagramAppId,
      authorizationBaseUrl: 'https://api.instagram.com/oauth/authorize',

      app: {
        clientId: this.instagramAppId,
        redirectUrl: `${window.location.origin}/integrations/integration/instagram/connections`,
        responseType: 'code',
      },

      default: {
        clientId: this.instagramAppId,
        redirectUrl: `${window.location.origin}/integrations/integration/instagram/connections`,
        responseType: 'code',
      },

      web: {
        clientId: this.instagramAppId,
        redirectUrl: `${window.location.origin}/integrations/integration/instagram/connections`,
        responseType: 'code',
      },

      scope: [
        'user_profile',
        'user_media',
      ].join(',')

    }));
  }

  calcState() {
    return Math.random().toString(36).substring(2, 12);
  }

  connect(options: any = {}) {
    return new Promise((resolve, reject) => {

      // first, authorize application
      this.authorize().then((authResponse: any) => {
        console.log('authResponse', authResponse);

        // then, run connect process
        this.integrations.connect(
          Object.assign(options, authResponse)
        ).then((chooseResponse: chooseResponse) => {

          // if connects selected, add them
          if (!!chooseResponse && !!chooseResponse.data && !!chooseResponse.data.items && !!chooseResponse.data.items.length) {
            chooseResponse.data.items.forEach(async (item: integrationConnection) => {
              try {
                let pageToken: string = (item.page_token || ''), userToken: string = (item.user_token || '');

                if (!!authResponse && !!authResponse.access_token) {
                  pageToken = (pageToken || authResponse.access_token);
                }

                if (!!authResponse && !!authResponse.authorization_response && !!authResponse.authorization_response.long_lived_token) {
                  userToken = authResponse.authorization_response.long_lived_token;
                }

                if (!!authResponse && (!!authResponse.access_token_response && !!authResponse.access_token_response.access_token)) {
                  pageToken = (pageToken || authResponse.access_token_response.access_token);
                }

                let refreshToken: string = (`${(item.refresh_token || authResponse.refresh_token) || ''}` || '');

                if (!!authResponse && !!authResponse.access_token_response && !!authResponse.access_token_response.refresh_token) {
                  refreshToken = authResponse.access_token_response.refresh_token;
                }

                const create: any = await this.createConnection({
                  active: true,
                  name: `${item.name || ''}`,
                  page_id: parseInt(`${item.page_id || (item.uid || item.id)}`),
                  photo: `${item.photo || ''}`,
                  url: `${item.url || ''}`,
                  user: this.userService.getUid(),

                  // tokens:
                  page_token: `${item.page_token || pageToken}`,
                  refresh_token: (refreshToken || item.refresh_token),
                  user_token: `${userToken || (item.user_token || '')}`,
                });

              } catch (e) {
                console.warn('adding connection failed', e);
              }
            });
          }

          resolve(chooseResponse);
        }).catch(reject);
      }).catch(reject);
    });
  }

  createConnection(connection: integrationConnection) {
    connection.platform = 'instagram';
    return this.meta.createConnection(connection);
  }

  debugToken(token: string) {
    return this.meta.debugToken(token);
  }
  
  delete(connectionId: number) {
    return this.deleteConnection(connectionId);
  }

  deleteConnection(connectionId: number) {
    return this.meta.deleteConnection(connectionId);
  }

  deletePost(postId: number) {
    return this.meta.deletePost(postId, 'instagram');
  }

  detailItem(item: any | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.meta.getApiRequestLog({
      filter: Object.assign(options, {
        platform: 'instagram',
      }),
    }, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    options = (typeof options === 'object' ? options : {});
    options.filter = options.filter || {};
    options.filter.platform = 'instagram';

    return this.AppCMS.loadPluginData('meta', options, ['connections'], {}, blForceRefresh);
  }

  getCustomSelectionOptions() {
    return this.customSelectionOptions;
  }

  getSubscribedApps(pageId: number, options: any = {}, blForceRefresh: boolean = false) {
    return this.meta.getSubscribedApps(pageId, options, blForceRefresh);
  }

  importComments(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("instagram", options, ['import_comments']);
  }

  importMedia(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("instagram", options, ['import_media']);
  }

  importPosts(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("instagram", options, ['import_posts']);
  }

  subscribeToWebhook(pageId: number, options: any = {}, blForceRefresh: boolean = false) {
    return this.meta.subscribeToWebhook(pageId, options, blForceRefresh);
  }

  updateConnection(connection: integrationConnection) {
    return this.meta.updateConnection(connection);
  }

}