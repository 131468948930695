import { Component, OnInit } from '@angular/core';

import { BlogService } from 'src/app/services/pipeline/blog.service';
import { CitiesService } from 'src/app/services/geo/cities.service';
import { CountriesService } from 'src/app/services/geo/countries.service';
import { ModalService } from "src/app/services/core/modal.service";
import { EventsService } from "src/app/services/core/events.service";
import { ExternalService } from 'src/app/services/extensions/external.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-connect-shop',
  standalone: false,
  templateUrl: './connect-shop.page.html',
  styleUrls: ['./connect-shop.page.scss'],
})
export class ConnectShopPage implements OnInit {

  fallbackImg: string = './assets/img/fallback.webp';

  introOverlayConfig: introOverlayConfig = {
    allowManually: false,
    headline: 'connect_shop',
    input_placeholder: 'shop_url',
    input_type: 'url',
    showAiCreate: true,
    showAiSettings: false,
    showInput: true,
    submitLabel: 'connect',
  };
  
  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    loading: false,
    source: {},
    title: 'connect_shop',
  };

  constructor(
    private blog: BlogService,
    private cities: CitiesService,
    private countries: CountriesService,
    private events: EventsService,
    private external: ExternalService,
    private media: MediaextendService,
    private modalService: ModalService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {

  }

  aiCreate() {

    if(!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    this.view.input = this.view.aiCreateInput;

    this.onUrlChanged();
    this.startManually();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async dismiss(data: any|null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  loadCategories() {
    this.blog.getCategories()
      .then((categories: category[]) => {
        this.view.categories = categories;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.initEvents();
    this.calcViewVars();
    this.loadCategories();
  }

  onSourcePhotoClick() {
    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.view.source.photo = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  onUrlChanged() {
    if (
      this.view.input &&
      (this.view.input.length > 4) &&
      (this.view.input.indexOf('.') !== -1)
    ) {

      const validate: any = this.tools.validateUrl(this.view.input);

      if (!validate.success) {
        return false;
      }

      this.view.loading = true;

      this.external.verifySupportedShopURL(this.view.input, true)
        .then((response: any) => {
          this.view.loading = false;
          this.view.success = !!response.success;
        })
        .catch((error: any) => {
          this.view.loading = false;
          this.view.success = false;
          console.warn('error', error);
        });
    }
  }

  pickCountry() {
    this.countries.pick()
      .then((response: any) => {
        console.log('response', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  pickRegion() {
    this.cities.pick()
      .then((response: any) => {
        let city: city = (response && response.data && response.data.item ? response.data.item : response);

        if (!!city) {
          this.view.source.region = city.city;
          this.view.source.region_uid = city.uid;
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  startManually() {
    this.view.startManually = true;
  }

  submit() {
    this.view.source.url = 'https://' + (this.view.input.replace('https://', '').replace('http://', ''));
    this.view.source.active = true;
    
    this.external.submitSource(this.view.source)
      .then((response: any) => {
        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  thumbnailLoadingFailed() {
    this.view.source.photo = this.fallbackImg;
  }

}