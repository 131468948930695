import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { BrowserService } from "src/app/services/utils/browser.service";
import { ClipboardService } from "src/app/services/utils/clipboard.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-heygen-edit-video',
  standalone: false,
  templateUrl: './heygen-edit-video.page.html',
  styleUrls: ['./heygen-edit-video.page.scss'],
})
export class HeygenEditVideoPage implements OnInit {
  
  fallbackImg: string = './assets/img/fallback.webp';
  
  heygen: any;

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};
  
  view: any = {
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'integration_heygen_edit_video_top_card',
      text: 'integration_heygen_edit_video_top_card_text',
      title: 'integration_heygen_edit_video_top_card_title',
    },
    options: {
    },
    showMenuButton: false,
    showProjectsSelect: false,
    title: 'integration_heygen_edit_video',
  };

  constructor(
    private browser: BrowserService,
    private clipboard: ClipboardService,
    private modalService: ModalService,
    private navParams: NavParams,
    private viewService: ViewService,
  ) {
    let action: string|null = this.navParams.get('action');

    if(!!action) {
      this.view.action = action;
    }
    
    this.heygen = this.navParams.get('heygenService');
    
    let media: any = this.navParams.get('media');

    this.view.media = media || {};
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  copy(string: string) {
    this.clipboard.copyText(string);
  }
  
  dismiss(data: any|null = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any|null = null) {
    
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  onEntryClick(entry: metaApiRequestLogEntry) {
    entry.checked = !entry.checked;
  }

  openURL(url: string) {
    this.browser.create(url);
  }

  submit() {

  }

  thumbnailLoadingFailed(entry: metaApiRequestLogEntry) {
    (entry.data as any).image_url = this.fallbackImg;
  }
}
