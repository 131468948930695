import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { OauthService } from 'src/app/services/core/oauth.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessCentralService {

  constructor(
    private AppCMS: AppcmsService,
    private oauth: OauthService,
  ) {

  }

  authorize() {
    return this.oauth.authorize({
    });
  }

  connect(options: any = {}) {
    return new Promise((resolve, reject) => {

      // first, authorize application
      this.authorize().then((authResponse: any) => {
        console.log('authResponse', authResponse);
        resolve(authResponse);
      }).catch(reject);
    });
  }

  createConnection(connection: integrationConnection) {
    return this.AppCMS.loadPluginData('businesscentral', {
      connection: connection,
    }, ['connections', 'create']);
  }

  delete(connectionId: number) {
    return this.deleteConnection(connectionId);
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('businesscentral', {
      uid: connectionId,
    }, ['connections', 'delete']);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('businesscentral', options, ['connections'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('businesscentral', options, ['settings'], {}, blForceRefresh);
  }

  updateConnection(connection: integrationConnection) {
    connection = JSON.parse(JSON.stringify(connection));

    delete connection.checked;

    return this.AppCMS.loadPluginData('businesscentral', {
      connection: connection,
    }, ['connections', 'update']);
  }

}