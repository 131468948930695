import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";

@Injectable({
    providedIn: 'root'
})
export class AudioService {

    audioTracks: any[] = [
        {
            "name": "01__mazaphonk-music-by-0to8-alexi-action-infraction-128845_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/01__mazaphonk-music-by-0to8-alexi-action-infraction-128845_mixdown.mp3",
            "uid": "01__mazaphonk-music-by-0to8-alexi-action-infraction-128845_mixdown.mp3"
        },
        {
            "name": "02__electronic-future-beats-117997_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/02__electronic-future-beats-117997_mixdown.mp3",
            "uid": "02__electronic-future-beats-117997_mixdown.mp3"
        },
        {
            "name": "03__electronic-rock-king-around-here-15045_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/03__electronic-rock-king-around-here-15045_mixdown.mp3",
            "uid": "03__electronic-rock-king-around-here-15045_mixdown.mp3"
        },
        {
            "name": "04__epic-motivational-trailer-129210_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/04__epic-motivational-trailer-129210_mixdown.mp3",
            "uid": "04__epic-motivational-trailer-129210_mixdown.mp3"
        },
        {
            "name": "05__hip-hop-rock-beats-118000_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/05__hip-hop-rock-beats-118000_mixdown.mp3",
            "uid": "05__hip-hop-rock-beats-118000_mixdown.mp3"
        },
        {
            "name": "06__abstract-world-127012_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/06__abstract-world-127012_mixdown.mp3",
            "uid": "06__abstract-world-127012_mixdown.mp3"
        },
        {
            "name": "07__best-time-112194_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/07__best-time-112194_mixdown.mp3",
            "uid": "07__best-time-112194_mixdown.mp3"
        },
        {
            "name": "08__cyber-war-126419_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/08__cyber-war-126419_mixdown.mp3",
            "uid": "08__cyber-war-126419_mixdown.mp3"
        },
        {
            "name": "09__fun-life-112188_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/09__fun-life-112188_mixdown.mp3",
            "uid": "09__fun-life-112188_mixdown.mp3"
        },
        {
            "name": "10__around-129089_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/10__around-129089_mixdown.mp3",
            "uid": "10__around-129089_mixdown.mp3"
        },
        {
            "name": "11__bounce-114024_mixdown",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/11__bounce-114024_mixdown.mp3",
            "uid": "11__bounce-114024_mixdown.mp3"
        },
        {
            "name": "12__time-technology-11257",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/12__time-technology-11257.mp3",
            "uid": "12__time-technology-11257.mp3"
        },
        {
            "name": "13__optimistic-technology-corporate-uplifting-124595",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/13__optimistic-technology-corporate-uplifting-124595.mp3",
            "uid": "13__optimistic-technology-corporate-uplifting-124595.mp3"
        },
        {
            "name": "14__background-inspiring-corporate-129486",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/14__background-inspiring-corporate-129486.mp3",
            "uid": "14__background-inspiring-corporate-129486.mp3"
        },
        {
            "name": "15__inspiring-cinematic-ambient-116199",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/15__inspiring-cinematic-ambient-116199.mp3",
            "uid": "15__inspiring-cinematic-ambient-116199.mp3"
        },
        {
            "name": "16__this-minimal-technology-pure-12327",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/16__this-minimal-technology-pure-12327.mp3",
            "uid": "16__this-minimal-technology-pure-12327.mp3"
        },
        {
            "name": "17__minimal-inspiring-ambient-121005",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/17__minimal-inspiring-ambient-121005.mp3",
            "uid": "17__minimal-inspiring-ambient-121005.mp3"
        },
        {
            "name": "18__inspirational-background-112290",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/18__inspirational-background-112290.mp3",
            "uid": "18__inspirational-background-112290.mp3"
        },
        {
            "name": "19__corporate-presentation-129500",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/19__corporate-presentation-129500.mp3",
            "uid": "19__corporate-presentation-129500.mp3"
        },
        {
            "name": "20__background-technology-128047",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/20__background-technology-128047.mp3",
            "uid": "20__background-technology-128047.mp3"
        },
        {
            "name": "21__abstract-technology-112288",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/21__abstract-technology-112288.mp3",
            "uid": "21__abstract-technology-112288.mp3"
        },
        {
            "name": "22__timelapse-trip-hop-118726",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/22__timelapse-trip-hop-118726.mp3",
            "uid": "22__timelapse-trip-hop-118726.mp3"
        },
        {
            "name": "23__digital-technology-126323",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/23__digital-technology-126323.mp3",
            "uid": "23__digital-technology-126323.mp3"
        },
        {
            "name": "24__fashion-hip-hop-113774",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/24__fashion-hip-hop-113774.mp3",
            "uid": "24__fashion-hip-hop-113774.mp3"
        },
        {
            "name": "25__no-gravity-128761",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/25__no-gravity-128761.mp3",
            "uid": "25__no-gravity-128761.mp3"
        },
        {
            "name": "26__the-weekend-117427",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/26__the-weekend-117427.mp3",
            "uid": "26__the-weekend-117427.mp3"
        },
        {
            "name": "27__lifelike-126735",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/27__lifelike-126735.mp3",
            "uid": "27__lifelike-126735.mp3"
        },
        {
            "name": "28__motivated-14108",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/28__motivated-14108.mp3",
            "uid": "28__motivated-14108.mp3"
        },
        {
            "name": "29__around-129089",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/29__around-129089.mp3",
            "uid": "29__around-129089.mp3"
        },
        {
            "name": "30__inspiring-confidence-128760",
            "url": "https://api.getgenius.ai/static_cdn/audio/music/30__inspiring-confidence-128760.mp3",
            "uid": "30__inspiring-confidence-128760.mp3"
        }
    ];

    containStrings: string[] = ['youtube.com/embed', '<video'];

    constructor(
        private AppCMS: AppcmsService,
        private media: MediaextendService,
    ) {

    }


    getAudioList(
        options: any = {},
        blForceRefresh: boolean = false,
        params: any = {}
    ) {
        return new Promise((resolve, reject) => {
            let tracks: any[] = this.getAudioTracks(options, blForceRefresh);
            
            if (!!tracks && !!tracks.length) {
                tracks = tracks.map((track: any) => {
                    track.post_title = track.post_title || track.name;
                    track.title = track.title || track.name;
                    track.type = 'audio';

                    return track;
                });
            }

            resolve(tracks);
        });
    }

    getAudioTracks(options: any = {}, blForceRefresh: boolean = false) {
        return this.audioTracks;
    }

}