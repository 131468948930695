import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { AiBridgeService } from '../../../../services/ai/ai-bridge.service';
import { AiToolsService } from 'src/app/services/ai/ai-tools.service';

import { ConfigService } from "src/app/services/core/config.service";
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ParamsService } from 'src/app/services/core/params.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

import { ViewMediaPage } from 'src/app/pages/core/media/media/view-media/view-media.page';
import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-ai-video-creator',
  standalone: false,
  templateUrl: './ai-video-creator.page.html',
  styleUrls: ['./ai-video-creator.page.scss'],
})
export class AiVideoCreatorPage implements AfterContentInit, OnInit {

  aiSettings: aiSettings = {
    //auto_upscale: true,
    context: 'image_to_video',
    upscale_end_result: true,
    upscale_first_frame: false,
    fps: 24,
    //models: ['stabilityai/stable-diffusion-3-medium-diffusers'],
    height: 768, // 480, //576,
    width: 1360, // 720, // 1024,
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'image_to_video',
      'text_generation',
      'text_to_image',
    ],
  };

  @ViewChild('aiVideoCreatorSwiper') swiper: ElementRef | undefined;
  @ViewChild('aiVideoCreatorThumbsSwiper') thumbsSwiper: ElementRef | undefined;

  appConfig: pipelineAppConfig;

  cards: any = {
    information: { open: true },
    output: { open: true },
  };

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  @ViewChild('infoPopover') infoPopover: any;
  @ViewChild('itemInfoPopover') itemInfoPopover: any;

  isInfoPopoverOpen: boolean = false;
  isItemInfoPopoverOpen: boolean = false;

  media: mediaItem = {};

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  sliderOptions: any = {
    autoHeight: true,
    centeredSlides: true,
    spaceBetween: 30,
    parallax: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    speed: 1000,
    slidesPerView: 1,
    watchSlidesProgress: true,
  };

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_working.splinecode',
  };

  state: state = {};

  stateKeys: string[] = ['failed', 'waiting', 'validating', 'starting', 'done'];

  // for timeline in expert mode
  template: any = {
    config: {
      aspect_ratio: '3x2',
      aspect_ratios: {
        '3x2': [],
      },
      timeline: {},
    },
    type: 'video',
  };

  thumbsSliderOptions: any = {
    autoHeight: true,
    centeredSlides: true,
    parallax: false,
    navigation: false,
    freeMode: false,
    watchSlidesProgress: false,
    speed: 1000,
    slidesPerView: (window.innerWidth > 768 ? 6 : 3),
  };

  view: any = {
    animation_editor: {
      keyframes: [],
      type: 'preset',
    },
    aspect_ratio: '3x2',
    aspect_ratios: [
      {
        icon: 'tablet-landscape-outline',
        uid: '16x9',
        name: 'aspect_ratio_16x9',
      },
      {
        checked: true,
        icon: 'tablet-landscape-outline',
        uid: '3x2',
        name: 'aspect_ratio_3x2',
        label: 'suggested',
        labelColor: 'primary',
      },
      {
        icon: 'square-outline',
        uid: '1x1',
        name: 'aspect_ratio_1x1',
      },
      {
        icon: 'tablet-portrait-outline',
        uid: '9x16',
        name: 'aspect_ratio_9x16',
      },
    ],
    buttonAction: 'generate',
    canGenerate: false,
    hideOrderByBtn: true,
    hideSearch: true,
    iMaxSectionFails: 5,
    iVideoLength: 15,
    input: '',
    inputModes: [
      {
        icon: 'text-outline',
        uid: 'text',
        name: 'input_mode_text',
      },
      {
        icon: 'images-outline',
        uid: 'media',
        name: 'input_mode_media',
      }
    ],
    introCard: {
      uid: 'ai_video_creator_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      text: 'ai_video_creator_top_card_text',
      title: 'ai_video_creator_top_card_title',
    },
    multiple: true,
    output: '',
    partLookupUids: [],
    phase: 'edit',
    progress: 0,
    route: 'ai/items',
    sectionIndex: 0,
    sections: [],
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showSplineView: false,
    showViewModeSelect: true,
    tab: 'item',
    tabs: [
      {
        icon: 'bulb-outline',
        name: 'recommendations',
        uid: 'item',
      },
      {
        icon: 'image-outline',
        name: 'assets',
        uid: 'assets',
      },
      {
        icon: 'film-outline',
        name: 'creatives',
        uid: 'creatives',
      },
      {
        icon: 'newspaper-outline',
        name: 'posts',
        uid: 'posts',
      },
    ],
    title: 'ai_video_creator',
    viewType: 'grid',
  };

  constructor(
    private aiBridge: AiBridgeService,
    private aiTools: AiToolsService,

    private configService: ConfigService,
    private dani: DaniService,
    private events: EventsService,
    private mediaService: MediaextendService,
    private modalService: ModalService,
    private params: ParamsService,
    private projects: ProjectsService,
    private sd: StablediffusionService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  accept(section: any, index: number, blWatch: boolean = true) {
    return new Promise((resolve, reject) => {
      console.log('ai-video-creator: accept: section', section);
      console.log('ai-video-creator: accept: index', index);
      console.log('ai-video-creator: accept: blWatch', blWatch);

      // if url is missing, load photo
      if (!section.url) {
        return this.loadMissingSectionPhoto(section, index);
      }

      // handle exit edit mode
      if (section.mode === 'edit') {
        section.mode = 'view';
        this.view.sections[index] = section;
        reject(false);
        return false;
      }

      // else, handle image to video
      this.view.mode = 'generate';

      section.mode = 'accept';
      section.loading = true;
      section.loading_text = 'ai_video_creator_section_loading_image_to_video';
      section.state = 'waiting';

      this.view.sections[index] = section;

      this.aiSettings.width = this.aiSettings.width || 768;
      this.aiSettings.height = this.aiSettings.height || 768;

      let prompt: string = `${!!section.camera_behaviour ? (section.camera_behaviour + ', ') : ''}${section.prompt || ''}`;

      this.events.publish('toast', {
        icon: 'hourglass-outline',
        message: 'ai_image_to_video_process_started',
        color: 'primary',
      });

      console.log('ai-video-creator: accept: set index', (index || 0));

      // define the settings for image to video operation
      const imageToVideoConfig: any = {

        // text prompt
        prompt: prompt,
        negative_prompt: `${section.negative_prompt || ''}`,

        // attributes
        height: this.aiSettings.height,
        width: this.aiSettings.width,

        // used for time calculation
        set_index: (index || 0),
      };

      console.log('ai-video-creator: accept: aiSettings', this.aiSettings);
      console.log('ai-video-creator: accept: imageToVideoConfig', imageToVideoConfig);

      this.aiTools.setConfig(this.aiSettings);

      // execute the image to video execution
      this.sd.imageToVideo(section.photo || section.url, imageToVideoConfig)
        .then((response: any) => {
          console.log('ai-video-creator: accept: image to video response', response);

          // response is a link to media queue which can be watched then
          if (!!response && !!response.item && !!response.item.uid) {
            this.view.partLookupUids.push(response.item.uid);

            section.queue_item_uid = response.item.uid;
            section.state = 'waiting';
            section.uid = section.uid || section.queue_item_uid;

            this.view.sections[index] = section;

            if (!!blWatch) {
              this.watchSingleSection(section, response.item);
            }

          } else {
            section.loading = false;
          }

          if (!!response && !!response.images) {
            this.addResponseImagesToHistory(response.images);
          }

          this.view.ai_tool_selection = null;
          this.view.loading = false;

          resolve(this.view);
        })
        .catch((error: any) => {
          this.view.ai_tool_selection = null;

          section.loading = false;
          section.loading_text = null;

          reject(error);
        });
    });
  }

  acceptAll() {
    return new Promise((resolve, reject) => {

      if (!this.view.sections || !this.view.sections.length) {
        reject(false);
      }

      let iDone: number = 0,
        iAll: number = this.view.sections.length;

      this.view.sections.forEach(async (section: any, index: number) => {
        setTimeout(async () => {
          try {
            await this.accept(section, index, false);

            iDone++;

            console.log('accepted ' + iDone + ' of ' + iAll);

            if (iDone === iAll) {
              resolve(true);
            }
          } catch (e) {
            console.warn('accepting single section failed', e);

            iDone++;

            if (iDone === iAll) {
              resolve(true);
            }
          }
        }, (index * 1000));
      });
    });
  }

  addMediaResponseItemToSection(item: any, section: any) {

    // handle single file selection first
    section.url = item.guid;

    if (section.url.indexOf('.getgenius.') !== -1) {
      section.url = section.url.replace('.mp4', '.webm');
    }
    
    section.type = item.type || section.type;

    return section;
  }

  addResponseImagesToHistory(images: any[]) {
  }

  addSection(data: any = {}) {

    const section: any = Object.assign({
      active: true,
      index: (this.view.sections.length || 0),
      loading: false,
      mode: 'edit',
      prompt: '',
      url: '',
    }, data);

    this.setSections([section].concat(this.view.sections));
    console.log('ai-video-creator: updated view.sections', this.view.sections);

    this.updateSlider();
    this.slideTo(0);

    return section;
  }

  aiCreate() {

    // request alert if changes are made and not saved
    // allow video project saving

    if (!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    this.view.finalResultUrl = null;
    this.view.generating = true;
    this.view.loading = true;
    this.view.progress = 0;
    this.view.showSplineView = true;

    setTimeout(() => {
      this.startManually();
    }, 3500);

    this.dani.executeCreateVideo({
      input: this.view.aiCreateInput,
      video_length: (this.view.iVideoLength || 15),
    })
      .then(async (response: any) => {
        this.startManually();

        this.view.generating = false;
        this.view.loading = false;
        this.view.showSplineView = false;
        this.view.introCard.hidden = true;

        if (!!response && !!response.data) {

          const sections: any[] = (response.data || []).map((section: any) => {
            section.mode = section.mode || 'text';
            return section;
          });

          this.setSections(sections);
          this.updateSlider();
          this.loadMissingSectionPhotos();
        }
      })
      .catch((error: any) => {
        this.view.generating = false;
        this.view.loading = false;
        this.view.showSplineView = false;

        this.events.publish('error', error);
      });
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  amountChanged() {
    this.view.blCustomAmount = true;
  }

  aspectRatioChanged() {
    this.aiSettings = this.aiSettings || {};

    let iWidth: number = 768, iHeight: number = 768, fps: number = (this.aiSettings.fps || 24);
    let iDefaultFps: number = 16;

    this.calcSlidesPerView();

    switch (this.view.aspect_ratio) {
      case '3x2':
        iDefaultFps = 24, iHeight = 768, iWidth = 1360;
        //iDefaultFps = 24, iHeight = 480, iWidth = 720;
        break;
      case '9x16':
        iHeight = 1024, iWidth = 576;
        break;
      case '16x9':
        iHeight = 576, iWidth = 1024;
        break;
    }

    this.aiSettings.fps = fps || iDefaultFps;
    this.aiSettings.height = iHeight || 768;
    this.aiSettings.width = iWidth || 768;

    // update related variables
    if (!!this.view.aspect_ratio) {
      this.view.filters = this.view.filters || {};
      this.view.filters.aspect_ratios = [this.view.aspect_ratio];

      if (!!this.view.templateView) {
        this.view.templateView.aspectRatio = this.view.aspect_ratio;
      }

    }

    this.updateThumbsSlider();
  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  calcInputModeStates() {
    this.view.selectedInputModesList = {};

    this.view.selectedInputModes = (this.view.inputModes || []).filter((mode: any) => {
      this.view.selectedInputModesList[mode.uid] = !!mode.checked;

      return !!mode.checked;
    });

    this.view.hasSelectedInputModes = !!this.view.selectedInputModes.length;
  }

  async calcSliderVars() {
    try {
      this.view.activeIndex = await this.swiper.nativeElement.swiper.activeIndex;

      this.view.canSlideBack = (this.view.activeIndex > 0);
      this.view.canSlideNext = (this.view.sections.length > this.view.activeIndex);
    } catch (e) {
      console.warn('updating swiper failed', e);
    }
  }

  calcSlidesPerView() {

    switch (this.view.aspect_ratio) {
      case '3x2':
        this.thumbsSliderOptions.slidesPerView = (this.view.isDesktop ? 7 : 3);
        break;
      case '9x16':
        this.thumbsSliderOptions.slidesPerView = (this.view.isDesktop ? 12 : 5);
        break;
      case '16x9':
        this.thumbsSliderOptions.slidesPerView = (this.view.isDesktop ? 6 : 3);
        break;
      default:
        this.thumbsSliderOptions.slidesPerView = (this.view.isDesktop ? 8 : 3);
        break;
    }

    console.log('this.thumbsSliderOptions.slidesPerView', this.thumbsSliderOptions.slidesPerView);
  }

  calcTicks() {

    if (!this.template.config.timeline || !this.template.config.timeline.end) {
      return false;
    }

    let iCurrent: number = 0,
      ticks: any[] = [];

    while (iCurrent < this.template.config.timeline.end) {
      iCurrent++;

      ticks.push({
        value: iCurrent,
      });
    }

    this.view.ticks = ticks;
  }

  calcViewStates() {
    this.view.canGenerate = true;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.calcColSize();
  }

  compileParts() {

    const parts: string[] = this.view.sections.map((section: any) => {
      return section.url;
    });

    if (!parts || !parts.length) {
      return false;
    }

    this.view.loading = true;
    this.view.showSplineView = true;

    this.mediaService.compileVideoParts({
      parts: parts,
    })
      .then((response: any) => {
        if (!!response && !!response.url) {
          this.view.loading = false;
          this.view.showSplineView = false;
          this.view.finalResultUrl = response.url;
          this.view.phase = 'done';

          this.media.guid = response.url;
          this.media.type = 'video';
          this.media.videoSrc = response.url;
        }
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.view.phase = 'error';
        this.view.showSplineView = false;

        this.events.publish('error', error);
      });
  }

  delete(section: any, index: number) {
    console.log('delete', section);

    this.setSections(this.view.sections.filter((_section: any, _index: number) => {
      return index !== _index;
    }));

    this.updateSlider();
  }

  dismiss(data: any | null = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any | null = null) {
    this.loadVideoCreator(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  download() {

    if (!this.view.finalResultUrl || !this.view.finalResultUrl.length) {
      return false;
    }

    this.view.downloaded = false;

    this.mediaService.importFromUrl(this.view.finalResultUrl)
      .then((response: any) => {
        console.log('response', response);
        this.view.downloaded = true;
        //this.browser
      })
      .catch((error: any) => {
        this.view.downloaded = false;
        this.events.publish('error', error);
      });
  }

  edit(section: any, index: number) {
    section.mode = 'edit';
    this.view.mode = 'edit';
  }

  editVideoParts() {
    this.view.finalResultUrl = null;
    this.view.phase = 'edit';
  }

  focusSection(section: any, index: number) {
    this.view.showSplineView = false;

    this.view.sections.forEach((_section: any, _index: number) => {
      _section.active = (index === _index);
    });

    this.view.sectionIndex = index;

    // Music
    if (!!this.view.music_types && !!this.view.music_types[0]) {
      this.view.music_types[0].checked = !!(!!section && !!section.background_sound);
    }

    // Voiceover
    this.view.use_tts = !!(!!section && !!section.speaker_text);

    this.slideTo(index);
  }

  async generate() {
    this.view.finalResultUrl = null;
    this.view.partLookupUids = [];

    // first, accept all sections
    await this.acceptAll();

    this.startManually();

    // then, watch queue
    this.watchQueue();
  }

  async imageToText(sectionIndex: number) {

    if (!this.view.sections || !this.view.sections[sectionIndex] || !this.view.sections[sectionIndex].url) {
      return false;
    }

    this.view.sections[sectionIndex].analyzing = true;

    console.log('ai-video-creator: imageToText: section', this.view.sections[sectionIndex]);

    try {
      //const exec: any = await this.sd.imageToText(this.view.sections[sectionIndex].url);

      //if (!!exec && !!exec.caption) {
      //  this.view.sections[sectionIndex].prompt = exec.caption;
      //}

      if (!!this.view.sections[sectionIndex].media_uid) {
        const analyseResponse: any = await this.mediaService.analyse([this.view.sections[sectionIndex].media_uid], {}, false);
        console.log('ai-video-creator: imageToText: analyseResponse', analyseResponse);
      }

      this.view.sections[sectionIndex].analyzing = false;
    } catch (e) {
      console.warn('ai-video-creator: imageToText: error', e);
      this.view.sections[sectionIndex].analyzing = false;
    }
  }

  async importToLibrary() {

    if (!this.view.finalResultUrl || !this.view.finalResultUrl.length) {
      return false;
    }

    this.view.imported = false;

    this.mediaService.importFromUrl(this.view.finalResultUrl)
      .then((response: any) => {
        this.view.imported = (!!response && !!response.success);

        if (!!response && !!response.link_uid) {
          this.media.uid = response.link_uid;
        }

        return this.media;
      })
      .catch((error: any) => {
        this.view.imported = false;
        this.events.publish('error', error);

        return error;
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
      this.updateSlider();
    });
  }

  initSwiper() {
    try {

      this.sliderOptions.thumbs = {
        swiper: this.thumbsSwiper.nativeElement.swiper,
      };

    } catch (e) {
      console.warn('preparing swiper failed', e);
    }
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  itemInfo(image: any, event: any | null = null) {
    this.view.item = image;
    this.itemInfoPopover.event = event;
    this.isItemInfoPopoverOpen = true;
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadMissingSectionPhoto(section: any, index: number, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      section.loading = true;
      section.loading_text = 'ai_video_creator_section_loading_text_to_image';
      section.state = 'waiting';

      this.view.sections[index] = section;

      this.aiSettings.width = this.aiSettings.width || 768;
      this.aiSettings.height = this.aiSettings.height || 768;

      const searchOptions: any = Object.assign(this.aiSettings, {
        blFineTuneInput: true,
        limit: 1,
        negative_prompt: `${section.negative_prompt || ''}`,
        query: `${section.prompt || ''}`,
        request: 'images',
        showStep: (data: any) => {
          console.log('ai-video-creator: showStep: data', data);
        },
        height: this.aiSettings.height, // parseInt(`${this.aiSettings.height * 1.25}`),
        width: this.aiSettings.width, // parseInt(`${this.aiSettings.width * 1.25}`),
      });

      this.aiTools.search(searchOptions, {}, blForceRefresh)
        .then((response: any) => {
          section.loading = false;
          section.loading_text = null;

          this.view.sections[index] = section;

          const suggestion: any = (!!response && !!response.suggestions && !!response.suggestions[0] ? response.suggestions[0] : response);

          if (!!suggestion && !!suggestion.images && !!suggestion.images[0]) {
            section.iFailed = 0;
            section.guid = suggestion.images[0];
            section.photo = suggestion.images[0];
            section.state = 'done';
            section.type = 'image';
            section.url = suggestion.images[0];
            section.mode = 'view';

            // append metadata object
            if (!!suggestion.info) {
              section.info = suggestion.info;
            }

            this.view.sections[index] = section;

            if (!!this.aiSettings && !!this.aiSettings.auto_upscale) {
              this.upscale(section, index, blForceRefresh);
            }

            this.updateSlider();
          } else {
            section.state = 'failed';
          }

          resolve(section);
        })
        .catch((error: any) => {
          section.loading = false;
          section.loading_text = null;

          this.view.sections[index] = section;

          section.iFailed = section.iFailed || 0;
          section.iFailed++;

          if (section.iFailed < this.view.iMaxSectionFails) {
            this.loadMissingSectionPhoto(section, index, blForceRefresh).then(resolve).catch(reject);
          } else {
            resolve(section);
          }
        });
    });
  }

  loadMissingSectionPhotos() {

    if (!this.view.sections || !this.view.sections.length) {
      return false;
    }

    this.view.iCount = this.view.sections.length;
    this.view.iDone = 0;
    this.view.progress = 0;

    this.view.sections.forEach((section: any, index: number) => {
      setTimeout(async () => {

        if (!section.photo || !section.photo.length) {
          await this.loadMissingSectionPhoto(section, index);
        }

        this.view.iDone++;
        this.view.progress = ((100 / this.view.iCount) * this.view.iDone / 100);

        // reset progress if done
        if (this.view.progress === 1) {
          setTimeout(() => {
            this.view.progress = 0;
          }, 250);
        }

      }, (index * 1000));
    });
  }

  loadQueue() {
    return new Promise(async (resolve, reject) => {

      // first, try accepting all items before throwing an error
      if (!this.view.partLookupUids || !this.view.partLookupUids.length) {
        console.warn('empty parts list, accepting all items');
        await this.acceptAll();
      }

      // if still no part uids exist, throw error
      if (!this.view.partLookupUids || !this.view.partLookupUids.length) {
        reject('error_missing_part_lookup_uids');
      } else {
        // otherwise, queue + build parts

        //this.view.iCount = this.view.partLookupUids.length;
        this.view.iCount = this.view.sections.length;
        this.view.phase = 'loading';

        this.aiBridge.getQueue(true, {
          filter: {
            user_uid: this.userService.getUid(),
          },
          lookup_uids: this.view.partLookupUids,
        })
          .then((queue: mediaQueueItem[]) => {
            try {

              // sort queue by states + url existence
              queue.sort((a, b) => this.stateKeys.indexOf(b.state) - this.stateKeys.indexOf(a.state))
                .sort((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0;
                });

              // apply results to sections
              if (!!queue && !!queue.length) {
                queue.forEach((queueItem: any) => {

                  let matchingSection: any | null = null,
                    matchingSectionIndex: number | null = null;

                  this.view.sections.forEach((section: any, sectionIndex: number) => {
                    if (section.queue_item_uid === queueItem.uid) {
                      matchingSection = section;
                      matchingSectionIndex = sectionIndex;
                    }
                  });

                  if (!!queueItem.url && !!matchingSection && (!!matchingSection.loading || (!matchingSection.url || (matchingSection.type !== 'video')))) {
                    matchingSection.loading = false;
                    matchingSection.loading_text = null;
                    matchingSection.state = queueItem.state || matchingSection.state;
                    matchingSection.type = 'video';
                    matchingSection.url = queueItem.url;
                    matchingSection.uid = matchingSection.uid || matchingSection.queue_item_uid;

                    if (!!matchingSection.url) {
                      matchingSection.state = 'done';
                    }

                    if (!!this.view.showSplineView && (matchingSectionIndex !== null)) {
                      this.focusSection(matchingSection, matchingSectionIndex);
                    }

                    if (matchingSectionIndex !== null) {
                      this.view.sections[matchingSectionIndex] = matchingSection;
                      this.view.iDone++;
                      this.view.progress = ((100 / this.view.iCount) * this.view.iDone) / 100;

                      if (this.view.iDone === this.view.iCount) {
                        this.onPartsCreated();
                      }
                    }
                  } else
                    if (!!matchingSection && (!!matchingSection.loading || (!matchingSection.url || (matchingSection.type !== 'video'))) && !!queueItem.state) {
                      matchingSection.state = queueItem.state;
                    }

                });
              }

              resolve(this.view);
            } catch (e) {
              console.warn('updating queue failed', e);
              resolve(this.view);
            }
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  }

  loadVideoCreator(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.loading = true;

      await this.loadProject();

      this.view.loading = false;

      resolve(this.view);
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadUIParams() {
    const createParams: any = this.params.get('viewData_createVideo');
    console.log('createParams', createParams);

    if (!createParams) {
      return false;
    }

    if (!!createParams.search) {
      this.search = Object.assign(this.search, createParams.search);
    }

    if (!!createParams.view) {
      this.view = Object.assign(this.view, createParams.view);
    }

    let imageToTextRequests: number = 0;

    // apply media from view params
    if (!!this.view && !!this.view.mediaList) {
      this.view.mediaList = this.view.mediaList.slice(0, 100);

      this.view.mediaList.reverse().forEach((mediaItem: mediaItem) => {
        console.log('mediaItem', mediaItem);

        let prompt: string = '';

        // iterate attributes first
        if (!!mediaItem && !!mediaItem.attributes) {
          mediaItem.attributes.forEach((attribute: any) => {
            if ((attribute.key === 'caption' || attribute.uid === 'caption') && !!attribute.value) {
              prompt = attribute.value;
            }
          });
        }

        // if prompt is empty and tags are defined, create prompt on tags
        if ((!prompt || !prompt.length) && (!!mediaItem && !!mediaItem.tags && !!mediaItem.tags.length)) {
          prompt = this.tools.trim(mediaItem.tags.map((tag: any) => {
            return (tag.title || tag.indent);
          }).join(', '));
        }

        const section: any = {
          headline: mediaItem.title,
          media_uid: (mediaItem.ID || mediaItem.uid),
          mode: 'view',
          prompt: prompt,
          state: 'done',
          type: mediaItem.type,
          url: mediaItem.guid,
        };

        console.log('section', section);

        this.addSection(section);

        // interrogate image if no caption found
        if (!prompt || !prompt.length) {
          imageToTextRequests++;

          const sectionIndex: number = this.view.sections.length - 1;

          setTimeout(() => {
            this.imageToText(sectionIndex);
          }, (imageToTextRequests * 250));
        }
      });
    }

    setTimeout(() => {
      if (!!createParams.fire) {
        this.generate();
      } else {
        this.startManually();
      }
    }, 100);
  }

  mediaItemActionsToolbarButtonClicked(event: any | null = null) {
    switch (event.uid) {
      case 'addResponseImagesToHistory':
        //this.addResponseImagesToHistory(event.images || []);
        break;
      case 'addResponseQueueItemToHistory':
        //this.addResponseQueueItemToHistory(event.item || {});
        break;
      case 'inpaint':
        //this.inpaint();
        break;
      case 'useAsPrimary':
        //this.useAsPrimary(event.item, event.index || 0);
        break;
    }
  }

  newProject() {
    this.media = {};
    this.setSections([]);
    this.view.startManually = false;
  }

  ngAfterContentInit() {
    this.initSwiper();
  }

  ngOnInit() {
    this.calcViewVars();

    this.loadCards();
    this.loadProject();

    this.loadUIParams();
  }

  onItemChecked(item: mediaTemplate) {

    this.view.selectedItems = this.view.items.filter((_item: mediaTemplate) => {
      return _item.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onMusicSettingsChanged(settings: any) {

  }

  onPartsCreated() {
    this.view.phase = 'presenting';
    this.view.progress = 0;

    this.stopWatcher();
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

  }

  onTimelineLayerClick(event: any) {
    console.log('ai-video-creator: onTimelineLayerClick: event', event);
    
    if(event.hasOwnProperty('index') && !!this.view.sections[event.index]) {
      this.view.sectionIndex = (event.index || 0);
    }
  }

  onVoiceoverSettingsChanged(settings: any) {

  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  qualityChanged() {

  }

  async rebuildSection(section: any, index: number) {
    console.log('ai-video-creator: reubildSection: section', section);

    // if type is video, rebuild using image to video
    if (section.type === 'video') {
      return this.accept(section, index, false);
    }

    // else, rebuild using image to image
    this.view.sections[index] = section;

    const lookup: any = await this.loadMissingSectionPhoto(section, index, true);
    console.log('ai-video-creator: reubildSection: lookup', lookup);

  }

  runAiPrompt(event: any = null) {

  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  setSections(sections: any[] = []) {
    this.view.sections = (sections || []);

    const iVideoLength: number = 4;

    if (!!this.template && !!this.template.config && !!this.template.config.aspect_ratios && !!this.template.config.aspect_ratio) {

      this.template.config.aspect_ratios[this.template.config.aspect_ratio] = this.view.sections.map((section: any, index: number) => {
        section.settings = section.settings || {};
        section.settings.src = section.url;
        section.settings.start = (!!index ? ((index * iVideoLength) - index) : 0);
        section.settings.end = (section.settings.start + iVideoLength);

        console.log('> section', section);

        return section;
      });

      this.template.config.timeline = this.template.config.timeline || {};
      this.template.config.timeline.end = this.view.sections.length * iVideoLength;

      this.calcTicks();
    }
  }

  setVideoLength(iLength: number) {
    this.view.iVideoLength = iLength;
    this.view.iRequiredSections = parseInt(`${(iLength / 4) + 1}`);
  }

  async shareFinalVideo() {
    await this.importToLibrary();
    this.viewMedia(this.media);
  }

  async slideBack() {

    try {
      this.swiper.nativeElement.swiper.slidePrev();
      this.calcSliderVars();
    } catch (e) {
      console.warn('updating swiper failed', e);
    }
  }

  slidechangetransitionend(event: any) {
    this.calcSliderVars();
  }

  slidechangetransitionstart(event: any) {
    this.calcSliderVars();
  }

  slideTo(index: number) {
    setTimeout(() => {

      try {
        this.swiper.nativeElement.swiper.slideTo(index);
      } catch (e) {
        console.warn('updating swiper failed', e);
      }

      /*
      try {
        this.thumbsSwiper.nativeElement.swiper.slideNext();
      } catch(e) {
        console.warn('updating swiper failed', e);
      }
      */

    });
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.canSlideBack = false;
    this.view.canSlideNext = true;
    this.view.startManually = true;

    this.calcSlidesPerView();
  }

  slideNext() {
    setTimeout(async () => {

      try {
        this.swiper.nativeElement.swiper.slideNext();
        this.calcSliderVars();
      } catch (e) {
        console.warn('updating swiper failed', e);
      }

      /*
      try {
        this.thumbsSwiper.nativeElement.swiper.slideNext();
      } catch(e) {
        console.warn('updating swiper failed', e);
      }
      */

    });
  }

  stopWatcher() {
    try {
      clearInterval(this.view.refreshInterval);
    } catch (e) {

    }

    this.view.loading = false;
    this.view.showSplineView = false;
  }

  tabChanged() {

  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  toggleAspectRatio(aspect_ratio: any, iAspectRatio: number) {

    // legacy, use multi support:
    this.view.aspect_ratios.forEach((_aspect_ratio: any) => {
      _aspect_ratio.checked = false;
    });

    aspect_ratio.checked = !aspect_ratio.checked;

    this.view.aspect_ratio = aspect_ratio.uid;

    // update template
    this.template = this.template || {};
    this.template.config = this.template.config || {};
    this.template.config.aspect_ratio = this.view.aspect_ratio;

    this.template.config.aspect_ratios = this.template.config.aspect_ratios || {
      '1x1': [],
      '3x2': [],
      '4x3': [],
      '9x16': [],
      '16x9': [],
    };

    this.template.config.aspect_ratios[this.template.config.aspect_ratio] = this.template.config.aspect_ratios[this.template.config.aspect_ratio] || [];

    this.aspectRatioChanged();
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  toggleInputMode(mode: any, section: any) {
    section.mode = 'edit';
    section.type = 'image';

    // choose media from uploader
    if (mode.uid === 'media') {
      this.mediaService.applyFromWeb(null, {})
        .then((response: any) => {
          this.calcViewStates();

          if (!!response && !!response.items && !!response.items[0] && !!response.items[0].guid) {

            this.addMediaResponseItemToSection(response.items[0], section);

            // handle multi file selection if set
            if (!!response && !!response.items && !!response.items && (response.items.length > 1)) {
              response.items.forEach((item: any, itemIndex: number) => {

                if (!itemIndex) {
                  return;
                }

                const itemSection: any = this.addSection();

                this.addMediaResponseItemToSection(item, itemSection);
              });

            }
          }
        })
        .catch((error: any) => {
          if (!!error) {
            this.events.publish('error', error);
          }
        });
    }

    this.calcInputModeStates();
  }

  toggleLinkSectionWithNext(section: any) {
    section.linkWithNext = !section.linkWithNext;
  }

  updateSlider() {
    setTimeout(() => {

      // update main slider
      try {
        if (!!this.swiper && !!this.swiper.nativeElement) {
          this.swiper.nativeElement.swiper.update();
        }
      } catch (e) {
        console.warn('updating swiper failed', e);
      }

      // update thumbs slider
      this.updateThumbsSlider();
    });
  }

  updateThumbsSlider() {
    try {
      if (!!this.thumbsSwiper && !!this.thumbsSwiper.nativeElement) {
        this.thumbsSwiper.nativeElement.swiper.update();
      }
    } catch (e) {
      console.warn('updating swiper failed', e);
    }
  }

  upscale(section: any, index: number, blForceRefresh: boolean = false) {

    if (!section || !section.url || !section.url.length) {
      return false;
    }

    section.loading = true;
    section.loading_text = 'ai_image_is_upscaling';
    section.state = 'waiting';

    this.view.sections[index] = section;

    this.sd.upscale(section.url)
      .then((response: any) => {
        section.loading = false;
        section.loading_text = null;
        section.state = 'done';

        if (!!response && !!response.images && !!response.images[0]) {
          section.url = response.images[0];
        } else
          if (!!response && !!response.outputFile) {
            section.url = response.outputFile;
          }

        this.view.sections[index] = section;
      })
      .catch((error: any) => {
        console.warn('upscaling failed', error);

        section.loading = false;
        section.loading_text = null;
        section.state = 'failed';

        this.view.sections[index] = section;
      });
  }

  async viewMedia(mediaItem: any) {
    mediaItem.guid = mediaItem.guid || mediaItem.url;
    mediaItem.post_mime_type = mediaItem.post_mime_type || mediaItem.type;
    mediaItem.thumbnail = mediaItem.thumbnail || mediaItem.photo;

    const modal: any = await this.modalService.create({
      component: ViewMediaPage,
      componentProps: {
        mode: 'view',
        media: mediaItem,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

  watchQueue() {
    this.stopWatcher();

    this.view.loading = true;
    this.view.showSplineView = true;

    this.view.iDone = 0;

    this.watchQueue_Interval();

    this.view.refreshInterval = setInterval(() => {
      this.watchQueue_Interval();
    }, (10 * 1000));
  }

  watchQueue_Interval() {
    this.loadQueue()
      .then(() => {
        this.view.loadingQueue = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    if (this.view.queueTimeIndex < 5) {
      this.view.queueTimeIndex++;
    } else {
      this.view.queueTimeIndex = 1;
    }
  }

  watchSingleSection(section: any, queueItem: any) {
    if (!!queueItem.uid) {
      this.view.partLookupUids = [queueItem.uid];
      this.watchQueue();
    }
  }

}