import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { GoogleextendService } from 'src/app/services/integrations/googleextend.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  constructor(
    private AppCMS: AppcmsService,
    private google: GoogleextendService,
    private integrations: IntegrationsService,
    private oauth: OauthService,
    private userService: UserService,
  ) {

  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("youtube", options, ['analyse']);
  }

  authorize() {
    const accessTokenRoute: string = this.AppCMS.getRequestUrl('youtube', ['authorized']);
    const redirectUrl: string = `${window.location.origin}/integrations/integration/youtube/connections`;
    const resourceUrl: string = this.AppCMS.getRequestUrl('youtube', ['profile']);

    return this.oauth.authorize({
      authorizationBaseUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
      accessTokenEndpoint: accessTokenRoute,
      default: {
        clientId: this.google.getClientId(),
        redirectUrl: redirectUrl,
        responseType: 'token',
      },
      web: {
        appId: this.google.getClientId(),
        responseType: "token", // implicit flow
        accessTokenEndpoint: "", // clear the tokenEndpoint as we know that implicit flow gets the accessToken from the authorizationRequest
        redirectUrl: redirectUrl,
        windowOptions: "height=600,left=0,top=0"
      },
      include_granted_scopes: true,
      resourceUrl: resourceUrl,
      scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
      state: this.calcState(),
    });
  }

  calcState() {
    return Math.random().toString(36).substring(2, 12);
  }

  connect(options: any = {}) {
    return new Promise((resolve, reject) => {

      // first, authorize application
      this.authorize().then((authResponse: any) => {
        let pageToken: string | null = null,
          refreshToken: string | null = null,
          userToken: string | null = null;

        console.log('youtube: authResponse', authResponse);

        if (!!authResponse && !!authResponse.access_token) {
          pageToken = authResponse.access_token;
          userToken = authResponse.access_token;
        }

        if (!!authResponse && !!authResponse.refresh_token) {
          refreshToken = authResponse.refresh_token;
        }

        // then, run connect process
        this.integrations.connect(
          Object.assign(options, authResponse)
        ).then((chooseResponse: chooseResponse) => {

          // if connects selected, add them
          if (!!chooseResponse && !!chooseResponse.data && !!chooseResponse.data.items && !!chooseResponse.data.items.length) {
            chooseResponse.data.items.forEach(async (item: integrationConnection) => {
              try {

                const create: any = await this.createConnection({
                  active: true,
                  name: `${item.name || ''}`,
                  page_id: (item.page_id || (item.id || item.uid)),
                  page_token: `${item.page_token || (pageToken || '')}`,
                  photo: `${item.photo || ''}`,
                  platform: 'youtube',
                  refresh_token: `${item.refresh_token || (refreshToken || '')}`,
                  url: `${item.url || ''}`,
                  user: this.userService.getUid(),
                  user_token: `${item.user_token || (userToken || '')}`,
                });

              } catch (e) {
                console.warn('adding connection failed', e);
              }
            });
          }

          resolve(chooseResponse);
        }).catch(reject);
      }).catch(reject);
    });
  }

  createConnection(connection: integrationConnection) {
    return this.google.createConnection(connection);
  }

  delete(connectionId: number) {
    return this.deleteConnection(connectionId);
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('youtube', {
      uid: connectionId,
    }, ['connections', 'delete']);
  }

  deletePost(postId: number) {
    return this.google.deletePost(postId, 'youtube');
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.google.getApiRequestLog({
      filter: Object.assign(options, {
        platform: 'youtube',
      }),
    }, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return this.google.getConnections(Object.assign(options, {
      platform: 'youtube',
    }), blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.google.getSettings(options, blForceRefresh);
  }

  importComments(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("youtube", options, ['import_comments']);
  }

  importMedia(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("youtube", options, ['import_media']);
  }

  importPosts(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("youtube", options, ['import_posts']);
  }

  updateConnection(connection: integrationConnection) {
    connection = JSON.parse(JSON.stringify(connection));

    delete connection.checked;

    return this.AppCMS.loadPluginData('youtube', {
      connection: connection,
    }, ['connections', 'update']);
  }

}