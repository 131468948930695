<ion-card class="sidebar-accordion-card" *ngIf="!!view">

  <ion-card-header>

    <ion-card-title>
      <span [innerHTML]="'music'|translate"></span>
    </ion-card-title>

    <ion-button *ngIf="!!cards && !!cards.music" class="card-toggle" (click)="toggleCard()" icon-only color="dark"
      fill="clear">
      <ion-icon [name]="!!cards && !!cards.music && !!cards.music.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
    </ion-button>

  </ion-card-header>

  <ion-card-content [hidden]="!cards || !cards.music || !cards.music.open">
    <p [innerHTML]="'music_card_text'|translate"></p>
  </ion-card-content>

  <ion-grid [hidden]="!cards || !cards.music || !cards.music.open" class="types-grid">
    <ion-row>

      <ion-col size="6" *ngFor="let type of view.music_types; let iType = index;">

        <ion-button class="types-btn" [class.active]="type.checked" size="block"
          [fill]="type.checked ? 'solid' : 'outline'" color="primary" (click)="toggleType(type, iType)">
          <ion-icon [name]="type.icon"></ion-icon>
          <ion-label [innerHTML]="type.name|translate"></ion-label>
        </ion-button>

      </ion-col>

    </ion-row>
  </ion-grid>

  <ion-list [hidden]="!cards || !cards.music || !cards.music.open">

    <!-- Input prompt -->
    <ion-item [hidden]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked" lines="none">
      <ion-icon name="text-outline" slot="start"></ion-icon>
      <ion-textarea [disabled]="options.disabled" [rows]="view.isDesktop ? 3 : 6" [(ngModel)]="options.input"
        [placeholder]="'music_input'|translate"></ion-textarea>
    </ion-item>

    <!-- Music provider -->
    <ion-item [hidden]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked"
      [disabled]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked" lines="none"
      class="ion-text-wrap">
      
      <ion-select [(ngModel)]="view.music_provider" interface="popover"
        [disabled]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked"
        [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [label]="'music_provider'|translate"
        (ionChange)="musicProviderChanged()">
        <ion-select-option *ngFor="let provider of view.music_providers" [value]="provider"
          [innerHTML]="provider.name|translate"></ion-select-option>
      </ion-select>

    </ion-item>

    <!-- Track -->
    <ion-item
      [hidden]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked || !view.music_provider"
      [disabled]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked" lines="none"
      *ngIf="!!view.music_tracks && !!view.music_tracks.length" class="ion-text-wrap">

      <ion-select [(ngModel)]="view.music_track" interface="popover"
        [disabled]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked"
        [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [label]="'music_track'|translate"
        (ionChange)="musicChanged()">
        <ion-select-option *ngFor="let track of view.music_tracks" [value]="track"
          [innerHTML]="track.name|translate"></ion-select-option>
      </ion-select>

      <ion-button slot="end" fill="clear" color="dark" (click)="chooseTrack()">
        <ion-icon name="settings-outline"></ion-icon>
      </ion-button>

    </ion-item>

    <!-- Preview -->
    <ion-item
      [hidden]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked || !options || !options.input"
      [disabled]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked" lines="none">
      <ion-label [innerHTML]="'audio_preview_label'|translate"></ion-label>

      <ion-spinner [hidden]="!view.loadingMusic"></ion-spinner>

      <ion-button slot="end" fill="clear"
        [disabled]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked" color="primary"
        (click)="playPreview()" [hidden]="!!view.loadingMusic || !!view.playing">
        <ion-icon name="play"></ion-icon>
      </ion-button>

      <ion-button slot="end" fill="clear"
        [disabled]="!view.music_types || !view.music_types[0] || !view.music_types[0].checked" color="primary"
        (click)="stopPreview()" [hidden]="!!view.loadingMusic || !view.playing">
        <ion-icon name="stop"></ion-icon>
      </ion-button>

    </ion-item>

  </ion-list>

  <audio #musicAudioPlayer id="musicAudioPlayer" [hidden]="!cards || !cards.music || !cards.music.open" *ngIf="!view.loadingMusic && !!view.music_preview_file"
    controls autoplay [src]="view.music_preview_file"></audio>

</ion-card>