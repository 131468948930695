import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class CohereService {

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  analyse(connectionIds: number[], options: any = {}) {
    options = options || {};
    options.uids = (connectionIds || []);
    return this.AppCMS.loadPluginData("cohere", options, ['analyse']);
  }

  calcState() {
    return Math.random().toString(36).substring(2, 12);
  }

  createConnection(connection: integrationConnection) {
    return this.AppCMS.loadPluginData('cohere', {
      connection: connection,
    }, ['connections', 'create']);
  }

  deleteConnection(connectionId: number) {
    return this.AppCMS.loadPluginData('cohere', {
      uid: connectionId,
    }, ['connections', 'delete']);
  }
  
  getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('cohere', options, ['log'], {}, blForceRefresh);
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false): Promise<any> {
    return this.AppCMS.loadPluginData('cohere', options, ['connections'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('cohere', options, ['settings'], {}, blForceRefresh);
  }

  updateConnection(connection: integrationConnection) {
    connection = JSON.parse(JSON.stringify(connection));

    delete connection.checked;

    return this.AppCMS.loadPluginData('cohere', {
      connection: connection,
    }, ['connections', 'update']);
  }

}