import { Component, OnInit } from '@angular/core';

import { ModalService } from "src/app/services/core/modal.service";
import { EventsService } from "src/app/services/core/events.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';

import { ProjectPage } from 'src/app/pages/core/projects/project/project.page';

@Component({
  selector: 'app-folder-settings',
  standalone: false,
  templateUrl: './folder-settings.page.html',
  styleUrls: ['./folder-settings.page.scss'],
})
export class FolderSettingsPage implements OnInit {

  folder: folder;

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    route: '/media/folder/edit',
    segment: 'general',
    showMenuButton: false,
    showProjectsSelect: false,
  };

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
  ) {

  }

  addProject() {
    /*
    this.projectsService.pick({
      multiple: true,
    })
    .then((projects: project[]) => {
      console.log('> projects', projects);
      this.doRefresh();
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
    */
  }

  calcViewVars() {
    this.view.isModal = this.modalService.isModal();
    this.view.title = `${!!this.folder && !!this.folder.title ? this.folder.title : 'create_folder'}`;
  }

  async createProject() {

    const modal: any = await this.modalService.create({
      component: ProjectPage,
    });

    this.modalService.present(modal);

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh() {
    this.loadAccess(true);
  }

  loadAccess(blForceRefresh: boolean = false) {
    console.log('> load access', blForceRefresh);
  }

  ngOnInit() {
    this.calcViewVars();
  }

  update() {
    this.media.updateFolder(this.folder)
      .then(() => {
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  updateFolderLanguage(event: any | null = null) {
    console.log('updateFolderLanguage', event);
    //this.folder.language = event;
  }

}
