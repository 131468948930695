<ion-card class="sidebar-accordion-card statistics-card">

  <ion-card-header>

    <ion-card-title>
      <span [innerHTML]="'statistics'|translate"></span>
    </ion-card-title>

    <ion-button *ngIf="!!cards && !!cards.statistics" class="card-toggle" (click)="toggleCard()" icon-only color="dark"
      fill="clear">
      <ion-icon [name]="!!cards && !!cards.statistics && !!cards.statistics.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
    </ion-button>

  </ion-card-header>

  <ion-grid [hidden]="!!cards && !!cards.statistics && !cards.statistics.open">
    <ion-row>

      <!-- Comments -->
      <ion-col size="6">
        <ion-card>
          <ion-card-header>
            <h1 [innerHTML]="!!view.loading || !!view.loadingComments ? '...' : (!!view.comments && !!view.comments.length ? view.comments.length : 0)">...</h1>
            <ion-card-subtitle [innerHTML]="'comments'|translate"></ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </ion-col>

      <!-- Likes -->
      <ion-col size="6">
        <ion-card>
          <ion-card-header>
            <h1 [innerHTML]="!!view.statistics && !!view.statistics.likes ? view.statistics.likes : 0">...</h1>
            <ion-card-subtitle [innerHTML]="'likes_count'|translate"></ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </ion-col>

      <!-- Shares -->
      <ion-col size="6">
        <ion-card>
          <ion-card-header>
            <h1 [innerHTML]="!!view.statistics && !!view.statistics.shares ? view.statistics.shares : 0">...</h1>
            <ion-card-subtitle [innerHTML]="'shares_count'|translate"></ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </ion-col>

      <!-- Views -->
      <ion-col size="6">
        <ion-card>
          <ion-card-header>
            <h1 [innerHTML]="!!view.statistics && !!view.statistics.views ? view.statistics.views : 0">...</h1>
            <ion-card-subtitle [innerHTML]="'views_count'|translate"></ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </ion-col>

    </ion-row>
  </ion-grid>

  <!--
  <pipeline-chart *ngIf="!!view.expertMode && !!view.statistics && !!view.statistics.length && !!view.chart"
    [chart]="view.chart"></pipeline-chart>

  <pipeline-no-entries-card *ngIf="!view.chart || (!view.statistics || !view.statistics.length)"
    [hidden]="!!view.loading"></pipeline-no-entries-card>
  -->

</ion-card>