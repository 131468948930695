<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!tasks && !!tasks.length">

      <!-- Save -->
      <ion-button shape="round" [fill]="view.isSaveMode ? 'solid' : 'clear'"
        [color]="view.isSaveMode ? 'primary' : 'dark'" (click)="save()" [disabled]="!view.canSave"
        [disabled]="!!view.isSaveMode && !view.task.name">
        <ion-icon [name]="view.isSaveMode ? 'checkmark-outline' : 'save-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate" [hidden]="!view.isSaveMode && !view.isDesktop"></ion-label>
      </ion-button>

      <!-- Run automation -->
      <ion-button fill="solid" shape="round" *ngIf="!view.isSaveMode && !view.running" color="primary"
        [disabled]="!view.canRun">
        <ion-icon name="play" slot="start"></ion-icon>
        <ion-label [innerHTML]="'run'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

      <!-- Stop execution -->
      <ion-button fill="solid" shape="round" *ngIf="!view.isSaveMode && !!view.running" color="primary"
        (click)="stopSet()">
        <ion-icon name="stop" slot="start"></ion-icon>
        <ion-label [innerHTML]="'stop'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Create automation benchmark intro mode -->
  <pipeline-intro-overlay *ngIf="!!view.task && !view.task.uid && !view.startManually && (!tasks || !tasks.length)"
    [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions" [config]="introOverlayConfig"
    [hidden]="!!view.startManually" [(view)]="view" (onInput)="onOverlayInputChanged($event)">
  </pipeline-intro-overlay>

  <!-- Intro card -->
  <div class="container" *ngIf="!view.isDesktop && !view.introCard.hidden">
    <div class="ion-padding">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>
  </div>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <!-- Main container -->
  <div class="container slider-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <div class="main-view">

      <ion-grid class="main-grid">

        <ion-row>

          <!-- Sidebar -->
          <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

            <!-- Source -->
            <div class="col-inner">
              <ion-card>

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('source')">
                    <span [innerHTML]="'source'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('source')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.source && !!cards.source.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.source && !cards.source.open">

                  <ion-card-content>
                    <p [innerHTML]="'ai_task_benchmark_source_text'|translate"></p>
                  </ion-card-content>

                  <!-- Tasks list -->
                  <ion-list *ngIf="!!tasks && !!tasks.length">

                    <ion-item *ngFor="let task of tasks">
                      <ion-checkbox [(ngModel)]="task.checked" justify="start" labelPlacement="end"
                        class="ion-text-wrap">
                        <ion-label [innerHTML]="task.name" class="ion-text-wrap"></ion-label>
                      </ion-checkbox>
                    </ion-item>

                  </ion-list>

                </div>

              </ion-card>
            </div>

            <!-- AI settings -->
            <pipeline-ai-settings-card [(cards)]="cards" [(config)]="aiSettings" [options]="aiSettingsOptions"
              [(view)]="view"></pipeline-ai-settings-card>

          </ion-col>

          <!-- Content -->
          <ion-col [size]="view.isDesktop ? 9 : 12">

            <!-- Intro card -->
            <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="view.introCard.hidden" class="introCard"
              [(view)]="view.introCard"></pipeline-intro-card>

            <!-- Loading spinner -->
            <ion-spinner [hidden]="!view.loading"></ion-spinner>

            <!-- Preview -->
            <ion-card class="preview-card" [hidden]="!!view.loading">

              <ion-card-header>
                <ion-card-title [innerHTML]="'preview'|translate"></ion-card-title>
              </ion-card-header>

            </ion-card>

            <ion-card *ngFor="let task of tasks">

              <!-- Table view -->
              <pipeline-table-view *ngIf="!!task && !!task.children" (itemsChanged)="onItemsChanged($event)"
                [(items)]="task.children" [buttons]="buttons" [fields]="view.dataKeys" [method]="view.method"
                mode="view" [(view)]="view" [options]="tableViewOptions" [service]="aiWorker"></pipeline-table-view>

            </ion-card>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>