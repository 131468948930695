import { Injectable } from '@angular/core';

import { MenuService } from "src/app/services/core/menu.service";

import { AccountPage } from 'src/app/pages/core/account/account/account.page';
import { AdsAdminPage } from 'src/app/pages/core/ads/ads-admin/ads-admin.page';

import { AiAssistantsPage } from 'src/app/pages/core/ai/ai-assistants/ai-assistants.page';
import { AiBrowserPage } from 'src/app/pages/core/ai/ai-browser/ai-browser.page';
import { AiCodesPage } from 'src/app/pages/core/ai/ai-codes/ai-codes.page';
import { AiKnowledgebasePage } from 'src/app/pages/core/ai/ai-knowledgebase/ai-knowledgebase.page';
import { AiModelsPage } from 'src/app/pages/core/ai/ai-models/ai-models.page';
import { AiPlannerPage } from 'src/app/pages/core/ai/ai-planner/ai-planner.page';
import { AiPlansPage } from 'src/app/pages/core/ai/ai-plans/ai-plans.page';
import { AiTasksPage } from 'src/app/pages/core/ai/ai-tasks/ai-tasks.page';
import { AiTrainingPage } from 'src/app/pages/core/ai/ai-training/ai-training.page';
import { AiTextToAudioPage } from 'src/app/pages/core/ai/ai-text-to-audio/ai-text-to-audio.page';
import { AiTtsPage } from 'src/app/pages/core/ai/ai-tts/ai-tts.page';
import { AiUrlLookupPage } from 'src/app/pages/core/ai/ai-url-lookup/ai-url-lookup.page';
import { AiVideoCreatorPage } from 'src/app/pages/core/ai/ai-video-creator/ai-video-creator.page';

import { CreatorBlogsPage } from 'src/app/pages/extensions/blog-admin/creator-blogs/creator-blogs.page';
import { CreateMediaPage } from 'src/app/pages/core/media/media/create-media/create-media.page';
import { CreatorShopsPage } from 'src/app/pages/extensions/shop/creator-shops/creator-shops.page';
import { CreatorStatisticsPage } from 'src/app/pages/core/statistics/creator-statistics/creator-statistics.page';
import { CustomersAdminPage } from 'src/app/pages/core/user/customers/customers-admin/customers-admin.page';
import { EditPostPage } from 'src/app/pages/core/post/edit-post/edit-post.page';
import { IntegrationsPage } from 'src/app/pages/integrations/integrations/integrations.page';
import { MailCrawlerBlacklistPage } from 'src/app/pages/extensions/mail/mail-crawler-blacklist/mail-crawler-blacklist.page';
import { MailCrawlersPage } from 'src/app/pages/extensions/mail/mail-crawlers/mail-crawlers.page';
import { MailInboxPage } from 'src/app/pages/extensions/mail/mail-inbox/mail-inbox.page';
import { MediaLibraryPage } from 'src/app/pages/core/media/media/library/media-library.page';
import { MediaTemplatesPage } from 'src/app/pages/core/media/templates/templates/media-templates.page';
import { NewslettersPage } from 'src/app/pages/extensions/newsletters/newsletters/newsletters.page';
import { OrdersPage } from 'src/app/pages/extensions/shop/orders/orders.page';
import { ProjectsPage } from 'src/app/pages/core/projects/projects/projects.page';
import { SettingsPage } from 'src/app/pages/core/account/settings/settings.page';
import { ShopCategoriesPage } from 'src/app/pages/extensions/shop/shop-categories/shop-categories.page';
import { ShopProductsPage } from 'src/app/pages/extensions/shop/shop-products/shop-products.page';
import { TeamsPage } from 'src/app/pages/core/user/teams/teams/teams.page';
import { TranslationsPage } from 'src/app/pages/core/translations/translations/translations.page';
import { UserGroupsPage } from 'src/app/pages/core/user/user-groups/user-groups/user-groups.page';
import { WhitelabelAppsPage } from 'src/app/pages/core/apps/whitelabel-apps/whitelabel-apps.page';

@Injectable({
    providedIn: 'root'
})
export class WindowManagerLoaderService {

    componentsByUids: any;

    tools: appPage[];

    constructor(
        private menu: MenuService,
    ) {

    }

    applyComponentsToTools() {
        return new Promise((resolve, reject) => {
            try {

                if (!!this.tools && !!this.tools.length) {
                    this.tools.forEach((tool: appPage) => {
                        if (!!tool.key && this.componentsByUids.hasOwnProperty(tool.key)) {
                            tool.component = this.componentsByUids[tool.key];
                        } else {
                            console.warn('[ WINDOW MANAGER ] Tool not supported: ', tool);
                        }
                    });
                }

                resolve(true);
            } catch (e) {
                reject(e);
            }
        });
    }

    by(key: string, value: string) {
        return new Promise(async (resolve, reject) => {
            try {

                if (!this.componentsByUids) {
                    await this.init();
                }

                if (!this.tools) {
                    reject('error_missing_tools');
                    return false;
                } else {

                    const lookup: appPage[] = this.tools.filter((_tool: appPage) => {
                        return _tool[key] === value;
                    });

                    resolve(!!lookup && !!lookup[0] ? lookup[0] : null);
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    byRoute(route: string) {
        return this.by('url', route);
    }

    byUid(uid: string) {
        return this.by('uid', uid);
    }

    init() {
        return new Promise(async (resolve, reject) => {
            try {
                await this.loadAvailableAppPages();
                await this.initComponentsByRoutes();

                resolve(true);
            } catch (e) {
                reject(e);
            }
        });
    }

    initComponentsByRoutes() {
        return new Promise(async (resolve, reject) => {
            try {

                this.componentsByUids = this.componentsByUids || {
                    account: AccountPage,
                    ads: AdsAdminPage,
                    ai_assistants: AiAssistantsPage,
                    ai_browser: AiBrowserPage,
                    ai_codes: AiCodesPage,
                    ai_knowledgebase: AiKnowledgebasePage,
                    ai_models: AiModelsPage,
                    ai_planner: AiPlannerPage,
                    ai_tasks: AiTasksPage,
                    ai_training_log: AiTrainingPage,
                    ai_url_lookup: AiUrlLookupPage,
                    ai_video_creator_length_60: AiVideoCreatorPage,
                    apps: WhitelabelAppsPage,
                    campaigns: AiPlansPage,
                    create_media: CreateMediaPage,
                    create_post: EditPostPage,
                    creators_blog_admin: CreatorBlogsPage,
                    creators_shop_admin: CreatorShopsPage,
                    customers: CustomersAdminPage,
                    integrations: IntegrationsPage,
                    mail_blacklist: MailCrawlerBlacklistPage,
                    mail_crawlers: MailCrawlersPage,
                    mail_inbox: MailInboxPage,
                    media_library: MediaLibraryPage,
                    media_templates: MediaTemplatesPage,
                    newsletters: NewslettersPage,
                    orders: OrdersPage,
                    settings: SettingsPage,
                    shop_categories: ShopCategoriesPage,
                    shop_products: ShopProductsPage,
                    text_to_audio: AiTextToAudioPage,
                    text_to_speech: AiTtsPage,
                    translations_admin: TranslationsPage,
                    statistics_admin: CreatorStatisticsPage,
                    projects_admin: ProjectsPage,
                    teams: TeamsPage,
                    user_groups_admin: UserGroupsPage,
                };

                await this.applyComponentsToTools();

                resolve(true);
            } catch (e) {
                reject(e);
            }
        });
    }

    async loadAvailableAppPages(blForceRefresh: boolean = false) {
        this.tools = (await this.menu.getAvailableAppPages(blForceRefresh) || []) as appPage[];
    }

}